Ext.define('FieldServices.view.report.background.BackgroundView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.background.BackgroundViewController',
        'Shared.Components.field.DateTimeField',
        'FieldServices.view.widgets.filters.DateRangeFilter',
    ],

    xtype: 'backgroundReportForm',

    controller: 'backgroundViewController',

    items: [{
        xtype: 'fieldset',
        itemId: 'reportBox',
        title: '<b>Report</b> <small>Get current usage statistics for subscribing companies</small>',
        style: {
            width: '600px',
            background: 'white',
            margin: '10px auto',
        },
        items: [{
            xtype: 'fieldset',
            itemId: CONSTANTS.ADMIN_REPORT.GROUP.USAGE_STATISTICS.ID,
            title: '<b>Get usage statistics for subscribers</b>',
            extraFields: ['subscribers', 'churnedSubscribers', 'usageStatsDateRange_from', 'usageStatsDateRange_to'],
            items: [{
                xtype: 'checkboxfield',
                checked: true,
                name: 'subscribers',
                label: 'include subscribers to report',
                labelWidth: 'auto',
            }, {
                xtype: 'checkboxfield',
                name: 'churnedSubscribers',
                label: 'include churned subscribers to report',
                labelWidth: 'auto',
            }, {
                xtype: 'dateRangeFilter',
                title: 'Date Range:',
                name: 'usageStatsDateRange',
                layout: 'hbox',
                mode: 'date',
                labelAlign: 'top',
            }],
        }, {
            xtype: 'textareafield',
            label: 'Email(s) <small>(separator ; (Semicolon))</small>',
            labelWidth: 'auto',
            maxRows: 4,
            name: 'emails',
            placeholder: 'xx@powerednow.com;yy@powerednow.com',
        }, {
            xtype: 'checkboxfield',
            name: 'isShowFormData',
            label: 'is show form data in email(s)',
            labelWidth: 'auto',
        }, {
            xtype: 'button',
            itemId: 'sendButton',
            text: 'Send',
        }],
    }],
});

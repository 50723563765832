Ext.define('FieldServices.store.ClientErrorInfo', {
    extend: 'FieldServices.store.Aggregate',
    requires: ['FieldServices.model.ClientErrorInfo'],
    alias: 'store.clientErrorInfo',

    config: {
        model: 'FieldServices.model.ClientErrorInfo',
    },
    storeId: 'clientErrorInfo',

});

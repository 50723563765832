Ext.define('FieldServices.view.components.address.AddressView', {
    extend: 'Ext.grid.Grid',

    xtype: 'addressView',

    itemConfig: {
        viewModel: true,
    },

    height: 50,
    margin: '10 0 0 10',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'grideditable',
        enableDeleteButton: false,
    }],

    selectable: false,

    emptyText: 'empty',

    columns: [{
        text: 'Address id',
        dataIndex: 'id',
    }, {
        text: 'Country',
        dataIndex: 'country',
        editable: true,
    }, {
        text: 'County',
        dataIndex: 'county',
        editable: true,
    }, {
        text: 'city',
        dataIndex: 'city',
        editable: true,
    }, {
        text: 'Postcode',
        dataIndex: 'postcode',
        editable: true,
    }, {
        text: 'state',
        dataIndex: 'state',
        editable: true,
    }, {
        text: 'address1',
        dataIndex: 'address1',
        flex: 1,
        editable: true,
    }, {
        text: 'address2',
        dataIndex: 'address2',
        editable: true,
    }, {
        text: 'isDeleted',
        dataIndex: 'isdeleted',
        renderer: 'rendererIsDeleted',
        defaultEditor: {
            xtype: 'checkbox',
        },
        editable: true,
    }],
});

const config = require('config');

Ext.define('FieldServices.view.company.user.edit.LoginWithUserModalViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.loginWithUserModalViewController',
    xtype: 'loginWithUserModalViewController',

    control: {
        noButton: {
            tap: 'onNoButtonTap',
        },
        yesButton: {
            tap: 'onYesButtonTap',
        },
        rememberMe: {
            change: 'onChangeRememberMe',
        },
        urlField: {
            focus: 'onUrlFieldFocus',
        },
        newTabButton: {
            tap: 'onNewTabButtonTap',
        },
    },

    config: {
        token: null,
        appBaseUrl: null,
        rememberMe: 1,
    },

    init(view) {
        this.setToken(view.config.token);
        this.config.appBaseUrl = view.config.appBaseUrl;
        this._changeUrlFieldValue();
    },

    onNoButtonTap() {
        this.getView().fireEvent('answer', false);
    },

    onYesButtonTap() {
        Utilities.openNewWindow(this._getUrl());
        this.getView().fireEvent('answer', true);
    },

    onChangeRememberMe(elem, newValue) {
        this.setRememberMe(newValue ? 1 : 0);
        this._changeUrlFieldValue();
    },

    onNewTabButtonTap() {
        Utilities.openNewTab(this._getUrl());
        this.getView().fireEvent('answer', true);
    },

    onUrlFieldFocus() {
        this.getView().down('#urlField').select();
    },

    _changeUrlFieldValue() {
        this.getView().down('#urlField').setValue(this._getUrl());
    },

    _getUrl() {
        let baseUrl = `${config.APP_BASE_URL}desktop.html`;
        if (this.config.appBaseUrl) {
            baseUrl = this.config.appBaseUrl;
        }
        return `${baseUrl}#autoLogin/${this.getToken()}/${this.getRememberMe()}`;
    },

});

Ext.define('FieldServices.view.report.salesReport.SalesReportBaseGrid', {
    extend: 'Ext.grid.Grid',

    xtype: 'salesReportBaseGrid',

    layout: 'auto',

    variableHeights: true,
    plugins: [{
        type: 'gridexporter',
    }],

    bind: {
        masked: '{salesReportLoading}',
    },
    itemConfig: {
        viewModel: true,
    },
    emptyText: true,

    config: {
        dataKeys: [],
        overrideColumns: null,
        columnWidthOverrides: null,
        store: null,
    },
    discardMeasureRow: true,

    constructor(config) {
        config.columns = this.getColumnDefs(config);
        this.callParent(arguments);
    },

    getColumnDefs(config) {
        const generatedColumns = [];
        config.dataKeys.forEach(key => {
            const columnFlex = config.columnWidthOverrides && config.columnWidthOverrides[key] ? config.columnWidthOverrides[key] : 1;
            if (['company_id', 'company_name', 'currency'].includes(key)) {
                generatedColumns.push({
                    ...this.getBaseColumnDef(key, columnFlex),
                    text: this.transformKeyToTitle(key),
                });
                return;
            }
            if (config.overrideColumns && Object.keys(config.overrideColumns).includes(key)) {
                return;
            }
            if (key.includes('_amount') || key.includes('_total')) {
                return;
            }
            const cleanedKey = key.replace('_count', '');
            const amountKey = config.dataKeys.filter(dataKey => [`${cleanedKey}_amount`, `${cleanedKey}_total`].includes(dataKey));
            const mainColumnDef = this.getBaseColumnDef('', columnFlex);
            delete mainColumnDef.dataIndex;
            mainColumnDef.text = this.transformKeyToTitle(cleanedKey);
            mainColumnDef.height = 'auto';
            mainColumnDef.menu = null;
            mainColumnDef.columns = [
                {
                    ...this.getBaseColumnDef(key, columnFlex / 2),
                    text: '#',
                    cls: ['full-text-cell', 'text-small'],
                    height: 'auto',
                    cell: {
                        cls: ['full-text-cell', 'text-small'],
                    },
                },
                {
                    ...this.getBaseColumnDef(amountKey, columnFlex / 2),
                    text: 'Amount',
                    flex: 1.5,
                    cls: ['full-text-cell', 'text-small'],
                    height: 'auto',
                    cell: {
                        cls: ['full-text-cell', 'text-small'],
                    },
                },
            ];
            generatedColumns.push(mainColumnDef);
        });
        if (config.overrideColumns) {
            Object.keys(config.overrideColumns).forEach(dataKey => {
                const columnConfig = config.overrideColumns[dataKey];
                if (columnConfig !== 'skip') {
                    const insertIndex = generatedColumns.findIndex(columnDef => columnDef.dataIndex === columnConfig.insertAfter);
                    generatedColumns.splice(insertIndex + 1, 0, columnConfig.columnDef);
                }
            });
        }

        return generatedColumns;
    },

    getBaseColumnDef(dataIndex, columnFlex) {
        return {
            cls: 'full-text-cell',
            dataIndex,
            flex: columnFlex,
            height: 100,
            resizable: false,
            cell: {
                encodeHtml: false,
                cls: 'full-text-cell',
            },
        };
    },

    transformKeyToTitle(key) {
        const titleWords = key.split('_').filter(word => word !== 'is' && word !== 'count');
        return titleWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },

});

Ext.define('FieldServices.view.company.settings.chargeBee.ChargeBeeViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.chargeBeeViewModel',

    data: {
        loaded: false,
        chargeBeeData: null,
    },
    formulas: {
        isCompanyInChargeBee: {
            bind: '{chargeBeeData}',
            get(chargeBeeData) {
                return chargeBeeData && chargeBeeData.chargeBeeId;
            },
        },
        companyMovedTo: {
            bind: '{chargeBeeData}',
            get(chargeBeeData) {
                return chargeBeeData && chargeBeeData.moved;
            },
        },
        statusData: {
            bind: {
                data: '{chargeBeeData}',
                companyMovedTo: '{companyMovedTo}',
                isCompanyInChargeBee: '{isCompanyInChargeBee}',
            },
            get({ data, companyMovedTo, isCompanyInChargeBee }) {
                return {
                    status: companyMovedTo ? `Moved to ${companyMovedTo}` : isCompanyInChargeBee ? 'Connected' : 'Not connected',
                    chargeBeeId: data && data.chargeBeeId && !companyMovedTo ? data.chargeBeeId : 'N/A',
                };
            },
        },
    },
});

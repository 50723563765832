import { ADMIN_REPORT } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.usageReport.UsageReportViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.usageReportViewController',
    xtype: 'usageReportViewController',

    control: {
        gridTabs: true,
        filterComponent: {
            changeFilter: 'loadReportData',
            exportRequested: 'onExportRequested',
        },

    },

    async loadReportData({ filterData, includeChurned }) {
        this.getViewModel().setData({ usageReportLoading: true });
        try {
            const apiResponse = await FieldServices.app.callAPI(
                {
                    url: 'api/admindata/usageReport/getUsageHistoryDataForMonth',
                    method: 'GET',
                    params: { filter: Ext.encode(filterData) },
                    headers: {},
                },
            );

            const apiResponseData = apiResponse || [];
            this.processAllReportGroups(apiResponseData, includeChurned);
            this.getViewModel().setData({ usageReportLoading: false });
        } catch (error) {
            handleClientError(error);
        }
    },

    processAllReportGroups(apiResponseData, includeChurned) {
        const groupConfigs = ADMIN_REPORT.USAGE_HISTORY_GROUPS;
        const reportConfigs = [
            { dataKeys: groupConfigs.COMPANY_DATA, storeName: 'UsageReportCompanyData', diffNeeded: false },
            { dataKeys: groupConfigs.COMPANY_SETTINGS, storeName: 'UsageReportCompanySettings', diffNeeded: true },
            { dataKeys: groupConfigs.COMPANY_ACTIVITY, storeName: 'UsageReportCompanyActivity', diffNeeded: true },
        ];
        const { selectedMonthData, previousMonthData } = apiResponseData;
        const filteredSelected = selectedMonthData.filter(responseRow => includeChurned || responseRow.active_purchases > 0);
        reportConfigs.forEach(config => {
            if (config.diffNeeded) {
                this.processDataWithDiffs(filteredSelected, previousMonthData, config.dataKeys, config.storeName);
            } else {
                this.processDataWithoutDiffs(filteredSelected, config.dataKeys, config.storeName);
            }
        });
    },

    processDataWithoutDiffs(responseRows, dataKeys, storeName) {
        const processedData = responseRows.map(responseRow => {
            const resultObject = {};
            dataKeys.forEach(key => {
                resultObject[key] = responseRow[key];
            });
            return resultObject;
        });
        Ext.getStore(storeName).setData(processedData);
    },

    processDataWithDiffs(selectedMonthData, previousMonthData, dataKeys, storeName) {
        const processedData = selectedMonthData.map(responseRow => {
            const resultObject = {};
            const previousMonthValues = previousMonthData.filter(record => record.company_id === responseRow.company_id)[0];
            dataKeys.forEach(key => {
                resultObject[key] = responseRow[key];
                if (key !== 'company_id' && key !== 'company_name' && key !== 'currency') {
                    const previousValue = previousMonthValues ? previousMonthValues[key] || 0 : 0;
                    resultObject[`${key}_diff`] = responseRow[key] - (previousValue || 0);
                }
            });
            return resultObject;
        });
        Ext.getStore(storeName).setData(processedData);
    },

    onExportRequested() {
        const activeTab = this.getGridTabs().getActiveItem();
        const reportName = activeTab.tab.config.title.replace(' ', '_');
        const reportDate = this.getFilterComponent().getDisplayValue();
        activeTab.saveDocumentAs({ fileName: `${reportName} ${reportDate}.xlsx` });
    },

});

Ext.define('FieldServices.overrides.grid.HeaderContainer', {
    override: 'Ext.grid.HeaderContainer',

    onColumnSort(column, direction, newDirection) {
        if (direction !== null) {
            this.fireEvent('columnsort', this, column, direction, newDirection);
        } else {
            this.fireEvent('columnsortRemoved', this, column);
        }
    },
});

Ext.define('Shared.Components.field.yearMonthPicker.YearMonthPicker', {
    extend: 'Ext.Panel',
    xtype: 'widget.powerednow.yearmonthpicker',
    requires: [
        'Ext.panel.YearPicker',
        'Shared.Components.field.yearMonthPicker.MonthPicker',
    ],
    config: {
        value: null,
        maxDate: null,
        minDate: null,
        format: null, 
    },

    publishes: {
        value: true,
    },
    focusable: true,

    layout: 'fit',
    viewModel: {
        data: {
            width: 0,
            height: 0,
            maxDate: null,
            minDate: null,
            needsMonthPicker: true,
        },
        formulas: {
            pickerWidth: {
                bind: {
                    fullWidth: '{width}',
                    needsMonthPicker: '{needsMonthPicker}',
                },
                get({ fullWidth, needsMonthPicker }) {
                    return needsMonthPicker ? (fullWidth / 2) - 5 : fullWidth;
                },
            },
            maxYear: {
                bind: '{maxDate}',
                get(maxDate) {
                    return maxDate.getFullYear();
                },
            },
            lastMonth: {
                bind: {
                    selectedYear: '{yearpickerfield.selection}',
                    maxDate: '{maxDate}',
                },
                get({ selectedYear, maxDate }) {
                    if (selectedYear && selectedYear.id === maxDate.getFullYear()) {
                        return maxDate.getMonth();
                    }
                    return null;
                },
            },
        },
    },
    bind: {
        height: '{height}',
        width: '{width}',
    },

    listeners: {
        painted: 'onPainted',
    },
    
    items: [{
        xtype: 'yearpicker',
        itemId: 'yearpickerfield',
        reference: 'yearpickerfield',
        top: 0,
        left: 0,
        bottom: 0,
        focusable: true,
        listeners: {
            yeartap: 'onYearSelect',
            scope: 'owner',
        },
        bind: {
            width: '{pickerWidth}',
            end: '{maxYear}',
        },
    }, {
        xtype: 'widget.powerednow.monthpicker',
        itemId: 'monthpickerfield',
        top: 0,
        bottom: 0,
        focusable: true,
        listeners: {
            monthtap: 'onMonthSelect',
            scope: 'owner',
        },
        bind: {
            lastMonth: '{lastMonth}',
            width: '{pickerWidth}',
            left: '{pickerWidth + 10}',
            hidden: '{!needsMonthPicker}',
        },
    },
        
    ],
    
    onRender() {
        this.callParent();
        this.adjustSizeToParent();
        this.ownerField.on('resize', this.adjustSizeToParent, this);
    },

    doDestroy() {
        this.ownerField.removeListener('resize', this.adjustSizeToParent, this);
        this.callParent();
    },

    onPainted() {
        this.focusValues(this.ownerField.getValue());
    },

    adjustSizeToParent() {
        const ownerFieldSize = this.ownerField.lastSize;
        this.getViewModel().setData({
            width: ownerFieldSize.width,
            height: ownerFieldSize.height * 4,
        });
    },
    
    setMaxDate(value) {
        this.getViewModel().setData({ maxDate: value });
    },
    
    setValue(value) {
        value.setDate(1);
        this.ownerField.setValue(value);
        this.ownerField.fireEvent('change');
        this.callParent([value]);
    },
    
    setFormat(value) {
        const needsMonthPicker = value.match(/m/i);
        this.getViewModel().setData({ needsMonthPicker });
        if (!needsMonthPicker) {
            const value = this.getValue() || this.ownerField.getValue();
            value.setMonth(0);
            this.setValue(value);
        }
    },

    focusValues(value) {
        const yearField = this.items.get('yearpickerfield');
        const monthField = this.items.get('monthpickerfield');
        if (yearField.isRendered() && monthField.isRendered()) {
            yearField.focusYear(value.getFullYear());
            monthField.focusMonth(value.getMonth());
        }
    },

    setValueWithoutAnim(value) {
        this.setValue(value);
    },

    onYearSelect(field, value) {
        const monthField = this.items.get('monthpickerfield');
        field.focusYear(value);
        this.getValue().setYear(value);
        monthField.focusMonth(0);
        if (!this.getViewModel().get('needsMonthPicker')) {
            this.confirmSelection();
        }
    },

    onMonthSelect(field, value) {
        field.focusMonth(value);
        this.getValue().setMonth(value);
        this.confirmSelection();
    },
    
    confirmSelection() {
        this.fireEvent('select', this, this.getValue());
        this.ownerField.fireEvent('change');
        this.ownerField.completeEdit();
    },
});

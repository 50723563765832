import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.UserProfileRoleCustom', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.UserProfileRoleCustom'],
    alias: 'store.UserProfileRoleCustom',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.UserProfileRoleCustom',
        storeId: 'UserProfileRoleCustom',
        remoteFilter: true,
        remoteSort: true,
        extraKeys: {
            byUserProfileRoleCustomId: 'id',
            byRoleIdAndUserProfileId: {
                keyFn: item => `${item.data.role_id}__${item.data.userprofile_id}`,
            },
        },
        byRoleIdAndUserProfileIdSeparator: '__',
    },
    storeId: 'UserProfileRoleCustom',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/UserProfileRoleCustom`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },
    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

Ext.define('FieldServices.view.logs.popup.MongoPopupView', {
    extend: 'Ext.Panel',

    xtype: 'mongo.popupList',
    requires: ['FieldServices.view.logs.popup.MongoPopupViewController'],
    controller: 'mongo.popup',

    store: 'mongos',
    itemId: 'MongoPopupId',

    scrollable: true,

    keyMap: {
        ESC: {
            handler: 'onCancelMongoPopup',
            scope: 'controller',
            event: 'keydown',
        },
    },

    tools: [{
        type: 'close',
        handler: 'onCancelMongoPopup',
    }],

    title: 'File',
    tpl: Ext.create('Ext.XTemplate', '<div class="userSelectable json4">{json4}</div>'),
    items: [
        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->', {
                xtype: 'button',
                text: 'Cancel',
                iconCls: 'ion-ios-close-outline',
                handler: 'onCancelMongoPopup',
            }],
        },
    ],

});

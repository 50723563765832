/**
 *
 * @class Override.Button
 * @extends Ext.Button
 *
 */

Ext.define('FieldServices.overrides.Tool', {
    override: 'Ext.Tool',
    /**
     * Use ionicons as icon source for tools' icons.
     * @param type
     * @param oldType
     */
    updateType(type, oldType) {
        const me = this;
        const baseCls = 'ion';
        const { iconElement } = me;

        me.type = type;
        iconElement.replaceCls(oldType && (`${baseCls}-${oldType}`),
            type && (`${baseCls}-${type}`));
    },
});

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.prompt.PromptsView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.prompt.PromptsViewController',
        'FieldServices.view.prompt.PromptsViewModel',
        'FieldServices.view.prompt.list.PromptsListView',
        'FieldServices.view.prompt.list.PromptsListViewController',
        'FieldServices.view.prompt.edit.PromptsFormView',
        'FieldServices.view.prompt.edit.PromptsFormViewController',
    ],

    controller: 'prompt.main',

    xtype: 'prompt.main',

    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },

    header: false,
    viewModel: 'prompt.main',

    items: [{
        xtype: 'prompt.grid',
        reference: 'promptsGrid',
        flex: 1,
        title: 'Prompts <small>(Changes will not be sent to users until the value of the prompt in TableVersion is increased)</small>',
        ui: '-prompt',
        loadStoreWhenVisible: true,
    }],
});

import ADMIN_CONSTANTS from 'client/admin/constants';

const CONSTANTS = require('@powerednow/shared/constants');

const { STATUSES } = CONSTANTS.FORMS;

Ext.define('FieldServices.view.company.formDocument.FormDocumentListView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.company.formDocument.FormDocumentListViewController',
        'FieldServices.view.company.formDocument.FormDocumentInfoPopUp',
        'FieldServices.view.company.formDocument.FormDocumentListItemViewModel',
    ],

    xtype: 'formDocumentListView',
    controller: 'formDocumentListViewController',

    itemConfig: {
        viewModel: {
            type: 'formDocumentListItemViewModel',
        },
        bind: {
            ui: '{ui}',
        },
    },

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'formDocumentFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/FormDocumentWithHeaders',
            params: {
                sort: [{
                    property: 'isdeleted',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    operator: '=',
                    value: '{companyId}',
                }, {
                    value: [true, false],
                    property: 'isdeleted',
                    operator: 'in',
                }],
                requiredFields: ['company_id', 'isdeleted', 'min_app_version', 'max_app_version', 'is_archived'],
            },
        },
        dateRangeFilters: [{
            property: 'dt_created',
            label: 'created',
        }, {
            property: 'issue_date',
            label: 'Issue date',
        }],
    }],

    columns: [{
        text: 'Id',
        dataIndex: 'id',
    }, {
        text: 'User Id',
        dataIndex: 'user_id',
    }, {
        text: 'Customer Id',
        dataIndex: 'customer_id',
    }, {
        text: 'Project Id',
        dataIndex: 'job_id',
    }, {
        text: 'Display Number',
        dataIndex: 'display_number',
    }, {
        text: 'Document Number',
        dataIndex: 'document_number',
    }, {
        text: 'Status',
        dataIndex: 'status',
        gridWithFilter: {
            searchValueMap: STATUSES,
        },
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'selectfield',
                itemId: 'selectStatusField',
                bind: {
                    value: '{statusValue}',
                    options: '{statusOptions}',
                },
            },
        },
    }, {
        text: 'Form Id',
        dataIndex: 'form_id',
    }, {
        text: 'Form Title',
        dataIndex: 'FormTitle',
    }, {
        text: 'Plot',
        dataIndex: 'plot',
    }, {
        text: 'Min App Version',
        dataIndex: 'min_app_version',
    }, {
        text: 'Max App Version',
        dataIndex: 'max_app_version',
    }, {
        text: 'MediaGroup Id',
        dataIndex: 'mediagroup_id',
    }, {
        text: 'Signature Id',
        dataIndex: 'signature_id',
    }, {
        text: 'Uuid',
        dataIndex: 'uuid',
    }, {
        text: 'Issue Date',
        dataIndex: 'issue_date',
        gridWithFilter: {
            skipFromFilter: true,
        },
    }, {
        text: 'Date Created',
        dataIndex: 'dt_created',
        gridWithFilter: {
            skipFromFilter: true,
        },
    }, {
        text: 'Last activity',
        dataIndex: 'job_id',
        minWidth: '150',
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            bind: {
                value: '{jobLastActivity}',
            },
        },
    }, {
        text: 'Is Deleted',
        dataIndex: 'isdeleted',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        cell: {
            bind: {
                value: '{isDeletedString}',
            },
        },
    }, {
        text: 'Is Archived',
        dataIndex: 'is_archived',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        cell: {
            bind: {
                value: '{archivedString}',
            },
        },
    }, {
        text: 'Form Data',
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'button',
                text: 'Info',
                handler: 'onInfoButtonTap',
            },
        },
    }],
});

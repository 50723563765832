require('./PurchaseActionDetailFormView.scss');

Ext.define('FieldServices.view.report.action.detail.PurchaseActionDetailFormView', {
    extend: 'Ext.form.Panel',

    requires: [
        'Shared.Components.field.DateTimeField',
        'FieldServices.view.report.action.detail.PurchaseActionDetailFormViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
    ],

    xtype: 'purchaseActionDetailFormView',

    controller: 'purchaseActionDetailFormViewController',

    scrollable: true,
    layout: 'hbox',
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 1,
            items: [{
                xtype: 'dateRangeFilter',
                title: 'Subscription Date:',
                name: 'action_created',
                tableName: 'PurchaseAction',
                mode: 'date',
                layout: 'hbox',
            }],
        },
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 1,
            items: [
                {
                    xtype: 'selectfield',
                    reference: 'actionType',
                    name: 'action',
                    tableName: 'PurchaseAction',
                    label: 'Action type:',
                    labelAlign: 'top',
                    labelWidth: 'auto',
                    autoHideInputMask: true,
                    options: [
                        { text: 'All', value: 'all' },
                        { text: 'New subscription', value: '0' },
                        { text: 'Renewal', value: '1' },
                        { text: 'Expiry', value: '2' },
                    ],
                    bind: {
                        isFilter: '{actionType.value !== "all"}',
                    },
                    displayField: 'text',
                    filterOperator: '=',
                    valueField: 'value',
                },
                {
                    xtype: 'whiteLabelFilter',
                    includeTest: false,
                },
            ],
        }, {
            xtype: 'container',
            docked: 'bottom',
            items: [{
                xtype: 'button',
                text: 'Filter',
                iconCls: 'ion-ios-checkmark-outline',
                cls: 'btn',
                handler: 'onChangeFilter',
            }, {
                xtype: 'button',
                text: 'xls',
                iconCls: 'ion-document',
                handler: 'onGetCsv',
                right: '0',
            }],
        },
    ],

});

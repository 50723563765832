Ext.define('FieldServices.view.company.user.profile.UserProfileViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.userProfileViewModel',

    formulas: {
        loadGridStore: {
            bind: {
                company: '{company}',
            },
            async get() {
                try {
                    this.set('isLoading', true);
                    const store = await this.generateCustomUserProfileRoles();
                    this.set('isLoading', false);
                    return store;
                } catch (e) {
                    handleClientError(e);
                }
            },
        },
        masked: {
            bind: {
                company: '{company}',
                isLoading: '{isLoading}',
            },
            get() {
                const isLoading = this.get('isLoading');
                return isLoading ? {
                    xtype: 'loadmask',
                } : false;
            },
        },
    },

    data: {
        mapSeparator: '__',
        teamColumns: [{
            key: 'main',
            text: 'Main user',
            defaultValueKey: 'mainDefaultValue',
            constantValue: CONSTANTS.USER_PROFILE.MAIN_USER,
        }, {
            key: 'manager',
            text: 'Managers',
            defaultValueKey: 'managerDefaultValue',
            constantValue: CONSTANTS.USER_PROFILE.MANAGER,
        }, {
            key: 'engineer',
            text: 'Engineers',
            defaultValueKey: 'engineerDefaultValue',
            constantValue: CONSTANTS.USER_PROFILE.ENGINEER,
        }],
    },

    async generateCustomUserProfileRoles() {
        await this._loadRoles();

        await this._loadUserProfileRoleCustom();

        return this._mergeCustomStore();
    },

    async _loadRoles() {
        return Ext.getStore('Roles').waitToLoad();
    },

    async _loadUserProfileRoleCustom() {
        const store = Ext.getStore('UserProfileRoleCustom');
        const filters = [
            {
                property: 'company_id',
                value: this.get('companyId'),
                operator: '=',
            }, {
                property: 'isdeleted',
                operator: '=',
                value: false,
            }];

        store.clearFilter(true);
        store.filter(filters);

        return store.waitToLoad();
    },

    _mergeCustomStore() {
        const mergedData = this._mergeRoleAndCustomRole();

        return Ext.create('Ext.data.Store', {}).setData(mergedData);
    },

    _mergeRoleAndCustomRole() {
        const roles = Ext.getStore('Roles').getRecords().filter(record => !record.data.system);

        return roles.reduce((prev, roleRecord) => [...prev, this._addTeamValueToRoleRow(roleRecord.data)], []);
    },

    _addTeamValueToRoleRow(newRowData) {
        this.get('teamColumns').forEach(column => {
            const customRole = this._getCustomRoleRecord(newRowData.id, column.constantValue);
            let status = 0;
            if (customRole) {
                status = customRole.data.mode;
            } else {
                status = newRowData[column.defaultValueKey];
            }
            newRowData[column.key] = status;
        });

        return newRowData;
    },

    _getProfileIdByKey(modifiedKey) {
        return this._getTeamColumnByKey(modifiedKey).constantValue || null;
    },

    _getTeamColumnByKey(keyValue) {
        const team = this.get('teamColumns').filter(map => map.key === keyValue);
        return team[0];
    },

    _getCustomRoleRecord(roleId, userProfileId) {
        const mapKey = this._generateMapKey(roleId, userProfileId);
        return Ext.getStore('UserProfileRoleCustom').data.byRoleIdAndUserProfileId.get(mapKey);
    },
    _generateMapKey(roleId, userProfileId) {
        return [roleId, userProfileId].join(this.get('mapSeparator'));
    },

});

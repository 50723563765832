Ext.define('FieldServices.view.company.CompanyViewModel', {
    extend: 'FieldServices.view.BaseViewModel',

    alias: 'viewmodel.companyViewModel',
    data: {
        hubspotRecordUrl: null,
        hubspotButtonText: 'Connecting to Hubspot',
    },
    stores: {
        subscriptionInfoStore: {
            type: 'PurchasesInfo',
            model: 'FieldServices.model.PurchasesInfo',
            storeId: 'SubscriptionInfoStore',

            remoteSort: false,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                }],
            sorters: [
                {
                    property: 'purchase_date',
                    direction: 'DESC',
                }],
        },

        subscriptionsStore: {
            type: 'PurchasesGrouped',

            remoteSort: false,
            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }],
            sorters: [
                {
                    property: 'purchaseDate',
                    direction: 'DESC',
                }, {
                    property: 'id',
                    direction: 'DESC',
                }],
        },

        subscriptionsChainedStore: {
            source: '{subscriptionsStore}',
        },

        commentsStore: {
            type: 'AdminAuditTrail',
            storeId: 'CompanyCommentsStore',
            autoLoad: true,

            filters: [
                {
                    property: 'linked_company_id',
                    value: '{companyId}',
                    operator: '=',
                },
                {
                    property: 'linked_id',
                    value: 0,
                    operator: '=',
                },
            ],
            sorters: [{
                property: 'dt_created',
                direction: 'DESC',
            }],
        },

        mainUserStore: {
            type: 'User',
            autoLoad: true,
            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                }, {
                    property: 'is_main',
                    operator: '=',
                    value: true,
                },
            ],
        },

        companyIntegrationStore: {
            type: 'CompanyIntegration',
            autoLoad: true,
            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        paymentsStore: {
            type: 'Payment',

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                },
            ],
        },

        appointmentTypesStore: {
            type: 'AppointmentType',
            autoLoad: true,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        customerStore: {
            type: 'Customer',
            autoLoad: true,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        contactStore: {
            type: 'Contact',
            autoLoad: true,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        contactToAddressStore: {
            type: 'ContactToAddress',
            autoLoad: true,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        jobStore: {
            type: 'Job',
            autoLoad: true,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
            proxy: {
                extraParams: {
                    requiredFields: ['lastActivity'],
                },
            },
        },

        paymentsChained: {
            source: '{paymentsStore}',
        },

        whiteLabelStore: {
            type: 'WhiteLabel',
            storeId: 'mainWhiteLabelStore',
            autoLoad: true,
        },

        whitelabelOptionStore: {
            source: '{whiteLabelStore}',
            filters: [
                {
                    property: 'whitelabel_status',
                    operator: '!=',
                    value: CONSTANTS.WHITELABEL.STATUS_KEYS.OBSOLETE,
                },
            ],
        },

        subscriptionInfoChained: {
            source: '{subscriptionInfoStore}',
        },

    },

    formulas: {
        isPortraitView: {
            get() {
                return Ext.Viewport.getOrientation() === 'portrait';
            },
        },
        infoHeaderFlex: {
            bind: '{isPortraitView}',
            get(isPortrait) {
                return isPortrait ? 0.5 : 1;
            },
        },
        selectedCompanyData: {
            bind: {
                bindTo: '{company}',
                deep: true,
            },
            get(selectedCompany) {
                let baseData = Ext.clone(selectedCompany?.data);
                if (baseData) {
                    if (selectedCompany.dirty) {
                        baseData = { ...baseData, ...selectedCompany.modified };
                    }
                    baseData.businessType = Ext.getStore('BusinessTypes').findRecord('id', baseData.businesstypes_id).get('type');
                    baseData.formattedCreationDate = Ext.Date.format(new Date(baseData.dt_created), 'Y. M d.');
                }
                return baseData;
            },
        },

        currentSubscriptionName: {
            bind: {
                bindTo: {
                    subStore: '{subscriptionInfoStore}',
                    selectedCompanyData: '{selectedCompanyData}',
                    loadCount: '{subscriptionInfoStore.loadCount}',
                },
                deep: true,
            },
            get({ subStore, selectedCompanyData }) {
                if (!selectedCompanyData) {
                    return '...';
                }
                if (!subStore.isLoaded()) {
                    if (!subStore.isLoading()) {
                        subStore.load();
                    }
                    return '...';
                }
                const allActiveSubs = subStore.getRecords().filter(record => record.get('isActive'));
                if (!allActiveSubs.length) {
                    subStore.load();
                    return '...';
                }
                return allActiveSubs[0].get('product_name');
            },
        },

        whiteLabelName: {
            bind: {
                bindTo: {
                    companyData: '{selectedCompanyData}',
                    whiteLabelStore: '{whiteLabelStore}',
                    storeLoadCount: '{whiteLabelStore.loadCount}',
                },
                deep: true,
            },
            get({ companyData, whiteLabelStore }) {
                if (!companyData) {
                    return '...';
                }
                if (!whiteLabelStore.isLoaded()) {
                    if (!whiteLabelStore.isLoading()) {
                        whiteLabelStore.load();
                    }
                    return '...';
                }
                const whiteLabel = whiteLabelStore.findRecord('id', companyData.whitelabel_id);
                return whiteLabel.get('name');
            },
        },

        companyContactData: {
            bind: {
                bindTo: {
                    mainUserStore: '{mainUserStore}',
                    mainUserStoreLoads: '{mainUserStore.loadCount}',
                    company: '{company}',
                },
                deep: true,
            },
            get({ mainUserStore, company }) {
                const mainUser = mainUserStore.first();
                if (!mainUser || !company) {
                    return null;
                }

                const { email: companyEmail, bccEmail: companyBccEmail, phone: companyPhone } = company.data;
                const { firstname, lastname, email: mainUserEmail } = mainUser.data;
                if (mainUser.data.company_id === company.data.id) {
                    this.loadHubspotUrl(mainUserEmail).catch(handleClientError);
                }
                return {
                    mainUserName: `${firstname} ${lastname}`,
                    mainUserEmail,
                    companyEmail,
                    companyBccEmail,
                    companyPhone,
                };
            },
        },

        xeroIntegrationRecord: {
            bind: {
                bindTo: {
                    integrationStore: '{companyIntegrationStore}',
                    loadCount: '{companyIntegrationStore.loadCount}',
                },
                deep: true,
            },
            get({ integrationStore }) {
                return integrationStore.findRecord('integration_type_id', CONSTANTS.INTEGRATION.INTEGRATION_TYPE.XERO);
            },
        },

        companyIntegrationData: {
            bind: {
                bindTo: {
                    company: '{company}',
                    xeroRecord: '{xeroIntegrationRecord}',
                },
                deep: true,

            },
            get({ company, xeroRecord }) {
                if (!company) {
                    return null;
                }
                const xeroUsageEnabled = xeroRecord ? xeroRecord.get('is_usage_enabled') : false;
                const xeroEnabled = xeroRecord ? xeroRecord.get('is_enabled') : false;
                const {
                    cis_enabled, vat_registered, vat_id, xero_id, mtd_enabled, chargebee_id,
                } = company.data;
                return {
                    xeroUsageEnabled,
                    xeroEnabled,
                    cis_enabled,
                    vat_registered,
                    vat_id,
                    xero_id,
                    mtd_enabled,
                    chargebee_id,
                };
            },
        },

        companyViewLoadMask: {
            bind: {
                company: '{company}',
                isCompanyLoading: '{isCompanyLoading}',
            },
            get({ company, isCompanyLoading }) {
                if (!company && isCompanyLoading) {
                    return { xtype: 'loadmask' };
                }
                return false;
            },
        },

        isSubscriptionInfoLoading: {
            bind: {
                bindTo: {
                    store: '{subscriptionInfoStore}',
                    loadCount: '{subscriptionInfoStore.loadCount}',
                },
                deep: true,
            },
            get({ store, loadCount }) {
                return loadCount === 0 || store.isLoading();
            },
        },

    },

    async loadHubspotUrl(email) {
        this.setData({ hubspotRecordUrl: null, hubspotButtonText: 'Connecting to Hubspot' });

        const { hubspotRecordUrl, result } = await FieldServices.app.callAPIEndpointRequest('POST', 'api/admindata/company/getHubspotUrl/0', { email });
        if (hubspotRecordUrl) {
            this.setData({ hubspotRecordUrl, hubspotButtonText: 'Go to Hubspot Record' });
        } else {
            this.setData({ hubspotButtonText: result === 'notFound' ? 'No Hubspot Record' : 'Hubspot connection failed' });
        }
    },
});

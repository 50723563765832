Ext.define('FieldServices.view.BaseViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.baseViewModel',

    data: {
        companyId: undefined,
    },
    stores: {
        companyStore: {
            type: 'Company',
            storeId: 'mainCompanyStore',
            filters: [{
                property: 'id',
                value: '{companyId}',
                operator: '=',
                id: 'companyFilter',
            }],
            autoLoad: true,
        },
    },
    formulas: {
        company: {
            bind: {
                bindTo: {
                    store: '{companyStore}',
                    loadCount: '{companyStore.loadCount}',
                    companyId: '{companyId}',
                },
                deep: true,
            },
            async get({ store, companyId }) {
                if (!companyId || store.isLoading() || !store.isLoaded()) {
                    return null;
                }
                return store.first();
            },
        },
        isCompanyLoading: {
            bind: {
                bindTo: {
                    store: '{companyStore}',
                    loadCount: '{companyStore.loadCount}',
                },
                deep: true,
            },
            get({ store, loadCount }) {
                return loadCount === 0 || store.isLoading();
            },
        },
    },
    getCompanyId(company = this.getData().company) {
        return company ? company.getData().id : null;
    },
});

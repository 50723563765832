Ext.define('FieldServices.view.report.monthlyReports.charts.MonthlyReportsChartsView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.report.monthlyReports.charts.MonthlyReportsChartsViewController',
        'Ext.chart.CartesianChart',
        'Ext.chart.plugin.ItemEvents',
        'Ext.chart.series.Bar',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
        'FieldServices.overrides.SpriteLegend',
    ],

    xtype: 'monthlyReportsCharts',

    controller: 'monthlyReportsChartsViewController',

    viewModel: {
        data: {
            dateRangeValid: true,
            dataLoading: false,
        },
        formulas: {
            loadEnabled: {
                bind: {
                    dateRangeValid: '{dateRangeValid}',
                    dataLoading: '{dataLoading}',
                },
                get({ dateRangeValid, dataLoading }) {
                    return dateRangeValid && dataLoading === false;
                },
            },
            chartContainerMask: {
                bind: {
                    dataLoading: '{dataLoading}',
                },
                get({ dataLoading }) {
                    return dataLoading ? {
                        xtype: 'loadmask',
                        message: 'Loading reports',
                    } : false;
                },
            },
        },
    },
    layout: 'fit',

    items: [{
        xtype: 'container',
        layout: 'vbox',
        items: [
            {
                xtype: 'formpanel',
                layout: 'vbox',
                itemId: 'filterPanel',
                flex: 1,
                items: [
                    {
                        xtype: 'container',
                        layout: 'hbox',
                        items: [
                            {
                                flex: 3,
                                xtype: 'dateRangeFilter',
                                itemId: 'dateRangeFilter',
                                title: 'Date range:',
                                name: 'dt_created',
                                layout: 'hbox',
                                labelAlign: 'top',
                                fixedRangeMode: 'inRange',
                                dateFormat: 'Y-m',
                                mode: 'date',
                                selectableRangeLimit: {
                                    value: 12,
                                    intervalType: Ext.Date.MONTH,
                                },
                            },
                            {
                                flex: 1,
                                xtype: 'whiteLabelFilter',
                                cls: 'monthlyReportsCharts',
                                includeTest: false,
                                includeObsolete: true,
                                itemId: 'whiteLabelFilter',
                            },
                        ],
                    },
                    {
                        flex: 1,
                        xtype: 'checkbox',
                        label: 'Exclude Marketing Subscriptions',
                        labelWidth: 'auto',
                        itemId: 'promoExcludeCheckbox',
                    },
                    {
                        xtype: 'button',
                        itemId: 'loadButton',
                        text: 'Load Charts',
                        cls: 'btn',
                        handler: 'loadCharts',
                        bind: {
                            disabled: '{!loadEnabled}',
                        },
                    },
                ],
            },
            {
                xtype: 'container',
                itemId: 'chartsContainer',
                layout: {
                    type: 'hbox',
                    wrap: true,
                    pack: 'space-around',
                },
                style: {
                    border: '0.3em darkgray solid',
                    margin: '0 1em 0.5em 1em',
                    borderRadius: '0.5em',
                },
                flex: 4,
                items: [],
                bind: {
                    masked: '{chartContainerMask}',
                },
            },

        ],
    },
    ],

});

import ADMIN_CONSTANTS from '../../constants';

const _ = require('lodash');

Ext.define('FieldServices.plugins.GridWithFilter', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.gridWithFilter',

    requires: [
        'FieldServices.view.widgets.filters.DateRangeFilter',
    ],
    toolbarControlConfig: {
        xtype: 'container',
        layout: 'vbox',
        itemId: 'toolbarControls',
        flex: 1,
        defaults: {
            style: {
                'min-height': '55px',
            },
        },
        items: [{
            xtype: 'container',
            layout: 'hbox',
            flex: 1,
            bodyCls: ['verticalOnMobile'],
            defaults: {
                flex: 1,
            },
            items: [{
                xtype: 'textfield',
                flex: 4,
                itemId: 'searchValue',
                placeholder: 'Search',
                triggers: {
                    search: {
                        type: 'search',
                        side: 'left',
                    },
                },
            }, {
                xtype: 'selectfield',
                itemId: 'searchColumns',
                options: [],
                mode: 'multi',
                placeholder: 'field to look into',
                matchFieldWidth: false,
                floatedPicker: {
                    width: 210,
                },
            }, {
                xtype: 'button',
                itemId: 'searchButton',
                text: 'search',
            }, {
                xtype: 'button',
                itemId: 'resetButton',
                text: 'reset',
            }],
        }, {
            xtype: 'container',
            layout: 'hbox',
            itemId: 'additionalFilterFieldsHolder',
            hidden: true,
            bodyCls: ['verticalOnMobile'],
            flex: 1,
            defaults: {
                flex: 1,
            },
        }, {
            xtype: 'container',
            itemId: 'checkBoxContainer',
            flex: 1,
            layout: 'hbox',
            hidden: true,
            defaults: {
                flex: 1,
            },
            items: [],
        }],
    },
    pagerConfig: {
        xtype: 'container',
        itemId: 'pager',
        hidden: true,
        items: [{
            xtype: 'button',
            itemId: 'previousButton',
            disabled: true,
            text: 'previous',
        }, {
            xtype: 'button',
            itemId: 'nextButton',
            disabled: true,
            text: 'next',
        }],
    },

    config: {
        useCheckBoxFilter: false,
        filterOnSearchButton: false,
        nonColumnFilterOptions: [],
        requestConfig: {},
        additionalFilterFields: [],
        dateRangeFilters: [],
        paging: false,
        page: 1,
    },

    loadCount: 0,

    init(gridView) {
        this.setCmp(gridView);
        if (this.getFilterOnSearchButton()) {
            this._getGridView().on('painted', this.onGridPainted, this);
        }
        this._getGridView().on('storechange', this.onStoreChange, this);
        this._getGridView().on('columnsort', this.onGridSort, this);
        this._getGridView().on('columnsortRemoved', this.onGridSort, this);
    },

    applyRequestConfig(requestConfig) {
        return new Ext.util.ObjectTemplate(requestConfig);
    },

    onGridPainted() {
        this._getGridView().setData([]);
        this._setTitle();
        this._resetAllFilters();
        this._enablePaging();
    },

    onGridSort() {
        if (this.loadCount) {
            this.reloadData();
        }
    },

    _enablePaging({ limit, total } = {}) {
        const paging = this.getPaging();
        const pager = this._getGridView().down('#pager');
        const previousButton = this._getPreviousButton();
        const nextButton = this._getNextButton();

        pager.setHidden(!paging);
        if (limit !== undefined && total !== undefined) {
            const pageLeft = (limit * this.getPage()) < total;
            nextButton.setDisabled(!pageLeft);
        }
        previousButton.setDisabled(this.getPage() < 2);
    },

    onChangeFilterColumns() {
        this._doFilteringByValue(this._getSearchTextValue());
    },

    onChangeTextField(elem, newValue) {
        this._doFilteringByValue(newValue);
    },

    onStoreChange(view, store) {
        if (store instanceof Ext.data.AbstractStore) {
            const filterOnSearchButton = this.getFilterOnSearchButton();
            const loadStore = store.isLoaded && !store.isLoaded();
            if (!filterOnSearchButton && store && store.load && loadStore) {
                store.load();
            }
            this._initFilter();
            this._initDefaultCheckBoxFilters();
        }
    },

    _getColumnsWithCheckboxFilter() {
        return this._getGridView().getColumns()
            .filter(column => column.useCheckbox);
    },

    _initCheckBoxes(columnsWithCheckboxFilter) {
        this._getCheckboxContainer().setHidden(false);
        this._getCheckboxContainer().setItems([
            ...columnsWithCheckboxFilter
                .map(column => ({
                    xtype: 'checkboxfield',
                    name: column.getDataIndex(),
                    boxLabel: column.useCheckbox.boxLabel,
                    value: column.useCheckbox.excludeValue,
                    checked: column.useCheckbox.checked || false,
                })),
        ]);
    },

    _getGridView() {
        return this.getCmp();
    },

    _getNextButton() {
        return this._getGridView().down('#nextButton');
    },

    _getPreviousButton() {
        return this._getGridView().down('#previousButton');
    },

    _getSearchTextView() {
        return this._getGridView().down('#searchValue');
    },

    _getSearchColumnsView() {
        return this._getGridView().down('#searchColumns');
    },

    _getResetButtonView() {
        return this._getGridView().down('#resetButton');
    },

    _getCheckboxContainer() {
        return this._getGridView().down('#checkBoxContainer');
    },

    _getSearchButton() {
        return this._getGridView().down('#searchButton');
    },

    _getAdditionalFilterFieldsHolder() {
        return this._getGridView().down('#additionalFilterFieldsHolder');
    },

    _getSearchTextValue() {
        return this._getSearchTextView().getValue();
    },

    _initFilter() {
        if (!this._getSearchColumnsView()) {
            this._insertToolbarToGrid();
            const columnsWithCheckBoxFilter = this._getColumnsWithCheckboxFilter();
            if (columnsWithCheckBoxFilter.length) {
                this.setUseCheckBoxFilter(true);
                this._initCheckBoxes(columnsWithCheckBoxFilter);
            }
            this._setFilterColumns();
            this._initAdditionalFilterFields();
            this._initDateRangeFilters();
            this._initSearchButton();
        } else {
            this._doFilteringByValue(this._getSearchTextValue());
        }
        this._setTitle();
    },

    _initSearchButton() {
        const filterOnSearchButton = this.getFilterOnSearchButton();
        this._getSearchButton().setHidden(!filterOnSearchButton);
    },

    _initDefaultCheckBoxFilters() {
        if (this._getCheckboxContainer().getItems().length !== 0) {
            const store = this._getGridView().getStore();
            store.filterBy(record => this._applyCheckBoxFilters(record));
        }
    },

    _getCheckboxFilters() {
        return this._getCheckboxContainer().getItems().items
            .filter(checkbox => checkbox.getChecked() === false)
            .map(checkbox => ({
                fieldName: checkbox.getName(),
                filterValue: checkbox.getValue(),
            }));
    },

    _applyCheckBoxFilters(record) {
        const filters = this._getCheckboxFilters();
        return !filters.some(({ fieldName, filterValue }) => (Boolean(record.get([fieldName])) === Boolean(filterValue)));
    },

    _setTitle({ totalCount = 0 } = {}) {
        const grid = this._getGridView();
        if (grid.getStore() && grid.getStore().data) {
            const length = grid.getStore().data.length || 0;
            const template = new Ext.Template(this._getTitleTemplate());
            grid.setTitle(template.apply({ length, totalCount }));
        }
        if (Ext.Viewport.getOrientation() !== 'portrait' && (grid.getTitle() || grid.config.titleBar.items)) {
            grid.getTitleBar().show();
        }
    },

    reloadData() {
        if (!this._getGridView().isPainted()) {
            return;
        }
        this.setPage(1);
        this._requestData().catch(handleClientError);
    },

    async _requestData() {
        this._getGridView().setMasked({ xtype: 'loadmask', message: 'Loading...' });
        const requestConfig = this.getRequestConfig();
        const viewModelData = this._getGridView().lookupViewModel().getData();
        const request = requestConfig.apply(viewModelData);

        const selectFieldValue = this._getSearchColumnsView().getSelection();
        const searchInputValue = (this._getSearchTextValue() || '').trim();
        const emptyFreeTextFilter = !searchInputValue;
        const isAllFilterSelected = selectFieldValue && selectFieldValue.data.value === 'all' && !emptyFreeTextFilter;
        let filters = [];

        if (!emptyFreeTextFilter) {
            filters = [{
                $or: [
                    ...(this._getSelectedColumnDataKeyPaths().reduce((newFilters, filter) => {
                        const requestFilter = this._generateFilter(filter, searchInputValue);
                        if (requestFilter) {
                            newFilters.push(requestFilter);
                        }
                        return newFilters;
                    }, [])),
                ],
            }];
        }

        const additionalFilterFields = this.getAdditionalFilterFields();
        let anyAdditionFilterFilled = false;
        let additionalFilters = additionalFilterFields.reduce((newAdditionalFilters, additionalFilterField) => {
            const field = this._getGridView().down(`#${additionalFilterField.property}`);
            const fieldValue = (field.getValue() || '').trim();
            const value = isAllFilterSelected && !fieldValue && !additionalFilterField.skipFromFilter ? searchInputValue : fieldValue;

            if (value) {
                const requestFilter = this._generateFilter(additionalFilterField, value);
                if (requestFilter) {
                    newAdditionalFilters.push(requestFilter);
                }
            }
            if (fieldValue) {
                anyAdditionFilterFilled = true;
            }
            return newAdditionalFilters;
        }, []);

        const findInAll = filters.length === 1 && filters[0].$or;
        if (isAllFilterSelected && !anyAdditionFilterFilled && findInAll) {
            filters[0].$or = [
                ...filters[0].$or,
                ...additionalFilters,
            ];
            additionalFilters = [];
        }

        const dateRangeFilters = this.getDateRangeFilters().reduce((newDateRangeFilters, dateRangeFilter) => {
            const { property } = dateRangeFilter;
            const field = this._getGridView().down(`#${this._getDateRangeFieldItemId(property)}`);
            const rangeFilters = field.getFilters();

            return [
                ...newDateRangeFilters,
                ...rangeFilters,
            ];
        }, []);
        const emptyFilter = emptyFreeTextFilter && dateRangeFilters.length === 0 && additionalFilters.length === 0;
        const limit = !emptyFilter ? ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_MEDIUM : ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_NORMAL;
        const page = this.getPage();

        const gridSort = this.getGridSort();
        request.params.sort = gridSort ? JSON.stringify(gridSort) : JSON.stringify(request.params.sort || []);
        request.params = {
            limit,
            page,
            start: ((page || 1) - 1) * limit,
            skip: ((page || 1) - 1) * limit,
            ...request.params,
            filter: JSON.stringify([
                ...((request.params || {}).filter || []),
                ...(filters),
                ...additionalFilters,
                ...dateRangeFilters,
            ]),
        };

        const result = await FieldServices.app.callAPI(request);
        this._getGridView().setData(result.data ? result.data : result);
        this.loadCount += 1;
        this._setTitle({
            totalCount: result.total,
        });
        this._enablePaging({
            limit,
            total: result.total,
        });
        this._getGridView().setMasked(false);
    },

    getGridSort() {
        const sorterColumn = this._getGridView().getColumns().filter(column => column.sortState)[0];
        if (sorterColumn) {
            const sorter = sorterColumn.getSorter();
            return [{
                property: sorter.getProperty(),
                direction: sorter.getDirection(),
            }];
        }
        return false;
    },

    _generateFilter(filter, searchInputValue) {
        const { property, valueValidator } = filter;
        const value = filter.searchValueMap ? this._findMappedValues(searchInputValue, filter.searchValueMap) : searchInputValue;
        const isArray = Ext.isArray(value);
        const isAnyId = [
            'id',
            'company_id',
            'customer_id',
        ].includes(property) || isArray;
        const operatorPredefined = filter.operator;
        const operator = operatorPredefined || (isAnyId ? 'in' : 'like');
        const validatedValue = valueValidator ? valueValidator(value) : value;
        const operatorValueMap = {
            in: Ext.isArray(validatedValue) ? validatedValue : [validatedValue],
            '=': validatedValue,
            like: encodeURIComponent(`%${validatedValue}%`),
        };
        if (operator === 'numberLike' && isNaN(parseInt(value))) {
            return null;
        }
        if ((!isArray && validatedValue) || (isArray && !Ext.isEmpty(validatedValue))) {
            return {
                property,
                value: operatorValueMap[operator] ? operatorValueMap[operator] : validatedValue,
                operator,
            };
        }
        return null;
    },

    _getTitleTemplate() {
        return this._getGridView().titleTemplate;
    },

    _insertToolbarToGrid() {
        const grid = this._getGridView();
        let toolbarControls = this.toolbarControlConfig;
        const actionConfigs = grid.config.titleBar.items || [];
        if (Ext.Viewport.getOrientation() === 'portrait') {
            toolbarControls = {
                xtype: 'panel',
                title: 'Filters & Actions',
                collapsible: {
                    xtype: 'Ext.panel.Collapser',
                    animation: null,
                },
                items: [...actionConfigs, this.toolbarControlConfig],
            };
        }
        const toolbarConfig = {
            xtype: 'toolbar',
            docked: 'top',
            layout: 'vbox',
            bodyCls: ['gridWithFilterToolbarBody'],
            items: [toolbarControls, this.pagerConfig],
        };
        const configWithUi = { ui: grid.getUi(), ...toolbarConfig };
        this._getGridView().insert(1, configWithUi);
    },

    _setFilterColumns() {
        const filterOptions = this._getColumns();
        const nonColumnFilterOptions = this.getNonColumnFilterOptions();

        this._getSearchColumnsView().setOptions([
            ...filterOptions,
            ...nonColumnFilterOptions,
        ]);
        this._setSearchColumnsToFirstElem();
        this._initFilterEvents();
    },

    _initAdditionalFilterFields() {
        const additionalFilterFields = this.getAdditionalFilterFields();
        if (additionalFilterFields.length) {
            this._getAdditionalFilterFieldsHolder().setHidden(false);
        }
        additionalFilterFields.forEach(additionalFilterField => {
            const field = this._getAdditionalFilterFieldsHolder().add({
                ...additionalFilterField,
                itemId: additionalFilterField.property,
            });
            field.on('keyup', this._onSearchTextViewKeyUp.bind(this));
        });
    },

    _initDateRangeFilters() {
        const dateRangeFilters = this.getDateRangeFilters();
        dateRangeFilters.forEach(dateRangeFilter => {
            const {
                property, label, dbType, mode, fixedRangeMode, convertToObjectId, selectableRangeLimit,
            } = dateRangeFilter;
            const filterCmp = Ext.create({
                xtype: 'dateRangeFilter',
                flex: 2,
                title: label,
                fieldName: property,
                dbType,
                fixedRangeMode,
                convertToObjectId,
                selectableRangeLimit,
                mode: mode || 'date',
                itemId: this._getDateRangeFieldItemId(property),
                titleAlign: 'left',
                timeIncrement: 30,
            });
            this._getAdditionalFilterFieldsHolder().add(filterCmp);
            filterCmp.on('validated', this.onDateRangeValidate, this);
        });
        if (dateRangeFilters.length) {
            this._getAdditionalFilterFieldsHolder().setHidden(false);
        }
    },

    onDateRangeValidate(isValid) {
        this._getSearchButton().setDisabled(!isValid);
    },

    _getDateRangeFieldItemId(property) {
        return `${property}_range`;
    },

    _setSearchColumnsToFirstElem() {
        const filterDropDown = this._getSearchColumnsView();
        // reset section
        filterDropDown.setSelection(filterDropDown.getStore().getAt(0));
    },

    _initFilterEvents() {
        this._getSearchTextView().on('change', this.onChangeTextField.bind(this), this, { buffer: 750 });
        this._getSearchColumnsView().on('change', this.onChangeFilterColumns.bind(this));
        this._getResetButtonView().on('tap', this._resetAllFilters.bind(this));
        this._getSearchButton().on('tap', this.reloadData.bind(this));
        this._getNextButton().on('tap', this.onNextButtonTap.bind(this));
        this._getPreviousButton().on('tap', this.onPreviousButtonTap.bind(this));
        this._getSearchTextView().on('keyup', this._onSearchTextViewKeyUp.bind(this));
        this._getCheckboxContainer().getItems().items
            .forEach(checkbox => checkbox.on('change', this._initDefaultCheckBoxFilters.bind(this)));
    },

    _onSearchTextViewKeyUp(field, event) {
        const filterOnSearchButton = this.getFilterOnSearchButton();
        if (event && event.keyCode === 13 && filterOnSearchButton) {
            this.reloadData();
        }
    },

    onNextButtonTap() {
        this.setPage(this.getPage() + 1);
        this._requestData()
            .catch(handleClientError);
    },

    onPreviousButtonTap() {
        this.setPage(this.getPage() - 1);
        this._requestData()
            .catch(handleClientError);
    },

    _findMappedValues(value, searchValueMap) {
        let mapParam = searchValueMap;
        if (_.isFunction(mapParam)) {
            mapParam = mapParam();
        }
        return Object.entries(mapParam)
            .map(([searchValue, realValue]) => ({ searchValue: searchValue.toLowerCase().replace(/_/gi, ''), realValue }))
            .reduce((matches, currentValue) => {
                if (value && currentValue.searchValue.match(new RegExp(value, 'gi'))) {
                    matches.push(currentValue.realValue);
                }
                return matches;
            }, []);
    },

    _getColumns() {
        const gridColumns = this._getGridView().getColumns();
        const gridColumnsConfig = gridColumns
            .filter(column => !column.useCheckbox && !column.config.gridWithFilter || (column.config.gridWithFilter && !column.config.gridWithFilter.skipFromFilter))
            .reduce((prev, column) => [...prev, column.config], [{
                text: 'All',
                dataIndex: 'all',
                gridWithFilter: {
                    skipFromFilter: true,
                },
            }]);
        return gridColumnsConfig.map(config => ({
            text: config.text,
            value: config.dataIndex || this._extractBindString(config),
            ...(config.gridWithFilter ? config.gridWithFilter : {}),
        }));
    },

    _extractBindString(config) {
        const hasBind = config.cell && config.cell.bind;
        let bindString;
        if (hasBind) {
            if (_.isObject(config.cell.bind)) {
                bindString = config.cell.bind.value;
            } else {
                bindString = config.cell.bind;
            }
        }
        return bindString;
    },

    _doFilteringByValue(value) {
        const filterOnSearchButton = this.getFilterOnSearchButton();
        if (!filterOnSearchButton) {
            if (value === null) {
                return;
            }
            const store = this._getGridView()
                .getStore();

            if (!Ext.isEmpty(store)) {
                const [{ property }] = this._getSelectedColumnDataKeyPaths();

                this._applyStoreFilters(value, store);
                this._getGridView().fireEvent('filterValueChanged', { value, property });
            }
            this._setTitle();
        }
    },

    _resetStoreFilters(store) {
        store.clearFilter();
    },

    _applyStoreFilters(value, store) {
        const regEx = new RegExp(value, 'i');
        const isBindStringRegex = new RegExp('^{.+}$', 'i');

        const dataKeyPaths = this._getSelectedColumnDataKeyPaths();
        const dataIndexesToRenderers = this._getDataIndexesToRenderers();

        if (store.remoteFilter) {
            if (dataKeyPaths.length === 0) {
                return;
            }

            if (dataKeyPaths.length > 1) {
                console.error(new Error('Not implemented yet'));
                return;
            }

            store.addFilter({
                id: 'uniqueFilter',
                value,
                property: dataKeyPaths[0].property,
            });
            return;
        }

        store.clearFilter();

        const dummyViewModel = this._createDummyViewModel();

        store.filterBy(record => ((this.getUseCheckBoxFilter() ? this._applyCheckBoxFilters.call(this, record) : true)
                && dataKeyPaths.some(dataKeyPath => {
                    if (isBindStringRegex.test(dataKeyPath)) {
                        dummyViewModel.setData({ record });
                        dummyViewModel.notify();
                        const valueFromViewModel = dummyViewModel.get(dataKeyPath.match(/{(.*)}/)[1]);
                        return regEx.test(valueFromViewModel);
                    }

                    let renderer = dataIndexesToRenderers[dataKeyPath];
                    if (renderer) {
                        if (!_.isFunction(renderer)) {
                            const controller = this._getGridView().getController();
                            renderer = controller[renderer].bind(controller);
                        }
                        return regEx.test(renderer(record.data[dataKeyPath], record));
                    }
                    return regEx.test(record.get(dataKeyPath.property));
                })));

        dummyViewModel.destroy();
    },

    _createDummyViewModel() {
        let viewModelClass = 'Ext.app.ViewModel';
        const itemViewModelType = this._getGridView().getItemConfig().viewModel.type;
        if (itemViewModelType) {
            viewModelClass = `viewmodel.${itemViewModelType}`;
        }
        return Ext.create(viewModelClass);
    },

    _getDataIndexesToRenderers() {
        return this._getGridView().config.columns
            .filter(({ filterByRenderer }) => filterByRenderer)
            .reduce((accumulator, { renderer, dataIndex }) => Object.assign(accumulator, { [dataIndex]: renderer }), {});
    },

    _getSelectedColumnDataKeyPaths() {
        const selectFieldValue = this._getSearchColumnsView().getSelection();
        if (selectFieldValue && selectFieldValue.data.value !== 'all') {
            return [{
                property: selectFieldValue.data.value,
                searchValueMap: selectFieldValue.data.searchValueMap,
                operator: selectFieldValue.data.operator,
                valueValidator: selectFieldValue.data.valueValidator,
            }];
        }
        return this._getSearchColumnsView()
            .getOptions()
            .data.items
            .filter(column => !column.data.skipFromFilter)
            .map(({ data }) => ({
                property: data.value,
                searchValueMap: data.searchValueMap,
                operator: data.operator,
                valueValidator: data.valueValidator,
            }));
    },

    _resetAllFilters() {
        this._getSearchTextView().setValue('');
        this.getAdditionalFilterFields().forEach(additionalFilterField => {
            const field = this._getGridView().down(`#${additionalFilterField.property}`);
            if (field.setValue) {
                field.setValue('');
            }
        });
        const dateRangeFilters = this.getDateRangeFilters();
        dateRangeFilters.forEach(dateRangeFilter => {
            const { property } = dateRangeFilter;
            const field = this._getGridView().down(`#${this._getDateRangeFieldItemId(property)}`);
            field.clearFilters();
        });
        this._setSearchColumnsToFirstElem();
        this._getGridView().fireEvent('filterResetFinished', {});
    },
});

Ext.define('FieldServices.view.whiteLabel.whiteLabelView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.whiteLabel.whiteLabelViewController',
        'FieldServices.view.whiteLabel.whiteLabelViewModel',
        'FieldServices.view.whiteLabel.settings.settingsView',
        'FieldServices.view.whiteLabel.addNew.addNewView',
        'FieldServices.view.whiteLabel.whiteLabelListView',
    ],

    alias: 'widget.whiteLabel',

    controller: 'whiteLabelViewController',
    viewModel: {
        type: 'whiteLabelViewModel',
    },

    layout: 'vbox',
    bind: {
        masked: '{whitelabelStore.loading}',
    },

    items: [
        {
            xtype: 'container',
            flex: 1,
            layout: 'vbox',
            items: [{
                xtype: 'whiteLabelListView',
                reference: 'actualWhiteLabelPartner',
                bind: {
                    store: {
                        bindTo: '{whitelabelStore}',
                        deep: true,
                    },
                },
            }],
        },
        {
            xtype: 'panel',
            flex: 1,
            title: 'Partner settings',
            bind: {
                masked: '{editorMaskValue}',
            },
            items: [
                {
                    xtype: 'whiteLabelPartnerSettings',
                    itemId: 'partnerSettings',
                },
            ],
        },
    ],
});

import API_RESPONSES from '@powerednow/shared/constants/apiResponses';

Ext.define('FieldServices.view.company.settings.chargeBee.ChargeBeeViewController', {
    extend: 'FieldServices.view.BaseViewController',
    alias: 'controller.chargeBeeViewController',
    xtype: 'chargeBeeViewController',

    control: {
        reconnectingCompanyIdField: true,
        mainContainer: true,
    },

    config: {
        goToChargeBee: null,
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.refreshStatus, this);
    },

    onPainted() {
        this.refreshStatus()
            .catch(handleClientError);
    },

    onCheckAgainTapped() {
        this.refreshStatus()
            .catch(handleClientError);
    },

    onGoToChargeBee() {
        Ext.getStore('mainCompanyStore').loadAsync()
            .then(() => {
                this._openChargeBeeCustomerPage();
            })
            .catch(handleClientError);
    },

    _handleServiceError(response) {
        handleClientError(generateClientError({
            ...response,
            type: ServiceError,
        }, {
            warningBody: response.message,
            showWarningAlert: true,
        }));
    },

    onReconnectAccount() {
        this._reconnectChargeBee().catch(handleClientError);
    },

    async _reconnectChargeBee() {
        try {
            const targetCompanyId = this.getReconnectingCompanyIdField().getValue();
            if (!targetCompanyId) {
                Ext.Msg.alert('Error', 'Please enter target company id!');
                return;
            }
            const { error, sourceChargeBeeId } = await this._checkChargeBeeCanBeMoved();

            if (!error && sourceChargeBeeId) {
                await this.showLoader(this.getMainContainer());
                await this._setChargeBeeIdToCompany(targetCompanyId, sourceChargeBeeId);
                await this.refreshStatus();
            } else {
                Ext.Msg.alert('Error during subscription move', `${error || 'Cannot move due unknown reason!'}`);
            }
        } catch (err) {
            handleClientError(err);
        }
        await this.clearLoader(this.getMainContainer());
    },

    async _checkChargeBeeCanBeMoved() {
        try {
            const targetCompanyId = this.getReconnectingCompanyIdField().getValue();
            const sourceCompanyId = this.getViewModel().get('companyId');

            await this.showLoader(this.getMainContainer());
            const { sourceChargeBeeId } = await FieldServices.app.callAPI({
                url: `integration/chargebee/checkChargeBeeCanBeMoved/${sourceCompanyId}-${targetCompanyId}`,
                method: 'GET',
            });
            await this.clearLoader(this.getMainContainer());

            return {
                sourceChargeBeeId,
            };
        } catch (err) {
            await this.clearLoader(this.getMainContainer());
            if (err.code === API_RESPONSES.CHARGEBEE_ERROR.code) {
                return {
                    error: err.message,
                };
            }
            throw err;
        }
    },

    async _setChargeBeeIdToCompany(newCompanyId, chargebeeId) {
        return FieldServices.app.callAPI({
            method: 'PUT',
            url: `api/admindata/company-tables/Company/${newCompanyId}`,
            jsonData: {
                chargebee_id: chargebeeId,
                id: `${newCompanyId}`,
            },
        });
    },

    _callApiAndCheckChargeBeeConnection(url) {
        this.showLoader(this.getMainContainer());

        FieldServices.app.callAPI({
            url,
            method: 'GET',
        })
            .then(response => {
                this.clearLoader(this.getMainContainer());

                if (response && response.message) {
                    this._handleServiceError(response);
                } else {
                    this.refreshStatus()
                        .catch(handleClientError);
                }
            })
            .catch(err => {
                this.clearLoader(this.getMainContainer());
                handleClientError(err);
            });
    },

    async onConnectToChargeBee() {
        try {
            Ext.Msg.prompt(
                'Connect to a chargebee customer',
                'Enter the customer id in chargebee. Leave empty to create new customer.',
                (buttonId, value, opt) => {
                    this._callApiAndCheckChargeBeeConnection(`integration/chargebee/connectCompany/${this.getViewModel().get('companyId')}-${value}`);
                },
                null,
                false,
            );
        } catch (error) {
            handleClientError(error);
        }
    },

    onDisconnectFromChargeBee() {
        this._callApiAndCheckChargeBeeConnection(`integration/chargebee/disconnectCompany/${this.getViewModel().get('companyId')}`);
    },

    _openChargeBeeCustomerPage() {
        this.showLoader(this.getMainContainer());

        FieldServices.app.callAPI({
            url: `integration/chargebee/getCustomerUrl/${this.getViewModel().get('companyId')}`,
            method: 'GET',
        })
            .then(response => {
                this.clearLoader(this.getMainContainer());

                Utilities.openNewTab(response);
            })
            .catch(err => {
                this.clearLoader(this.getMainContainer());
                handleClientError(err);
            });
    },

    async refreshStatus() {
        const chargeBeeData = await this._findConnectedChargeBeeCustomer(this.getViewModel().get('companyId'));
        this.getViewModel().setData({ chargeBeeData, loaded: true });
    },

    async _findConnectedChargeBeeCustomer(companyId) {
        await this.showLoader(this.getMainContainer());
        try {
            const response = await FieldServices.app.callAPI({
                url: `integration/chargebee/findConnectedChargeBeeContactForCompanyId/${companyId}`,
                method: 'GET',
            });
            await this.clearLoader(this.getMainContainer());
            return response;
        } catch (err) {
            handleClientError(err);
            await this.clearLoader(this.getMainContainer());
        }
        return false;
    },

    _isDevVersion() {
        return require('config.js').BASE_URL.indexOf('dev.powerednow') >= 0;
    },

});

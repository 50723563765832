require('./Field.scss');

Ext.define('FieldServices.overrides.Field', {
    override: 'Ext.field.Field',

    config: { isFilter: true },

    addTableName(filter) {
        if (this.tableName) {
            Object.assign(filter, { tableName: this.tableName });
        }
        return filter;
    },
});

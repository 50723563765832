Ext.define('FieldServices.view.company.info.activityInfo.ActivityInfoViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.activityInfoViewModel',
    xtype: 'activityInfoViewModel',

    requires: [
        'FieldServices.store.activity.Normal',
        'FieldServices.store.activity.Period',
    ],

    stores: {
        normalStore: {
            type: 'NormalActivity',
            filters: [{
                property: 'companyId',
                value: '{company.id}',
            }],
            autoLoad: true,
        },
        periodStore: {
            type: 'PeriodActivity',
            filters: [{
                property: 'companyId',
                value: '{company.id}',
            }],
            autoLoad: true,
        },
    },
});

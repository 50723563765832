const _ = require('lodash');

Ext.define('FieldServices.view.logoCollectionCategory.LogoCollectionCategoryController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.logoCollectionCategory.main',
    config: {
        control: {
            '#actionBtn': {
                click: 'onClickActionBtn',
            },
            '#clearBtn': {
                click: 'onClickClearBtn',
            },
        },

    },

    init() {
        Ext.getStore('logoCollectionCategory').load();
    },

    /**
     * This function maps the countryId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */
    countryRenderer(value) {
        return (value && _.get(Ext.getStore('countries').getById(value), 'data.name')) || '';
    },

    /**
     * Handles the click of our multi-function button which serves as a update/save button, based on the selection state of the grid.
     * If we have row selected in prompts grid, then this button will save the record(if any changes made into record).
     * If we don't have row selected, this button creates a new record in prompts db and reload the prompts grid's store.
     * TODO This method should NOT reload the store, instead use the newly inserted record id. To achieve this, we have to modify both nodeserver, to give back the new record's id
     * and
     *
     * @param {Ext.button.Button} btn
     */
    onClickActionBtn(btn) {
        const me = this;
        if (this.getView().getViewModel().get('updateMode') === false) {
            const data = this.getView()
                .getViewModel()
                .get('currentLogoCollectionCategory');

            this.getView()
                .down('#logoCollectionCategoryGrid')
                .getStore()
                .insert(0, data);
        }
        this.getView()
            .down('#logoCollectionCategoryGrid')
            .getStore()
            .sync({
                callback() {
                    me.syncComplete();
                },
            });
    },

    /**
     *
     * @param {Ext.button.Button} btn
     */
    onClickClearBtn(btn) {
        const me = this;
        this.getView().down('#logoCollectionCategoryGrid').selModel.deselectAll();
    },
    syncComplete() {
        const me = this;
        this.getView().down('#logoCollectionCategoryGrid').getStore().load();
    },
});

Ext.define('FieldServices.view.components.contact.list.ContactListView', {
    extend: 'Ext.grid.Grid',

    xtype: 'contactListView',

    height: 50,
    margin: '10 0 0 10',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'grideditable',
        enableDeleteButton: false,
    }],

    selectable: false,

    emptyText: 'empty',

    columns: [{
        text: 'Contact id',
        dataIndex: 'id',
    }, {
        text: 'First Name',
        dataIndex: 'firstname',
        editable: true,
    }, {
        text: 'Last Name',
        dataIndex: 'lastname',
        editable: true,
    }, {
        text: 'Title',
        dataIndex: 'title',
        flex: 1,
        editable: true,
    }, {
        text: 'alternateContactId',
        dataIndex: 'alternate_contact_id',
        editable: true,
    }, {
        text: 'isDeleted',
        dataIndex: 'isdeleted',
        renderer: 'rendererIsDeleted',
        defaultEditor: {
            xtype: 'checkbox',
        },
        editable: true,
    }],
});

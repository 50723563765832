Ext.define('FieldServices.overrides.data.Store', {
    override: 'Ext.data.Store',
    config: {
        // dependantStores is only implemented in waitToLoad
        dependantStores: [], // This feature is not implemented in autoLoad or an association should be used
    },
    applyProxy() {
        const proxy = this.callParent(arguments);
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');
        if (proxy && proxy.setHeaders) {
            const header = {};
            header[CONSTANTS.HTTP.AUTHENTICATION_HEADER_TOKEN] = adminToken;
            proxy.setHeaders(header);
        }
        return proxy;
    },

    getUrl() {
        return this.getProxy().getUrl();
    },

    getParams() {
        return {
            start: 0,
            skip: 0,
            limit: this.getLoadChunkSize(),
        };
    },

    loadRecords(records, options) {
        const me = this;
        const { length } = records;
        const data = me.getData();
        let addRecords; let i; let
            skipSort;

        if (options) {
            addRecords = options.addRecords;
        }

        if (!me.getRemoteSort() && !me.getSortOnLoad()) {
            skipSort = true;
            data.setAutoSort(false);
        }

        if (!addRecords) {
            me.clearData(true);
        }

        // Clear the flag AFTER the stores collection has been cleared down so that
        // observers of that collection know that it was due to a load, and a refresh is imminent.
        me.loading = false;

        me.ignoreCollectionAdd = true;
        me.callObservers('BeforePopulate');

        // Override data.add(records) with an additional pre process method calling
        data.add(this.preprocessRecords(records));

        me.ignoreCollectionAdd = false;

        if (skipSort) {
            data.setAutoSort(true);
        }

        for (i = 0; i < length; i++) {
            records[i].join(me);
        }

        if (!me.isEmptyStore) {
            ++me.loadCount;
            me.complete = true;
        }

        if (me.hasListeners.datachanged) {
            me.fireEvent('datachanged', me);
        }

        if (me.hasListeners.refresh) {
            me.fireEvent('refresh', me);
        }

        me.callObservers('AfterPopulate');
    },

    preprocessRecords(records) {
        return records;
    },

});

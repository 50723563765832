Ext.define('FieldServices.view.report.ReportViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.reportMainCtrl',
    xtype: 'reportMainCtrl',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '#': {
            setSubPage: 'setSubPage',
        },
    },

    beforeactiveItemchange(tabpanel, newTab, oldTab) {
        if (newTab.getItemId() === 'reportRegistrationTab') {
            return confirm('This is very loads. Continue?');
        }
        return true;
    },
});

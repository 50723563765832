Ext.define('FieldServices.view.logs.TransactionLogs', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.widgets.list.GridListView',
        'FieldServices.view.logs.MongoLogController',
    ],

    controller: 'mongoLogController',
    xtype: 'transactionLogsTable',
    plugins: [{
        type: 'gridcolumnresizing',
    },
    {
        type: 'gridWithFilter',
        id: 'transactionLogsFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/log/Mongo',
            params: {
                limit: 500,
                totalNeeded: true,
                filter: [{
                    property: 'table',
                    value: 'transactionlogs',
                },
                ],
            },
        },
        additionalFilterFields: [
            {
                xtype: 'textfield',
                property: 'companyId',
                placeholder: 'Company ID',
                label: 'Company ID',
                labelAlign: 'left',
                operator: 'numberLike',
            },
            {
                xtype: 'whiteLabelFilter',
                property: 'whitelabelId',
                placeholder: 'White Label',
                labelAlign: 'left',
                operator: '$eq',
                valueValidator: Ext.Number.parseFloat,
                skipFromFilter: true,
            },
        ],
        dateRangeFilters: [{
            property: '_id',
            mode: 'datetime',
            fixedRangeMode: 'dateRange',
            convertToObjectId: true,
            selectableRangeLimit: {
                value: 7,
                intervalType: Ext.Date.DAY,
            },
            label: 'Log date:',
            dbType: 'mongo',
        }],
    }, {
        type: 'gridexporter',
    }],

    layout: 'vbox',
    flex: 1,

    config: {
        showOnlyCompanyData: false,
    },

    listeners: {
        painted: 'setCompanyIdFilter',
    },

    setShowOnlyCompanyData(value) {
        if (value) {
            this.getPlugin('transactionLogsFilterPlugin').getRequestConfig().template.params.filter.push({
                property: 'companyId',
                value: [null],
                operator: '$nin',
            });
        }
    },
    itemConfig: {
        viewModel: true,
    },
    sortable: false,

    emptyText: 'empty',
    columns: [
        {
            text: 'Method',
            dataIndex: 'method',
            flex: 1,
        },
        {
            text: 'Url',
            dataIndex: 'url',
            flex: 3,
        },
        {
            text: 'Company ID',
            dataIndex: 'companyId',
            gridWithFilter: {
                skipFromFilter: true,
            },
            flex: 1,
        },
        {
            text: 'Table name',
            dataIndex: 'tableName',
            flex: 1,
        },
        {
            text: 'User Device Id',
            dataIndex: 'userDeviceId',
            gridWithFilter: {
                operator: 'numberLike',
            },
            flex: 1,
        },
        {
            text: 'Log date',
            dataIndex: 'date',
            gridWithFilter: {
                skipFromFilter: true,
            },
            flex: 1,
        },
        {
            text: 'Response time',
            dataIndex: 'responseTime',
            gridWithFilter: {
                skipFromFilter: true,
            },
            flex: 1,
        },
        {
            text: 'Message',
            dataIndex: 'message',
            flex: 3,
        },
        {
            text: 'Details',
            dataIndex: 'details',
            flex: 3,
        },
    ],
});

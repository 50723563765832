import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.user.list.UserListView', {
    extend: 'Ext.grid.Grid',
    ui: '-users',

    requires: [
        'FieldServices.view.ListItemBaseViewModel',
        'FieldServices.view.company.user.list.UserListViewController',
        'Ext.grid.filters.Filters',
        'FieldServices.view.company.user.profile.UserProfileView',
        'FieldServices.view.components.helpTool.HelpToolView',
    ],

    xtype: 'userList',

    controller: 'userListController',
    viewModel: 'userViewModel',

    layout: 'fit',
    flex: 1,

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'userFilterPlugin',
        filterOnSearchButton: true,
        requestConfig: {
            url: 'api/admindata/company-tables/UserWithHeaders',
            params: {
                sort: [{
                    property: 'isdeleted',
                    direction: 'ASC',
                }, {
                    property: 'is_active',
                    direction: 'DESC',
                }, {
                    property: 'id',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }],
                requiredFields: ['company_id', 'isdeleted'],
            },
        },
    }],

    itemConfig: {
        viewModel: {
            type: 'listItemBaseViewModel',
        },
        bind: {
            ui: '{ui}',
        },
    },

    columns: [
        {
            text: 'ID',
            dataIndex: 'id',
        }, {
            text: 'Email',
            dataIndex: 'email',
            flex: 1,
        }, {
            text: 'Profiles',
            dataIndex: 'userProfileName',
            flex: 1,
        }, {
            text: 'First Name',
            dataIndex: 'firstname',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },

        }, {
            text: 'Last Name',
            dataIndex: 'lastname',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'consumption id',
            dataIndex: 'consumption_id',
        }, {
            text: 'is active',
            dataIndex: 'is_active',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isActiveString}',
                },
            },
        }, {
            text: 'is deleted',
            dataIndex: 'isdeleted',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isDeletedString}',
                },
            },
        }],
});

Ext.define('FieldServices.view.widgets.findCompany.FindCompanyView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.widgets.findCompany.FindCompanyViewController',
    ],

    xtype: 'findCompanyForm',

    controller: 'findCompanyFormCtrl',

    layout: 'vbox',

    title: 'Find Company',

    defaultType: 'textfield',

    items: [
        {
            name: 'id',
            label: 'company_id',
        }, {
            name: 'name',
            label: 'company name',
        },
        {
            xtype: 'button',
            text: 'Find',
            handler: 'onSetCompanySubmit',
        },
    ],

});

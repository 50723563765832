Ext.define('FieldServices.view.tableVersion.TableVersionViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.tableVersionViewController',

    init() {
        this._getStore().load();
    },

    onIncreaseButtonTap(elem) {
        const recordData = elem.ownerCmp.getRecord().getData();
        this._getStore().getById(recordData.id).set('version', parseInt(recordData.version, 10) + 1);
        this._getStore().save();
    },

    _getStore() {
        return Ext.getStore('TableVersion');
    },
});

import 'FieldServices/proxy/GlobalPoweredNowApi';

Ext.define('FieldServices.store.WhiteLabel', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.WhiteLabel'],
    alias: 'store.WhiteLabel',
    config: {
        autoLoad: true,
        model: 'FieldServices.model.WhiteLabel',
        storeId: 'WhiteLabel',

    },
    storeId: 'WhiteLabel',

    proxy: {
        type: 'GlobalPoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/WhiteLabel`,
    },
});

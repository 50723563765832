Ext.define('FieldServices.view.report.usageReport.filter.UsageReportFilterView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.usageReport.filter.UsageReportFilterViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
    ],

    xtype: 'usageReportFilterView',

    controller: 'usageReportFilterViewController',

    scrollable: true,

    layout: 'hbox',

    constructor(config) {
        config.items = this.getItemsConfig();
        this.callParent(arguments);
    },

    getDisplayValue() {
        return this.down('#monthSelect').getFormattedDate();
    },

    getItemsConfig() {
        const initialDate = new Date();
        initialDate.setHours(0, 0, 0, 0);
        initialDate.setMonth(initialDate.getMonth() - 1, 1);
        return [
            {
                xtype: 'container',
                layout: 'vbox',
                flex: 1,
                items: [
                    {
                        xtype: 'container',
                        layout: 'hbox',
                        flex: 1,
                        items: [
                            {
                                xtype: 'container',
                                layout: 'vbox',
                                flex: 1,
                                items: [{
                                    xtype: 'powerednow.datetimefield',
                                    itemId: 'monthSelect',
                                    mode: 'date',
                                    label: 'Month:',
                                    name: 'month',
                                    dateFormat: 'Y-m',
                                    value: initialDate,
                                    maxDate: initialDate,
                                    tableName: 'UsageHistory',
                                    isField: true,
                                },
                                ],
                            },
                            {
                                xtype: 'container',
                                layout: 'vbox',
                                flex: 1,
                                items: [
                                    {
                                        xtype: 'whiteLabelFilter',
                                        includeTest: false,
                                        itemId: 'whiteLabelSelect',
                                    },

                                ],
                            },
                        ],
                    },
                    {
                        xtype: 'formpanel',
                        layout: 'hbox',
                        defaults: {
                            labelAlign: 'top',
                            labelWidth: 'auto',
                        },
                        bodyCls: ['flexEndAligned', 'formPanel_padding'],
                        items: [
                            {
                                xtype: 'checkboxfield',
                                itemId: 'includeChurnedCheckbox',
                                label: 'Include Churned Companies',
                            },
                        ],
                    },
                ],
            },

            {
                xtype: 'container',
                docked: 'bottom',
                items: [{
                    xtype: 'button',
                    text: 'Load',
                    cls: 'btn',
                    handler: 'onChangeFilter',
                }, {
                    xtype: 'button',
                    text: 'Export as xls',
                    iconCls: 'ion-document',
                    handler: 'onDownloadCsvFile',
                    right: '0',
                }],
            },
        ];
    },

});

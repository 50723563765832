const ItemTemplate = require('shared/modules/utilities/itemTemplate');

Ext.define('FieldServices.overrides.Container', {
    override: 'Ext.Container',
    config: {
        requiredRole: null,
        redirectOnMissingRole: true,
    },

    listeners: {
        beforeshow: {
            fn: 'checkPermission',
            scope: 'this',
        },
    },
    constructor(...args) {
        const [config] = args;
        const items = Ext.clone(this.itemsTemplate);
        if (items) {
            const itemTemplate = new ItemTemplate({ ...this.config, ...config });
            config.items = itemTemplate.replace(items);
        }
        this.callParent(args);
    },

    async checkPermission() {
        if (this.getRequiredRole()) {
            const hasRole = await AuthManager.userHasRole(this.getRequiredRole());

            if (!hasRole) {
                this.hide();
                if (this.getRedirectOnMissingRole()) {
                    FieldServices.app.goToHome();
                }
            }
        }
    },

    updateLabel(newLabel, oldLabel) {
        this.down('checkboxfield').setLabel(newLabel);
    },

    updateLabelAlign(newLabelAlign, oldLabelAlign) {
        this.down('checkboxfield').setLabelAlign(newLabelAlign);
    },

    // We can't define custom function to keyMapTarget(the component where we listen to the event), just function name.
    keyMapTarget: 'getDocumentBody',
    // by default listen on the document for keyMap events.
    getDocumentBody() {
        return Ext.getBody();
    },

});

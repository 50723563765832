Ext.Factory.prototype.create = function (config, defaultType) {
    const me = this;
    const meDefaultType = me.defaultType;
    const Manager = Ext.ClassManager;
    const { cache } = me;
    const { typeProperty } = me;
    let alias; let className; let klass; let 
        suffix;

    if (config) {
        if (config[me.instanceProp]) {
            return config;
        }

        if (typeof config === 'string') {
            suffix = config;
            config = {};
            config[me.defaultProperty] = suffix;
        }

        className = config.xclass;
        suffix = config[typeProperty];
    }

    if (defaultType && defaultType.constructor === Object) {
        config = Ext.apply({}, config, defaultType);
        defaultType = defaultType[typeProperty];
    }

    if (className) {
        if (!(klass = Manager.get(className))) {
            return Manager.instantiate(className, config);
        }
    } else {
        if (!(suffix = suffix || defaultType || meDefaultType)) {
            klass = me.defaultClass;
        }
        // <debug>
        if (!suffix && !klass) {
            Ext.raise(`No type specified for ${me.type}.create`);
        }
        // </debug>

        //
        // OVERRIDE
        //
        if (config && (config.$isClass || (config.type && config.type.$isClass))) {
            const constructor = config.type || config;
            klass = constructor;
            suffix = klass.$className;
            config = Ext.apply({}, config, {
                [me.defaultProperty]: suffix,
            });
            cache[suffix] = klass;
        }
        //
        // END OF OVERRIDE
        //

        if (!klass && !(klass = cache[suffix])) {
            alias = me.aliasPrefix + suffix;
            className = Manager.getNameByAlias(alias);

            // this is needed to support demand loading of the class
            if (!(klass = className && Manager.get(className))) {
                return Manager.instantiateByAlias(alias, config);
            }
            cache[suffix] = klass;
        }
    }

    return klass.isInstance ? klass : new klass(config);
};

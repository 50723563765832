/**
 *
 * @class Override.Button
 * @extends Ext.Button
 *
 */
require('./Slider.scss');

Ext.define('FieldServices.overrides.Slider', {
    override: 'Ext.slider.Slider',
});

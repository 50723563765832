import CONSTANTS from 'shared/constants';
import {
    ValidationError,
    NetworkError,
    ServerError,
    ServiceError,
} from '@powerednow/shared/error';

window.ValidationError = ValidationError;
window.NetworkError = NetworkError;
window.ServerError = ServerError;
window.ServiceError = ServiceError;

export {
    ValidationError,
    NetworkError,
    ServerError,
    ServiceError,
};

window.showErrorByToast = function (message, timeout = 5000) {
    if (Ext && Ext.toast) {
        Ext.toast({ message, timeout });
    } else {
        alert(message);
    }
};

window.generateClientError = function (definition, data) {
    data = data || {};
    data.message = data.message || definition.message;
    data.code = data.code || definition.code;

    const error = new definition.type(data);

    if (data) {
        Object.keys(data).forEach(key => {
            error[key] = data[key];
        });
    }

    console.log(error.response);
    return error;
};

export function handleClientError(error) {
    console.log('---------->', error);

    if (error && error.stack) {
        console.trace(error.stack);
    }

    if (error instanceof NetworkError) {
        if (error.warningBody || error.warningTitle || error.showWarningAlert) {
            Ext.Msg.alert(error.warningTitle || L('NetworkError.WarningTitle', 'Network Error'), error.warningBody || L('NetworkError.WarningBody', 'You have to be online to use this feature!'));
        }
        //
        // I was dumb as NetworkError (and any other errors) inherited from Error, so (new NetworkError('')) instanceof Error === true
        // so we must terminate on every handled error.
        //
        return;
    }

    if (error instanceof window.ServiceError) {
        if (error.warningBody && error.showWarningAlert) {
            Ext.Msg.alert(error.warningTitle || 'Service Error', error.warningBody);
        }
    }

    if (error instanceof window.ServerError) {
        if (error.code === CONSTANTS.API_RESPONSES.AUTH_FAILED.code) {
            const message = {
                title: 'Authentication error',
                text: 'Authentication failed, please re-login into application',
            };
            console.log('ewfsdfsdfdssdvsdgewg');
            shared.Utils.utilities.forceRelogin(message);
        }
    }

    showErrorByToast(error.message || error.warningTitle || error.warningBody || error.showWarningAlert);
}

window.handleClientError = handleClientError;

window.addEventListener('error', errorEvent => {
    // LogUtils.logErrorEvent(errorEvent);
    if (errorEvent && errorEvent.filename && errorEvent.filename.indexOf(window.location.origin) > -1) {
        return handleClientError(errorEvent.error);
    }

    showErrorByToast(errorEvent.message);
});

import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.WhiteLabelMetrics', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.WhiteLabelMetrics'],
    alias: 'store.WhiteLabelMetrics',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.WhiteLabelMetrics',
        storeId: 'WhiteLabelMetrics',

    },
    storeId: 'WhiteLabelMetrics',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/WhiteLabelMetrics`,
    },
});

Ext.define('Override.Formula', {
    override: 'Ext.app.bind.Formula',

    react() {
        const me = this;
        const { owner } = me;
        const data = me.binding.lastValue;
        const { getterFn } = me;
        let arg;

        if (me.explicit) {
            arg = data;
        } else {
            arg = owner.getFormulaFn(data);
        }
        me.settingValue = true;
        me.calculation = me.get.call(owner, arg);
        //
        // ----- OVERRIDE: calculation can by an async method, need to handle it
        //
        if (me.calculation && me.calculation.then) {
            me.calculation.then(theArgs => {
                me.stub.set(me.calculation = theArgs);
                me.settingValue = false;

                if (me.single) {
                    me.destroy();
                }
            });
        } else {
            me.stub.set(me.calculation = me.get.call(owner, arg));
            me.settingValue = false;

            if (me.single) {
                me.destroy();
            }
        }
    },

});

const _ = require('lodash');
const { memoizeRunningPromise } = require('shared/decorators');

Ext.define('FieldServices.view.company.device.DeviceViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.deviceViewCtrl',
    xtype: 'deviceViewCtrl',

    mixins: ['FieldServices.mixins.subPage'],
    /**
     * @event infoButtonTap
     *
     */
    control: {
        '': {
            setSubPage: 'setSubPage',
            painted: '_loadDevices',
        },
    },

  init() {
    this.callParent();
    this.getViewModel().bind('{companyId}', this._loadDevices, this);
  },


    @memoizeRunningPromise
    async _loadDevices() {
      const view = this.getView();
        await this.showLoader(view);
        try {
          view.setData([]);
          const companyId = this.getViewModel().get('companyId');
          const apiResponse = await FieldServices.app.callAPIEndpointRequest('GET', `api/admindata/company/getDevice/${companyId}`, {})
          if (apiResponse) {
            const data = this._moveDeepDataToFirstLevel(apiResponse);
            view.setData(data);
            view.getColumns().forEach(column => column.autoSize())
          }
          const onlineUsers = await this._loadOnlineUsers();
          this._setOnlineStatus(onlineUsers);
        } catch (err){
          handleClientError(err);
        }
        await this.clearLoader(view)

    },

    _moveDeepDataToFirstLevel(data) {
        return data.map((item) => {
            this.getView().convertList.forEach((moveKey) => {
                Object.assign(item, { [moveKey]: _.get(item, moveKey) });
            });
            return item;
        });
    },

    onInfoButtonTap(elem) {
        const popup = References.getDevicePopup().show();
        popup.getController().setCompanyId(this.getViewModel().get('companyId'));
        popup.getController().setRecord(elem.ownerCmp.getRecord());
    },

    onDelete(elem) {
        const selectedRecords = [elem.ownerCmp.getRecord()];
        Ext.Msg.confirm('Delete', 'Are you sure you want to delete this device?', (btn) => {
          if (btn === 'yes') {
            this._deleteSelectedRecords(selectedRecords).catch(handleClientError);
          }
        });
    },

    async _loadOnlineUsers() {
        return Utilities.getOnlineUsers(this.getViewModel().get('companyId'));
    },

    _setOnlineStatus(onlineUsers) {
        const userDeviceIds = onlineUsers.map(onlineUser => onlineUser.userdevice);
        this.getView().getViewModel().set('onlineIds', userDeviceIds);
    },

    async _deleteSelectedRecords(records) {
        try {
            await this.showLoader(this.getView());
            await this._processDeletions(records);
            Ext.Msg.alert('Alert', 'The deletion started. The device cleanup process will run in the background.');
        } catch (error) {
            handleClientError(error);
        } finally {
            await this.clearLoader(this.getView());
        }
    },

    _processDeletions(records) {
        return Promise.all(
            records.map(record => this._callDeleteApi(record.get('id'))),
        );
    },

    _callDeleteApi(id) {
        if (!id) {
            return Promise.reject(new Error(`Id is not valid - ${id}`));
        }

        const detailsData = this._getDataById(id);
        return FieldServices.app.callAPI({
            method: 'DELETE',
            url: `api/admindata/audited-company-table/UserDevice/${this.getViewModel().get('companyId')}`,
            jsonData: detailsData,
        });
    },
    _getDataById(id) {
        const fields = {
            companyId: parseInt(this.getViewModel().get('companyId'), 10),
            id,
        };

        const audit = {
            event_type: CONSTANTS.ADMIN_AUDIT_TRAIL.EVENT_TYPES.DELETE_USER_DEVICE,
        };

        return {
            audit,
            fields,
        };
    },
});

export default Ext.define('FieldServices.model.Mongo', {
    extend: 'FieldServices.model.override.Model',

    // fields: [
    //     { name: '_id', type: 'string' },
    //     { name: 'dtCreated', type: 'string' },
    //     { name: 'appVersion', type: 'string' },
    //     { name: 'companyId', type: 'int' },
    //     { name: 'details', type: 'string' },
    //     { name: 'deviceId', type: 'int' },
    //     { name: 'userId', type: 'int' },
    //     { name: 'message', type: 'string' },
    //     { name: 'severity', type: 'int' },
    //     { name: 'stackTrace', type: 'string' },
    //     { name: '__v', type: 'int' },
    // ],

    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',

});

export default Ext.define('FieldServices.overrides.Exporter', {

    override: 'Ext.grid.plugin.Exporter',

    getCell(store, record, columnDefinition) {
        const { dataIndex } = columnDefinition;
        let value = record.get(dataIndex);

        // ----- >>>> OVERRIDE
        const { bind } = columnDefinition.column.getCell();
        if (!dataIndex && bind) {
            value = this._extractDataFromViewModel(bind, record);
        }
        // <<<<< ---- OVERRIDE

        return {
            value: columnDefinition.fn(value, record, dataIndex, null, columnDefinition.column),
        };
    },

    _extractDataFromViewModel(bind, record) {
        const itemConfig = this.cmp.getItemConfig();
        const viewModelType = itemConfig.viewModel && itemConfig.viewModel.type;
        let viewModelClass = 'Ext.app.ViewModel';
        if (viewModelType) {
            viewModelClass = `viewmodel.${viewModelType}`;
        }
        const viewModel = Ext.create(viewModelClass, { data: { record } });
        const bindText = bind instanceof Object
            ? bind.value
            : bind;
        viewModel.notify();
        const valueFromViewModel = viewModel.get(bindText.match(/{(.*)}/)[1]);
        viewModel.destroy();
        return valueFromViewModel;
    },

});

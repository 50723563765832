import { ADMIN_REPORT } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.salesReport.SalesReportViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.salesReportViewController',
    xtype: 'salesReportViewController',

    control: {
        gridTabs: true,
        filterComponent: {
            changeFilter: 'loadReportData',
            exportRequested: 'onExportRequested',
        },

    },

    async loadReportData({ filterData, includeChurned }) {
        this.getViewModel().setData({ salesReportLoading: true });
        try {
            const apiResponse = await FieldServices.app.callAPI(
                {
                    url: 'api/admindata/usageReport/getSalesReportData',
                    method: 'GET',
                    params: { filter: Ext.encode(filterData) },
                    headers: {},
                },
            );

            const apiResponseData = apiResponse || [];
            this.processAllReportGroups(apiResponseData, includeChurned);
        } catch (error) {
            handleClientError(error);
        }

        this.getViewModel().setData({ salesReportLoading: false });
    },

    processAllReportGroups(apiResponseData, includeChurned) {
        const groupConfigs = ADMIN_REPORT.USAGE_HISTORY_GROUPS;
        const reportConfigs = [
            { dataKeys: groupConfigs.COMPANY_SALES, storeName: 'SalesReportStore' },
            { dataKeys: groupConfigs.COMPANY_PAYMENTS, storeName: 'PaymentReportStore' },
        ];
        const { reportData } = apiResponseData;
        const filteredData = reportData.filter(responseRow => includeChurned || responseRow.active_purchases > 0);
        reportConfigs.forEach(config => {
            const processedData = filteredData.map(responseRow => {
                const resultObject = {};
                config.dataKeys.forEach(key => {
                    resultObject[key] = responseRow[key];
                });
                return resultObject;
            });
            Ext.getStore(config.storeName).setData(processedData);
        });
    },

    onExportRequested() {
        const activeTab = this.getGridTabs().getActiveItem();
        const reportName = activeTab.tab.config.title.replace(' ', '_');
        const reportDate = this.getFilterComponent().getDisplayValue();
        activeTab.saveDocumentAs({ fileName: `${reportName} ${reportDate}.xlsx` });
    },

});

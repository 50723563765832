Ext.define('FieldServices.plugins.GridWithHideableRows', {
    extend: 'Ext.plugin.Abstract',
    alias: 'plugin.gridWithHideableRows',

    defaultToolbar: {
        xtype: 'toolbar',
        docked: 'top',
        pack: 'start',
        layout: {
            type: 'hbox',
            align: 'start',
        },
        items: [{
            title: 'Groups',
            xtype: 'panel',
            itemId: 'groupingCheckBoxContainer',
            layout: {
                type: 'vbox',
                wrap: true,
            },
            height: 200,
            flex: 0.5,
            items: [],
        }],
    },

    init(gridView) {
        this.setCmp(gridView);
        this._insertToolbarToGrid();

        const { groupings } = this.getInitialConfig();
        this._insertGroupHidingCheckboxes(groupings);

        // @TODO hiding columns takes way too much time
        // Ext.grid.column.Column.prototype.isHideable = () => true;

        gridView.on('initialize', this._setupColumnsMenu.bind(this, gridView));
    },

    _hideNonDefaults(gridView) {
        const { groupings, defaults } = this.getInitialConfig();
        Object.keys(groupings)
            .filter(groupName => !defaults.includes(groupName))
            .forEach(groupName => {
                gridView.down(`#${groupName}Field`).updateChecked(false);
            });
    },

    _setupColumnsMenu(gridView) {
        const columnsMenuItem = gridView.getColumnsMenuItem();
        const menu = columnsMenuItem.getMenu();
        menu.fireEvent('beforeshow', menu);
        this._hideNonDefaults(gridView);
    },

    _insertGroupHidingCheckboxes(groupings) {
        this._getCheckboxContainer('#groupingCheckBoxContainer').setItems([
            ...Object.keys(groupings).map(this._createCheckbox.bind(this)),
        ]);
    },

    _createCheckbox(groupName) {
        return {
            xtype: 'checkboxfield',
            itemId: `${groupName}Field`,
            name: groupName,
            boxLabel: groupName.camelCaseToDisplay(),
            checked: this.config.defaults.includes(groupName),
            height: 50,
            listeners: {
                change(checkbox) {
                    this.lookupViewModel().set(groupName, checkbox.isChecked());
                },
            },
        };
    },

    _getGridView() {
        return this.getCmp();
    },

    _getCheckboxContainer(itemId) {
        return this._getGridView().down(itemId);
    },

    _insertToolbarToGrid() {
        const configWithUi = { ui: this._getGridView().getUi(), ...this.defaultToolbar };
        this._getGridView().insert(1, configWithUi);
    },
});

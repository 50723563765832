Ext.define('FieldServices.view.components.helpTool.HelpToolContentView', {
    extend: 'Ext.Dialog',
    requires: [
        'FieldServices.view.components.helpTool.HelpToolContentViewController',
    ],
    controller: 'helpToolContentViewController',

    xtype: 'helpToolContent',

    keyMap: {
        ESC: {
            handler: 'onClose',
            scope: 'controller',
            event: 'keydown',
        },
    },

    title: 'Help',

    layout: 'vbox',
    flex: 1,
    scrollable: 'vertical',

    tools: [{
        type: 'close',
        handler: 'onClose',
    }],

    padding: 10,

    items: [],
});

Ext.define('FieldServices.view.report.ReportView', {
    extend: 'Ext.tab.Panel',

    requires: [
        'FieldServices.view.report.ReportViewController',
        'FieldServices.view.report.subscriptions.SubscriptionsView',
        'FieldServices.view.report.action.PurchaseActionDetailView',
        'FieldServices.view.report.cohort.CohortMainView',
        'FieldServices.view.report.background.BackgroundView',
        'FieldServices.view.report.salesReport.SalesReportView',
        'FieldServices.view.report.monthlyReports.MonthlyReportsView',
        'FieldServices.view.report.usageReport.UsageReportView',
        'Ext.grid.plugin.SummaryRow',
    ],
    requiredRole: CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_REPORTS,
    alias: 'widget.report',

    controller: 'reportMainCtrl',

    defaults: {
        tab: {
            iconAlign: 'top',
            flex: 1,
        },
    },
    header: {
        layout: {
            align: 'stretchmax',
        },
        title: {
            text: 'PNAdmin',
            flex: 0,
        },
        iconCls: 'ion-ios-list-outline',
    },
    tabBar: {
        flex: 1,
        layout: {
            align: 'stretch',
            overflowHandler: 'none',
        },
    },
    ui: 'navigation',
    tabBarPosition: 'top',
    headerPosition: 'top',
    tabBarHeaderPosition: 1,
    titleRotation: 0,
    tabRotation: 0,
    cls: 'reportView',
    items: [
        {
            title: 'Subscriptions',
            itemId: 'subscriptionsTab',
            iconCls: 'ion-earth',
            layout: 'fit',
            items: [{
                xtype: 'subscriptionsView',
                itemId: 'subscriptionsTab',
            }],

        },
        {
            title: 'Purchase Actions',
            iconCls: 'ion-earth',
            layout: 'fit',
            items: [{
                xtype: 'purchaseActionDetailView',
            }],

        },
        {
            title: 'Cohort',
            iconCls: 'ion-earth',
            layout: 'fit',
            items: [{
                xtype: 'cohortMainView',
            }],

        },
        {
            title: 'Monthly Reports',
            itemId: 'monthlyReportsTab',
            iconCls: 'ion-calendar',
            layout: 'fit',
            items: [{
                xtype: 'monthlyReports',
            }],
        },
        {
            title: 'Usage Reports',
            iconCls: 'ion-earth',
            layout: 'fit',
            items: [{
                xtype: 'usageReportView',
            }],
        },
        {
            title: 'Sales & Payments Report',
            iconCls: 'ion-earth',
            layout: 'fit',
            items: [{
                xtype: 'salesReportView',
            }],
        },

    ],

});

/**
 * Created by steveetm1 on 11/08/15.
 */
Ext.define('FieldServices.view.logoCollectionCategory.LogoCollectionCategoryModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.logoCollectionCategory.main',

    stores: {
        logoCollectionCategory: {
            autoLoad: true,
        },
    },
    formulas: {
        currentLogoCollectionCategory: {
            bind: {
                bindTo: '{logoCollectionCategoryGrid.selection}',
                deep: true,
            },
            get(logoCollectionCategory) {
                return logoCollectionCategory;
                /**
                 * If we don't want a 'two-way' binding, for example modify the selected records in the form *without* writing back the results to the grid on-the-fly, then we must
                 * clone the data propery from logoCollectionCategory.
                 *
                 * Cloning the logoCollectionCategory object is NOT working!
                 */
                // return Ext.clone(logoCollectionCategory.data);
            },
        },
        /**
         * You can use this in binding configuration to check wheter the state of the view is in update(record selected in grid)
         * @param get function to query formulas and data from viewModel
         * @returns {boolean}
         */
        updateMode(get) {
            return Boolean(get('currentLogoCollectionCategory'));
        },
        /**
         * Dynamically modify the text on the actionButton to represent the state of the view.
         * Not too much sense btw, demo purposes right now.
         *
         * @param get function to query formulas and data from viewModel
         * @returns {*}
         */
        buttonText(get) {
            if ((get('currentLogoCollectionCategory'))) {
                return 'Update logoCollectionCategory record';
            }
            return 'Save new logoCollectionCategory record';
        },
    },
    data: {
        /**
         * By default we want to show both the grid and the form in our views.
         * Pass the noGrid config variable upon component creation to hide the grid.
         */
        noGrid: false,
    },
});

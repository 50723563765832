Ext.define('FieldServices.view.company.contact.info.ContactInfoView', {
    extend: 'Ext.Panel',
    xtype: 'contactInfoView',

    requires: [
        'FieldServices.view.company.contact.info.ContactInfoViewController',
        'FieldServices.view.company.contact.info.ContactInfoViewModel',

        'FieldServices.view.components.customer.CustomerView',
        'FieldServices.view.components.address.AddressView',
        'FieldServices.view.components.contactToAddress.ContactToAddressView',
        'FieldServices.view.components.contactMethod.ContactMethodView',
        'FieldServices.view.components.contact.list.ContactListView',
    ],

    controller: 'contactInfoViewController',
    viewModel: 'contactInfoViewModel',
    itemId: 'contactInfoView',

    keyMap: {
        ESC: {
            handler: 'onCancelContactPopup',
            scope: 'controller',
            event: 'keydown',
        },
    },

    title: 'Contacts box',

    tools: [{
        type: 'close',
        handler: 'onClose',
    }],

    layout: 'vbox',
    flex: 1,
    scrollable: 'vertical',

    config: {
        panelViewConfig: {
            xtype: 'panel',
            title: '',
            layout: 'vbox',
            margin: '5',
            items: [],
        },
        alternateViewConfig: {
            xtype: 'component',
            emptyText: 'No Data Loaded',
            cls: 'panel_padding',
            tpl: '<b class="contactTitle">{viewTitle}</b>',
        },
        buttonsViewConfig: {
            xtype: 'panel',
            items: [{
                xtype: 'button',
                text: 'Cancel',
                handler: 'onClose',
                margin: '5 80 0 10',
            }, {
                xtype: 'button',
                text: 'Restore All',
                handler: 'restoreCustomer',
                margin: '5 10 0 10',
            }, {
                xtype: 'button',
                text: 'Delete All',
                handler: 'deleteCustomer',
                margin: '5 10 0 10',
            }, {
                xtype: 'button',
                text: 'Revert changes',
                handler: 'revertChanges',
                margin: '5 10 0 10',
                bind: {
                    disabled: '{!dataChanged}',
                },
            }, {
                xtype: 'button',
                text: 'Save modifications',
                handler: 'saveModifications',
                margin: '5 10 0 10',
                bind: {
                    disabled: '{!dataChanged}',
                    cls: '{saveButtonClass}',
                },
            }],
        },
    },

    items: [
        {
            xtype: 'container',
            items: [],
            itemId: 'customerPanel',
        },
        {
            xtype: 'container',
            items: [],
            itemId: 'orphanPanel',
        }],
});

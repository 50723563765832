Ext.define('FieldServices.overrides.data.ChainedStore', {
    override: 'Ext.data.ChainedStore',

    getRecords() {
        return (this.getData && this.getData() && this.getData().items) || [];
    },

    getAutoLoad() {
        return this.getSource() ? this.getSource().getAutoLoad() : false;
    },
});

Ext.define('FieldServices.view.prompt.list.PromptsListViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.prompt.list',

    control: {
        '': {
            itemtap: 'onItemTap',
        },
        newPromptButton: {
            click: 'onNewPromptButtonClicked',
        },
    },

    onNewPromptButtonClicked() {
        try {
            this._showPromptPopup();
        } catch (err) {
            handleClientError(err);
        }
    },

    _showPromptPopup() {
        References.getPromptPopup().show();
        References.getPromptPopup().getViewModel().set({
            currentPrompt: new FieldServices.model.Prompt({}),
        });
    },

    /**
     * This function maps the countryId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */
    countryRenderer(value) {
        return this._findRecordValue('countries', value, 'name');
    },

    /**
     * This function maps the languageId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */
    languageRenderer(value) {
        return this._findRecordValue('languages', value, 'title');
    },

    _findRecordValue(table, rowId, retValField) {
        const id = rowId && rowId.id ? rowId.id : rowId;
        const record = Ext.getStore(table).getById(id);

        return record && record.data ? record.data[retValField] : '';
    },

    onItemTap(dataview, index, target, record, e, eOpts) {
        // console.log('onItemTap');

        References.getPromptPopup().show();
        References.getPromptPopup().getViewModel().setData({
            currentPrompt: record,
        });
    },
});

Ext.define('FieldServices.view.company.info.comments.CommentsView', {
    extend: 'Ext.panel.Panel',
    title: 'Comments',
    xtype: 'commentsView',
    requires: [
        'FieldServices.view.company.info.comments.CommentsViewController',
    ],
    layout: 'vbox',
    controller: 'commentsController',
    items: [
        {
            xtype: 'formpanel',
            layout: 'hbox',
            cls: 'newCommentFormPanel',
            itemId: 'newCommentForm',
            items: [
                {
                    flex: 4,
                    xtype: 'textareafield',
                    itemId: 'newCommentField',
                    reference: 'newCommentField',
                    minRows: 3,
                    name: 'comment',
                }, {
                    xtype: 'button',
                    text: 'Add new comment',
                    cls: 'sendCommentBtn',
                    bind: {
                        disabled: '{!newCommentField.value}',
                    },
                    itemId: 'sendNewCommentBtn',
                },
            ],
        },
        {
            xtype: 'list',
            itemId: 'commentsList',
            flex: 5,
            layout: {
                type: 'vbox',
                align: 'stretch',
            },
            itemContentCls: 'commentContainer',
            itemConfig: {
                xtype: 'container',
                layout: 'vbox',

                cls: 'commentContainer',
                viewModel: {
                    formulas: {
                        commentData: {
                            bind: '{record}',
                            get(record) {
                                return record.data;
                            },
                        },
                        hideDeleteBtn: {
                            bind: '{commentData}',
                            get(data) {
                                const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');
                                return data.user_id !== adminUserData.id || data.details.commenterCompanyId !== adminUserData.company_id;
                            },
                        },
                    },
                },
                items: [
                    {
                        xtype: 'button',
                        iconCls: 'ion-trash-a',
                        cls: 'deleteCommentBtn',
                        bind: {
                            data: '{record}',
                            hidden: '{hideDeleteBtn}',
                        },
                        listeners: {
                            tap: {
                                fn: 'onDeleteCommentClick',
                                scope: 'controller',
                            },
                        },
                    },
                    {
                        xtype: 'container',
                        cls: 'comment',
                        bind: {
                            data: '{commentData}',
                        },
                        tpl: '<div><small><b>{details.commenter}</b> - {dt_created}</small><hr>{details.comment:markdown}</div>',
                    },
                ],
            },
            selectable: false,
            bind: {
                store: {
                    bindTo: '{commentsStore}',
                    deep: true,
                },
            },
        },

    ],
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Contact', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Contact'],
    alias: 'store.Contact',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Contact',
        storeId: 'Contact',
        remoteFilter: true,
        remoteSort: true,
        loadInChunks: true,
        extraKeys: {
            byContactId: 'id',
        },
    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/Contact`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
        appendId: false,
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.job.JobListView', {
    extend: 'Ext.grid.Grid',
    ui: '-projects',

    requires: [
        'FieldServices.view.company.job.JobListViewModel',
        'FieldServices.view.company.job.JobListViewController',
        'Ext.grid.filters.Filters',
    ],

    xtype: 'jobListView',
    controller: 'jobListController',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'jobFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/Job',
            params: {
                sort: [{
                    property: 'isdeleted',
                    direction: 'ASC',
                }, {
                    property: 'dt_created',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    operator: '=',
                    value: '{companyId}',
                }, {
                    value: [true, false],
                    property: 'isdeleted',
                    operator: 'in',
                }],
                requiredFields: ['company_id', 'isdeleted', 'lastActivity'],
            },
        },
        dateRangeFilters: [{
            property: 'dt_created',
            label: 'created',
        }, {
            property: 'lastActivity',
            label: 'last activity',
        }],
    }],

    itemConfig: {
        viewModel: {
            type: 'jobListViewModel',
        },
        bind: {
            ui: '{ui}',
        },
    },

    emptyText: 'empty',

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'Description',
            dataIndex: 'description',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Created',
            dataIndex: 'dt_created',
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'Last Activity',
            dataIndex: 'lastActivity',
            flex: 1,
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'Total Quoted',
            dataIndex: 'totalquoted',
            width: 50,
        }, {
            text: 'Total Invoiced',
            dataIndex: 'totalinvoiced',
            flex: 1,
        }, {
            text: 'Total Paid',
            dataIndex: 'totalpaid',
            width: 50,
        }, {
            text: 'Total Accepted',
            dataIndex: 'totalaccepted',
            width: 50,
        }, {
            text: 'Outstanding',
            dataIndex: 'outstanding',
        }, {
            text: 'Customer Id',
            dataIndex: 'customer_id',
        }, {
            text: 'Site Id',
            dataIndex: 'site_id',
        }, {
            text: 'Is Deleted',
            dataIndex: 'isdeleted',
            width: 50,
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isDeletedString}',
                },
            },
        },
    ],
});

Ext.define('FieldServices.model.activity.Period', {
    extend: 'Ext.data.Model',

    fields: [{
        name: 'growthPercent',
        calculate: recordData => {
            const growth = ((recordData.actual / recordData.previous) * 100) - 100;
            return isNaN(growth) ? 0 : growth;
        },
    }, {
        name: 'growth',
        calculate: recordData => `${recordData.growthPercent > 0 ? '+' : ''}${recordData.growthPercent.toFixed(2)}%`,
    }, {
        name: 'label',

    }, {
        name: 'actual',

    }, {
        name: 'previous',
    }],

});

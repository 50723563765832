Ext.define('FieldServices.view.widgets.list.GridListView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.widgets.list.GridListViewController',
        'Ext.grid.cell.Widget',
    ],

    xtype: 'gridListView',
    controller: 'gridListViewCtrl',

    listeners: {
        painted: 'onLoad',
    },

    layout: 'fit',
    flex: 1,

    tools: [{
        type: 'plus',
        handler: 'onAdd',
        itemId: 'onAddId',
        hidden: true,
    }, {
        type: 'print',
        handler: 'onGetCsv',
        itemId: 'getCsvId',
    }],
    /**
     * @fires detailsTap
     * @param record
     */
    items: [
        {
            xtype: 'grid',
            reference: 'grid',

            infinite: true,
            grouped: true,

            plugins: [{
                type: 'gridcolumnresizing',
            }],

            emptyText: 'empty',

            columns: [
                {
                    text: 'Date',
                    dataIndex: 'date',
                    flex: 1,
                }, {
                    text: 'Subscribed',
                    dataIndex: 'subscribed',
                    flex: 1,
                }, {
                    text: 'Expired',
                    dataIndex: 'expired',

                }, {
                    text: 'Cumulative',
                    dataIndex: 'cumulative',
                },
            ],
        },
    ],

});

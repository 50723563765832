Ext.define('Shared.Components.field.dateTimeField.DateTimeFieldViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.dateTimeFieldViewModel',

    formulas: {
        dateFieldLabel: {
            bind: {
                label: '{label}',
                mode: '{mode}',
            },
            get() {
                const label = this.get('label');
                const isDateFieldNeeded = this._isDateFieldNeeded();
                return isDateFieldNeeded ? label : '';
            },
        },
        timeFieldLabel: {
            bind: {
                label: '{label}',
                mode: '{mode}',
            },
            get() {
                const label = this.get('label');
                const isTimeFieldNeeded = this._isTimeFieldNeeded();
                const isDateFieldNeeded = this._isDateFieldNeeded();
                return isTimeFieldNeeded && !isDateFieldNeeded ? label : '';
            },
        },
        isTimeFieldNeeded: {
            bind: '{mode}',
            get() {
                return this._isTimeFieldNeeded();
            },
        },
        isDateFieldNeeded: {
            bind: '{mode}',
            get() {
                return this._isDateFieldNeeded();
            },
        },
    },

    _isDateFieldNeeded() {
        return [
            'date',
            'datetime',
        ].includes(this.get('mode'));
    },

    _isTimeFieldNeeded() {
        return [
            'time',
            'datetime',
        ].includes(this.get('mode'));
    },
});

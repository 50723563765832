import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.PaymentType', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.PaymentType'],
    alias: 'store.PaymentType',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.PaymentType',
        storeId: 'PaymentType',
        extraKeys: {
            byPaymentTypeId: 'id',
        },
    },
    storeId: 'PaymentType',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/PaymentType`,
    },
});

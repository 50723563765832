Ext.define('FieldServices.view.company.appointment.details.AppointmentDetailsView', {
    extend: 'Ext.Panel',
    requires: [
        'FieldServices.view.company.appointment.details.AppointmentDetailsViewController',
    ],

    xtype: 'appointmentDetailsView',

    controller: 'appointmentDetailsViewController',

    itemId: 'AppointmentDetailsViewId',

    scrollable: true,

    title: 'Appointment details',

    keyMap: {
        ESC: {
            handler: 'onCancel',
            scope: 'controller',
            event: 'keydown',
        },
    },

    tools: [{
        type: 'close',
        handler: 'onCancel',
    }],
    layout: 'vbox',
    items: [
        {
            xtype: 'container',
            itemId: 'recordData',
            html: '',
        },
        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->', {
                xtype: 'button',
                itemId: 'cancelButton',
                text: 'Cancel',
                iconCls: 'ion-ios-close-outline',
            }],
        },
    ],

});

const config = require('config.js');

Ext.define('FieldServices.view.whiteLabel.settings.settingsViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.WhiteLabelPartnerSettingsViewController',

    requires: [],

    control: {},

    config: {},

    onCancelButtonTap() {
        this._fireEvent('cancelRecordChanges');
    },

    onSaveButtonTap() {
        if (this._isValid()) {
            this._fireEvent('saveRecord');
        }
    },

    onSwitchToTrialTap() {
        this._requestSwitchPartnerToForcedTrial();
    },

    _isValid() {
        const record = this._getRecord();
        const partnerName = (record.data.name || '').trim();

        if (!partnerName) {
            Ext.Msg.alert('Warning', 'Partner name must be filled');
            return false;
        }

        return true;
    },

    _fireEvent(eventName) {
        const record = this._getRecord();
        this.getView().fireEvent(eventName, record);
    },

    _getRecord() {
        return this.getViewModel().getData().record;
    },

    _requestSwitchPartnerToForcedTrial() {
        const { name } = this._getRecord().data;
        Ext.Msg.confirm('Warning', `Are You sure you would like to switch all accounts to 14 day trial for partner ${name}?`, buttonId => {
            if (buttonId === 'yes') {
                this._switchPartnerToForcedTrial()
                    .catch(handleClientError);
            }
        });
    },

    async _switchPartnerToForcedTrial() {
        const record = this._getRecord();
        const url = `${config.SERVER_URL}api/admindata/whiteLabel/switchPartnerToTrial/${record.data.id}`;
        this.getView().setMasked(true);
        await FieldServices.app.callAPIEndpointRequest('POST', url, {})
            .catch(err => {
                handleClientError(generateClientError({
                    ...err,
                    type: ServiceError,
                }, {
                    warningBody: err.message,
                }));
                this.getView().setMasked(false);
            });
        this.getView().setMasked(false);
        this.getViewModel().set('canTapOnSwitchToTrial', false);
        Ext.Msg.alert('Warning', `Switching all instances to 14 day trial for partner ${record.data.name} has been finished!`);
    },
});

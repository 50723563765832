import { ADMIN_REPORT } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.usageReport.UsageReportView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.report.usageReport.UsageReportViewController',
        'FieldServices.view.report.usageReport.filter.UsageReportFilterView',
        'FieldServices.view.report.usageReport.UsageReportBaseGrid',
    ],

    xtype: 'usageReportView',
    controller: 'usageReportViewController',
    viewModel: {
        stores: {
            companyData: {
                storeId: 'UsageReportCompanyData',
                data: [],
                fields: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_DATA,
            },
            companySettings: {
                storeId: 'UsageReportCompanySettings',
                data: [],
                fields: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_SETTINGS,
            },
            companyActivity: {
                storeId: 'UsageReportCompanyActivity',
                data: [],
                fields: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_ACTIVITY,
            },
        },
        data: {
            usageReportLoading: false,
        },
    },
    layout: 'vbox',
    items: [{
        xtype: 'usageReportFilterView',
        itemId: 'filterComponent',
    },
    {
        xtype: 'tabpanel',
        itemId: 'gridTabs',
        flex: 1,
        items: [
            {
                tab: {
                    title: 'Company Data',
                    flex: 1,
                },
                xtype: 'usageReportBaseGrid',
                dataKeys: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_DATA,
                store: 'UsageReportCompanyData',
                columnWidthOverrides: {
                    email: 2,
                    phone: 1.3,
                    users: 0.5,
                    team_size: 0.5,
                    time_logs: 0.5,
                    checkins: 0.7,
                    active_purchases: 0.8,
                    expired_purchases: 0.8,
                    active_subscription_tiers: 2,
                },
            },
            {
                tab: {
                    title: 'Company Settings',
                    flex: 1,
                },
                xtype: 'usageReportBaseGrid',
                dataKeys: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_SETTINGS,
                store: 'UsageReportCompanySettings',
                convertBooleans: true,
                showDiffs: true,
                columnWidthOverrides: {
                    company_name: 2,
                },
            },
            {
                tab: {
                    title: 'Company Activity',
                    flex: 1,
                },
                xtype: 'usageReportBaseGrid',
                showDiffs: true,
                dataKeys: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_ACTIVITY,
                store: 'UsageReportCompanyActivity',
                columnWidthOverrides: {
                    company_name: 1.5,
                    customers_linked_to_pn: 1.2,
                },
            },
        ],
    }],
});

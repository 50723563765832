import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Country', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Country'],
    alias: 'store.Country',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Country',
        storeId: 'Country',
        remoteFilter: true,
        remoteSort: true,
    },

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/Country`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    _createCountryMatchingRegex(searchValue) {
        const regexString = searchValue.replace(/[\W_]+/g, '').split('').join('\\s*');
        return new RegExp(`^${regexString}$`, 'i');
    },

    findMatchingCountry(fieldName, searchValue) {
        const regex = this._createCountryMatchingRegex(searchValue);
        return this.getData().find(fieldName, regex, undefined, undefined, undefined, true);
    },
});

Ext.define('shared.overrides.grid.plugin.Editable', {
    override: 'Ext.grid.plugin.Editable',
    mixins: ['Shared.mixins.ApiRequest'],

    requires: [
        'Ext.grid.plugin.Editable',
    ],

    onSubmitTap(...args) {
        this.callParent(args);
        this._fireEventOnGrid('onRowEditSubmitTap');
    },

    onCancelTap(...args) {
        this.callParent(args);
        this._fireEventOnGrid('onRowEditCancelTap');
    },

    _fireEventOnGrid(eventName) {
        this.getGrid().fireEvent(eventName, this.form.getRecord());
    },
});

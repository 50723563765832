if (module.hot) {
    module.hot.accept();
}

const config = {
    // SERVER_URL: 'http://dev-api.powerednow.com:3000/',
    SERVER_URL: 'http://127.0.0.1:3000/',
    APP_BASE_URL: 'http://127.0.0.1:9000/',
};

module.exports = Object.keys(config)
    .reduce((configObject, key) => Object.assign(configObject, {
        [key]: window[key] || config[key],
    }), {});

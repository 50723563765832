require('./ClientErrorInfoView.scss');

Ext.define('FieldServices.view.company.info.clientError.ClientErrorInfoView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.company.info.clientError.ClientErrorInfoViewController',
        'FieldServices.view.company.info.clientError.ClientErrorInfoViewModel',
        'FieldServices.view.company.info.clientError.ClientErrorInfoRowViewModel',
    ],

    alias: 'widget.clientErrorInfoView',
    xtype: 'clientErrorInfoView',

    controller: 'clientErrorInfoViewController',
    viewModel: 'clientErrorInfoViewModel',

    layout: 'vbox',
    scrollable: true,

    title: 'Errors which have caused restarts in the last two days ',

    items: [{
        xtype: 'componentdataview',
        flex: 1,
        itemId: 'clientErrorOfCompanyId',
        bind: {
            store: '{aggregateStore}',
        },
        itemConfig: {
            xtype: 'container',
            viewModel: {
                type: 'clientErrorInfoRowViewModel',
            },
            layout: 'hbox',
            items: [{
                xtype: 'container',
                flex: 1,
                layout: {
                    type: 'vbox',
                    align: 'middle',
                },
                bind: {
                    data: '{record}',
                },
                tpl: require('./errorInfo.tpl.html'),
            }, {
                xtype: 'button',
                bind: {
                    text: '{cardNumber}',
                    hidden: '{!cardNumber}',
                },
                handler(button, event) {
                    const viewModel = this.lookupViewModel();
                    this.up('componentdataview').fireEvent('showPNCardButtonTap', button, viewModel, event);
                },
            }, {
                xtype: 'button',
                text: 'Add to known errors',
                handler(button, event) {
                    const viewModel = this.lookupViewModel();
                    this.up('componentdataview').fireEvent('addToKnownErrorsButtonTap', button, viewModel, event);
                },
            }],
        },
        emptyText: 'There were no errors in the last two days',

    }],
});

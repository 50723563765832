import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.Aggregate', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Aggregate'],
    alias: 'store.aggregate',

    config: {
        autoLoad: false,
        remoteFilter: true,
        model: 'FieldServices.model.Aggregate',
    },
    storeId: 'Aggregate',

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/aggregate`,
        reader: {
            type: 'json',
            rootProperty: 'items',
        },
    },
});

const customerImportStructure = require('shared/structures/customerImport');

export default Ext.define('FieldServices.view.company.contact.list.ContactListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.contactListController',
    xtype: 'contactListController',

    config: {
        filterValue: '',
    },
    control: {
        '': {
            painted: 'onViewShow',
        },
        contactImportButton: {
            tap: 'onImportButtonTap',
        },
        exportContactsButton: {
            tap: 'onExportButtonTap',
        },
    },

    jobMap: {},

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this._reLoadContacts, this);
    },
    onViewShow() {
        this.getView().getTitleBar().show();
        this._reLoadContacts();
    },

    _openCustomerPopup(elem) {
        const recordData = elem.ownerCmp.getRecord().getData();
        const cleanedData = this._removeAssociationData(recordData);
        const popup = References.getContactPopup();
        popup.show();
        popup.getController().setCustomerData(cleanedData);
        popup.on('dataChanged', () => {
            Ext.Msg.confirm('Confirm', `<b>Would you like to reload this customer list?</b><br />
                    <small>(The user has to reinstall app (or #deleteAllDb/ on browser) to show the changes.)</small>`, btn => {
                if (btn === 'yes') {
                    this._reLoadContacts();
                }
            });
        }, this, {
            single: true,
        });
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('contactFilterPlugin');
    },

    _reLoadContacts() {
        this.getGridFilterPlugin().reloadData();
    },

    onInfoButtonTap(elem) {
        this._openCustomerPopup(elem);
    },

    _removeAssociationData(recordData) {
        return Object.keys(recordData).reduce((prev, key) => {
            if (key.indexOf('.') === -1) {
                prev[key] = recordData[key];
            }
            return prev;
        }, {});
    },

    _setStore(customers) {
        const store = Ext.create('Ext.data.Store').setData((customers || []));
        this.getView().setStore(store);

        this.clearLoader(this.getView());
    },

    onImportButtonTap() {
        const popup = Ext.create({
            xtype: 'contactImportView',
            floated: true,
            height: '60%',
            width: '75%',
            centered: true,
            controllerConfig: {
                customerImportStructure,
            },
        });
        Ext.Viewport.add(popup);
        popup.on('importComplete', async () => {
            await this.showLoader(this.getView());
            await this.getView().getPlugin('contactFilterPlugin').reloadData();
            await this.clearLoader(this.getView());
        }, this, {
            single: true,
        });
        popup.show();
    },

    onExportButtonTap() {
        this.getView().saveDocumentAs({ fileName: 'customers.xlsx' });
    },
});

Ext.define('FieldServices.view.widgets.list.GridListViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.gridListViewCtrl',
    xtype: 'gridListViewCtrl',

    control: {},

    config: {
        companyId: null,
        url: '',
        urlParam: '',
        headers: {},
        columns: [],
        autoLoad: true,
        params: {},
        paramNames: [],
        showCsv: true,
        showAdd: false,
        callInitData: false,
    },

    init() {
        const { controllerConfig } = this.getView();
        Object.keys(controllerConfig).forEach(key => {
            this[`set${key.replace(/\b\w/g, l => l.toUpperCase())}`](controllerConfig[key]);
        });
        this.getView().lookup('grid').setColumns(this.getColumns());

        if (!this.getShowCsv()) {
            this.getView().down('#getCsvId').hide();
        }
        if (this.getShowAdd()) {
            this.getView().down('#onAddId').show();
        }
    },

    onLoad() {
        if (!this.getUrl() || !this.getAutoLoad()) {
            return;
        }

        this.loadContent().catch(handleClientError);
    },

    loadContent() {
        return FieldServices.app.callAPI({
            method: 'GET',
            url: this._getHttpUrl(),
            params: this._generateUrlParams(),
            headers: this.getHeaders(),
        })
            .then(response => {
                if (response && response.length > 0) {
                    return this._prepareResponse(response)
                        .then(data => this._addStoreToGrid(data));
                }

                return this._addStoreToGrid([]);

                // throw Object.assign(new ServiceError(), {
                //     showWarningAlert: true,
                //     warningBody: 'EMPTY DATA',
                // });
            });
    },

    onDetailsTap(btn) {
        const cell = btn.getParent();
        const record = cell.getRecord();
        this.getView().fireEvent('detailsTap', record);
    },

    _prepareResponse(response) {
        if (this.getCallInitData()) {
            return this.getView().getParent().getController().initData(response);
        }
        return Promise.resolve(response);
    },

    _addStoreToGrid(data) {
        const store = Ext.create('Ext.data.Store', {}).setData(data);
        this.getView().lookup('grid').setStore(store);
        return data;
    },

    setNewColumns(columns) {
        this.setColumns(columns);
        this.getView().lookup('grid').setColumns(this.getColumns());
    },

    _generateUrlParams() {
        return this.getParamNames()
            .reduce((map, key) =>
                // const paramName = `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
                // console.log('-----GET', paramName);
                Object.assign(map, {
                    [key]: this.getParams()[key] || null,
                }), {});
    },

    onGetCsv() {
        FieldServices.app.callAPI({
            method: 'GET',
            url: this._getHttpUrl(),
            params: this._generateUrlParams(),
            headers: FieldServices.app.getCsvHeaders(),
        })
            .then(response => {
                if (response.url) {
                    Utilities.openNewTab(response.url);
                }
            })
            .catch(err => handleClientError(err));
    },

    onAdd() {
        this.getView().getParent().getController().addForm();
    },

    rendererDeepDataIndex(...data) {
        return Renderer.deepDataIndex(...data);
    },

    rendererDateToIso(value) {
        return value ? Ext.Date.format(new Date(value), 'Y-m-d H:i:s') : '';
    },

    _getHttpUrl() {
        const url = this.getUrl();
        return url.replace(/\/:(\w+)/g, (pattern, key) => {
            const getterName = `get${key.charAt(0).toUpperCase()}${key.slice(1)}`;
            const value = this[getterName]();
            return `/${value}`;
        });
    },

});

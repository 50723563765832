import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.UserProfileRole', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.UserProfileRole'],
    alias: 'store.UserProfileRole',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.UserProfileRole',
        storeId: 'UserProfileRole',
        remoteFilter: true,
        remoteSort: true,
        extraKeys: {
            byUserProfileRoleId: 'id',
        },
    },
    storeId: 'UserProfileRole',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/UserProfileRole`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },
    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

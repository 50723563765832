import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { DOCUMENT } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.company.document.list.DocumentListItemViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.documentListItemViewModel',
    xtype: 'documentListItemViewModel',

    config: {
        uiMap: {
            isdeleted: {
                ui: '-IsDeleted',
                condition(dataItem) {
                    return Boolean(dataItem.isdeleted);
                },
            },
            confirmed: {
                ui: '-IsConfirmed',
                condition(dataItem) {
                    return Boolean(dataItem.confirmed);
                },
            },
        },
    },

    formulas: {
        isDeletedJobCellUi: {
            bind: {
                record: '{record}',
            },
            get({ record }) {
                return record.data.JobIsDeleted ? '-off' : '-on';
            },
        },
        confirmedString: {
            bind: '{record.confirmed}',
            get(confirmed) {
                return this.booleanToString(confirmed);
            },
        },
        isLockedString: {
            bind: '{record.islocked}',
            get(islocked) {
                return this.booleanToString(islocked);
            },
        },
        jobDescription: {
            bind: '{record.JobDescription}',
            get(JobDescription) {
                return Ext.util.Format.htmlDecode(JobDescription);
            },
        },
        jobIsDeletedString: {
            bind: '{record.JobIsDeleted}',
            get(JobIsDeleted) {
                return this.booleanToString(JobIsDeleted);
            },
        },
        customerName: {
            bind: '{record.CustomerCompany}',
            get(CustomerCompany) {
                return Ext.util.Format.htmlDecode(CustomerCompany);
            },
        },
        statusName: {
            bind: '{record.status}',
            get(status) {
                return getKeyByValue(DOCUMENT.STATUS, status);
            },
        },
        typeName: {
            bind: '{record.type}',
            get(type) {
                return DOCUMENT.TYPES[type];
            },
        },
        jobLastActivity: {
            bind: '{record.job_id}',
            get(job_id) {
                const record = Ext.getStore('Job').findRecord('id', job_id);
                return record?.data.lastActivity ?? '';
            },
        },
    },
});

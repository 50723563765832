const _ = require('lodash');

Ext.define('FieldServices.controller.Renderer', {
    singleton: true,
    alternateClassName: 'Renderer',

    /**
     *
     * @param value
     * @param record
     * @param dataIndexPath xxx.yyy.zzz
     * @returns {*}
     */
    deepDataIndex(value, record, dataIndexPath) {
        if (!value && dataIndexPath) {
            const data = record.getData ? record.getData() : record;
            if (data[dataIndexPath] !== undefined) {
                return data[dataIndexPath];
            }
            return _.get(data, dataIndexPath);
        }
        return value;
    },
});

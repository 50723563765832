const CONFIG = require('config.js');

Ext.define('FieldServices.view.company.document.edit.DocumentEditViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.documentEditViewController',
    xtype: 'documentEditViewController',

    control: {
        documentnumber: {
            change: 'onDocumentNumberChange',
        },
    },

    config: {
        companyId: null,
        documentData: null,
    },

    onLoad() {
        this._load();
    },

    onCancelButtonTap() {
        this._hide();
    },

    onDeleteDocumentButtonTap() {
        this._deleteDocument();
    },

    onConfirmDocumentButtonTap() {
        this._confirmDocument();
    },

    onRestoreDocumentButtonTap() {
        this._changeDocument();
    },

    onSaveButtonTap() {
        this._changeDocument();
    },

    onViewOnlineButtonTap() {
        this._viewDocumentOnline();
    },

    onDocumentNumberChange(elem, newValue, oldValue) {
        this._validateDocumentNumberChange(newValue, oldValue);
    },

    _load() {
        this.setDocumentData(this.getView().getViewModel().getData());
    },

    _hide() {
        this.getView().hide();
    },

    _deleteDocument() {
        const callConfig = {
            method: 'DELETE',
        };
        this._requestChange(callConfig).catch(err => {
            handleClientError(err);
        });
    },

    _confirmDocument() {
        const callConfig = {
            jsonData: {
                confirmed: 1,
                displaynumber: 'TBA',
                id: this.getDocumentData().id,
            },
        };
        this._requestChange(callConfig)
            .then(response => {
                Ext.Msg.alert('alert', `Document with id: ${this.getDocumentData().id} has been confirmed now it has the display number ${response.displaynumber}`);
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _changeDocument() {
        const callConfig = {
            jsonData: this._getJsonData(),
        };

        if (!this._isValid(callConfig.jsonData)) {
            return;
        }
        this._requestChange(callConfig);
    },

    async _viewDocumentOnline() {
        const url = await this._getViewUrl();
        Utilities.openNewTab(url);
    },

    async _getViewUrl() {
        const documentData = this.getDocumentData();
        return FieldServices.app.callAPI({
            url: 'api/interfaces/autoLogin',
            method: 'POST',
            useAuth: false,
            headers: {
                [CONSTANTS.HTTP.AUTHENTICATION_HEADER_DOMAIN]: CONSTANTS.LOGIN.SESSION_DOMAINS.ADMIN,
            },
            jsonData: {
                userToken: FieldServices.app.getAdminToken(),
                userTokenData: {
                    companyId: documentData.company_id,
                    customerId: documentData.customer_id,
                    linkedId: documentData.id,
                    type: CONSTANTS.MESSAGES.CUSTOMER_MESSAGE_LINK_TYPES.DOCUMENT,
                },
            },
        });
    },

    _validateDocumentNumberChange(newValue, oldValue) {
        if (newValue !== '' && !this._isValidDocumentNumber(newValue)) {
            Ext.Msg.alert('alert', `This document number is invalid ${newValue}`);
            this.getDocumentnumber().setValue(oldValue);
        }
    },

    async _requestChange(additionalConfig) {
        await this.showLoader(this.getView());
        try {
            const response = await this._callApi(additionalConfig);
            this.getView().fireEvent('dataChanged', {});
            return response;
        } finally {
            await this.clearLoader(this.getView());
            this._hide();
        }
    },

    async _callApi(additionalConfig) {
        return FieldServices.app.callAPI({
            method: 'PUT',
            url: `api/admindata/company-tables/Document/${this.getDocumentData().company_id}`,
            jsonData: {
                id: this.getDocumentData().id,
            },
            ...additionalConfig,
        });
    },

    _getJsonData() {
        return this.getView().changeDocumentDataFields.reduce((prev, key) => {
            const elem = this.getView().down(`[name=${key}]`);
            const { convertFn } = elem.initialConfig;
            const newValue = convertFn ? convertFn(elem.getValue()) : elem.getValue();
            Object.assign(prev, { [key]: newValue });
            return prev;
        }, {});
    },

    _isValid(data) {
        if (!this._isOverwriteDisplayName(data) && !this._isSupplierInvoice(this.getDocumentData().type)) {
            Ext.Msg.alert('alert', 'A document with this name already existing ');
            return false;
        }
        if (!this._isValidDocumentNumber(data.documentnumber)) {
            Ext.Msg.alert('alert', 'A document number is invalid');
            return false;
        }
        return true;
    },

    _isOverwriteDisplayName(document) {
        let isRestore = true;
        Ext.getStore('Document').getData().items.forEach(doc => {
            const docRow = doc.getData();
            if (docRow.isdeleted === false && docRow.displaynumber === document.displaynumber) {
                isRestore = false;
            }
        });

        return isRestore;
    },

    _isValidDocumentNumber(documentNumber) {
        return /^\d+$/.test(documentNumber);
    },

    _isSupplierInvoice(type) {
        return parseInt(type, 10) === CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE;
    },
});

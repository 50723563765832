import CONSTANTS from 'shared/constants';
import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

const moment = require('moment');

export default Ext.define('FieldServices.store.PurchasesInfo', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.PurchasesInfo'],
    alias: 'store.PurchasesInfo',
    config: {
        autoload: false,
        model: 'FieldServices.model.PurchasesInfo',
        storeId: 'PurchasesInfo',
        remoteFilter: true,
        remoteSort: true,
        dependantStores: ['PurchaseSetup'],
    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/Purchases`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    preprocessRecords(purchases) {
        if (!Ext.getStore('mainCompanyStore').first()) {
            return [];
        }
        const purchaseInfoData = this._generatePurchaseInfoData(purchases.map(purchase => purchase.data));
        return purchaseInfoData.map(data => Ext.create('FieldServices.model.PurchasesInfo', data));
    },

    _generatePurchaseInfoData(purchases, actualDate = new Date()) {
        const sortedPurchases = purchases.sort((a, b) => moment(a.purchase_date) - moment(b.purchase_date));

        const purchaseRecords = sortedPurchases.reduce((purchaseInfoRecords, purchase) => {
            if (purchase.isdeleted) {
                return purchaseInfoRecords;
            }

            const latestPurchaseInfo = purchaseInfoRecords[purchaseInfoRecords.length - 1];

            if (this._isContinuous(latestPurchaseInfo.expires_date, purchase.purchase_date)) {
                this._handleContinuousPeriods(purchaseInfoRecords, latestPurchaseInfo, purchase);
            } else {
                this._handleNotContinuousPeriods(purchaseInfoRecords, latestPurchaseInfo, purchase);
            }
            return purchaseInfoRecords;
        }, [this._getNoSubData(sortedPurchases[0])]);
        const lastExpiredRecord = purchaseRecords.sort((a, b) => moment(a.expires_date) - moment(b.expires_date))[0];
        if (new Date(lastExpiredRecord.expires_date) < actualDate) {
            const lastNoSubPeriod = this._getNoSubData(undefined, lastExpiredRecord);
            purchaseRecords.push(this._setType(lastExpiredRecord, lastNoSubPeriod));
        }
        return purchaseRecords;
    },

    _createNoSubRecord(purchaseInfoRecords, noSubRecordData) {
        purchaseInfoRecords.push(noSubRecordData);
    },

    _createNewRecord(purchaseInfoRecords, latestPurchaseInfo, purchase) {
        const newRecord = this._setType(latestPurchaseInfo, purchase);
        purchaseInfoRecords.push(newRecord);
    },

    _handleContinuousPeriods(purchaseInfoRecords, latestPurchaseInfo, purchase) {
        if (latestPurchaseInfo.product === purchase.product) {
            this._updateRecord(latestPurchaseInfo, purchase);
        } else {
            this._createNewRecord(purchaseInfoRecords, latestPurchaseInfo, purchase);
        }
    },

    _handleNotContinuousPeriods(purchaseInfoRecords, latestPurchaseInfo, purchase) {
        const noSubRecordData = this._getNoSubData(purchase, latestPurchaseInfo);
        this._createNoSubRecord(purchaseInfoRecords, noSubRecordData);
        this._createNewRecord(purchaseInfoRecords, noSubRecordData, purchase);
    },

    _setType(latestPurchaseInfo, purchase) {
        if (latestPurchaseInfo.weight > purchase.weight) {
            purchase.type = CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.DOWNGRADE;
        } else if (latestPurchaseInfo.weight < purchase.weight) {
            purchase.type = CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.UPGRADE;
        } else {
            purchase.type = CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.NO_CHANGE;
        }
        return purchase;
    },

    _isContinuous(expiresDate, purchaseDate) {
        return moment(purchaseDate) < moment(expiresDate).clone().add(7, 'days');
    },

    _updateRecord(latestPurchaseInfo, purchase) {
        latestPurchaseInfo.expires_date = purchase.expires_date;
        latestPurchaseInfo.purchase_amount += purchase.purchase_amount;
        latestPurchaseInfo.quantity += 1;
    },

    _getInitialDate() {
        const companyData = Ext.getStore('mainCompanyStore').first().data;
        return companyData.dt_created;
    },

    _getNoSubData(purchase, previousRecord) {
        return {
            expires_date: purchase ? purchase.purchase_date : new Date('2088'),
            purchase_date: previousRecord ? previousRecord.expires_date : this._getInitialDate(),
            product: 'No Subscription',
            product_name: 'Free period',
            weight: 0,
            quantity: 1,
            type: CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.FREE,
        };
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

Ext.define('FieldServices.view.knownError.KnownErrorViewController', {
    extend: 'FieldServices.view.BaseViewController',

    xtype: 'knownErrorViewController',

    alias: 'controller.knownErrorViewController',
    control: {
        saveModificationsButton: {
            tap: 'onSaveModificationsButtonTap',
        },
        restoreModificationButton: {
            tap: 'onRestoreModificationButtonTap',
        },
        newButton: {
            tap: 'onNewButtonTap',
        },
    },

    async onSaveModificationsButtonTap() {
        try {
            await this.showLoader(this.getView());
            await this._getKnownErrorListStore().syncAsync();
            this.clearLoader(this.getView());
        } catch (err) {
            handleClientError(err);
        }
    },

    async onRestoreModificationButtonTap() {
        try {
            const buttonId = await Ext.Msg.confirmAsync('Restore', 'Are you sure you want to restore it?');
            if (buttonId !== 'yes') {
                return;
            }
            this._getKnownErrorListStore().revertModifications();
        } catch (err) {
            handleClientError(err);
        }
    },

    onNewButtonTap() {
        const store = this._getKnownErrorListStore();
        store.add({
            is_harmful: false,
        });

        Ext.Msg.alert('Notice', 'Double-click on the blank line to edit the new line');
    },

    onGenerateHashButtonTap(btn) {
        const { form } = (btn.up('grid').getPlugins() || []).find(plugin => plugin.type === 'grideditable');
        const values = form.getValues();
        const hashedStack = shared.Utils.utilities.generateHashFromError({ stack: values.stack });
        form.setValues({ hashed_stack: hashedStack });
    },

    _getKnownErrorListStore() {
        return this.getViewModel().get('KnownErrorStore');
    },
});

Ext.define('FieldServices.overrides.SpriteLegend', {
    override: 'Ext.chart.legend.SpriteLegend',

    onClick(event) {
        const chart = this.getChart();
        const surface = this.getSurface();
        let result;
        let point;

        if (chart && chart.hasFirstLayout && surface) {
            point = surface.getEventXY(event);
            result = surface.hitTest(point);

            if (result && result.sprite) {
                this.toggleItem(result.sprite);
                chart.fireEvent('legendClick', { sourceChart: chart, sprite: result.sprite });
            }
        }
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.UserUserProfile', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.UserUserProfile'],
    alias: 'store.UserUserProfile',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.UserUserProfile',
        storeId: 'UserUserProfile',
        remoteFilter: true,
        remoteSort: true,
        loadInChunks: true,
        extraKeys: {
            byUserUserProfileId: 'id',
        },
    },

    proxy: {
        type: 'PoweredNowApi',

        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/UserUserProfile`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',

        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_NORMAL,
});

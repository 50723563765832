Ext.define('FieldServices.view.components.customer.CustomerView', {
    extend: 'Ext.grid.Grid',

    xtype: 'customerView',

    itemConfig: {
        viewModel: true,
    },

    height: 50,
    margin: '10 0 0 10',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'grideditable',
        enableDeleteButton: false,
    }],

    selectable: false,

    emptyText: 'empty',

    columns: [{
        text: 'Customer id',
        dataIndex: 'id',
    }, {
        text: 'Company',
        dataIndex: 'company',
        editable: true,
    }, {
        text: 'Company number',
        dataIndex: 'company_number',
        editable: true,
    }, {
        text: 'Insurance number',
        dataIndex: 'insurance_number',
        editable: true,
    }, {
        text: 'Verification number',
        dataIndex: 'verification_number',
        editable: true,
    }, {
        text: 'Cis rate id',
        dataIndex: 'cis_rate_id',
        editable: true,
    }, {
        text: 'utr',
        dataIndex: 'utr',
        flex: 1,
        editable: true,
    }, {
        text: 'isDeleted',
        dataIndex: 'isdeleted',
        renderer: 'rendererIsDeleted',
        defaultEditor: {
            xtype: 'checkbox',
        },
        editable: true,
    }],
});

/**
 * Created by steveetm1 on 11/08/15.
 */
Ext.define('FieldServices.view.prompt.PromptsViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.prompt.main',

    stores: {
        prompts: {
            autoLoad: false,
            type: 'prompts',
        },
        countries: {
            autoLoad: false,
        },
    },

    data: {
        /**
         * By default we want to show both the grid and the form in our views.
         * Pass the noGrid config variable upon component creation to hide the grid.
         */
        noGrid: false,
    },
});

Ext.define('FieldServices.view.whiteLabel.settings.settingsView', {
    extend: 'Ext.Container',
    xtype: 'whiteLabelPartnerSettings',

    flex: 1,
    requires: [
        'FieldServices.view.whiteLabel.settings.settingsViewController',
        'FieldServices.view.whiteLabel.settings.settingsViewModel',

    ],

    viewModel: 'WhiteLabelPartnerSettingsViewModel',
    controller: 'WhiteLabelPartnerSettingsViewController',

    scrollable: 'vertical',

    items: [
        {
            xtype: 'textfield',
            label: 'Partner name:',
            labelAlign: 'top',
            bind: {
                value: '{record.name}',
            },
        }, {
            xtype: 'selectfield',
            label: 'Partner status:',
            labelAlign: 'top',
            valueField: 'id',
            displayField: 'name',
            bind: {
                options: '{statusOptions}',
                value: '{record.whitelabel_status}',
                hidden: '{record.phantom}',
            },
        }, {
            xtype: 'textfield',
            label: 'Partner desktop url:',
            labelAlign: 'top',
            bind: {
                value: '{record.desktop_url}',
            },
        }, {
            xtype: 'container',
            cls: 'btnBar',
            layout: {
                type: 'hbox',
                pack: 'end',
            },
            items: [{
                xtype: 'button',
                text: 'Switch all instances to 14 day trial',
                handler: 'onSwitchToTrialTap',
                bind: {
                    hidden: '{record.phantom}',
                    disabled: '{!canTapOnSwitchToTrial}',
                },
            }, {
                xtype: 'button',
                text: 'cancel',
                handler: 'onCancelButtonTap',
                bind: {
                    disabled: '{cancelDisabled}',
                },
            }, {
                xtype: 'button',
                text: 'save',
                handler: 'onSaveButtonTap',
                bind: {
                    disabled: '{!record.dirty}',
                },
            }],
        },
    ],
});

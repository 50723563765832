Ext.define('FieldServices.view.report.action.detail.PurchaseActionDetailFormViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.purchaseActionDetailFormViewController',
    xtype: 'purchaseActionDetailFormViewController',

    onChangeFilter() {
        this._changeFilter();
    },

    onGetCsv() {
        this.getView().fireEvent('exportRequested', {});
    },

    _changeFilter() {
        const filterData = this.getView().getFilters();
        this.getView().fireEvent('changeFilter', { filterData });
    },

});

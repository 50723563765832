import {
    NetworkError,
    ServiceError,
    ServerError,
    DatabaseVersionTableNotExistsError,
    DatabaseVersionChangeError,
    DatabaseVersionNotSetError,
    DatabaseError,
} from '@powerednow/shared/error';

module.exports = {
    ERRORS: {
        NETWORK_FAILURE: {
            type: NetworkError,
            message: 'Network failure. Please try again.',
            code: 1000,
        },
        NEED_TO_BE_ONLINE: {
            type: ServiceError,
            message: 'You have to be online to use this feature!',
            code: 1001,
        },
        SERVER_ERROR: {
            type: ServerError,
            message: 'Internal server error',
            code: 1010,
        },
        SERVER_STATUS: {
            type: ServerError,
            message: 'Internal server error',
            code: 1020,
        },
        STORE_CONTENT_UNDEFINED: {
            type: ServerError,
            message: 'Error! downloadedObject is undefined',
            code: 1021,
        },
        FIRST_LOGIN_SERVER_ERROR: {
            type: ServerError,
            message: 'Server error occurred, please try again later.',
            code: 1041, // reference to server-side API_RESPONSES.NO_USERDEVICE_EXISTS
        },
        PROXY_SYNC_ERROR: {
            type: Error,
            message: "We're sorry but it seems a sync issue has occured. <br/>You'll have to log in again. If the problem persists, please contact the customer support!",
            code: 1050,
        },
        RECORD_NOT_FOUND: {
            type: Error,
            message: 'Record not found',
            code: 1085,
        },
        PROXY_OPEN_ERROR: {
            type: Error,
            message: "We're sorry but it seems an internal issue has occured. <br/> Please reload the application. If the problem persists, please contact the customer support!",
            code: 1090,
        },
        NO_HASH_CHANGE: {
            type: ServiceError,
            message: 'redirectTo called with same hash is already active.',
            code: 1095,
        },
        PERMISSION_DENIED: {
            type: ServiceError,
            message: 'Permission denied.',
            code: 1098,
        },
        LOCATION: {
            GENERAL: {
                type: ServiceError,
                message: 'Cannot load location data.',
                code: 2000,
            },
            MISSING_PARAMETER: {
                type: ServiceError,
                message: 'Missing paramter.',
                code: 2001,
            },
            LOCATION_API_MISSING: {
                type: ServiceError,
                message: 'Location or GoogleMaps API not loaded.',
                code: 2005,
            },
            INVALID_ADDRESS_STRING_FOR_LATLANG: {
                type: ServiceError,
                message: 'Could not find lattitude and longitude data for address.',
                code: 2010,
            },
            INVALID_ADDRESS_STRING_FOR_GEOCODE: {
                type: ServiceError,
                message: 'Could not find geoCode data for address.',
                code: 2011,
            },
            GEOCODER_RESULT_ERROR: {
                type: ServiceError,
                message: 'Could not fetch address from Geocoder.',
                code: 2020,
            },
            GEOLOCATION_ERROR: {
                type: ServiceError,
                message: 'User denied Geolocation.',
                code: 2030,
            },
            DIRECTION_NOT_FOUND: {
                type: ServiceError,
                message: 'Direction not found.',
                code: 2040,
            },
            POSITION_FROM_SERVER: {
                type: ServiceError,
                message: 'Failed to get position data from server.',
                code: 2050,
            },
        },
        DATABASE_ERROR: {
            type: DatabaseError,
            message: 'Local database error',
            code: 3000,
        },
        DATABASE_VERSION_TABLE_NOT_EXISTS: {
            type: DatabaseVersionTableNotExistsError,
            message: 'Database version table not yet exists.',
            code: 3001,
        },
        DATABASE_VERSION_NOT_SET: {
            type: DatabaseVersionNotSetError,
            message: 'Database version not yet set.',
            code: 3002,
        },
        DATABASE_VERSION_CHANGE: {
            type: DatabaseVersionChangeError,
            message: 'Database version changed.',
            code: 3003,
        },
        SOCIAL: {
            LOGIN_ERROR: {
                type: ServiceError,
                message: 'Social auth failed',
                code: 4000,
            },
            API_ERROR: {
                type: ServiceError,
                message: 'Social api call failed',
                code: 4001,
            },
            PROVIDER_NOT_FOUND: {
                type: ServiceError,
                message: 'Social provider not found.',
                code: 4002,
            },
            LOGIN_ABORTED: {
                type: ServiceError,
                message: 'Social login aborted by user.',
                code: 4003,
            },
        },
        REGISTRATION_ERROR: {
            type: ServiceError,
            message: 'Registration error',
            code: 4500,
        },
        MTD_SUBMIT_FAILED: {
            type: ServiceError,
            message: 'MTD submit failed',
            code: 6000,
        },
    },
    CARD_FORMATS: [{
        CARD_NUMBER_PATTERN: /([^/]|^)\b(PN-[0-9]+)\b/g,
        CARD_NUMBER_POSITION: '$2',
        URL_PATTERN: ' [[CARD_NUMBER]](https://powerednow.myjetbrains.com/youtrack/issue/[CARD_NUMBER])',
        CARD_NUMBER_KEY: /\[CARD_NUMBER\]/g,
    }, {
        CARD_NUMBER_PATTERN: /([^/]|^)\b(TLS-[0-9]+)\b/g,
        CARD_NUMBER_POSITION: '$2',
        URL_PATTERN: ' [[CARD_NUMBER]](https://powerednow.myjetbrains.com/youtrack/issue/[CARD_NUMBER])',
        CARD_NUMBER_KEY: /\[CARD_NUMBER\]/g,
    }],
};

/**
 *
 * @class Override.Button
 * @extends Ext.Button
 *
 */
require('./Panel.scss');

Ext.define('FieldServices.overrides.Panel', {
    override: 'Ext.Panel',
});

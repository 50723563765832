import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.CompanyIntegration', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.CompanyIntegration'],
    alias: 'store.CompanyIntegration',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.CompanyIntegration',
        storeId: 'CompanyIntegration',
        remoteFilter: true,
        remoteSort: true,
    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/CompanyIntegration`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
        extraParams: {
            requiredFields: [
                'company_id',
            ],
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_NORMAL,
});

Ext.define('FieldServices.view.company.purchases.subscriptions.SubscriptionListViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.subscriptionListViewModel',
    xtype: 'subscriptionListViewModel',

    config: {
        uiMap: {
            isActive: {
                ui: '-IsActive',
                condition(dataItem) {
                    if (dataItem.isActive) {
                        const isHighestWeight = this.getView().getParent().getStore().data.items
                            .filter(item => item.data.isActive)
                            .every(item => dataItem.weight >= item.data.weight);
                        return isHighestWeight;
                    }
                    return false;
                },
            },
            isdeleted: {
                ui: '-IsDeleted',
                condition(dataItem) {
                    return Boolean(dataItem.isdeleted);
                },
            },
        },
    },
});

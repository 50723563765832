export default Ext.define('FieldServices.view.company.user.list.UserListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.userListController',
    xtype: 'userListController',

    control: {
        '': {
            painted: 'onViewShow',
            itemtap: 'onItemTap',
        },
        profileRolesButton: {
            click: 'onProfileRoleButtonTap',
        },
    },

    init() {
        this.callParent();
        Ext.getStore('UserProfile').load();
        this.getViewModel().bind('{companyId}', this.reloadUserList, this);
    },

    onViewShow() {
        this.reloadUserList().catch(handleClientError);
    },

    onItemTap(dataview, index, target, record, e, eOpts) {
        References.getUserPopup().show().on('dataChanged', async () => {
            await this.reloadUserList();
            References.getUserPopup().destroy();
        }, this, {
            single: true,
        });
        References.getUserPopup().getViewModel().setData(Ext.clone(record.data));
    },

    onProfileRoleButtonTap() {
        this._openProfileRolePopup();
    },

    async reloadUserList() {
        await this.showLoader(this.getView());
        await this.getGridFilterPlugin().reloadData();
        await this.clearLoader(this.getView());
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('userFilterPlugin');
    },

    _openProfileRolePopup() {
        const popup = References.getProfileRolePopup();
        popup.show();
    },
});

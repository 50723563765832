import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.Form', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Form'],
    alias: 'store.form',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Form',
        storeId: 'Form',

    },
    storeId: 'Form',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/Form`,
    },
});

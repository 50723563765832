Ext.define('FieldServices.view.message.newPushMessageView', {
    extend: 'Ext.Dialog',
    xtype: 'newPushMessageDialog',

    requires: [
        'FieldServices.view.message.newPushMessageViewController',
    ],

    viewModel: {
        message: '',
        url: '',
        mainOnly: true,
        idField: -1,
        single: true,
        csvData: [],

        formulas: {
            hideFileSelector: {
                bind: {
                    single: '{single}',
                    csvData: '{csvData}',
                },
                get(data) {
                    return data.single || data.csvData.length !== 0;
                },
            },
            hideIdSelector: {
                bind: {
                    single: '{single}',
                    csvData: '{csvData}',
                },
                get(data) {
                    return data.single || data.csvData.length === 0;
                },
            },
            sendEnabled: {
                bind: {
                    single: '{single}',
                    idField: '{idField}',
                    message: '{message}',
                },
                get(data) {
                    return data.message && (data.single || (!data.single && data.idField !== -1));
                },
            },
        },
    },

    controller: 'newPushMessageViewController',

    title: 'Send new push notification',
    width: 600,

    modal: true,
    centered: true,
    floated: true,

    items: [{
        xtype: 'formpanel',
        title: 'Load csv file',
        itemId: 'loadCsvForm',
        bind: {
            hidden: '{hideFileSelector}',
        },
        items: [{
            xtype: 'filefield',
            name: 'csv_file',
            fieldLabel: 'csv_file',
            itemId: 'csvFile',
        }, {
            xtype: 'button',
            text: 'Load file',
            handler: 'onLoadFileButtonTapped',
        }],
    }, {
        xtype: 'container',
        bind: {
            hidden: '{hideIdSelector}',
        },
        items: [{
            xtype: 'selectfield',
            itemId: 'idFieldSelector',
            label: 'Choose Company ID field',
            options: [],
            bind: {
                value: '{idField}',
            },

        }, {
            xtype: 'checkboxfield',
            label: 'Main user only',
            bind: {
                checked: '{mainOnly}',
            },
        }],
    }, {
        xtype: 'textareafield',
        label: 'Message text',
        labelAlign: 'top',
        bind: {
            value: '{message}',
        },
    }, {
        xtype: 'textfield',
        label: 'URL in app to open',
        labelAlign: 'top',
        bind: {
            value: '{url}',
        },
    }, {
        xtype: 'container',
        layout: {
            type: 'hbox',
            pack: 'end',
        },
        items: [{
            itemId: 'cancelButton',
            xtype: 'button',
            text: 'Cancel',
        }, {
            itemId: 'sendButton',
            xtype: 'button',
            text: 'Send',
            bind: {
                disabled: '{!sendEnabled}',
            },
        }],
    }],

});

Ext.define('FieldServices.view.tableVersion.TableVersionView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.tableVersion.TableVersionViewController',
    ],
    xtype: 'TableVersionView',

    controller: 'tableVersionViewController',

    title: 'Table versions',

    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },

    items: [{
        xtype: 'grid',
        itemId: 'tableVersionList',
        ui: '-tableVersion',

        store: 'TableVersion',

        flex: 1,
        width: 500,

        columns: [
            {
                text: 'ID',
                dataIndex: 'id',
            }, {
                text: 'Table name',
                dataIndex: 'tableName',
                flex: 1,
            }, {
                text: 'version',
                dataIndex: 'version',
            }, {
                text: '',
                cell: {
                    xtype: 'widgetcell',
                    widget: {
                        xtype: 'button',
                        text: '+ 1',
                        handler: 'onIncreaseButtonTap',
                    },
                },
            },
        ],
    }],
});

window.process = {};
window.process.env = {};

window.Promise = require('bluebird');
require('@powerednow/ext-sources/sdk/resources/theme-neptune-all-debug.css');
require('@powerednow/ext-sources/sdk/resources/charts-all-debug.css');

require('@powerednow/client-shared/resources/scss/vendor/fontawesome/_fontawesome.scss');
require('@powerednow/client-shared/resources/scss/vendor/ionicons/_ionicons.scss');

require('resources/scss/style.scss');

window.appVersion = [require('package.json').version, process.env.BUILD_NUMBER].join('.');
window.displayVersion = [require('package.json').version, process.env.BUILD_NUMBER].join('.');

window.Ext = {};

require('@powerednow/ext-sources/sdk/ext/packages/core/src/Boot.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/env/Feature.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/env/Browser.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/class/Loader.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/lang/Array.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/lang/Object.js');
require('@powerednow/ext-sources/sdk/ext/packages/core/src/data/Store.js');
require('@powerednow/client-shared/overrides/Factory');

// Require errors
require('./error.js');
//
// Native is quicker so use that as we do not use additional features
// provided by Ext.JSON
//
Ext.USE_NATIVE_JSON = true;
//
// Merge shared and client side constants and make sure there is no overlap in keys
//
const sharedConst = require('shared/constants').default;
const clientConst = require('./constants.js');

const intersection = Ext.Array.intersect(Ext.Object.getKeys(sharedConst), Ext.Object.getKeys(clientConst));
if (intersection.length > 0) {
    throw (`Shared and Client side constants definition shares some keys. ${JSON.stringify(intersection)}`);
}
window.CONSTANTS = Ext.Object.merge(sharedConst, clientConst);

//
// PN-2569 All lists invisible on desktop when touch is supported.
// Dirty hack when desktop reports touchc capability. This must be fixed later!
//
if (Ext.is.Desktop) {
    Ext.supports.Touch = false;
    Ext.supports.touchScroll = true;
}
//
// Theme specific code is missing from EXT6, leave this here for now
// This is needed for Ext.Msg.* popups
//
Ext.theme = {};
Ext.theme.is = {};

Ext.Loader.setConfig({
    paths: {
        Ext: 'sdk/src',
        'Ext.ux': 'utils/ux',
        'Ext.mixin': 'utils/mixin',
        FieldServices: 'app',
        Override: 'app/overrides',
    },
});

Ext.safeCreateVar = function (cls, variable, options) {
    return Ext.safeCreate(cls + variable, options);
};

Ext.safeCreate = function (cls, options) {
    return new Ext.Promise((resolve, reject) => {
        Ext.require(cls, () => {
            try {
                const created = Ext.create(cls, options);
                resolve(created);
            } catch (e) {
                reject(e);
            }
        });
    });
};

/**
 * This function called when someone opens the app through the external powerednow:// url.
 * The registration of this function must be done as early as possible to make sure it
 * is already defined by the time the framework calls it (i.e. before deviceReady)
 * See https://issues.apache.org/jira/browse/CB-7606 for details
 *
 * @param url
 * */
window.handleOpenURL = function (url) {
    setTimeout(() => {
        console.log('App opened from external URL:', url);
        //
        // In case of cold start we can not be sure the app is set up to
        // process the URL. Therefore we store it and will use the stored value
        // in Utilities.openUrlHandler
        //
        window.openURL = url;
    }, 0);
};

/**
 *
 * This is not so nice, consider it as inprogress refactor.
 * There is one drawback with per-component styles, the base overridden styles order _is_ matter.
 * Which means we must be sure that our overridden components which defines their own style _must_ be required(not loaded, not initialise,
 * only required) by webpack _before_ our custom components.
 *
 * As in the `Ext.Application` block we extend from `FieldServices.Application` the required components go before the
 * overrides, so the base overrides can potentially override the customized css classes.
 *
 * The ultimate solution would be some kind of automatic override lookup, e.g. if we require Ext.Panel the Ext-loader
 * should check the app/ folder for any overridden class for Ext.Panel, and automatically require it beforehand.
 *
 * This is out-of-scope atm.
 *
 * TLDR;
 * All of our component overrides should end up there, until something breaks.
 */

Ext.define(null, {
    requires: [
        'FieldServices.overrides.Tool',
        'FieldServices.overrides.Button',
        'Shared.data.field.Number',
        'FieldServices.overrides.Container',
        'FieldServices.overrides.DataviewAbstract',
        'FieldServices.overrides.Panel',
        'FieldServices.overrides.data.Field',
        'FieldServices.overrides.ViewController',
        'FieldServices.overrides.FormPanel',
        'FieldServices.overrides.FieldCheckbox',
        'FieldServices.overrides.Field',
        'FieldServices.overrides.FieldSelect',
        'FieldServices.overrides.FieldText',
        'FieldServices.overrides.Grid',
        'FieldServices.overrides.grid.CellBase',
        'FieldServices.overrides.grid.Row',
        'FieldServices.overrides.grid.HeaderContainer',
        'FieldServices.overrides.Tab',
        'FieldServices.overrides.TabPanel',
        'FieldServices.overrides.TabBar',
        'FieldServices.overrides.ListItem',
        'FieldServices.overrides.Slider',
        'FieldServices.overrides.UtilFormat',
        'FieldServices.overrides.Exporter',
        'FieldServices.overrides.DataviewList',
        'FieldServices.overrides.FieldToggle',
        'FieldServices.overrides.CartesianChart',
    ],
});

/*
 * This file is generated and updated by Sencha Cmd. You can edit this file as
 * needed for your application, but these edits will have to be merged by
 * Sencha Cmd when upgrading.
 */

Ext.application({
    name: 'FieldServices',

    extend: 'FieldServices.Application',

    requires: [
        'FieldServices.view.Viewport',
        'Shared.overrides.data.AbstractStore',
        'Shared.overrides.data.Store',
        'Shared.overrides.MessageBox',
        'Shared.overrides.grid.plugin.Editable',
        'Shared.plugins.LazyTabs',
        'Shared.overrides.Component',
        'Shared.overrides.bind.Stub',
        'Ext.form.*', // @TODO ext-loader should find this,
        'Ext.Label',
        'Ext.panel.*',
        'Ext.layout.*',
        'Ext.field.*',
        'Ext.drag.Source',
        'Ext.drag.Info',
        'Ext.Animator',
        'Ext.field.trigger.File',
        'Ext.menu.Menu',
        'Override.Formula',
        'FieldServices.overrides.data.Store',
        'FieldServices.overrides.data.ChainedStore',
        'FieldServices.overrides.LocalStorage',
        'FieldServices.plugins.GridWithFilter',
        'FieldServices.plugins.GridWithHideableRows',
        'Ext.fx.easing.BoundMomentum',
        'Ext.grid.HeaderDropZone',
        'Ext.grid.HeaderDragZone',
    ],
    autoCreateViewport: true,

    // The name of the initial view to create. With the classic toolkit this class
    // will gain a "viewport" plugin if it does not extend Ext.Viewport. With the
    // modern toolkit, the main view will be added to the Viewport.
    //
    mainView: 'FieldServices.view.Viewport',

    //-------------------------------------------------------------------------
    // Most customizations should be made to FieldServices.Application. If you need to
    // customize this file, doing so below this section reduces the likelihood
    // of merge conflicts when upgrading to new versions of Sencha Cmd.
    //-------------------------------------------------------------------------
});

const io = require('socket.io-client');
const serverUrl = require('config.js').SERVER_URL;

Ext.define('FieldServices.controller.Socket', {

    singleton: true,
    alternateClassName: 'Socket',

    mixins: ['Ext.mixin.Observable'],

    socket: null,
    proxyEvents: ['admin', 'login', 'urlchange', 'log', 'forceLogoutAllUserDone', 'forceLogoutOneUserDone', 'connections'],

    constructor(config) {
        this.mixins.observable.constructor.call(this, config);
        //
        // Connect to our socket server
        //
        if (typeof io === 'undefined') {
            Ext.Msg.alert('Warning!!!', 'Socket.io couldn\'nt be loaded???', Ext.emptyFn);
        }
    },

    _generateLoginData() {
        return {
            company: 'admin',
            authParams: {
                token: this._getAdminToken(),
                domain: CONSTANTS.LOGIN.SESSION_DOMAINS.ADMIN,
            },
        };
    },

    /**
     * Connection to the socket server
     *
     */
    connectSocket() {
        if (this.socket !== null) {
            return;
        }
        this.socket = io.connect(serverUrl, {
            forceNew: false,
            transports: ['websocket'],
            query: {
                loginData: JSON.stringify(this._generateLoginData()),
            },
        });
        this.socket.on('connect', this._onSocketConnection.bind(this));
    },

    /**
     * call socket emit event
     * @param eventName
     * @param data
     */
    emit(eventName, data) {
        return new Promise(resolve => {
            this.socket.emit(eventName, data, response => {
                resolve(response);
            });
        });
    },

    /**
     * Event handler of successful socket connection
     */
    _onSocketConnection() {
        this.socket.emit('login');

        this.proxyEvents.forEach(event => {
            this.socket.on(event, data => {
                this.fireEvent(event, data);
            });
        });
    },

    _getAdminToken() {
        const storage = Ext.util.LocalStorage.get('auth');
        return storage.getItem('adminToken');
    },

});

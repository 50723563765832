Ext.define('FieldServices.view.report.cohort.filter.CohortFilterView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.cohort.filter.CohortFilterViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
    ],

    xtype: 'cohortFilterView',

    controller: 'cohortFilterViewController',

    scrollable: true,
    layout: 'vbox',

    items: [
        {
            xtype: 'formpanel',
            layout: 'hbox',
            defaults: {
                labelAlign: 'top',
            },
            items: [
                {
                    xtype: 'selectfield',
                    itemId: 'cohortsFormat',
                    reference: 'cohortsFormat',
                    label: 'Cohorts',
                    name: 'cohortQueryFormat',
                    options: [],
                    flex: 1,
                },
                {
                    xtype: 'dateRangeFilter',
                    itemId: 'dateRangeFilter',
                    title: 'Date range:',
                    name: 'dt_created',
                    layout: 'hbox',
                    fixedRangeMode: 'inRange',
                    maxDate: new Date(),
                    fieldConfigs: [
                        { tableName: 'Company', property: 'dt_created' },
                    ],
                    mode: 'date',
                    flex: 4,
                },
            ],
        },

        {
            xtype: 'formpanel',
            layout: {
                type: 'vbox',
                wrap: true,
            },
            defaults: {
                labelAlign: 'top',
            },
            items: [
                {
                    xtype: 'container',
                    layout: 'hbox',
                    defaults: {
                        labelAlign: 'top',
                        flex: 1,
                        required: false,
                    },
                    items: [
                        {
                            xtype: 'selectfield',
                            itemId: 'purchaseSetupSelectField',
                            label: 'Purchase',
                            tableName: 'Purchases',
                            filterOperator: 'regexp',
                            name: 'product',
                            options: [],
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'countrySelectField',
                            label: 'Country',
                            name: 'country_id',
                            tableName: 'CompanyAddress',
                            filterOperator: '=',
                            options: [],
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'mediaSourceSelectField',
                            label: 'Media Source',
                            name: 'mediaSource',
                            filterOperator: '=',
                            tableName: 'Company',
                            options: [],
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'adSetNameSelectField',
                            label: 'Ad Set Name',
                            name: 'adSetName',
                            filterOperator: '=',
                            tableName: 'Company',
                            options: [],
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'businessTypesIdField',
                            name: 'businesstypes_id',
                            label: 'Business types',
                            filterOperator: '=',
                            tableName: 'Company',
                            options: [],
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'teamSizeField',
                            name: 'teamSize',
                            label: 'Number of employees',
                            filterOperator: '=',
                            tableName: 'Company',
                            options: [],
                        },
                    ],
                },
                {
                    xtype: 'container',
                    layout: 'hbox',
                    defaults: {
                        labelAlign: 'top',
                        flex: 1,
                        required: false,
                    },
                    items: [
                        {
                            xtype: 'whiteLabelFilter',
                            includeTest: false,
                            itemId: 'partnerSelectField',
                        },
                        {
                            xtype: 'textfield',
                            ui: '-default',
                            name: 'campaignName',
                            label: 'Campaign Name',
                            filterOperator: 'anyLike',
                            tableName: 'Company',
                            flex: 3,
                        },
                    ],
                },

            ],
        },
        {
            xtype: 'container',
            layout: 'hbox',
            cls: 'reportViewButtonsLine',
            defaults: {
                labelAlign: 'right',
                labelWidth: 'auto',
            },
            items: [
                {
                    xtype: 'button',
                    itemId: 'filterButton',
                    text: 'Load',
                    iconCls: 'ion-ios-checkmark-outline',
                    cls: 'btn',
                },
                {
                    xtype: 'checkboxfield',
                    itemId: 'isCumulativeCheckbox',
                    name: 'isCumulative',
                    label: ' cumulative',
                    bind: {
                        checked: '{isCumulative}',
                    },
                },
                {
                    xtype: 'checkboxfield',
                    itemId: 'isPercentageCheckbox',
                    name: 'isPercentage',
                    label: ' percentage',
                    bind: {
                        checked: '{isPercentage}',
                    },

                },

            ],
        },

    ],

});

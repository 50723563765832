Ext.define('FieldServices.view.company.settings.productExport.ProductExportView', {
    extend: 'Ext.form.FieldSet',
    requires: [
        'FieldServices.view.company.settings.productExport.ProductExportViewController',
        'FieldServices.view.company.settings.productExport.ProductExportViewModel',
    ],

    xtype: 'productExportView2',

    controller: 'productExportViewController2',
    viewModel: 'productExportViewModel',

    scrollable: true,

    title: 'Export products',
    defaultType: 'textfield',
    layout: 'hbox',
    items: [
        {
            xtype: 'formpanel',
            itemId: 'exportForm',
            flex: 1,
            layout: 'vbox',
            cls: 'exportFormPanel',
            items: [
                {
                    flex: 1,
                    xtype: 'textfield',
                    name: 'email',
                    itemId: 'exportEmailField',
                    reference: 'exportEmailField',
                    label: 'Email to send products to: ',
                    labelAlign: 'top',
                    placeholder: 'xxx@powerednow.com',
                    errorTarget: 'under',
                    validators: {
                        fn: 'isEmailValid',
                    },
                    bind: {
                        value: '{exportEmail}',
                    },
                },

                {
                    xtype: 'button',
                    itemId: 'submitButton',
                    text: 'Export',
                    iconCls: 'ion-ios-checkmark-outline',
                    cls: 'btn btn--centered',
                    handler: 'onSubmitButtonTap',
                    bind: {
                        disabled: '{exportBtnDisabled}',
                    },
                },
            ],
        },

    ],

});

Ext.define('FieldServices.view.company.select.SelectCompanyViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.selectCompanyMainCtrl',
    xtype: 'selectCompanyMainCtrl',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '#selectCompanyList': {
            companySelected: 'onCompanySelected',
        },
        '#findCompany': {
            search: 'onSearchForCompany',
        },
        '#': {
            show: 'onShowView',
            hide: 'onHide',
        },
    },

    config: {
        haveBeenChosen: null,
        haveBeenSearched: null,
    },

    onSearchForCompany() {
        this.setHaveBeenSearched(true);
    },

    onShowView() {
        this.setHaveBeenChosen(false);
        this.setHaveBeenSearched(false);
    },

    onCompanySelected(companyId) {
        this.setHaveBeenChosen(true);
        this.getView().fireEvent('companySelected', companyId);
        References.getSelectCompanyPopup().hide();
    },

    onCloseSelectPanel() {
        this._hideSelectPanel();
    },

    onHide() {
        if (this.getHaveBeenSearched() && !this.getHaveBeenChosen()) {
            this._showNotSelectedWarning();
        }
        this.getView().destroy();
    },

    _showNotSelectedWarning() {
        const companyStore = Ext.getStore('mainCompanyStore');
        const currentCompany = companyStore ? companyStore.getData().first() : false;
        let companyDetailsText = '';
        if (currentCompany) {
            const {
                data: {
                    id,
                    name,
                    email,
                },
            } = currentCompany;
            companyDetailsText = `active company remained:
             <br>
             <strong>
             <br>id: ${id}
             <br>-----
             <br>company name: ${name}
             <br>-----
             <br>email: ${email}
             </strong>
             <br>`;
        }
        Ext.Msg.confirm(
            'Warning',
            `You didn't select any company,
             ${companyDetailsText}
             do you want to proceed?`,
            choice => {
                if (choice === 'yes') {
                    this._hideSelectPanel();
                } else {
                    this._showSelectPanel();
                }
            },
        );
    },

    _hideSelectPanel() {
        References.getSelectCompanyPopup().hide();
    },
    _showSelectPanel() {
        References.getSelectCompanyPopup().show();
    },

});

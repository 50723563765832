import { ADMIN_REPORT } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.salesReport.SalesReportView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.report.salesReport.SalesReportViewController',
        'FieldServices.view.report.salesReport.filter.SalesReportFilterView',
        'FieldServices.view.report.salesReport.SalesReportBaseGrid',
    ],

    xtype: 'salesReportView',
    controller: 'salesReportViewController',
    viewModel: {
        stores: {
            salesStore: {
                storeId: 'SalesReportStore',
                data: [],
                fields: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_SALES,
            },
            paymentStore: {
                storeId: 'PaymentReportStore',
                data: [],
                fields: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_PAYMENTS,
            },
        },
        data: {
            usageReportLoading: false,
        },
    },
    layout: 'vbox',

    items: [
        {
            xtype: 'salesReportFilterView',
            itemId: 'filterComponent',
        },
        {
            xtype: 'tabpanel',
            itemId: 'gridTabs',
            flex: 1,
            items: [
                {
                    tab: {
                        title: 'Company Sales',
                        flex: 1,
                    },
                    xtype: 'salesReportBaseGrid',
                    dataKeys: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_SALES,
                    store: 'SalesReportStore',

                },
                {
                    tab: {
                        title: 'Company Payments',
                        flex: 1,
                    },
                    xtype: 'salesReportBaseGrid',
                    dataKeys: ADMIN_REPORT.USAGE_HISTORY_GROUPS.COMPANY_PAYMENTS,
                    store: 'PaymentReportStore',
                    columnWidthOverrides: {
                        company_id: 0.8,
                        currency: 0.8,
                    },
                    overrideColumns: {
                        payments: {
                            insertAfter: 'currency',
                            columnDef: {
                                cls: 'full-text-cell',
                                text: 'All Payments',
                                flex: 2,
                                height: 'auto',
                                resizable: false,
                                menu: null,
                                columns: [
                                    {
                                        cls: ['full-text-cell', 'text-small'],
                                        dataIndex: 'payments',
                                        text: 'Count',
                                        flex: 1,
                                        height: 'auto',
                                        resizable: false,
                                        cell: {
                                            cls: ['full-text-cell', 'text-small'],
                                        },
                                    },
                                    {
                                        cls: ['full-text-cell', 'text-small'],
                                        dataIndex: 'payments_amount',
                                        text: 'Amount',
                                        flex: 1,
                                        height: 'auto',
                                        resizable: false,
                                        cell: {
                                            cls: ['full-text-cell', 'text-small'],
                                        },
                                    },
                                    {
                                        cls: ['full-text-cell', 'text-small'],
                                        dataIndex: 'expense_payments_amount',
                                        text: 'Expense Amount',
                                        flex: 1,
                                        height: 'auto',
                                        resizable: false,
                                        cell: {
                                            cls: ['full-text-cell', 'text-small'],
                                        },
                                    },
                                ],

                            },
                        },
                        payments_amount: 'skip',
                        expense_payments_amount: 'skip',
                    },
                },
            ],
        }],
});

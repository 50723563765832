Ext.define('FieldServices.view.company.settings.chargeBee.ChargeBeeView', {
    extend: 'Ext.form.FieldSet',

    requires: [
        'FieldServices.view.company.settings.chargeBee.ChargeBeeViewController',
        'FieldServices.view.company.settings.chargeBee.ChargeBeeViewModel',
    ],

    alias: 'widget.chargeBeeView',

    controller: 'chargeBeeViewController',
    viewModel: 'chargeBeeViewModel',

    title: 'ChargeBee',

    listeners: {
        painted: {
            fn: 'onPainted',
            single: true,
        },
    },

    items: [
        {
            minHeight: '15em',
            masked: { xtype: 'loadmask' },
            bind: {
                hidden: '{loaded}',
            },
        },
        {
            xtype: 'container',
            itemId: 'mainContainer',
            layout: 'vbox',
            flex: 1,
            bind: {
                hidden: '{!loaded}',
            },
            items: [
                {
                    xtype: 'container',
                    layout: 'vbox',
                    defaults: {
                        flex: 1,
                        bind: {
                            data: '{statusData}',
                        },
                        style: {
                            'font-size': 'larger',
                            padding: '1em 0.5em 0',
                        },
                    },
                    items: [
                        {
                            xtype: 'container',
                            itemId: 'statusHolder',
                            tpl: '<b>Connection Status:</b> {status}',
                        }, {
                            xtype: 'container',
                            tpl: '<b>ID in Chargebee:</b> {chargeBeeId}',
                            itemId: 'chargeBeeIdHolder',
                        },
                    ],
                }, {
                    xtype: 'container',
                    layout: 'vbox',
                    defaults: {
                        flex: 1,
                    },
                    items: [
                        {
                            xtype: 'button',
                            text: 'Refresh',
                            handler: 'onCheckAgainTapped',

                        },
                        {
                            xtype: 'button',
                            text: 'Connect to Chargebee',
                            handler: 'onConnectToChargeBee',
                            itemId: 'connectToChargeBee',
                            bind: {
                                hidden: '{isCompanyInChargeBee || companyMovedTo}',
                            },
                        },
                        {
                            xtype: 'container',
                            layout: 'hbox',
                            flex: 1,
                            bind: {
                                hidden: '{!isCompanyInChargeBee || companyMovedTo}',
                            },
                            items: [
                                {
                                    xtype: 'button',
                                    text: 'View in Chargebee',
                                    handler: 'onGoToChargeBee',
                                    itemId: 'goToChargeBee',
                                },
                                {
                                    xtype: 'button',
                                    text: 'Disconnect from Chargebee',
                                    handler: 'onDisconnectFromChargeBee',
                                    itemId: 'disconnectFromChargeBee',
                                    bind: {
                                        hidden: '{companyMovedTo}',
                                    },
                                },
                            ],
                        },

                    ],
                }, {
                    xtype: 'fieldset',
                    itemId: 'reConnectAccountContainer',
                    cls: 'subset',
                    layout: 'hbox',
                    title: 'Move ChargeBee customer to another Powered Now company',
                    bind: {
                        hidden: '{!isCompanyInChargeBee || companyMovedTo}',
                    },
                    items: [{
                        xtype: 'numberfield',
                        flex: 1,
                        placeholder: 'Target Powered Now company ID',
                        itemId: 'reconnectingCompanyIdField',
                    }, {
                        flex: 1,
                        xtype: 'button',
                        text: 'Move customer',
                        handler: 'onReconnectAccount',
                    }],

                }],
        },

    ],
});

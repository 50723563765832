Ext.define('FieldServices.view.knownError.KnownErrorViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.knownErrorViewModel',
    xtype: 'knownErrorViewModel',
    stores: {
        KnownErrorStore: {
            type: 'KnownError',
            autoLoad: {
                addRecords: true,
            },
        },
    },
    formulas: {
        saveButtonClass(get) {
            return get('dataChanged') ? 'priority-button' : '';
        },
        dataChanged: {
            bind: {
                modifiedRecords: '{KnownErrorStore.modifiedRecords}',
            },
            get(boundValues) {
                return boundValues.modifiedRecords.length > 0;
            },
        },
        modifiedNumber: {
            bind: {
                modifiedRecords: '{KnownErrorStore.modifiedRecords}',
            },
            get(boundValues) {
                return boundValues.modifiedRecords.length;
            },
        },
    },
});

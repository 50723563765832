const Bluebird = require('bluebird');

Ext.define('shared.overrides.MessageBox', {
    override: 'Ext.MessageBox',
    statics: {},
    alertAsync(title, message, scope) {
        return new Bluebird(resolve => {
            this.alert(title, message, resolve, scope);
        });
    },

    showAsync(options) {
        return new Bluebird(resolve => {
            this.show(Object.assign(options, {
                fn: resolve,
            }));
        });
    },

    promptAsync(options) {
        return new Bluebird(resolve => {
            this.show(Object.assign(options, {
                buttons: Ext.MessageBox.OK,
                prompt: { label: options.label || null, ...options.prompt },
                fn(buttonText, valueEntered, messageBoxConfig) {
                    resolve({ buttonText, valueEntered, messageBoxConfig });
                },
            }));
        });
    },
    confirmAsync(title, message, options) {
        return new Bluebird(resolve => {
            this.show({
                title,
                message,
                buttons: [
                    {
                        itemId: 'Yes',
                        text: 'Yes',
                        maskAutoDestroy: false,
                    },
                    {
                        itemId: 'No',
                        text: 'No',
                        maskAutoDestroy: false,
                    },
                ],
                fn: buttonId => {
                    resolve(buttonId.toString().toLowerCase() === 'yes');
                },
                ...options,
            });
        });
    },

    _getDefaultButtonItemId(buttonConfig = Ext.MessageBox.OK) {
        const convertedButton = Ext.convertKeyedItems(buttonConfig);
        if (convertedButton && convertedButton[0]) {
            return convertedButton[0].itemId;
        }
        return null;
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.PurchaseAction', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.PurchaseAction'],
    alias: 'store.PurchaseAction',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.PurchaseAction',
        storeId: 'PurchaseAction',
        remoteFilter: true,
        remoteSort: true,

    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/PurchaseAction`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_BIG,

});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Company', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Company'],
    alias: 'store.Company',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Company',
        storeId: 'Company',
        remoteFilter: true,
        remoteSort: true,
    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/Company`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
        extraParams: {
            requiredFields: ['chargebee_id'],
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_MEDIUM,
});

import TEAM_SIZE from '@powerednow/shared/constants/teamSize';

Ext.define('FieldServices.view.company.settings.CompanySettingsView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.company.settings.CompanySettingsViewController',
        'FieldServices.view.company.settings.CompanySettingsViewModel',
        'FieldServices.view.company.settings.chargeBee.ChargeBeeView',
        'FieldServices.view.company.settings.productExport.ProductExportView',
        'Shared.Components.field.dateTimeField.DateTimeField',
    ],

    xtype: 'companySettingsView',
    cls: 'settingsPage',

    controller: 'companySettingsController',
    viewModel: 'companySettingsViewModel',

    listeners: {
        painted: {
            fn: 'onPageShow',
            single: true,
        },
    },
    bind: {
        masked: '{saveLoadMask}',
    },
    layout: 'vbox',
    flex: 1,
    scrollable: true,

    items: [{
        xtype: 'container',
        layout: 'hbox',
        flex: 1,
        defaults: {
            cls: 'settingsSaveHeader',
        },
        items: [
            { flex: 1 },
            {
                flex: 2,
                xtype: 'button',
                text: 'Save Changes',
                handler: 'saveChangedSettings',
                disabled: true,
                bind: {
                    disabled: '{!unsavedChanges}',
                },
            },
            { flex: 1 },
        ],
    },
    {
        xtype: 'container',
        layout: 'hbox',
        flex: 11,
        cls: 'mainSettingsContainer',
        scrollable: true,
        items: [
            {
                xtype: 'container',
                layout: 'hbox',
                bodyCls: ['verticalOnMobile'],
                flex: 1,
                items: [{
                    xtype: 'container',
                    layout: 'vbox',
                    flex: 1,
                    defaults: {
                        cls: 'companyPagePanel',
                    },
                    items: [{
                        xtype: 'formpanel',
                        flex: 1.5,
                        title: 'General Settings',
                        items: [{
                            xtype: 'selectfield',
                            itemId: 'whitelabelIdField',
                            label: 'White Label',
                            labelAlign: 'top',
                            flex: 1,
                            usePicker: false,
                            bind: {
                                value: '{companyWhiteLabelId}',
                                store: '{whitelabelOptionStore}',
                                masked: '{whitelabelOptionStore.loading}',
                                hidden: '{!canChangeWhiteLabel}',
                            },
                            valueField: 'id',
                            displayField: 'name',
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'businessTypeIdField',
                            label: 'Business Type',
                            labelAlign: 'top',
                            flex: 1,
                            usePicker: false,
                            bind: {
                                value: '{companyBusinessTypeId}',
                                store: '{businessTypeOptionStore}',
                            },
                            valueField: 'id',
                            displayField: 'type',
                        },
                        {
                            xtype: 'selectfield',
                            itemId: 'teamSizeField',
                            label: 'Team Size',
                            labelAlign: 'top',
                            flex: 1,
                            usePicker: false,
                            options: [
                                { text: TEAM_SIZE.JUST_ME.text, value: TEAM_SIZE.JUST_ME.value },
                                { text: TEAM_SIZE.TWO_TO_SIX.text, value: TEAM_SIZE.TWO_TO_SIX.value },
                                { text: TEAM_SIZE.SEVEN_TO_FOURTEEN.text, value: TEAM_SIZE.SEVEN_TO_FOURTEEN.value },
                                { text: TEAM_SIZE.FIFTEEN_PLUS.text, value: TEAM_SIZE.FIFTEEN_PLUS.value },
                            ],
                            bind: {
                                value: '{companyTeamSize}',
                            },
                        },
                        {
                            xtype: 'togglefield',
                            itemId: 'isTestCompanyField',
                            label: 'Test company',
                            cls: 'full-width',
                            flex: 1,
                            labelWidth: 'auto',
                            bind: {
                                hidden: '{!isTopLevelAdmin}',
                                value: '{companyIsTest}',
                            },
                        }],
                    }, {
                        xtype: 'formpanel',
                        flex: 1,
                        title: 'Subscription Settings',
                        items: [
                            {
                                xtype: 'powerednow.datetimefield',
                                itemId: 'trialExpirationDateField',
                                mode: 'date',
                                dateFormat: 'Y-m-d',
                                label: 'Trial expiration date',
                                flex: 1,
                                bind: {
                                    value: '{trialExpirationDate}',
                                },
                            },
                            {
                                xtype: 'container',
                                layout: 'hbox',
                                flex: 1,
                                items: [
                                    {
                                        xtype: 'selectfield',
                                        itemId: 'concurrentLoginLimitModeField',
                                        reference: 'concurrentLoginLimitMode',
                                        label: 'Concurrent login limit',
                                        labelAlign: 'top',
                                        options: [{
                                            text: 'Disabled',
                                            value: 0,
                                        }, {
                                            text: 'Subscription Default',
                                            value: 1,
                                        }, {
                                            text: 'Manual',
                                            value: 2,
                                        }],
                                        flex: 1,
                                        usePicker: false,
                                        bind: {
                                            value: '{concurrentLoginLimitSetting}',
                                        },
                                    },
                                    {
                                        xtype: 'spinnerfield',
                                        itemId: 'concurrentLoginLimitValue',
                                        label: 'Value',
                                        labelAlign: 'top',
                                        minValue: 1,
                                        flex: 1,
                                        bind: {
                                            value: '{concurrentLoginLimitValue}',
                                            hidden: '{!concurrentLoginLimitValue}',
                                        },
                                    },
                                ],
                            },
                          {
                            xtype: 'numberfield',
                            itemId: 'maxEmailLimitPerMonth',
                            label: 'Maximum e-mail per month',
                            labelAlign: 'top',
                            flex: 1,
                            bind: {
                              value: '{maxEmailLimitPerMonth}',
                            },
                          },
                        ],
                    },
                    ],
                }, {
                    xtype: 'container',
                    layout: 'vbox',
                    flex: 1,
                    defaults: {
                        cls: 'companyPagePanel',
                    },
                    items: [{
                        xtype: 'formpanel',
                        flex: 1,
                        title: 'Tax & Integration Settings',
                        items: [
                            {
                                xtype: 'fieldset',
                                title: 'Tax settings',
                                items: [
                                    {
                                        xtype: 'selectfield',
                                        itemId: 'vatSchemeField',
                                        label: 'VAT Scheme',
                                        labelAlign: 'top',
                                        usePicker: false,
                                        flex: 1,
                                        options: [
                                            {
                                                text: 'Standard',
                                                value: CONSTANTS.TAX.SCHEMES.STANDARD,
                                            },
                                            {
                                                text: 'Cash Accounting',
                                                value: CONSTANTS.TAX.SCHEMES.CASH_ACCOUNTING,
                                            },
                                        ],
                                        bind: {
                                            value: '{companyVATScheme}',
                                        },
                                        fieldForOption: 'vat_scheme',
                                    },
                                    {
                                        xtype: 'togglefield',
                                        itemId: 'enableMTDField',
                                        flex: 1,
                                        cls: 'full-width',
                                        label: 'MTD enabled',
                                        labelAlign: 'left',
                                        labelWidth: 'auto',
                                        bind: {
                                            value: '{companyMTDEnabled}',
                                        },
                                    },
                                ],
                            },
                            {
                                xtype: 'fieldset',
                                title: 'Xero',
                                items: [
                                    {
                                        xtype: 'togglefield',
                                        itemId: 'enableXeroUsageField',
                                        cls: 'full-width',
                                        flex: 1,
                                        label: 'Xero usage enabled',
                                        labelAlign: 'left',
                                        labelWidth: 'auto',
                                        bind: {
                                            disabled: '{!xeroIntegrationRecord}',
                                            value: '{companyXeroUsageEnabled}',
                                        },
                                    },
                                ],
                            },
                            {
                                xtype: 'chargeBeeView',

                            },
                        ],
                    }],
                }, {
                    xtype: 'container',
                    layout: 'vbox',
                    flex: 1,
                    defaults: {
                        cls: 'companyPagePanel',
                    },
                    items: [{
                        xtype: 'formpanel',
                        title: 'Form & Certificate Settings',
                        flex: 1,
                        layout: 'vbox',
                        defaults: {
                            xtype: 'togglefield',
                            cls: 'full-width',
                            labelWidth: 'auto',
                            labelAlign: 'left',
                        },
                        items: [
                            {
                                itemId: 'renewableEnergyFormsToggleField',
                                label: 'Renewable Energy Forms Enabled',
                                bind: {
                                    value: '{renewableEnergyFormsEnabled}',
                                },
                            },
                            {
                                itemId: 'limitCertificatesToggleField',
                                label: 'New Certificates Blocked',
                                bind: {
                                    value: '{newCertificatesBlockedCurrentValue}',
                                },
                            },
                        ],
                    }, {
                        xtype: 'formpanel',
                        title: 'Company Actions',
                        flex: 2,
                        layout: 'vbox',
                        items: [
                            {
                                xtype: 'fieldset',
                                title: 'User Actions',
                                items: [
                                    {
                                        xtype: 'container',
                                        layout: 'vbox',
                                        flex: 1,
                                        items: [{
                                            xtype: 'button',
                                            text: 'Edit Profile Roles',
                                            handler: 'openRoleSettingsModal',
                                            bind: {
                                                disabled: '{!selectedCompanyData}',
                                            },
                                        }, {
                                            xtype: 'button',
                                            text: 'Force Logout All Users',
                                            itemId: 'forceLogoutButton',
                                            handler: 'forceLogoutAllUsers',
                                            ui: '-danger',
                                            bind: {
                                                disabled: '{!selectedCompanyData}',
                                            },
                                        },
                                        ],
                                    },
                                ],
                            },
                            {
                                xtype: 'productExportView2',
                            },
                        ],
                    }],
                }],
            },
        ],
    }],
});

Ext.define('FieldServices.view.company.purchases.subscriptions.add.AddSubscriptionView', {
    extend: 'Ext.form.Panel',
    requires: [
        'FieldServices.view.company.purchases.subscriptions.add.AddSubscriptionViewController',
        'Shared.Components.field.dateTimeField.DateTimeField',
    ],

    xtype: 'addSubscriptionView',

    controller: 'addSubscriptionViewController',

    itemId: 'AddSubscriptionPopupId',

    scrollable: true,

    title: 'New extra',

    tools: [{
        type: 'close',
        handler: 'onCancelButtonTap',
    }],

    defaultType: 'textfield',
    layout: 'vbox',
    defaults: {
        labelAlign: 'top',
        flex: 1,
    },
    items: [
        {
            xtype: 'selectfield',
            itemId: 'selectPurchaseSetup',
            reference: 'productSelector',
            label: 'Product:',
            name: 'product_id',
            isRequired: true,
        },
        {
            xtype: 'container',
            layout: 'hbox',
            items: [{
                flex: 1,
                xtype: 'powerednow.datetimefield',
                itemId: 'purchaseDate',
                label: 'Purchase date:',
                name: 'purchase_date',
                mode: 'date',
                dateFormat: 'Y-m-d',
                value: new Date(),
            },
            {
                flex: 1,
                xtype: 'powerednow.datetimefield',
                itemId: 'expiresDate',
                label: 'Expires date:',
                name: 'expires_date',
                mode: 'date',
                dateFormat: 'Y-m-d',
                value: new Date(),
            }],
        },
        {
            xtype: 'textfield',
            reference: 'noteField',
            name: 'note',
            label: 'Note: ',
            isRequired: true,
        },

        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->',
                {
                    xtype: 'button',
                    itemId: 'submitButton',
                    text: 'Submit',
                    iconCls: 'ion-ios-checkmark-outline',
                    bind: {
                        disabled: '{!noteField.value || !productSelector.value}',
                    },
                }, {
                    xtype: 'button',
                    itemId: 'cancelButton',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                }],
        },
    ],

});

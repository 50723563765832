Ext.define('FieldServices.view.report.usageReport.filter.UsageReportFilterViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.usageReportFilterViewController',
    xtype: 'usageReportFilterViewController',

    control: {
        monthSelect: true,
        whiteLabelSelect: true,
        includeChurnedCheckbox: true,
    },

    onChangeFilter() {
        this._changeFilter();
    },

    onDownloadCsvFile() {
        this.getView().fireEvent('exportRequested', {});
    },

    _changeFilter() {
        const filterData = this.getView().getFilters();
        const includeChurned = this.getIncludeChurnedCheckbox().getChecked();
        this.getView().fireEvent('changeFilter', { filterData, includeChurned });
    },

});

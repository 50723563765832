import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { SUBSCRIPTION } from '@powerednow/shared/constants';

const dateUtils = require('shared/modules/utilities/date');

Ext.define('FieldServices.model.PurchasesGrouped', {
    extend: 'Ext.data.Model',

    requires: [
        'Ext.data.proxy.Rest',
    ],
    fields: [
        { name: 'product' },
        { name: 'expires_date' },
        { name: 'purchase_date' },
        { name: 'purchase_origin' },
        { name: 'original_purchase_date' },
        { name: 'purchaseSetupName' },
        {
            name: 'product_name',
            calculate: data => data['PurchaseSetup.product_name'],
        }, {
            name: 'weight',
            calculate: data => data['PurchaseSetup.weight'] || data['PurchaseSetupAndroid.weight'],
        }, {
            name: 'expiresDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d')(data.expires_date, 'C'),
        }, {
            name: 'purchaseDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d')(data.purchase_date, 'C'),
        }, {
            name: 'originalPurchaseDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d')(data.original_purchase_date, 'C'),
        }, {
            name: 'purchaseOriginName',
            calculate: data => getKeyByValue(SUBSCRIPTION.PURCHASE_ORIGIN_ID, parseInt(data.purchase_origin, 10)),
        }, {
            name: 'isActive',
            calculate: data => {
                const date = new Date();
                const startDate = data.purchase_date;
                const endDate = data.expires_date;
                return dateUtils.isDateBetween({ date, startDate, endDate });
            },
        },
    ],
});

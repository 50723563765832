/**
 * @private
 */
Ext.define('Ext.fx.animation.PopOut', {
    extend: 'Ext.fx.animation.Pop',

    alias: 'animation.popOut',

    config: {
        // @hide
        out: true,

        before: {}
    }
});

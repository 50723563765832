import WhiteLabelConstants from '@powerednow/shared/constants/whiteLabel';

const config = require('config.js');

Ext.define('FieldServices.view.whiteLabel.settings.settingsViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.WhiteLabelPartnerSettingsViewModel',

    data: {
        canTapOnSwitchToTrial: false,
    },

    formulas: {
        cancelDisabled: {
            bind: {
                isDirty: '{record.dirty}',
                isNewRecord: '{record.phantom}',
            },
            get({ isDirty, isNewRecord } = {}) {
                if (!isNewRecord) {
                    return !isDirty;
                }
                return false;
            },
        },
        statusOptions: {
            get() {
                return Object.keys(WhiteLabelConstants.STATUS).map(statusKey => ({
                    id: statusKey,
                    name: WhiteLabelConstants.STATUS[statusKey],
                }));
            },
        },
    },

    constructor(...args) {
        this.callParent(args);

        this.bind({
            partnerId: '{record.id}',
            isNewRecord: '{record.phantom}',

        }, this._updateCanTapOnSwitchToTrial);
    },

    async _updateCanTapOnSwitchToTrial({ partnerId, isNewRecord } = {}) {
        this.set('canTapOnSwitchToTrial', false);
        const canSwitchPartnerToTrial = await this._canSwitchPartnerToTrial(partnerId);
        this.set('canTapOnSwitchToTrial', !isNewRecord && canSwitchPartnerToTrial);
    },

    async _canSwitchPartnerToTrial(partnerId) {
        const url = `${config.SERVER_URL}api/admindata/whiteLabel/canSwitchPartnerToTrial/${partnerId}`;
        const { canSwitchPartnerToTrial } = await FieldServices.app.callAPIEndpointRequest('GET', url, {});
        return canSwitchPartnerToTrial;
    },
});

Ext.define('FieldServices.view.report.action.detail.PurchaseActionDetailFormViewListView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.report.action.detail.PurchaseActionDetailListViewController',
        'Ext.grid.plugin.PagingToolbar',
    ],

    xtype: 'purchaseActionDetailListView',

    controller: 'purchaseActionDetailListViewController',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridexporter',
    }],

    emptyText: 'empty',

    title: 'Purchase Action detail list (-)',

    columns: [
        {
            text: 'Company Id',
            dataIndex: 'company_id',
        }, {
            text: 'Action',
            dataIndex: 'actionName',
        }, {
            text: 'Created date',
            dataIndex: 'action_created_dt',
        }, {
            text: 'Product Name',
            dataIndex: 'productName',
        }, {
            text: 'Product id',
            dataIndex: 'product_id',
        }, {
            text: 'Company',
            dataIndex: 'companyName',
            flex: 1,
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'First',
            dataIndex: 'firstName',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Last',
            dataIndex: 'lastName',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Main Email',
            dataIndex: 'mainEmail',
        },
    ],

});

const EmailValidator = require('shared/modules/validation/emailValidator');
const moment = require('moment');

Ext.define('FieldServices.view.report.background.BackgroundViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.backgroundViewController',
    xtype: 'backgroundViewController',

    control: {
        '#': {
            painted: 'onPainted',
        },
        sendButton: {
            tap: 'onButtonTap',
        },
        reportBox: true,
        monthSelect: true,
    },

    config: {
        methodsConfig: {},
        methods: [],
        dynamicItems: [],
    },

    onPainted() {
        this._loadFormConfig().bind(this)
            .then(this._extendFormFields);
        this._getLocalStorage();
    },

    onButtonTap() {
        const data = this._prepareFormValue();
        this._setLocalStorage();
        if (!data) {
            return Promise.resolve();
        }
        return FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/report/background/sendReport',
            jsonData: data,
        })
            .then(response => {
                if (response === true) {
                    Ext.Msg.alert('alert', 'Processing is in the background, reports will be sent to the specified email(s).');
                } else {
                    Ext.Msg.alert('error', 'There was an error in the backend.');
                }
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _setLocalStorage() {
        const { emails } = this.getView().getValues();
        localStorage.setItem('reportEmails', emails);
    },
    _getLocalStorage() {
        const emails = localStorage.getItem('reportEmails');
        this.getView().setValues({ emails });
    },
    _loadFormConfig() {
        return FieldServices.app.callAPI({
            method: 'GET',
            url: 'api/admindata/report/background/getReportsConstants',
        })
            .then(response => {
                if (response) {
                    return response;
                }
                return {};
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _extendFormFields(config) {
        this.setMethodsConfig(config);
        this._removeDynamicItems();
        Object.values(CONSTANTS.ADMIN_REPORT.GROUP).forEach(({ ID: groupId }) => {
            if (groupId && config[groupId] && config[groupId].methods) {
                this._insertDynamicItems(groupId, config[groupId].methods);
            }
        });
    },

    _removeDynamicItems() {
        this.getDynamicItems().forEach(elem => elem.destroy());
        this.setDynamicItems([]);
    },

    _insertDynamicItems(groupId, methods) {
        methods.forEach(method => {
            const checkBoxItem = this._getCheckbox({ groupId, method });
            this._insertDynamicItem(groupId, checkBoxItem);

            const htmlItem = this._getHtml({ groupId, method });
            this._insertDynamicItem(groupId, htmlItem);
        });
    },

    _insertDynamicItem(groupId, item) {
        const dynamicItem = this.getReportBox().down(`#${groupId}`).insertLast(item);
        this.getDynamicItems().push(dynamicItem);
        return dynamicItem;
    },

    _getCheckbox(options) {
        return {
            xtype: 'checkboxfield',
            name: options.method,
            label: this._getMethodConfig(Object.assign(options, { prompt: 'label' })),
            labelWidth: 'auto',
        };
    },
    _getHtml(options) {
        return {
            xtype: 'container',
            html: this._getMethodConfig(Object.assign(options, { prompt: 'html' })),
            cls: 'color_silver',
        };
    },

    _getMethodConfig({ groupId, method, prompt }) {
        const config = this.getMethodsConfig();
        return config[groupId][prompt][method] || method;
    },

    _prepareFormValue() {
        const value = this.getView().getValues();
        const methodsConfig = this.getMethodsConfig();
        const emails = this._getEmailList(value.emails);
        const reports = [];

        if (!this._isEmailsValid(emails)) {
            return;
        }

        Object.keys(methodsConfig).forEach(groupId => {
            methodsConfig[groupId].methods.forEach(method => {
                if (value[method]) {
                    reports.push(this._getFormattedReportData(groupId, method));
                }
            });
        });
        return {
            reports,
            email: {
                prompts: {
                    subject: 'AdminReportHtml.emailSubject',
                    body: 'AdminReportHtml.emailHtml',
                },
                recipientNames: emails,
                addresses: emails,
            },
            isShowFormData: value.isShowFormData,
        };
    },

    _getEmailList(emails) {
        return emails
            .split(';')
            .map(item => item.trim())
            .filter(item => item.indexOf('@') !== -1);
    },

    _getFormattedReportData(groupId, method) {
        return {
            options: {
                fields: this._getFields(groupId),
            },
            method: {
                groupId,
                name: method,
            },
        };
    },

    _getFields(groupId) {
        const value = this.getView().getValues();
        if (!value['filter-usageStatsDateRange']) {
            delete value.usageStatsDateRange_from;
            delete value.usageStatsDateRange_to;
        }
        const { extraFields } = this.getView().down(`#${groupId}`);
        if (extraFields) {
            return extraFields.reduce((prev, name) => Object.assign(prev, { [name]: value[name] }), {});
        }
        return {};
    },

    _isEmailsValid(emails) {
        if (emails.length === 0) {
            Ext.Msg.alert('Error', 'Please, use @ char in the email field');
            return false;
        }
        const emailValidator = new EmailValidator();
        return emails.every(email => {
            if (!emailValidator.isValidEmail(email)) {
                Ext.Msg.alert('Error', `"${email}" is not valid email format!`);
                return false;
            }
            return true;
        });
    },
});

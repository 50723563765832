Ext.define('FieldServices.view.report.monthlyReports.charts.MonthlyReportsChartsViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.monthlyReportsChartsViewController',
    xtype: 'monthlyReportsChartsViewController',

    requires: [
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
    ],

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        dateRangeFilter: {
            validated: 'onDateRangeValidate',
        },
        loadButton: true,
        whiteLabelFilter: true,
        filterPanel: true,
        chartsContainer: true,
        promoExcludeCheckbox: true,
    },

    allPurchasesStore: Ext.create('Ext.data.Store', {}),
    newPurchasesStore: Ext.create('Ext.data.Store', {}),
    subscribersAtEndOfMonthStore: Ext.create('Ext.data.Store', {}),
    newRegistrationsStore: Ext.create('Ext.data.Store', {}),

    init(...args) {
        this.callParent(args);
        this._setDateRangeFields();
        this.showEmptyState();
    },

    showEmptyState() {
        const container = this.getChartsContainer();
        container.setItems([
            {
                xtype: 'emptytext',
                itemId: 'emptyState',
            },
        ]);
    },

    onDateRangeValidate(dateRangeValid) {
        this.getViewModel().setData({ dateRangeValid });
    },

    _setDateRangeFields() {
        const toDate = new Date();
        toDate.setHours(0, 0, 0, 0);
        toDate.setMonth(toDate.getMonth() - 1, 1);
        const fromDate = new Date(toDate);
        fromDate.setFullYear(toDate.getFullYear() - 1);
        this.getDateRangeFilter().setDateRange({ from: fromDate, to: toDate });
    },

    async loadChartData() {
        const filters = this.getFilterPanel().getFilters();
        if (this.getPromoExcludeCheckbox().getChecked()) {
            filters.push({
                operator: '!=',
                property: 'purchase_origin',
                tableName: 'Purchases',
                value: 4,
            });
        }
        const response = await FieldServices.app.callAPI(
            {
                url: 'api/admindata/purchases/getDataForMonthlyReportCharts/0',
                method: 'GET',
                params: {
                    filter: Ext.encode(filters),
                },
                headers: {},
            },
        );
        const chartData = {
            allPurchases: { data: [], fieldSet: new Set() },
            newPurchases: { data: [], fieldSet: new Set() },
            subscribersAtEndOfMonth: { data: [], fieldSet: new Set() },
            newRegistrations: { data: [], fieldSet: new Set() },
        };
        if (!response.length) {
            return false;
        }
        response.reduce((parsed, row) => {
            Object.keys(parsed).forEach(metric => {
                Object.keys(row[metric]).forEach(whiteLabelName => parsed[metric].fieldSet.add(whiteLabelName));
                parsed[metric].data.push({ month: row.month, ...row[metric] });
            });
            return parsed;
        }, chartData);
        return chartData;
    },

    async loadCharts() {
        this.getViewModel().setData({ dataLoading: true });
        const chartsContainer = this.getChartsContainer();
        chartsContainer.removeAll(true, true);
        const chartsData = await this.loadChartData();
        if (!chartsData) {
            this.showEmptyState();
            this.getViewModel().setData({ dataLoading: false });
            return;
        }

        const charts = [
            this.createChartForMetric(chartsData, 'allPurchases', this.allPurchasesStore, 'All Subscriptions'),
            this.createChartForMetric(chartsData, 'newPurchases', this.newPurchasesStore, 'New Subscriptions'),
            this.createChartForMetric(chartsData, 'subscribersAtEndOfMonth', this.subscribersAtEndOfMonthStore, 'Active subscribers at the end of month'),
            this.createChartForMetric(chartsData, 'newRegistrations', this.newRegistrationsStore, 'New Registrations'),
        ];
        chartsContainer.setItems(charts);

        this.getViewModel().setData({ dataLoading: false });
    },

    createChartForMetric(chartsData, metric, store, title) {
        const vm = this.getViewModel();
        const { data, fieldSet } = chartsData[metric];
        const chart = Ext.create(this.getChartConfig(Array.from(fieldSet), title));
        store.setData(data);
        chart.setStore(store);
        return chart;
    },

    getChartConfig(fields, title) {
        return {
            xtype: 'cartesian',
            insetPadding: '20 10',
            background: '#efefef',
            style: {
                'padding-bottom': '1em',
                width: '48%',
            },
            legend: fields.length > 1 ? {
                background: '#efefef',
                type: 'sprite',
                docked: 'top',
            } : null,

            axes: [{
                type: 'numeric',
                position: 'left',
                minimum: 0,
            }, {
                type: 'category',
                position: 'bottom',
                fields: 'month',
                title: {
                    text: title,
                    fontSize: 20,
                },
                label: {
                    fontSize: 10,
                    rotate: {
                        degrees: -30,
                    },
                },
            }],

            series: [{
                type: 'bar',
                xField: 'month',
                title: fields,
                yField: fields,
                stacked: false,
                style: {
                    width: '2em',
                    opacity: 0.80,
                },
                tooltip: {
                    trackMouse: true,
                    renderer(toolTip, record, ctx) {
                        toolTip.setHtml(`${ctx.field} : <b>${record.data[ctx.field]}</b> </br>${record.get(ctx.series.getXField())}`);
                    },
                },
            }],
            plugins: [{
                type: 'chartitemevents',
                clickEvents: true,
            }],
            listeners: {
                itemclick: 'redirectToDetailedReport',
                legendClick: 'onLegendClick',
            },

        };
    },

    redirectToDetailedReport(chart, clickedItem) {
        const allChartItems = this.getChartsContainer().items.items;
        const filteredChartItems = allChartItems.filter(allChartItem => !allChartItem.isHidden());
        const metricIndex = filteredChartItems.findIndex(item => item === chart);
        const { month } = clickedItem.record.data;
        const whiteLabelId = Ext.getStore('WhiteLabel').findRecord('name', clickedItem.field).id;
        this.getView().fireEvent('chartItemClick', { metricIndex, month, whiteLabelId });
    },

    onLegendClick({ sourceChart, sprite }) {
        const otherCharts = this.getChartsContainer().getItems().items.filter(chart => chart !== sourceChart && chart.getLegend);
        otherCharts.forEach(chart => {
            const legend = chart.getLegend();
            const spriteToToggle = legend.getSprites().filter(legendSprite => legendSprite.text === sprite.text)[0];
            if (spriteToToggle) {
                legend.toggleItem(spriteToToggle);
            }
        });
    },
});

import ComplexModel from 'shared/modules/complexData/tableVersion';

export default Ext.define('FieldServices.model.TableVersion', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],

});

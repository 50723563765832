import ComplexModel from 'shared/modules/complexData/contactMethod';

import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { MESSAGES } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.model.ContactMethod', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',
    config: {
        fields: [{
            name: 'contactmethodtype_id',
        }, {
            name: 'contactMethodTypeName',
            calculate: data => getKeyByValue(MESSAGES.TYPES, data.contactmethodtype_id),
        }],
    },
});

Ext.define('shared.Utils.Renderers', {

    singleton: true,

    config: {},

    getDateRenderer(format) {
        return (value, inputFormat = 'Y-m-d H:i:s') => (value ? Ext.Date.format(Ext.Date.parse(value, inputFormat), format) : '');
    },

    getDateRendererWithoutExt(value, format = 'Y-m-d H:i:s') {
        return value ? Ext.Date.format(new Date(value), format) : '';
    },

});

Ext.define('FieldServices.view.company.document.formDocument.FormDocumentListItemViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.formDocumentListItemViewModel',
    xtype: 'documentListViewModel',

    config: {
        uiMap: {
            isdeleted: {
                ui: '-IsDeleted',
                condition(dataItem) {
                    return Boolean(dataItem.isdeleted);
                },
            },
        },
    },
    formulas: {
        statusOptions: {
            get() {
                return Object.entries(CONSTANTS.FORMS.STATUSES).map(([text, value]) => ({ text, value }));
            },
        },
        statusValue: {
            get() {
                const record = this.get('record');
                if (record) {
                    return record.data.status;
                }
                return '';
            },
        },
        jobLastActivity: {
            bind: '{record.job_id}',
            get(job_id) {
                const record = Ext.getStore('Job').findRecord('id', job_id);
                return record?.data.lastActivity ?? '';
            },
        },
    },
});

Ext.define('FieldServices.view.report.action.PurchaseActionDetailViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.purchaseActionDetailViewController',
    xtype: 'purchaseActionDetailViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '': {
            setSubPage: 'setSubPage',
        },
        purchaseActionDetailFormView: {
            changeFilter: 'onChangeFilterDetailList',
            exportRequested: 'onExportRequested',
        },
        purchaseActionDetailListView: {
            changeFilter: 'onChangeFilterDetailList',
        },
    },

    config: {
        companyId: null,
    },

    onExportRequested() {
        const companyListView = this.getView().down('#purchaseActionDetailListView');
        companyListView.getController().exportList();
    },

    onChangeFilterDetailList(data) {
        const companyListView = this.getView().down('#purchaseActionDetailListView');

        companyListView.getController().loadActionDetailList(data);
    },

});

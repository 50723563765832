import ComplexModel from 'shared/modules/complexData/form';

export default Ext.define('FieldServices.model.Form', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],

});

Ext.define('FieldServices.view.company.device.DeviceViewModelItem', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.deviceViewModelItem',
    xtype: 'deviceViewModelItem',

    config: {
        uiMap: {
            id: {
                ui: '-IsActive',
                condition(dataItem) {
                    return this.get('onlineIds').includes(dataItem.id);
                },
            },
        },
    },

    formulas: {
        ui: {
            bind: {
                record: '{record}',
                // could be anything
                onlineIds: '{onlineIds}',
            },
            get() {
                const uiMap = this.getUiMap();
                return this._generateUiList(uiMap);
            },
        },
    },
});

Ext.define('FieldServices.overrides.LocalStorage', {
    override: 'Ext.util.LocalStorage',

    /**
     * value decode
     *
     * @param key
     */
    getObject(key) {
        const itemValue = this.getItem(key);
        try {
            return JSON.parse(itemValue);
        } catch (e) {
            return itemValue;
        }
    },

    setObject(key, value) {
        return this.setItem(key, JSON.stringify(value));
    },

    removeObject(key) {
        return this.removeItem(key);
    },
});

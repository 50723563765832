import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

const { default: DATABASE } = require('shared/constants/database');

export default Ext.define('FieldServices.store.KnownError', {
    extend: 'Ext.data.Store',
    alias: 'store.KnownError',
    requires: ['FieldServices.proxy.PoweredNowApi', 'FieldServices.model.KnownError'],
    config: {
        storeId: 'KnownError',
        model: 'FieldServices.model.KnownError',
        autoLoad: false,
        extraKeys: {
            byHashedStackAndMinAppVersion: {
                keyFn: item => `${item.data.hashed_stack}__${item.data.min_app_version}`,
            },
        },
    },
    storeId: 'KnownError',
    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/knownError`,
        extraParams: {
            responseFieldNames: DATABASE.FIELDS.NAMING.DB,
        },
    },
    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

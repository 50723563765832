import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.message.MessageView', {
    extend: 'Ext.Panel',
    ui: '-messages',

    requires: [
        'FieldServices.view.company.message.MessageListItemViewModel',
        'FieldServices.view.company.message.MessageViewController',
        'Ext.data.ChainedStore',
    ],

    xtype: 'messageView',
    controller: 'messageViewController',

    layout: 'fit',
    flex: 1,

    items: [{
        xtype: 'grid',
        ui: '-messages',
        itemId: 'messageList',
        cls: 'messageList',
        emptyText: 'empty',
        plugins: [{
            type: 'gridcolumnresizing',
        }, {
            type: 'gridWithFilter',
            id: 'messageFilterPlugin',
            filterOnSearchButton: true,
            paging: true,
            requestConfig: {
                url: 'api/admindata/company-tables/MessageRecipient',
                params: {
                    sort: [{
                        property: 'isdeleted',
                        direction: 'ASC',
                    }, {
                        property: 'dt_created',
                        direction: 'ASC',
                    }, {
                        property: 'id',
                        direction: 'DESC',
                    }],
                    filter: [{
                        property: 'company_id',
                        operator: '=',
                        value: '{companyId}',
                    }, {
                        value: [true, false],
                        property: 'isdeleted',
                        operator: 'in',
                    }],
                    requiredFields: ['company_id', 'isdeleted'],
                    limit: 100,
                },
            },
            dateRangeFilters: [{
                property: 'dt_created',
                label: 'created',
            }],
        }],

        itemConfig: {
            viewModel: {
                type: 'contactListItemViewModel',
            },
        },

        columns: [
            {
                text: 'Type',
                dataIndex: 'message_template_id',
            }, {
                text: 'Recipient',
                dataIndex: 'recipient',
            }, {
                text: 'Subject',
                dataIndex: 'subject',
                renderer: value => {
                    return Ext.util.Format.htmlDecode(value);
                },
            }, {
                text: 'Created',
                dataIndex: 'dt_created',
                gridWithFilter: {
                    skipFromFilter: true,
                },
            }, {
                text: 'Sent',
                dataIndex: 'dt_sent',
                gridWithFilter: {
                    skipFromFilter: true,
                },
            }, {
                text: 'Opens',
                dataIndex: 'opens',
            }, {
                text: 'Clicks',
                dataIndex: 'clicks',
            }, {
                text: 'Status',
                dataIndex: 'status',
            }, {
                text: 'Contact Id',
                dataIndex: 'contact_id',
            }, {
                text: 'Id',
                dataIndex: 'id',
            }, {
                text: 'isDeleted',
                dataIndex: 'isdeleted',
                gridWithFilter: {
                    searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
                },
                cell: {
                    bind: {
                        value: '{isDeletedString}',
                    },
                },
            }, {
                text: '',
                gridWithFilter: {
                    skipFromFilter: true,
                },
                cell: {
                    xtype: 'widgetcell',
                    widget: {
                        xtype: 'button',
                        itemId: 'reSendButton',
                        text: 'reSend',
                    },
                },
            }],
    }],
});

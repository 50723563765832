import { ROLES, USER_PROFILE } from '@powerednow/shared/constants';

const EmailValidator = require('@powerednow/shared/modules/validation/emailValidator');
const PasswordValidator = require('@powerednow/shared/modules/validation/passwordValidator');

Ext.define('FieldServices.view.company.user.edit.UserEditViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.userEditViewController',
    xtype: 'userEditViewController',

    control: {
        profileSelectField: {
            change: 'onProfileSelectFieldChange',
        },
        closeButton: {
            tap: 'onCloseButtonTap',
        },
        submitButton: {
            tap: 'onSubmitButtonTap',
        },
        restoreUserButton: {
            tap: 'onRestoreUserButtonTap',
        },
        deleteUserButton: {
            tap: 'onDeleteUserButtonTap',
        },
        loginUserButton: {
            tap: 'onLoginUserButtonTap',
        },
        loginWithPartnerButton: {
            tap: 'onLoginWithPartnerButtonTap',
        },
        deleteSocialButton: {
            tap: 'onDeleteSocialButtonTap',
        },
        sendMessageButton: {
            tap: 'onSendMessageButtonButtonTap',
        },
    },

    onEscKeydown() {
        this.getView().hide();
    },
    onCloseToolButtonTap() {
        this.getView().hide();
    },
    onCloseButtonTap() {
        this.getView().hide();
    },

    async onSubmitButtonTap() {
        try {
            await this._updateRecord();
        } catch (err) {
            if (err.title && err.message) {
                Ext.Msg.alert(err.title, err.message);
            } else {
                handleClientError(err);
            }
        }
    },

    async onRestoreUserButtonTap() {
        try {
            await this._restoreUser();
        } catch (err) {
            handleClientError(err);
        }
    },

    async onDeleteUserButtonTap() {
        try {
            await this._deleteUser();
        } catch (err) {
            handleClientError(err);
        }
    },

    async onLoginUserButtonTap() {
        try {
            await this._loginWithUser();
        } catch (err) {
            handleClientError(err);
        }
    },

    async onLoginWithPartnerButtonTap() {
        try {
            await this._loginWithUser(true);
        } catch (err) {
            handleClientError(err);
        }
    },

    async onDeleteSocialButtonTap() {
        try {
            await this._deleteSocialData();
        } catch (err) {
            handleClientError(err);
        }
    },

    async onProfileSelectFieldChange(elem, newValue, oldValue) {
        try {
            const viewValues = this._getViewModelData();
            if (oldValue === null || !viewValues.userProfileId || newValue === viewValues.userProfileId) {
                return;
            }
            await this._changeProfile(newValue);
        } catch (err) {
            handleClientError(err);
        }
    },

    async _restoreUser() {
        const confirmed = await Ext.Msg.confirmAsync('Restore', 'Are you sure you want to restore it?');
        if (!confirmed) {
            return;
        }

        const view = this.getView();
        const viewValues = this._getViewModelData();

        await this.showLoader(view);

        await this._sendCompanyTableRequest({
            method: 'PUT',
            data: {
                id: viewValues.id,
                company_id: viewValues.company_id,
                isdeleted: false,
            },
        });

        await this.clearLoader(view);
        view.hide();
    },

    async _deleteUser() {
        const confirmed = await Ext.Msg.confirmAsync('Delete', 'Are you sure you want to delete it?');
        if (!confirmed) {
            return;
        }

        const view = this.getView();
        const viewValues = this._getViewModelData();

        await this.showLoader(view);

        await this._sendCompanyTableRequest({
            method: 'DELETE',
            data: {
                id: viewValues.id,
                company_id: viewValues.company_id,
            },
        });
        await this.clearLoader(view);
        view.hide();
    },

    async _loginWithUser(loginWhitelabel = false) {
        const view = this.getView();
        const viewValues = this._getViewModelData();
        let whiteLabelUrl;
        if (loginWhitelabel) {
            whiteLabelUrl = viewValues.whiteLabelDesktopUrl;
        }

        try {
            const response = await FieldServices.app.callAPIEndpointRequest('POST', `api/admindata/user/getUserLoginToken/${viewValues.company_id}`, {
                id: viewValues.id,
                company_id: viewValues.company_id,
            });

            if (response && response.token) {
                this._loginUserConfirm(response.token, whiteLabelUrl);
            } else {
                Ext.Msg.alert('alert', 'error - user data');
            }
        } catch (err) {
            handleClientError(err);
        } finally {
            await this.clearLoader(view);
        }
    },

    async _deleteSocialData() {
        const buttonId = await Ext.Msg.confirmAsync('Delete', 'Are you sure you want to delete social ids?');
        if (buttonId !== 'yes') {
            return;
        }

        const viewValues = this._getViewModelData();
        const newUserData = {
            company_id: viewValues.company_id,
            socialId: '',
            socialProvider: '',
        };
        try {
            await this._sendUserData(viewValues.company_id, newUserData);
        } catch (err) {
            handleClientError(err);
        }
    },

    async _sendCompanyTableRequest(options) {
        const { method, data, tableName = 'User' } = options;
        const companyId = this._getViewModelData().company_id;
        const apiUrl = `api/admindata/company-tables/${tableName}/${companyId}`;
        const result = await FieldServices.app.callAPIEndpointRequest(method, apiUrl, data);
        this.getView().fireEvent(this._generateEventName(method, tableName), data);
        this._fireDataChangedEvent(method, tableName, data);
        return result;
    },

    _fireDataChangedEvent(method, tableName, data) {
        this.getView().fireEvent('dataChanged', {
            method,
            tableName,
            data,
        });
    },

    _getEventNameForMethod(method) {
        const methodMap = {
            POST: 'Created',
            PUT: 'Updated',
            DELETE: 'Deleted',
        };
        return methodMap[method] || method;
    },

    /**
     *
     * @param method
     * @param tableName
     * @returns userUpdated, userDeleted, userCreated, userUserProfileUpdated, userUserProfileDeleted, userUserProfileCreated
     * @private
     */
    _generateEventName(method, tableName) {
        const methodEventSuffix = this._getEventNameForMethod(method);
        return Ext.String.uncapitalize(tableName || '') + methodEventSuffix;
    },

    async _changeProfile(newProfileId) {
        const isChangeConfirmed = await Ext.Msg.confirmAsync('Change', 'Are you sure you want to change profile? (This action will trigger the clients to logout.)');
        if (!isChangeConfirmed) {
            return;
        }
        const view = this.getView();
        const viewValues = this._getViewModelData();
        await this.showLoader(view);
        await this._sendChangedProfile(newProfileId, viewValues.id);
        await this.clearLoader(view);
        Utilities.forceLogoutOneUser(viewValues.company_id, viewValues.id);
        view.hide();
    },

    async _sendChangedProfile(newProfileId, userId) {
        return this._sendCompanyTableRequest({
            method: 'PUT',
            tableName: 'UserUserProfile',
            data: {
                id: this._getViewModelData().id,
                userprofile_id: newProfileId,
                user_id: userId,
            },
        });
    },

    _loginUserConfirm(token, appBaseUrl) {
        const modal = Ext.create('FieldServices.view.company.user.edit.LoginWithUserModalView', { token, appBaseUrl });
        modal.on('answer', () => {
            modal.hide();
        }, this);

        Ext.Viewport.add(modal);
        modal.show();
    },

    _getViewModelData() {
        const view = this.getView();
        const viewModel = view.getViewModel();
        return viewModel.getData();
    },

    _validateForm() {
        const viewValues = this._getViewModelData();
        const emailValidator = new EmailValidator();
        const email = viewValues.email.trim();
        const password = viewValues.password.trim();
        const socialId = viewValues.socialId && viewValues.socialId.trim();
        const error = {
            title: 'Warning',
        };

        if (!emailValidator.isValidEmail(email)) {
            return { message: `"${email}" is not valid format!`, ...error };
        }
        if (this._isPasswordValidationRequired(password, socialId) && !this._isPasswordValid(password)) {
            return { message: `"${password}" is incorrect!`, ...error };
        }

        return true;
    },

    _isPasswordValidationRequired(password, socialId) {
        if (password !== '') {
            return true;
        }
        return socialId === '';
    },

    _isPasswordValid(password) {
        const passwordValidator = new PasswordValidator();
        return passwordValidator.validate(password) === true;
    },

    async _updateRecord() {
        const viewValues = this._getViewModelData();
        const email = viewValues.email.trim();
        const validationResult = this._validateForm();

        if (validationResult !== true) {
            return Promise.reject(validationResult);
        }

        this.showLoader(this.getView());

        const newUserData = {
            id: viewValues.id,
            email,
            firstname: viewValues.firstname,
            lastname: viewValues.lastname,
            company_id: viewValues.company_id,
        };

        const newPassword = this._getNewPassword(viewValues);
        if (newPassword) {
            Object.assign(newUserData, { password: newPassword });
        }
        await this.updateAdminProfile(viewValues.id, viewValues.companyId, this.getView().down('#currentAdminStatus').getValue());

        return this._sendUserData(viewValues.company_id, newUserData);
    },

    async updateAdminProfile(userId, companyId, newRight) {
        const needsUpdate = this.getViewModel().get('currentAdminStatus') !== newRight;
        const hasPermission = await this.getViewModel().get('canModifyAdminPermission');
        if (needsUpdate && hasPermission) {
            if (newRight !== 0) {
                await FieldServices.app.callAPIEndpointRequest('PUT', `api/admindata/user/addAdminAccess/${companyId}`, {
                    userId,
                    companyId,
                    adminProfile: newRight,
                });
            } else {
                await FieldServices.app.callAPIEndpointRequest('PUT', `api/admindata/user/removeAdminAccess/${companyId}`, {
                    userId,
                    companyId,
                });
            }
        }
    },

    _getNewPassword(viewValues) {
        if (viewValues.password && viewValues.password.length !== 64) {
            return shared.Utils.utilities.hash(viewValues.password.trim());
        }
        return '';
    },

    async onSendMessageButtonButtonTap() {
        try {
            const viewValues = this._getViewModelData();
            const addNewModal = Ext.create('FieldServices.view.message.newPushMessageView', {
                viewModel: {
                    data: {
                        single: true,
                        message: '',
                        url: '',
                        user_id: viewValues.id,
                        company_id: viewValues.company_id,
                        idField: -1,
                        csvData: [],
                        mainOnly: false,
                    },
                },
            });
            Ext.Viewport.add(addNewModal);
            addNewModal.show();
        } catch (error) {
            handleClientError(error);
        }
    },

    async _sendUserData(companyId, newUserData) {
        const view = this.getView();
        const viewValues = this._getViewModelData();

        if (!companyId || !viewValues.id) {
            return;
        }
        Object.assign(newUserData, { id: viewValues.id });
        if (!newUserData.email) {
            Object.assign(newUserData, { email: viewValues.email });
        }

        await FieldServices.app.callAPIEndpointRequest('PUT', `api/admindata/user/setUserData/${companyId}`, newUserData);
        this._fireDataChangedEvent('PUT', 'User', newUserData);

        await this.clearLoader(view);
        view.hide();
    },

});

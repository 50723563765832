Ext.define('Shared.Components.field.yearMonthPicker.MonthPicker', {
    extend: 'Ext.dataview.BoundList',
    xtype: 'widget.powerednow.monthpicker',

    classCls: `${Ext.baseCSSPrefix}yearpicker`,

    itemConfig: {
        ui: 'yearpicker',
        tools: null,
    },
    itemTpl: '{month}',
    pinFooters: false,
    pinHeaders: false,
    scrollToTopOnRefresh: false,
    config: {
        lastMonth: null,
    },
    publishes: {
        lastMonth: true,
    },

    initialize() {
        this.callParent();
        this.rebuildStore();
    },

    focusMonth(month) {
        const me = this;
        const rec = me.getStore().getById(month);
        const item = me.mapToItem(rec);
        const scrollable = me.getScrollable();
        const { y } = scrollable.getEnsureVisibleXY(item.element, {
            align: {
                y: 'center?',
            },
        });

        scrollable.scrollTo(null, y, false);
        me.getNavigationModel().setLocation(rec);
        me.select(rec);
    },

    onChildTap(location) {
        const rec = location.record;

        if (rec) {
            location.event.stopEvent();
            this.fireEvent('monthtap', this, rec.id);
        }
    },
    
    setLastMonth(value) {
        const store = this.getStore();
        if (value) {
            store.applyFilters([{
                id: 'lastMonthFilter',
                property: 'id',
                operator: '<=',
                value,
            }]);
        } else {
            store.removeFilter('lastMonthFilter');
        }
    },

    privates: {
        forceRefreshOnRender: true,

        rebuildStore() {
            const me = this;
            let store = me.getStore();

            if (me.isConfiguring) {
                return;
            }

            if (!store) {
                store = new Ext.data.Store({
                    autoDestroy: true,
                });
                me.setStore(store);
            }

            const data = Ext.Date.monthNames.map((monthName, index) => ({ month: monthName, id: index }));
            store.loadData(data);
        },
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.contact.list.ContactListView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.company.contact.list.ContactListItemViewModel',
        'FieldServices.view.company.contact.list.ContactListViewController',
        'FieldServices.view.company.contact.info.ContactInfoView',
        'FieldServices.view.company.contact.import.ContactImportView',
    ],

    xtype: 'customerListView',

    controller: 'contactListController',

    itemConfig: {
        viewModel: {
            type: 'contactListItemViewModel',
        },
    },

    titleBar: {
        shadow: false,
        items: [
            {
                align: 'right',
                xtype: 'button',
                iconCls: 'fa-plus-circle',
                itemId: 'contactImportButton',
                text: 'Import Customers',
            },
            {
                align: 'right',
                xtype: 'button',
                itemId: 'exportContactsButton',
                iconCls: 'fa-file-excel-o',
                text: 'Export to xls',
            },
        ],
    },

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'contactFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/ContactWithHeaders',
            params: {
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }],
                requiredFields: ['company_id', 'isdeleted'],
            },
        },
        additionalFilterFields: [{
            xtype: 'textfield',
            property: 'company',
            placeholder: 'Company name',
        }, {
            xtype: 'textfield',
            property: 'ContactFirstName',
            placeholder: 'First Name',
        }, {
            xtype: 'textfield',
            property: 'ContactLastName',
            placeholder: 'Last Name',
        }],
        dateRangeFilters: [{
            property: 'dt_created',
            label: 'Created:',
        }],
    }, {
        type: 'gridexporter',
    }],

    emptyText: 'empty',

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'Company name',
            dataIndex: 'company',
            minWidth: '450',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'First Name',
            dataIndex: 'ContactFirstName',
            minWidth: '250',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Last Name',
            dataIndex: 'ContactLastName',
            minWidth: '250',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Created',
            dataIndex: 'dt_created',
            minWidth: '150',
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'isCustomer',
            dataIndex: 'is_customer',
            minWidth: '120',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isCustomerString}',
                },
            },
        }, {
            text: 'is deleted',
            dataIndex: 'isdeleted',
            renderer: 'rendererIsDeleted',
            minWidth: '120',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isDeletedString}',
                },
            },
        }, {
            text: '',
            minWidth: '120',
            gridWithFilter: {
                skipFromFilter: true,
            },
            cell: {
                xtype: 'widgetcell',
                widget: {
                    xtype: 'button',
                    text: 'Info',
                    handler: 'onInfoButtonTap',
                },
            },
        },
    ],
});

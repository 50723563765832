import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { PURCHASES, SUBSCRIPTION } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.report.monthlyReports.detailed.grids.SubscriptionsMonthlyView', {
    extend: 'Ext.grid.Grid',

    xtype: 'subscriptionsMonthlyView',

    loadStoreWhenVisible: true,
    variableHeights: true,
    plugins: [{
        type: 'gridexporter',
    }],

    bind: {
        masked: '{monthlyDataLoading}',
    },
    store: 'AllMonthlyReportedPurchases',
    itemConfig: {
        viewModel: true,
    },
    emptyText: true,
    showStatus: true,
    constructor(config) {
        config.columns = this.getColumnDefs();
        if (this.showStatus) {
            config.columns.push({
                text: 'Status',
                dataIndex: 'PurchaseAction.action',
                renderer: (value, record) => {
                    const churnCorrected = record.get('PurchaseAction.is_reactivation') ? 2 : value;
                    return getKeyByValue(PURCHASES.LIFECYCLE_CATEGORY, churnCorrected);
                },
                flex: 0.5,
            });
        }
        this.callParent(arguments);
        this.store.on('datachanged', this.updateCountInTabTitle.bind(this));
    },
    updateCountInTabTitle() {
        const baseTitle = this.tab.config.title;
        const countText = this.store.isLoaded() ? this.store.count() : '-';
        this.tab.setTitle(`${baseTitle} (${countText})`);
    },
    getColumnDefs() {
        return [
            {
                text: 'Purchase Date',
                dataIndex: 'purchaseDate',
                renderer: value => shared.Utils.Renderers.getDateRenderer('Y-m-d')(value, 'C'),
                flex: 0.5,
            }, {
                text: 'Expires date',
                dataIndex: 'expiresDate',
                renderer: value => shared.Utils.Renderers.getDateRenderer('Y-m-d')(value, 'C'),
                flex: 0.5,
            }, {
                text: 'Product',
                dataIndex: 'product',
                cell: { encodeHtml: false },
                renderer: (value, record) => `<table><tr><td>Name :</td><td>${record.data.PurchaseSetupIOS.productName || record.data.PurchaseSetupAndroid.productName}</td></tr>
                                                 <tr><td>Subscription Length:</td><td>${record.data.PurchaseSetupIOS.days_before_expires || record.data.PurchaseSetupAndroid.days_before_expires} days</td></tr>
                                                 <tr><td>Actual Product:</td><td>${record.data.product}</td></tr></table>`,
                flex: 1,
            }, {
                text: 'Purchase',
                dataIndex: 'purchaseAmount',
                cell: { encodeHtml: false },
                renderer: (value, record) => `<table><tr><td>Amount:</td><td> ${record.data.purchaseAmount}</td></tr>
                                                 <tr><td>Original Price:</td><td> ${record.data.originalPrice}</td></tr>
                                                 <tr><td>Currency:</td><td> ${record.data.purchaseCurrency}</td></tr>
                                                 <tr><td>Origin:</td><td> ${getKeyByValue(SUBSCRIPTION.PURCHASE_ORIGIN_ID, record.data.purchaseOrigin)} </td></tr></table>`,
                flex: 0.7,
            }, {
                text: 'Company',
                dataIndex: 'company_id',
                renderer: (value, record) => `<table><tr><td>Name:</td><td>${record.data.Company.companyName}</td></tr>
                                                 <tr><td>Id:</td><td>${value}</td></tr>
                                                 <tr><td>Team Size:</td><td>${record.data.Company.teamSize}</td></tr>
                                                 ${record.data.Company.mediaSource ? `<tr><td>Media Source:</td><td>${record.data.Company.mediaSource}</td></tr>` : ''}
                                                 ${record.data.Company.campaignName ? `<tr><td>Campaign Name:</td><td>${record.data.Company.campaignName}</td></tr>` : ''}
                                                 <tr><td>Registration Date:</td><td>${shared.Utils.Renderers.getDateRenderer('Y-m-d')(record.data.Company.dt_created, 'C')}</td></tr></table>`,
                cell: { encodeHtml: false },
                flex: 1,
            }, {
                text: 'Main User',
                renderer: (value, record) => `<table><tr><td>Name:</td><td>${record.data.Company.MainUser.firstName} ${record.data.Company.MainUser.lastName}</td></tr>
                                                 <tr><td>Email:</td><td>${record.data.Company.MainUser.mainEmail}</td></tr></table>`,
                cell: { encodeHtml: false },
                flex: 1,
            },
        ];
    },

});

Ext.define('FieldServices.view.company.info.activityInfo.ActivityInfoViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.activityInfoViewController',
    xtype: 'activityInfoViewController',

    config: {
        companyId: null,
    },
});

Ext.define('FieldServices.view.logs.MongoLogController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.mongoLogController',
    control: {
        '#': {
            itemtap: 'onItemTap',
        },
    },

    setCompanyIdFilter() {
        const view = this.getView();
        const selectedCompanyId = Ext.Viewport.getViewModel().getCompanyId();
        if (this.getView().config.showOnlyCompanyData && selectedCompanyId) {
            view.on('filterResetFinished', () => {
                const filterField = view.down('#companyId');
                if (filterField) {
                    filterField.setValue(selectedCompanyId.toString());
                }
            }, this, { single: true });
        }
    },

    onItemTap(dataview, index, target, record, e, eOpts) {
        console.log('onItemTap', record);

        References.getMongoPopup().show();
        References.getMongoPopup().setData({
            json4: Ext.util.Format.htmlEncode(JSON.stringify(Ext.clone(record.data), null, 4)),
        });
    },
});

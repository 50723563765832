import ComplexModel from 'shared/modules/complexData/knownError';

export default Ext.define('FieldServices.model.KnownError', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',

    config: {
        fields: [{
            name: 'min_app_version',
        }, {
            name: 'max_app_version',
            convert: (value, record) => record.get('min_app_version'),
            depends: ['min_app_version'],
        }],
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Message', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Message'],
    alias: 'store.Message',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Message',
        storeId: 'Message',
        remoteFilter: true,
        remoteSort: true,

    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/Message`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    // TODO: there is no page scrolling in the document list
    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});

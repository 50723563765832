/**
 * Created by steveetm1 on 30/07/15.
 */
Ext.define('FieldServices.view.login.LoginController', {
    extend: 'FieldServices.view.BaseViewController',
    requires: ['FieldServices.view.home.HomeView'],
    alias: 'controller.view.login',
    config: {
        control: {
            '#loginButton': {
                release: 'onLoginClick',
                click: 'onLoginClick',

            },

        },
        routes: {
            'show/Home/': 'showDashboard',

        },
    },

    init() {
        this.getView().down('#adminAppVersion').setHtml(`Admin build version: <b>${Utilities.getVersion()}</b><br/>&nbsp;`);
    },

    showDashboard() {
        References.getMainContainer().setActiveItem(References.getDashboard().show());
    },

    onLoginClick() {
        this._login();
    },

    onEnterKey() {
        this._login();
    },

    async onLoginSuccess(response) {
        this.response = response;

        const storage = Ext.util.LocalStorage.get('auth');
        if (response && response.status === 200) {
            const responseObj = Ext.decode(response.responseText);

            if (responseObj && responseObj.userData && responseObj.token) {
                const adminToken = responseObj.token;
                storage.setItem('adminToken', adminToken);
                storage.setItem('userData', Ext.encode(responseObj.userData));
                storage.save();
                storage.release();
                FieldServices.app.addHeaderToProxies();
                await AuthManager.loadAdminRoles();
                this.getView().setKeyMap(null);
                this._goToHomeScreen();
            } else {
                storage.clear();
                Ext.Msg.alert('Login error', 'Invalid user name or password!');
            }
        } else {
            storage.clear();
            Ext.Msg.alert('Login error', 'Unknown error');
        }
        this.clearLoader(this.getView());
    },

    onLoginFailure(request) {
        let errMsg = 'Server error';
        if (request.responseText) {
            const respText = Ext.decode(request.responseText);
            if (respText.error_msg) {
                errMsg = respText.error_msg;
            }
        }
        this.clearLoader(this.getView());
        Ext.Msg.alert('Login error', errMsg);
    },

    _goToHomeScreen() {
        FieldServices.app.goToHome();
    },

    _login() {
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');
        storage.release();

        if (adminToken) {
            FieldServices.app.addHeaderToProxies();
            this._goToHomeScreen();
            return;
        }

        const button = this.getView().down('#loginButton');
        const form = button.up('formpanel');
        const email = form.down('#email').getValue();
        const password = form.down('#password').getValue();

        if (!email || !password) {
            return;
        }
        this.showLoader(this.getView());

        const digest = shared.Utils.utilities.hash(password);

        this.passwordHash = digest.toString();
        this.email = email;

        Ext.Ajax.request({
            url: `${require('config.js').SERVER_URL}api/interfaces/adminlogin`,
            method: 'POST',
            defaultPostHeader: 'application/json',
            params: Ext.JSON.encode({
                user: this.email,
                password: this.passwordHash,
            }),
            success: Ext.bind(this.onLoginSuccess, this),
            failure: Ext.bind(this.onLoginFailure, this),
        });
    },

});

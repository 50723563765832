Ext.define('FieldServices.view.knownError.KnownErrorView', {
    extend: 'Ext.Panel',

    xtype: 'knownErrorView',

    requires: [
        'FieldServices.view.knownError.KnownErrorViewController',
        'FieldServices.view.knownError.KnownErrorViewModel',
        'FieldServices.model.KnownError',
    ],
    viewModel: 'knownErrorViewModel',
    controller: 'knownErrorViewController',

    layout: 'vbox',
    flex: 1,

    title: 'Known Errors',

    bind: {
        title: 'Known Errors <small>(You can double-click the row to modify the data.)</small>',
    },

    items: [{
        xtype: 'container',
        bind: {
            hidden: '{KnownErrorStore.loading}',
        },
        items: [{
            xtype: 'button',
            itemId: 'newButton',
            text: 'New',
            margin: '5 10 0 10',
        }, {
            xtype: 'button',
            itemId: 'saveModificationsButton',
            text: 'Save modifications',
            margin: '5 10 0 10',
            bind: {
                disabled: '{!dataChanged}',
                cls: '{saveButtonClass}',
                text: 'Save {modifiedNumber} modifications',
            },
        }, {
            xtype: 'button',
            itemId: 'restoreModificationButton',
            text: 'Reject All Modification',
            margin: '5 10 0 10',
            bind: {
                disabled: '{!dataChanged}',
            },
        }],
    }, {
        xtype: 'grid',
        markDirty: true,
        itemId: 'knownErrorListId',

        bind: {
            store: '{KnownErrorStore}',
        },
        plugins: [{
            type: 'gridcolumnresizing',
        }, {
            type: 'grideditable',
            enableDeleteButton: false,
            toolbarConfig: {
                xtype: 'titlebar',
                docked: 'top',
                items: [{
                    xtype: 'button',
                    text: 'Cancel',
                    align: 'left',
                    action: 'cancel',
                }, {
                    xtype: 'button',
                    text: 'Generate hash',
                    align: 'right',
                    handler: 'onGenerateHashButtonTap',
                }, {
                    xtype: 'button',
                    text: 'Submit',
                    align: 'right',
                    action: 'submit',
                }],
            },
        }],

        flex: 1,
        margin: '10 10 10 10',
        selectable: false,
        emptyText: 'empty',

        columns: [{
            text: 'id',
            dataIndex: 'id',
        }, {
            text: 'Stack',
            dataIndex: 'stack',
            flex: 1,
            editable: true,
        }, {
            text: 'Hashed Stack',
            dataIndex: 'hashed_stack',
            editable: true,
        }, {
            text: 'Is harmful',
            dataIndex: 'is_harmful',
            editable: true,
            defaultEditor: {
                xtype: 'checkbox',
            },
        }, {
            text: 'App version',
            dataIndex: 'min_app_version',
            editable: true,
        }, {
            text: 'Card number',
            dataIndex: 'card_number',
            flex: 1,
            editable: true,
        }],
    }],
});

const _ = require('lodash');

export default Ext.define('FieldServices.model.ClientErrorInfo', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        _trimAppVersion(appVersion) {
            const position = 4;
            return (appVersion.split('.').length >= position) ? appVersion.substr(0, appVersion.indexOf('.', position)) : appVersion;
        },
    },
    idProperty: 'clientId',

    fields: [{
        name: 'data',
    }, {
        name: 'hashedStack',
        calculate(recordData) {
            return shared.Utils.utilities.generateHashFromError({ stack: recordData.data[0].stackTrace });
        },
    }],
    getKnownErrorModel() {
        const appVersion = _.get(this.data, 'appVersions[0]');
        if (!appVersion) {
            return null;
        }
        const minAppVersion = this.statics()._trimAppVersion(appVersion);
        return Ext.getStore('KnownError').data.byHashedStackAndMinAppVersion.get(`${this.data.hashedStack}__${minAppVersion}`);
    },

});

Ext.define('FieldServices.view.company.formDocument.FormDocumentInfoPopUp', {
    extend: 'FieldServices.view.components.JsonInfoBaseView',

    xtype: 'formDocumentInfoPopUp',

    viewModel: {
        data: {
            json4: '',
        },
    },

    title: 'Form Data',
    items: [{
        cls: ['userSelectable', 'json4'],
        scrollable: true,
        bind: {
            html: '{json4}',
        },
    }],
});

Ext.define('FieldServices.view.company.document.edit.DocumentEditViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.documentEditViewModel',

    formulas: {
        paddedDisplayNumber(get) {
            const { incrementalLength } = get('company').data.documentNumberConfig;
            const documentNumber = get('documentnumber');
            return this._prependZeros(documentNumber, incrementalLength);
        },
        isSupplierInvoice(get) {
            return this._isSupplierInvoice(get('type'));
        },
        getDisplayNumber(get) {
            if (this._isSupplierInvoice(get('type'))) {
                return get('displaynumber');
            }
            const prefix = get('prefix') || '';
            const suffix = get('suffix') || '';
            return `${prefix}${get('paddedDisplayNumber')}${suffix}`;
        },
    },

    _isSupplierInvoice(type) {
        return parseInt(type, 10) === CONSTANTS.DOCUMENT.TYPES_VALUES.SUPPLIER_INVOICE;
    },

    _prependZeros(num, len) {
        const str = (`${num}`);
        return (Array(Math.max((len - str.length) + 1, 1)).join('0') + str);
    },
});

Ext.define('FieldServices.view.whiteLabel.addNew.addNewView', {
    extend: 'Ext.Dialog',
    xtype: 'addNewWhiteLabelPartner',

    requires: [
        'FieldServices.view.whiteLabel.addNew.addNewViewController',
        'FieldServices.view.whiteLabel.addNew.addNewViewModel',
        'FieldServices.view.whiteLabel.settings.settingsView',

    ],

    viewModel: 'addNewWhiteLabelPartnerViewModel',
    controller: 'addNewWhiteLabelPartnerViewController',

    title: 'Add new White Label partner',
    width: 600,

    modal: true,
    centered: true,
    floated: true,

    items: [{
        xtype: 'whiteLabelPartnerSettings',
        itemId: 'partnerDetails',
    }],

});

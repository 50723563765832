require('./DataviewAbstract.scss');

Ext.define('FieldServices.overrides.DataviewAbstract', {
    override: 'Ext.dataview.Abstract',
    config: {
        loadStoreWhenVisible: false,
    },
    updateStore(newStore, oldStore) {
        if (newStore && this.getLoadStoreWhenVisible()) {
            this.whenVisible('ensureStoreLoaded');
        }
        this.callParent([newStore, oldStore]);
    },

    ensureStoreLoaded() {
        const store = this.getStore();
        if (store) {
            if (!store.isChainedStore && !store.isLoading() && !store.isLoaded()) {
                store.load();
            } else if (store.getSource) {
                const sourceStore = store.getSource();
                if (sourceStore && !sourceStore.isLoading() && !store.isLoaded()) {
                    sourceStore.load();
                }
            } else {
                store.load();
            }
        }
    },
});

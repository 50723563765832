Ext.define('FieldServices.view.company.device.DeviceViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.deviceViewModel',
    xtype: 'deviceViewModel',

    data: {
        onlineIds: [],
    },
});

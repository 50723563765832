/**
 * This class and its derived classes are used to manage access to the properties of an
 * object stored in a `Session`.
 * @private
 */
Ext.define('shared.overrides.bind.Stub', {
    override: 'Ext.app.bind.Stub',

    privates: {
        bindMappings: {
            store: {
                count: 'getCount',
                first: 'first',
                last: 'last',
                loading: 'hasPendingLoad',
                totalCount: 'getTotalCount',
                // ----- >>>> OVERRIDE (add)
                modifiedRecords: 'getModifiedRecords',
                // <<<<< ---- OVERRIDE
            },
            model: {
                dirty: 'isDirty',
                phantom: 'isPhantom',
                valid: 'isValid',
            },
        },
    },
});

Ext.define('FieldServices.view.company.info.InfoView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.company.info.InfoViewController',
        'FieldServices.view.company.info.comments.CommentsView',
        'FieldServices.view.company.info.subscription.SubscriptionInfoView',
        'FieldServices.view.company.info.infoPanels.ContactInfo',
        'FieldServices.view.company.info.infoPanels.IntegrationInfo',
        'FieldServices.view.company.info.activityInfo.ActivityInfoView',
        'FieldServices.view.company.info.clientError.ClientErrorInfoView',
    ],

    xtype: 'infoView2',

    controller: 'infoViewCtrl',

    layout: 'hbox',
    bodyCls: ['verticalOnMobile'],
    flex: 1,
    scrollable: true,
    defaults: {
        cls: 'showOverFlowOnMobile',
    },
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 2,
            defaults: {
                cls: 'companyPagePanel',
            },
            items: [
                {
                    xtype: 'container',
                    layout: 'hbox',
                    flex: 1,
                    cls: ['companyPagePanel', 'doubleHeightOnMobile'],
                    bodyCls: ['verticalOnMobile'],
                    items: [
                        {
                            xtype: 'contactInfoPanelView',
                            cls: 'contactInfoPanel',
                        }, {
                            cls: 'contactIntegrationPlaceholder',
                        }, {
                            xtype: 'integrationInfoView',
                            cls: 'integrationInfoPanel',
                        },
                    ],
                },
                {
                    xtype: 'activityInfoView',
                    itemId: 'activityInfoComponent',
                    flex: 1,
                }, {
                    xtype: 'clientErrorInfoView',
                    itemId: 'clientErrorComponent',
                    flex: 1,
                }],
        },
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 3,
            defaults: {
                cls: 'companyPagePanel',
            },
            items: [{
                xtype: 'panel',
                layout: 'vbox',
                flex: 1,
                title: 'Subscription Info',

                items: [
                    {
                        xtype: 'subscriptionInfoView',
                        itemId: 'companyPurchasesInfoCmp',
                        flex: 1,
                        masked: { xtype: 'loadmask' },
                        bind: {
                            hidden: '{!company}',
                            masked: '{isSubscriptionInfoLoading}',
                        },
                    },
                ],
            }, {
                xtype: 'commentsView',
                flex: 1,
            }],
        },
    ],
});

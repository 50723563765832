Ext.define('FieldServices.view.company.info.subscription.SubscriptionInfoView', {
    extend: 'Ext.grid.Grid',
    xtype: 'subscriptionInfoView',
    loadStoreWhenVisible: true,

    requires: [
        'FieldServices.view.company.info.subscription.SubscriptionInfoViewController',
        'FieldServices.view.company.info.subscription.SubscriptionInfoItemModel',
    ],

    controller: 'subscriptionInfoViewController',
    cls: ['change', 'subscriptionInfoGrid'],
    columnMenu: null,

    itemConfig: {
        viewModel: {
            type: 'subscriptionInfoItemModel',
        },
        bind: {
            ui: '{ui}',
        },
    },
    bind: {
        store: '{subscriptionInfoStore}',
    },
    selectable: false,
    emptyText: 'empty',
    columns: [{
        text: 'Name',
        dataIndex: 'product_name',
        menuDisabled: true,
        sortable: false,
        flex: 1.8,
    }, {
        text: 'Change',
        flex: 0.8,
        dataIndex: 'weight',
        renderer: 'handleChangeDirection',
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'Start date',
        dataIndex: 'purchaseDateView',
        flex: 1,
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'End date',
        dataIndex: 'expiresDateView',
        flex: 1,
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'Origin',
        dataIndex: 'purchaseOriginName',
        flex: 1,
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'Duration',
        dataIndex: 'duration',
        flex: 1,
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'No. of Subs',
        dataIndex: 'quantity',
        flex: 1,
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'Currency',
        flex: 0.5,
        dataIndex: 'purchase_currency',
        menuDisabled: true,
        sortable: false,
    }, {
        text: 'Amount',
        dataIndex: 'purchaseAmount',
        summary: 'sum',
        menuDisabled: true,
        sortable: false,
    }],
});

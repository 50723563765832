Ext.define('FieldServices.view.company.info.subscription.SubscriptionInfoViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.subscriptionInfoViewController',
    xtype: 'subscriptionInfoViewController',

    config: {
        companyId: null,
    },

    handleChangeDirection(value, record, property, cell) {
        this.setDirection(value, record, property, cell);
    },

    setDirection(value, record, property, cell) {
        cell.setCellCls(this.getType(record));
    },

    getType(record) {
        switch (record.data.type) {
            case CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.FREE:
                return 'change-nosub';
            case CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.UPGRADE:
                return 'change-upgrade';
            case CONSTANTS.SUBSCRIPTION.PURCHASE_INFO.TYPES.DOWNGRADE:
                return 'change-downgrade';
            default:
                return 'change-notchanged';
        }
    },
});

Ext.define('FieldServices.view.company.appointment.details.AppointmentDetailsViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.appointmentDetailsViewController',
    xtype: 'appointmentDetailsViewController',

    control: {
        '': {
            painted: 'onPainted',
        },
        cancelButton: {
            tap: 'onCancel',
        },
    },

    config: {
        companyId: null,
        record: null,
    },

    updateRecord() {
        const showData = this._getRecordAsJSON(this.getRecord().data);
        this.getView().down('#recordData').setHtml(`<pre>${showData}</pre>`);
    },

    onCancel() {
        const view = this.getView();

        view.hide();
    },

    onPainted() {
        this._showDeviceData(true);
    },

    _showDeviceData(isShow = true) {
        this.getView().down('#recordData').setHidden(!isShow);
    },

    _getRecordAsJSON(rawData) {
        let showData = {};
        try {
            showData = JSON.stringify(rawData, null, '\t');
        } catch (err) {
            showData = { error: err.message };
            handleClientError(err);
        }
        return showData;
    },
});

Ext.define('FieldServices.view.company.user.UsersView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.user.UsersViewController',
        'FieldServices.view.company.user.UsersViewModel',
        'FieldServices.view.company.user.list.UserListView',
        'FieldServices.view.company.user.edit.UserEditView',
    ],

    alias: 'widget.userView',

    controller: 'userViewController',

    viewModel: 'userViewModel',

    cls: ['usersGrid'],
    layout: 'vbox',
    flex: 1,

    items: [{
        xtype: 'userList',
        itemId: 'userList',
    }, {
        xtype: 'button',
        reference: 'toggleButton',
        bind: {
            text: 'Show/Hide Online users data ({onlineUsersCount})',
        },
        enableToggle: true,
    }, {
        xtype: 'panel',
        itemId: 'onlineUsers',
        title: 'Online Users',
        tpl: '<div class="userSelectable json4">{json4}</div>',
        bind: {
            hidden: '{!toggleButton.pressed}',
        },
    },
    ],
});

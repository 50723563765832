Ext.define('FieldServices.view.company.user.profile.UserProfileView', {
    extend: 'Ext.Dialog',

    requires: [
        'FieldServices.view.company.user.profile.UserProfileViewController',
        'FieldServices.view.company.user.profile.UserProfileViewModel',
        'FieldServices.view.company.user.profile.UserProfileRoleColumnView',
    ],

    xtype: 'userProfileView',

    controller: 'userProfileViewController',
    viewModel: 'userProfileViewModel',

    keyMap: {
        ESC: {
            handler: 'onClose',
            scope: 'controller',
            event: 'keydown',
        },
    },

    title: 'Edit Profile Roles',

    layout: 'vbox',
    flex: 1,
    scrollable: 'vertical',

    tools: [{
        type: 'close',
        handler: 'onClose',
    }],

    bind: {
        masked: '{masked}',
    },

    items: [{
        xtype: 'panel',
        items: [{
            xtype: 'button',
            text: 'Cancel',
            handler: 'onClose',
            margin: '5 40 0 10',
        }, {
            xtype: 'button',
            text: 'Disable All',
            handler: 'disableAll',
            margin: '5 10 0 10',
        }, {
            xtype: 'button',
            text: 'Set Default All',
            handler: 'setDefaultAll',
            margin: '5 40 0 10',
        }, {
            xtype: 'button',
            text: 'Save modifications',
            handler: 'saveModifications',
            margin: '5 10 0 10',
            bind: {
                cls: '{saveButtonClass}',
            },
            ui: '-primary',
        }],
    }, {
        xtype: 'grid',
        itemId: 'userProfileRole',

        ui: '-permission',
        itemConfig: {
            viewModel: true, // create default ViewModel for each item (i.e., Row)
        },
        flex: 1,

        plugins: [{
            type: 'gridcolumnresizing',
        }],

        bind: {
            store: '{loadGridStore}',
        },
        columns: [
            {
                text: 'ID',
                dataIndex: 'id',
            }, {
                text: 'Name',
                dataIndex: 'name',
                flex: 1,
            }, {
                text: 'Description',
                dataIndex: 'description',
                flex: 2,
            },
            {
                xtype: 'userProfileRoleColumnView',
                text: 'Main User',
                key: 'main',
            },
            {
                xtype: 'userProfileRoleColumnView',
                text: 'Manager',
                key: 'manager',
            }, {
                xtype: 'userProfileRoleColumnView',
                text: 'Engineer',
                key: 'engineer',
            },
        ],
    }],
});

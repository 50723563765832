Ext.define('FieldServices.view.company.user.edit.LoginWithUserModalView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.user.edit.LoginWithUserModalViewController',
    ],

    controller: 'loginWithUserModalViewController',

    title: 'Login',
    width: 300,

    modal: true,
    centered: true,
    floated: true,

    cls: 'LoginWithUserModalView',

    items: [{
        xtype: 'textfield',
        itemId: 'urlField',
        label: 'Browser url:',
    }, {
        xtype: 'container',
        html: 'The link is valid for 30 seconds',
        cls: 'silver_text',
    }, {
        xtype: 'container',
        html: '<b>Login with user?</b>',
    }, {
        xtype: 'checkboxfield',
        itemId: 'rememberMe',
        name: 'rememberme',
        label: 'RememberMe',
        checked: true,
    }, {
        xtype: 'container',
        html: 'The admin and the page may freeze:',
        cls: 'silver_text ta_left',
        items: [
            {
                xtype: 'button',
                text: 'Open New Tab',
                itemId: 'newTabButton',
            },
        ],
    }, {
        xtype: 'container',
        items: [
            {
                xtype: 'button',
                text: 'No',
                itemId: 'noButton',
            }, {
                xtype: 'button',
                text: 'Yes',
                itemId: 'yesButton',
                cls: 'btn',
            },
        ],
    }],
});

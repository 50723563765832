Ext.define('FieldServices.view.report.subscriptions.active.ActiveListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.subscriptionsActiveListViewController',
    xtype: 'subscriptionsActiveListViewController',

    requires: ['FieldServices.model.Custom'],
    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '': {
            itemtap: 'onSelectRow',
        },
    },

    exportList() {
        this.getView().saveDocumentAs({ fileName: 'Active_Subscriptions.xlsx' });
    },

    async loadActiveList({ filterData }) {
        await this.showLoader(this.getView());
        try {
            const apiResponse = await FieldServices.app.callAPI(
                {
                    url: 'api/admindata/purchases/getActivePurchasesData/0',
                    method: 'GET',
                    params: { filter: Ext.encode(filterData) },
                    headers: {},
                },
            );

            await this.clearLoader(this.getView());

            const data = apiResponse || [];
            // decode "companyName" values
            data.forEach((record) => {
                record.companyName = Ext.util.Format.htmlDecode(record.companyName);
            });
            const store = Ext.create('Ext.data.Store', {
                model: 'FieldServices.model.Custom',
            }).setData(data);
            this.getView().setStore(store);

            const newTitle = this.getView().getTitle().replace(/\(.*\)/, `(${data.length})`);
            this.getView().setTitle(newTitle);
        } catch (error) {
            await this.clearLoader(this.getView());
            handleClientError(error);
        }
    },

    onSelectRow(dataView, index, target, record) {
        const recordData = record.getData();
        if (!recordData.company_id) {
            return;
        }

        const navButton = Ext.ComponentQuery.query('#navButton')[0];
        navButton.setPressedButtons(['#companyPanelBtn']);

        FieldServices.app.redirectTo(`show/Company/0/${recordData.company_id}`);
    },

});

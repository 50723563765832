Ext.define('FieldServices.view.company.info.infoPanels.ContactInfo', {
    extend: 'Ext.panel.Panel',
    title: 'Contact Info',
    xtype: 'contactInfoPanelView',

    layout: 'vbox',
    masked: {
        xtype: 'loadmask',
    },
    bind: {
        masked: '{!companyContactData}',
    },
    defaults: {
        cls: 'infoPanelRow',
        bind: {
            data: '{companyContactData}',
            hidden: '{!companyContactData}',
        },
        flex: 1,
    },
    items: [
        { cls: 'infoPanelHeader', html: '<b>Main user</b>' },
        { tpl: '<b>Name:</b> {mainUserName}' },
        { tpl: '<b>E-mail:</b> {mainUserEmail}' },
        { cls: 'infoPanelHeader', html: '<b>Company</b>' },
        { tpl: '<b>Phone:</b> {companyPhone}' },
        { tpl: '<b>E-mail:</b> {companyEmail}' },
        { tpl: '<b>Bcc e-mail:</b> {companyBccEmail}' },
    ],
});

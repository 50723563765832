import EmailValidator from '@powerednow/shared/modules/validation/emailValidator';

Ext.define('FieldServices.view.company.settings.productExport.ProductExportViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.productExportViewController2',
    xtype: 'productExportViewController2',

    control: {
        exportForm: true,
        exportEmailField: true,
    },

    emailValidator: new EmailValidator(),

    init() { 
        this.callParent(); 
        this.getViewModel().bind('{companyId}', this._resetEmailField, this); 
    },

    async onSubmitButtonTap() {
        const data = this._getFormData();

        this.showLoader(this.getExportForm());
        this._resetEmailField();
        this._callApi(data)
            .then(() => {
                this._handleApiResponse();
            })
            .catch(err => {
                Ext.Msg.alert('Error', `Server error. ${err}`);
                handleClientError(err);
            })
            //  .finally polyfill
            .then(result => {
                this.clearLoader(this.getExportForm());
                return result;
            })
            .catch(error => {
                this.clearLoader(this.getgetExportForm());
                throw error;
            });
    },

    _resetEmailField() {
        this.getExportEmailField().setValue('');
    },

    _handleApiResponse() {
        this._resetFormFields();

        const message = 'Product export is being processed. '
            + 'The results will be delivered to email as soon as processing completes.';
        Ext.Msg.alert('Notification', message);
    },

    _resetFormFields() {
        const form = this.getExportForm().getValues();
        Object.keys(form).forEach(name => {
            const elem = this.getView().down(`[name=${name}]`);
            if (this._isResetField(elem)) {
                elem.setValue(elem.initialConfig.defaultValue);
            }
        });
    },

    _isResetField(elem) {
        return elem.initialConfig.isResetNeeded && elem.initialConfig.isResetNeeded === true && typeof elem.initialConfig.defaultValue !== 'undefined';
    },

    isEmailValid(email) {
        return this.emailValidator.isValidEmail(email) ? true : 'E-mail invalid';
    },

    _getFormData() {
        const fields = this.getExportForm().getValues();

        fields.companyId = parseInt(Ext.Viewport.getViewModel().getCompanyId(), 10);

        return fields;
    },

    _callApi(detailsData) {
        return FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/export/send/Product',
            jsonData: detailsData,
        });
    },
});

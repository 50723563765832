Ext.define('FieldServices.view.dashboard.navbar.NavBarController', {
    extend: 'Deft.mvc.ViewController',

    alias: 'controller.FieldServices.view.dashboard.navbar.NavBarController',

    control: {
        '#': {
            toggle: 'onButtonToggle',
        },
    },

    config: {
        permissionService: null,
        subscriptionService: null,
    },

    /**
     * Segmented button toggle event handler
     *
     * @param segmentedbutton
     * @param button
     * @param isPressed
     * @param eOpts
     */
    onButtonToggle(segmentedbutton, button, isPressed, eOpts) {
        //
        // This is called initially as well without user input!
        //
        if (isPressed) {
            const menuId = button.config.hash;

            //
            // Show the selected item
            //
            FieldServices.app.redirectTo(`${menuId}/`);

            //
            // Notify anyone interrested that we got an item selected
            //
            this.getView().fireEvent('itemselected');
        }
    },

});

import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.device.details.restore.RestoreViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.restoreViewController',
    xtype: 'restoreViewController',

    control: {
        '#': {
            painted: 'onPainted',
        },
        filterButton: {
            tap: 'onFilterButtonTap',
        },
        restoreButton: {
            tap: 'onRestoreTap',
        },
    },

    config: {
        device: null,
        from: null,
        to: null,
        /**
         * Can't use `store` as it tries to grab store from viewModel, don't really want to do major refactors there
         */
        logStore: {
            type: 'Mongos',
            pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
        },
    },

    applyLogStore(store) {
        return Ext.getStore(store);
    },
    onPainted() {
        this._loadDeletedRecords()
            .catch(handleClientError);
    },

    onFromButtonTap(button) {
        const recordIndex = button.ownerCmp.ownerCmp._recordIndex;
        this._selectFrom(recordIndex);
    },

    onToButtonTap(button) {
        const recordIndex = button.ownerCmp.ownerCmp._recordIndex;
        this._selectTo(recordIndex);
    },

    onFilterButtonTap() {
        this._loadDeletedRecords();
    },

    async onRestoreTap() {
        try {
            await this._restore();
        } catch (err) {
            handleClientError(err);
        }
    },

    async _loadDeletedRecords() {
        const filters = this._getFilters();

        const store = this.getLogStore();
        store.setSorters([{
            property: 'date',
            direction: 'ASC',
        }]);
        store.clearFilter();
        store.filter(filters);
        store.proxy.setReadInChunks(true);
        await store.waitToLoad()
            .catch(handleClientError);
        store.proxy.setReadInChunks(false);
    },

    _getFilters() {
        const deviceData = this.getDevice().data;
        const userDeviceId = deviceData.id;
        const { company_id } = deviceData;

        const filters = [
            { property: 'table', value: 'transactionlogs' },
            { property: 'method', value: 'DELETE', operator: '$eq' },
            { property: 'userDeviceId', value: userDeviceId, operator: '$eq' },
            { property: 'companyId', value: company_id, operator: '$eq' },
            { property: 'tableName', value: 'Onlinesync', operator: '$ne' },
        ];
        const filterRangeView = this.getView().down('#filterRange');
        if (filterRangeView.isEnabledDateRange()) {
            filters.push(...filterRangeView.getFilters());
        }

        return filters;
    },

    _getListView() {
        return this.getView().down('#deletedRecordsList');
    },

    _setFromToValues(selectedItems) {
        const first = selectedItems[0];
        const last = [...selectedItems].pop();

        this.setFrom(last.data.date);
        this.setTo(first.data.date);
    },

    _selectFrom(recordIndex) {
        const selectRecord = this._getListView().getSelectable().getSelection('records');

        this._clearSelection(selectRecord);

        selectRecord.setRangeStart(recordIndex);
        selectRecord.setRangeEnd(recordIndex);
    },

    _selectTo(recordIndex) {
        this._getListView().getSelectable().getSelection('records').setRangeEnd(recordIndex);
        this._setFromToValues(this._getListView().getSelected().items);
    },

    _clearSelection(selectRecord) {
        if (this._getListView().getSelected().items.length > 0) {
            selectRecord.clear();
        }
    },

    async _restore() {
        if (this._getSelectedItems().length === 0) {
            Ext.Msg.alert('Alert', 'Please click From and To button.');
            return;
        }
        const isConfirmed = await Ext.Msg.confirmAsync('Confirm', this._getConfirmMessage());

        if (isConfirmed) {
            const restoreData = this._getRestoreData();
            await this._restoreRequest(restoreData);
        }
    },

    _getConfirmMessage() {
        const elements = this._getSelectedItems().reduce((prev, record) => {
            prev[record.tableName] = (prev[record.tableName] || 0) + 1;
            return prev;
        }, {});
        const data = {
            from: this.rendererDateToIso(this.getFrom()),
            to: this.rendererDateToIso(this.getTo()),
            elements: Object.entries(elements).map(elem => ({ table: elem[0], count: elem[1] })),
        };
        const template = new Ext.XTemplate(this.getView().confirmTpl);
        return template.apply(data);
    },

    async _restoreRequest(restoreData) {
        const response = await FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/restore/restoreRecords',
            jsonData: restoreData,
        });

        if (response === true) {
            Ext.Msg.alert('alert', 'Processing is in the background, reports will be sent to the specified email(s).');
        } else {
            Ext.Msg.alert('error', 'There was an error in the backend.');
        }
    },

    _getRestoreData() {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');
        const getSelectedList = this._getSelectedItems();
        return {
            reports: [{
                options: {
                    records: getSelectedList,
                },
                method: {
                    groupId: 'restoreRecords',
                    name: 'companyTable',
                },
            }],
            email: {
                prompts: {
                    subject: 'AdminRestoreRecords.adminEmailSubject',
                    body: 'AdminRestoreRecords.adminEmailHtml',
                },
                recipientNames: [adminUserData.email],
                addresses: [adminUserData.email],
            },
            isShowFormData: null,
        };
    },

    _getSelectedItems() {
        const records = this._getListView().getSelected().items;
        return records.map(record => {
            if (!record.data.data.id) {
                throw new Error(`id is not defined ${record.data._id}`);
            }
            if (!record.data.companyId) {
                throw new Error(`companyId is not defined ${record.data._id}`);
            }

            return {
                tableName: record.data.tableName,
                company_id: record.data.companyId,
                id: record.data.data.id,
            };
        });
    },
});

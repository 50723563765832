import { NETWORK, COHORTS } from 'shared/constants';

export default Ext.define('FieldServices.view.report.cohort.CohortContentViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.cohortContentViewController',
    xtype: 'cohortContentViewController',

    control: {
        cohortFilterView: {
            changeFilterInCohort: 'onChangeFilterInCohort',
        },
        cohortContentTable: {
            childtap: 'onTableCellClick',
        },
        dataModeSelector: true,
    },
    _productNames: {},

    onPaintedCohortContentView() {
        this._loadProductNames();
    },

    onTableCellClick(grid, location) {
        const { recordIndex: row, columnIndex: column } = location;
        if (column < 2 || location.cell.getValue() === '') {
            return;
        }
        const { apiResponse: rawData, dataMode, isCumulative } = this.getViewModel().getData();
        const periodIndex = column - 2;
        const clickedPeriod = rawData[periodIndex].period;
        const clickedRowData = rawData[row];
        const result = {
            cancelled: [...clickedRowData[clickedPeriod].cancelled],
            subscription: [...clickedRowData[clickedPeriod].subscription],
        };
        if (isCumulative) {
            Object.keys(clickedRowData).forEach(dataKey => {
                if (dataKey !== 'period' && dataKey < clickedPeriod) {
                    result.cancelled.push(...clickedRowData[dataKey].cancelled);
                    result.subscription.push(...clickedRowData[dataKey].subscription);
                }
            });
        }
        const panel = Ext.create('Ext.Panel', {
            floated: true,
            modal: true,
            centered: true,
            closable: true,
            scrollable: true,
            width: 500,
            height: 700,
            padding: 6,
            items: [
                {
                    xtype: 'container',
                    layout: 'hbox',
                    flex: 1,
                    items: [
                        {
                            xtype: 'container',
                            layout: 'vbox',
                            flex: 1,
                            hidden: dataMode === COHORTS.TABS.CANCELLED,
                            items: [
                                { html: '<h3>Subscribed:</h3>' },
                                {
                                    xtype: 'list',
                                    selectable: false,
                                    itemTpl: '{value}',
                                    data: result.subscription.map(number => ({ value: number })),
                                },
                            ],
                        },
                        {
                            xtype: 'container',
                            layout: 'vbox',
                            flex: 1,
                            hidden: dataMode === COHORTS.TABS.SUB,
                            items: [
                                { html: '<h3>Cancelled:</h3>' },
                                {
                                    xtype: 'list',
                                    selectable: false,
                                    itemTpl: '{value}',
                                    data: result.cancelled.map(number => ({ value: number })),
                                },
                            ],
                        },

                    ],
                },
            ],
            title: `Companies in ${clickedPeriod}`,
        });

        panel.show();
    },

    async onChangeFilterInCohort(data) {
        const formValues = this._getFormValue();
        this.formValues = formValues;
        Object.assign(data, { formValues });
        await this._loadDataFromApi(data);
    },

    onDownloadXlsFile() {
        this.getView().down('#cohortContentTable').saveDocumentAs({ fileName: 'cohort.xlsx' });
    },
    updateDataMode() {
        const data = this.getDataModeSelector().getValues();
        this.getViewModel().setData({ dataMode: data.valueKey });
    },

    _getFormValue() {
        const formValues = this.getView().down('#cohortFilterView').getValues();
        // remove if value is null
        return Object.keys(formValues).reduce((prev, key) => {
            if (formValues[key] !== null) {
                prev[key] = formValues[key];
            }
            return prev;
        }, {});
    },

    _loadProductNames() {
        Ext.getStore('PurchaseSetup').waitToLoad()
            .then(purchaseSetup => {
                if (!purchaseSetup) {
                    return;
                }
                this._productNames = purchaseSetup.reduce((prev, item) => {
                    Object.assign(prev, {
                        [item.getData().ios_product_id]: item.getData().id,
                        [item.getData().android_product_id]: item.getData().id,
                    });
                    return prev;
                }, {});
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    async _loadDataFromApi(data) {
        await this.showLoader(this.getView());
        this.getViewModel().setData({ dataLoaded: false });
        try {
            const response = await FieldServices.app.callAPI(
                {
                    url: 'api/admindata/cohort-report/getReportData',
                    method: 'GET',
                    params: {
                        filter: Ext.encode(data.filterData),
                        formValues: Ext.encode(data.formValues),
                    },
                    timeout: NETWORK.TIMEOUT.MEDIUM,
                },
            );
            this.getViewModel().setData({ apiResponse: response });
            this.getViewModel().setData({ dataLoaded: true });
            await this.clearLoader(this.getView());
        } catch (err) {
            await this.clearLoader(this.getView());
            handleClientError(err);
        }
    },

});

Ext.define('FieldServices.view.report.monthlyReports.MonthlyReportsViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.monthlyReports',
    data: {
        monthlyDataLoading: false,
    },
    stores: {
        monthlyPurchaseData: {
            storeId: 'MonthlyPurchaseReportData',
            model: 'FieldServices.model.Custom',
        },
        allPurchaseData: {
            storeId: 'AllMonthlyReportedPurchases',
            source: 'MonthlyPurchaseReportData',
        },
        newPurchases: {
            storeId: 'NewMonthlyReportedPurchases',
            source: 'MonthlyPurchaseReportData',
            filters: [{
                filterFn: item => item.get('PurchaseAction.action') === 0,
                id: 'newSubFilter',
            }],
        },
        activeSubsAtMonthEnd: {
            storeId: 'ActiveSubsAtMonthEnd',
            model: 'FieldServices.model.Custom',
        },
        newRegistrationsInMonth: {
            storeId: 'NewRegistrationsInMonth',
            model: 'FieldServices.model.Custom',
        },
    },
});

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.prompt.edit.PromptsFormView', {
    extend: 'Ext.form.Panel',

    xtype: 'prompt.fieldset',

    controller: 'prompt.form',

    viewModel: 'prompt.main',
    config: {
        update: false,
    },

    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },

    title: 'Prompts form',

    items: [{

        xtype: 'formpanel',
        controler: 'promt.form',

        layout: {
            type: 'hbox',
            align: 'stretchmax',
        },
        defaults: {
            padding: 10,
        },

        items: [
            {
                xtype: 'container',
                layout: 'vbox',
                items: [{
                    xtype: 'textfield',
                    name: 'code',
                    bind: {
                        value: '{currentPrompt.code}',
                    },
                    label: 'Code',
                }, {
                    xtype: 'textfield',
                    name: 'subcode',
                    bind: {
                        value: '{currentPrompt.subcode}',
                    },
                    label: 'SubCode',
                }, {
                    xtype: 'selectfield',
                    allowBlank: false,
                    editable: false,
                    autoSelect: false,
                    name: 'language_id',
                    bind: {
                        store: 'languages',
                        value: '{currentPrompt.languageId}',
                    },
                    displayField: 'title',
                    valueField: 'id',
                    label: 'Language',

                }, {
                    xtype: 'selectfield',
                    allowBlank: false,
                    editable: false,
                    autoSelect: false,
                    bind: {
                        store: 'countries',
                        value: '{currentPrompt.countryId}',
                    },
                    name: 'country_id',
                    displayField: 'name',
                    valueField: 'id',
                    label: 'Country',
                }],
            }, {
                xtype: 'container',
                layout: 'vbox',
                items: [
                    {
                        xtype: 'container',
                        layout: 'hbox',
                        flex: 1,
                        items: [
                            {
                                xtype: 'textareafield',
                                bind: {
                                    value: '{currentPrompt.value}',
                                },
                                label: 'Value',
                                labelAlign: 'top',
                                name: 'value',
                            }, {
                                xtype: 'textareafield',
                                bind: {
                                    value: '{currentPrompt.description}',
                                },
                                allowBlank: false,
                                label: 'Description',
                                labelAlign: 'top',
                                name: 'description',
                            },
                        ],
                    },
                ],
            }, {
                xtype: 'toolbar',
                docked: 'bottom',
                items: ['->', {
                    xtype: 'button',
                    text: 'Submit',
                    iconCls: 'ion-ios-checkmark-outline',
                    handler: 'onSubmitUpdate',
                }, {
                    xtype: 'button',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                    handler: 'onCancelUpdate',
                }],
            }],
    }],
});

/**
 * Created by steveetm1 on 11/08/15.
 */
Ext.define('FieldServices.view.services.ServicesModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.services.main',

    stores: {
        services: Ext.create('Ext.data.Store',
            {
                data: [{
                    service: 'Memcache',
                    commands: [{
                        xtype: 'button',
                        text: 'restart',
                        itemId: 'restartMemcache',
                    }, {
                        xtype: 'button',
                        text: 'flush',
                        itemId: 'flushMemcache',
                    }],
                }, {
                    service: 'Mongodb',
                    commands: [{
                        xtype: 'button',
                        text: 'start',
                        itemId: 'startMongo',
                    }, {
                        xtype: 'button',
                        text: 'stop',
                        itemId: 'stopMongo',
                    }],
                }],
            }),
    },
    data: {},
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Purchases', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Purchases'],
    alias: 'store.Purchases',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Purchases',
        storeId: 'Purchases',
        remoteFilter: true,
        remoteSort: true,
        dependantStores: ['PurchaseSetup'],
    },

    proxy: {
        type: 'PoweredNowApi',
        // url: '/api/admindata/global/user',
        // url: 'http://10.0.0.56:3000/api/admindata/company-tables/Company',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/Purchases`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,

});

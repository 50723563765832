Ext.define('FieldServices.view.components.helpTool.HelpToolViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.helpToolViewController',
    xtype: 'helpToolViewController',

    control: {
        '#': {
            click: 'onOpen',
        },
    },

    config: {
        popup: null,
    },

    onOpen() {
        const popup = References.getHelpToolPopup();
        const tpl = Utils.requireTemplate(this.getView().config.contentTpl);
        popup.setTpl(tpl);
        popup.show();
    },
});

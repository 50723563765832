Ext.define('FieldServices.view.whiteLabel.whiteLabelViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.whiteLabelViewModel',

    formulas: {
        record: {
            bind: {
                actualWhitelabelSelection: '{actualWhiteLabelPartner.selection}',
            },
            get({ actualWhitelabelSelection }) {
                return actualWhitelabelSelection;
            },
        },
        editorMaskValue: {
            bind: {
                actualWhitelabelSelection: '{actualWhiteLabelPartner.selection}',
            },
            get({ actualWhitelabelSelection }) {
                if (actualWhitelabelSelection) {
                    return false;
                }
                return {
                    xtype: 'loadmask',
                    message: 'Select a partner from the list above to edit.',
                    indicator: false,
                };
            },
        },
    },

    stores: {
        whitelabelStore: {
            source: 'WhiteLabel',
        },
    },
});

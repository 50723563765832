Ext.define('FieldServices.overrides.FieldText', {
    override: 'Ext.field.Text',
    config: {
        animateUnderline: true,
    },
    filterOperators: {
        like: {
            operator: 'like',
            getNewValue: value => value,
        },
        anyLike: {
            operator: 'like',
            getNewValue: value => `%${value}%`,
        },
        startWith: {
            operator: 'like',
            getNewValue: value => `${value}%`,
        },
        endWith: {
            operator: 'like',
            getNewValue: value => `%${value}`,
        },
    },

    getFilters() {
        const filters = [];
        const property = this.fieldName ? this.fieldName : this.getName();
        const value = this.filterValue ? this.filterValue : this.getValue();
        const operator = this.filterOperator;
        if (this.isFilter !== false && operator && value !== '' && value !== null) {
            const { newValue, newOperator } = this._getNewFilterData(value, operator);
            const filter = {
                value: newValue, operator: newOperator, property, isDisabled: !!this.getDisabled(),
            };
            filters.push(this.addTableName(filter));
        }
        return filters;
    },

    _getNewFilterData(value, operator) {
        const newValue = this.filterOperators[operator] ? this.filterOperators[operator].getNewValue(value) : value;
        const newOperator = this.filterOperators[operator] ? this.filterOperators[operator].operator : operator;
        return { newValue, newOperator };
    },

    updateDisabled(disabled, oldDisabled) {
        this.callParent([disabled, oldDisabled]);
        // It's due to Safari
        this.setReadOnly(disabled);
    },

});

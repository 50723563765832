Ext.define('FieldServices.view.log.popupt.MongoPopupViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.mongo.popup',

    onCancelMongoPopup() {
        const view = this.getView();

        view.hide();
    },

});

Ext.define('FieldServices.view.whiteLabel.whiteLabelViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.whiteLabelViewController',

    control: {
        '#partnerSettings': {
            saveRecord: 'onEditPartnerSave',
            cancelRecordChanges: 'onCancelRecordChanges',
        },
    },

    onCreateNewTap() {
        this._showAddNewPartnerModal();
    },

    onEditPartnerSave() {
        this.getViewModel().get('whitelabelStore').source.sync();
    },

    onCancelRecordChanges(record) {
        this._discardRecordChanges(record);
    },

    _showAddNewPartnerModal() {
        const view = this.getView();
        const addNewModal = Ext.create('FieldServices.view.whiteLabel.addNew.addNewView', {
            viewModel: {
                data: {
                    record: Ext.create('FieldServices.model.WhiteLabel'),
                },
            },
            listeners: {
                async partnerAdded() {
                    const selectField = view.down('#whitelabelIdField');
                    selectField.getStore().reload();
                    selectField.setSelection(null);
                },
            },
        });
        Ext.Viewport.add(addNewModal);
        addNewModal.show();
    },

    _discardRecordChanges(record) {
        record.reject();
    },
});

Ext.define('FieldServices.view.report.subscriptions.active.ActiveListView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.report.subscriptions.active.ActiveListViewController',
        'Ext.grid.plugin.PagingToolbar',
    ],

    xtype: 'subscriptionsActiveListView',

    controller: 'subscriptionsActiveListViewController',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridexporter',
    }],

    emptyText: 'empty',

    title: 'Subscription Details list (-)',

    columns: [
        {
            text: 'Expires date',
            dataIndex: 'expiresDate',
        }, {
            text: 'Purchase Date',
            dataIndex: 'purchaseDate',
        }, {
            text: 'Product',
            dataIndex: 'product',
        }, {
            text: 'Product Name',
            dataIndex: 'productName',
        }, {
            text: 'Purchase Origin',
            dataIndex: 'purchaseOriginName',
        }, {
            text: 'Original Price',
            dataIndex: 'originalPrice',
            width: 50,
        }, {
            text: 'Purchase Amount',
            dataIndex: 'purchaseAmount',
            width: 50,
        }, {
            text: 'Purchase Currency',
            dataIndex: 'purchaseCurrency',
            width: 50,
        }, {
            text: 'Company id',
            dataIndex: 'company_id',
            width: 50,
        }, {
            text: 'Company Name',
            dataIndex: 'companyName',
            flex: 1,
        }, {
            text: 'Main Email',
            dataIndex: 'mainEmail',
        }, {
            text: 'First Name',
            dataIndex: 'firstName',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Last Name',
            dataIndex: 'lastName',
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        },
    ],

});

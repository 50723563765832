import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Mongos', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Mongo'],
    alias: 'store.Mongos',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Mongo',
        storeId: 'Mongos',
        remoteSort: true,
    },
    remoteFilter: true,

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/log/Mongo`,
        reader: {
            type: 'json',
            rootProperty: 'data',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DEFAULT,
});

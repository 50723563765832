import hmacSHA256 from 'crypto-js/hmac-sha256';

export let utilities;

export default Ext.define('shared.Utils.utilities', {

    singleton: true,

    requires: [],

    /**
     * Returns wether the device is online ATM
     *
     * @returns {*}
     */
    isOnline() {
        if (Ext.device) {
            return Ext.device.Connection.isOnline();
        }
        return navigator.onLine;
    },

    getVersion() {
        const { appVersion } = window;

        if (!appVersion) {
            console.error('window.appVersion is not defined');
        }

        return appVersion;
    },

    getDisplayVersion() {
        const { displayVersion } = window;

        if (!displayVersion) {
            console.error('window.displayVersion is not defined');
        }

        return displayVersion;
    },

    hash(message) {
        const digest = hmacSHA256(message.toString(), 'fe3k3rjf20dqw');
        return digest.toString();
    },

    parseJSON(text) {
        text = text || '';

        if (text === '') return '';
        let result;

        try {
            result = Ext.decode(text);
        } catch (error) {
            result = null;
        }
        return result;
    },

    forceRelogin(message) {
        Ext.Msg.alert(message.title, message.text, () => {
            window.localStorage.clear();
            window.location.reload();
        });
    },

    generateHashFromError(error) {
        const stack = this._getErrorStack(error);
        const serializedStack = this._serializeStack(stack);
        if (serializedStack) {
            return this.hash(serializedStack);
        }
        return stack;
    },

    _getErrorStack(error) {
        return typeof error.stack === 'string' ? error.stack : this._setErrorStack(error);
    },

    _setErrorStack(error) {
        error.stack = (new Error('missing stack')).stack;
        return error.stack;
    },

    _serializeStack(errorStack) {
        const stack = errorStack.match(/\/[^/]*?\.js:\d+:\d+/g);
        return stack ? stack.join('') : errorStack;
    },
}, instance => {
    utilities = instance;
});

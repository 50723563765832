import CONSTANTS from 'shared/constants';

const CSVParser = require('shared/modules/csv');
const _ = require('lodash');

export default Ext.define('FieldServices.view.company.contact.import.ContactImportViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.contactImportViewController',
    xtype: 'contactImportViewController',

    config: {
        customerImportStructure: {},
    },

    control: {
        csvFileInput: true,
    },

    _formattedData: [],
    _parser: null,

    hideImportPopup() {
        this.getView().hide();
    },

    onLoadFileButtonTapped() {
        this._readFile(this.getCsvFileInput().getFiles()[0])
            .then(fileContent => {
                this._parser = new CSVParser(fileContent, false, ' ');
                this._showSelectColumns();
            })
            .catch(err => handleClientError(err));
    },

    _getLimitedFieldNames() {
        return this._getStructure().fields
            .filter(({ limitToOneValue }) => limitToOneValue)
            .map(({ name }) => name);
    },

    onSelectFieldValueChange(selectField, newValue) {
        const limitedFieldNames = this._getLimitedFieldNames();
        const name = selectField.getName();

        if (name === 'country') {
            this._displayCountryMatchingTable(newValue);
        }

        if (limitedFieldNames.includes(name)) {
            return;
        }

        this._insertNewSiblingSelectField(selectField);
    },

    _insertNewSiblingSelectField(selectField) {
        const parentElement = selectField.up();
        const newField = this._generateSelectField({
            name: selectField.getName(),
            options: this._getFieldOptions(this._parser.getParsedData()),
        });
        parentElement.insertLast(newField);
    },

    _displayCountryMatchingTable(newValue) {
        const { isFirstLine } = this.getView().down('#isFirstLineForm').getValues();
        const countryIdentifierFieldset = this.getView().down('#countryIdentifierFieldset');

        if (newValue === null) {
            countryIdentifierFieldset.setItems([]);
            return;
        }

        const [header, ...rest] = this._parser.getParsedData().map(row => row[newValue]);
        const uniqueCountries = [...new Set(isFirstLine ? [header, ...rest] : rest)];

        countryIdentifierFieldset.setItems(this.createCountryIdentifiers(uniqueCountries));
        this.getView().down('#countryIdentifierPanel').setHidden(false);

        this._preSelectValues();
    },

    _preSelectValues() {
        const countryStore = Ext.getStore('Country');
        const selectFields = this.getView().down('#countryIdentifierFieldset').innerItems;

        selectFields.forEach(selectField => {
            const label = selectField.getLabel();
            const isCountryCode = label.length === 2;

            const countryRecord = countryStore.findMatchingCountry(isCountryCode ? 'code' : 'name', label);

            if (countryRecord) {
                selectField.setValue(countryRecord.data.code);
            } else {
                selectField.setValue('GB');
            }
        });
    },

    createCountryIdentifiers(countries) {
        return countries
            .filter(countryName => countryName !== '')
            .map(countryName => ({
                xtype: 'selectfield',
                label: countryName,
                name: countryName,
                labelWidth: 'auto',
                store: 'Country',
                displayField: 'name',
                valueField: 'code',
            }));
    },

    onPreviewButtonTapped() {
        this.moveToNextStep();
        this._generateMap();
    },

    onSendButtonTapped() {
        this.showLoader(this.getView());
        this._sendDataToServer()
            .then(() => {
                this.clearLoader(this.getView());
                this.getView().fireEvent('importComplete');
                return this.hideImportPopup();
            })
            .catch(err => {
                this.clearLoader(this.getView());
                return handleClientError(err);
            });
    },

    onBackButtonTapped() {
        const currentStep = this.getViewModel().get('currentStep');
        if (currentStep === 0) {
            this.hideImportPopup();
        } else {
            this.getViewModel().setData({ currentStep: currentStep - 1 });
        }
    },

    moveToNextStep() {
        const currentStep = this.getViewModel().get('currentStep');
        this.getViewModel().setData({ currentStep: currentStep + 1 });
    },

    _sendDataToServer() {
        return FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/import/customer',
            jsonData: {
                companyId: this.getViewModel().get('companyId'),
                importData: this._formattedData,
                structure: this._getStructure(),
            },
        });
    },

    _generateMap() {
        const mapValues = this.getView().down('#selectMapForm').getValues();
        const requiredValues = this.getView().down('#requiredForm').getValues();
        const { isFirstLine } = this.getView().down('#isFirstLineForm').getValues();
        const countryIdentifierMap = this.getView().down('#countryIdentifierPanel').getValues();

        let map = this._createMap(mapValues);
        map = this._addOtherElemToMap(map);

        const formattedData = this._parser.convertByMap(map);
        const data = this._trimValues(formattedData);

        if (!isFirstLine) {
            data.shift();
        }

        const customerTemplates = this._filterCustomerTemplatesByRequiredFields(data, requiredValues);

        if (_.isEmpty(countryIdentifierMap)) {
            this._formattedData = customerTemplates;
        } else {
            this._formattedData = this._replaceCustomerCountriesByMap(countryIdentifierMap, customerTemplates);
        }

        this._addStoreToGrid(this._formattedData);
    },

    _replaceCustomerCountriesByMap(countryMap, customerTemplates) {
        return customerTemplates.map(({ country, ...rest }) => ({
            ...rest,
            country: countryMap[country],
        }));
    },

    _trimValues(data) {
        return data.map(object => Object.keys(object).reduce((prev, key) => {
            prev[key] = (object[key] && object[key].trim()) || '';
            return prev;
        }, {}));
    },

    _getStructure() {
        return this.getCustomerImportStructure();
    },

    _addOtherElemToMap(map) {
        Object.assign(map, this._getOtherElements());
        return map;
    },

    _addStoreToGrid(data) {
        const cloneData = Ext.clone(data);
        const store = Ext.create('Ext.data.Store', {}).setData(cloneData);
        const customerImportGridView = this.getView().down('#customerImportGrid');

        customerImportGridView.setColumns(this._getGridColumns());
        customerImportGridView.setStore(store);

        const newTitle = this.getView().getTitle().replace(/\(.*\)/, `(${cloneData.length})`);
        this.getView().setTitle(newTitle);
    },

    _getGridColumns() {
        return this._getInputOptions()
            .map(fieldOptions => ({
                text: fieldOptions.text,
                dataIndex: fieldOptions.value,
                flex: 1,
            }));
    },

    _getRequiredExtItems() {
        const checkFields = this._getCheckedFields();
        return this._getInputOptions()
            .map(fieldOptions => ({
                xtype: 'checkboxfield',
                label: fieldOptions.text,
                name: fieldOptions.value,
                value: fieldOptions.value,
                checked: checkFields.indexOf(fieldOptions.value) !== -1,
                labelAlign: 'top',
            }));
    },

    _filterCustomerTemplatesByRequiredFields(list, requiredValues) {
        return list.filter(data => {
            let isRowOk = false;
            _.values(requiredValues).forEach(key => {
                if (data[key] && !isRowOk) {
                    isRowOk = true;
                }
            });
            return isRowOk;
        });
    },

    _createMap(valueMap) {
        return Object.entries(valueMap)
            .reduce((accumulator, [propertyName, value]) => Object.assign(accumulator, {
                [propertyName]: {
                    defaultValue: this._getDefaultValue(propertyName),
                    fields: Array.isArray(value) ? value.filter(v => !_.isNull(v)) : [value],
                },
            }), {});
    },

    _readFile(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.error = event => {
                reject(generateClientError(CONSTANTS.ERRORS.FILE_READER_ERROR, Object.assign(event, {
                    severity: 4,
                })));
            };
            fileReader.readAsText(file);
        });
    },

    _showSelectColumns() {
        this.moveToNextStep();
        const options = this._getFieldOptions(this._parser.getParsedData());

        const selectFields = this._initSelectFields(options);

        this.getView().down('#selectFieldList').setItems(selectFields);

        const checkFieldRequiredView = this.getView().down('#checkFieldRequired');
        checkFieldRequiredView.setItems(this._getRequiredExtItems());
    },

    _initSelectFields(options) {
        return this._getInputOptions()
            .map(fieldOptions => this._generateSelectRow({
                label: fieldOptions.text,
                name: fieldOptions.value,
                options,
            }));
    },

    _generateSelectField({ label, name, options }) {
        const matchingIndex = options.findIndex(item => item.text.match(new RegExp(name, 'i')));
        return {
            xtype: 'selectfield',
            name,
            label,
            options,
            value: matchingIndex !== -1 ? matchingIndex - 1 : null,
            shareableName: true,
            flex: 1,
            listeners: {
                change: 'onSelectFieldValueChange',
            },
        };
    },
    _generateSelectRow({ label, name, options }) {
        return {
            xtype: 'container',
            layout: 'hbox',
            margin: 1,
            items: [this._generateSelectField({ label, name, options })],
        };
    },

    /**
     * get a select field contents
     * @param header
     * @param firstLine
     * @private
     */
    _getFieldOptions([header, firstLine]) {
        return header.reduce((options, column, index) => [...options, {
            text: `${column} | ${firstLine[index]}`,
            value: index,
        }], [{ text: '-', value: '' }]);
    },

    _getDefaultValue(key) {
        const structure = this._getStructure().fields;
        const index = _.findIndex(structure, field => field.name === key);
        const isDefaultValue = index !== -1 && typeof structure[index].defaultValue !== 'undefined';

        return isDefaultValue ? structure[index].defaultValue : '';
    },

    _getCheckedFields() {
        return this._getStructure().fields.reduce((prev, field) => {
            if (field.isChecked === true) {
                prev.push(field.name);
            }
            return prev;
        }, []);
    },

    _getInputOptions() {
        return this._getStructure().fields.reduce((prev, field) => {
            if (field.label) {
                prev.push({
                    text: field.label,
                    value: field.name,
                });
            }
            return prev;
        }, []);
    },

    _getOtherElements() {
        return this._getStructure().fields
            .reduce((accumulator, { label, name }) => {
                if (!label) {
                    Object.assign(accumulator, {
                        [name]: {
                            defaultValue: this._getDefaultValue(name),
                            fields: [],
                        },
                    });
                }
                return accumulator;
            }, {});
    },
});

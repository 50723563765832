Ext.define('FieldServices.view.company.user.edit.UserEditViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.UserEditViewModel',
    stores: {
        adminUserProfiles: {
            type: 'AdminUserProfile',
            autoLoad: true,
        },
    },
    formulas: {
        userProfileId(get) {
            const userProfilesString = get('UserProfiles');
            if (userProfilesString) {
                try {
                    const userProfiles = JSON.parse(userProfilesString);
                    const userProfile = userProfiles[0];
                    return userProfile ? userProfile.id : null;
                } catch (error) {
                    handleClientError(error);
                }
            }
            return null;
        },
        currentAdminStatus: {
            bind: {
                bindTo: {
                    profileStore: '{adminUserProfiles}',
                    storeLoaded: '{adminUserProfiles.loadCount}',
                    profilesString: '{UserProfiles}',
                },
                deep: true,
            },
            get({ profileStore, profilesString, storeLoaded }) {
                if (profilesString && storeLoaded) {
                    try {
                        const userProfileIds = JSON.parse(profilesString).map(item => item.id);
                        const adminProfiles = profileStore.getRecords().map(record => record.get('id'));

                        const userProfile = userProfileIds.filter(id => adminProfiles.includes(id));
                        return userProfile.length ? userProfile[0] : 0;
                    } catch (error) {
                        handleClientError(error);
                    }
                }
                return null;
            },
        },
        decodedFirstName: {
            bind: '{firstname}',
            get(value) {
                return Ext.util.Format.htmlDecode(value);
            },
            set(value) {
                this.set('firstname', Ext.util.Format.htmlEncode(value));
            },
        },
        decodedLastName: {
            bind: '{lastname}',
            get(value) {
                return Ext.util.Format.htmlDecode(value);
            },
            set(value) {
                this.set('lastname', Ext.util.Format.htmlEncode(value));
            },
        },

        canModifyAdminPermission: {
            bind: {
                currentUserId: '{id}',
                currentUserCompanyId: '{company_id}',
                currentAdminStatus: '{currentAdminStatus}',
            },
            async get({ currentUserId, currentUserCompanyId, currentAdminStatus }) {
                const { id: adminUserId, company_id: adminCompanyId } = AuthManager.getCurrentAdminData();
                if (currentAdminStatus === null || (currentUserId === adminUserId && currentUserCompanyId === adminCompanyId)) {
                    return false;
                }
                const modifiablePermissionIds = (await AuthManager.getAdminProfileAccessMap()).enabled.map(profile => profile.id);
                return currentAdminStatus === 0 || modifiablePermissionIds.includes(currentAdminStatus);
            },
        },

        async currentAdminOptions() {
            const roles = [{ text: 'None', value: 0 }];
            const { enabled, disabled } = (await AuthManager.getAdminProfileAccessMap());
            enabled.forEach(profile => {
                roles.push({ text: profile.name, value: profile.id });
            });
            return roles;
        },

        whiteLabelDesktopUrl(get) {
            const companyId = get('company_id');
            const company = Ext.getStore('mainCompanyStore').findRecord('id', companyId);
            const whitelabel = Ext.getStore('mainWhiteLabelStore').findRecord('id', company.get('whitelabel_id'));
            return whitelabel.get('desktop_url');
        },
    },
});

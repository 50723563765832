Ext.define('FieldServices.view.report.subscriptions.active.ActiveFormViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.subscriptionsActiveMain',

    stores: {
        PurchaseOrigins: {
            type: 'PurchaseOrigins',
        },
    },
});

Ext.define('FieldServices.view.report.monthlyReports.MonthlyReportsViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.monthlyReportsViewController',
    xtype: 'monthlyReportsViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '#': {
            setSubPage: 'setSubPage',
        },
        detailedReportsPage: true,
    },

    redirectToDetailedReport({ metricIndex, month, whiteLabelId }) {
        this.getView().setActiveItem(0);
        this.getDetailedReportsPage().loadDetailedReport(metricIndex, month, whiteLabelId);
    },
});

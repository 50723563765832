Ext.define('FieldServices.view.company.document.list.PaymentListView', {
    extend: 'Ext.grid.Grid',
    ui: '-documents',
    loadStoreWhenVisible: true,

    requires: [
        'FieldServices.view.company.document.list.PaymentListViewController',
        'FieldServices.view.company.document.list.PaymentListItemViewModel',
        'Ext.grid.filters.Filters',
    ],

    xtype: 'paymentListView',

    controller: 'paymentListViewController',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        id: 'paymentFilterPlugin',
        type: 'gridWithFilter',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/PaymentWithHeaders',
            params: {
                sort: [{
                    property: 'isdeleted',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }],
                requiredFields: ['company_id', 'isdeleted', 'is_archived'],
            },
        },
    }, {
        type: 'gridexporter',
    }],
    itemConfig: {
        viewModel: {
            type: 'PaymentListItemViewModel',
        },
    },

    emptyText: 'empty',

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'document id',
            dataIndex: 'DocumentId',
        }, {
            text: 'Document display number',
            dataIndex: 'DocumentDisplayNumber',
        }, {
            text: 'Document isLocked',
            dataIndex: 'DocumentIsLocked',
        }, {
            text: 'Payment type',
            dataIndex: 'PaymentTypeName',
        }, {
            text: 'dt_created',
            dataIndex: 'dt_created',
        }, {
            text: 'amount',
            dataIndex: 'amount',
        }, {
            text: 'is locked',
            dataIndex: 'islocked',
        }, {
            text: 'locked date',
            dataIndex: 'dt_locked',
        }, {
            text: 'is archived',
            dataIndex: 'is_archived',
        }, {
            text: 'is deleted',
            dataIndex: 'isdeleted',
            width: 50,
            renderer: 'rendererIsDeleted',
        },
    ],
});

Ext.define('shared.overrides.Component', {
    override: 'Ext.Component',

    config: {
        masked: null,
    },

    applyMasked(masked) {
        let isVisible = true;
        let currentMask;

        if (masked === false) {
            masked = true;
            isVisible = false;
        }

        // ----- >>>> OVERRIDE (Ext['Mask'] replace to 'Ext.LoadMask')
        // Subscript notation is used to reference Ext.Mask to prevent creation of an auto-dependency
        currentMask = Ext.factory(masked, 'Ext.LoadMask', this.getMasked());
        // <<<<< ---- OVERRIDE

        if (currentMask) {
            currentMask.setHidden(!isVisible);

            // \\ TODO: Reliable render pathway and rendered transition.
            // was: this.el.append(currentMask.el);
            currentMask.render(this.el);
        }

        return currentMask;
    },
});

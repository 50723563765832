Ext.define('FieldServices.view.company.device.details.DetailsView', {
    extend: 'Ext.Panel',
    requires: [
        'FieldServices.view.company.device.details.DetailsViewController',
        'FieldServices.view.company.device.details.restore.RestoreView',
    ],

    xtype: 'deviceDetailsView',

    controller: 'deviceDetailsViewController',

    itemId: 'DeviceDetailsViewId',

    scrollable: true,

    title: 'Device details',

    keyMap: {
        ESC: {
            handler: 'onCancel',
            scope: 'controller',
            event: 'keydown',
        },
    },

    tools: [{
        type: 'close',
        handler: 'onCancel',
    }],
    layout: 'vbox',
    items: [
        {
            xtype: 'container',
            itemId: 'recordData',
            cls: ['deviceRecordData'],
            html: '',
        }, {
            xtype: 'restoreView',
            itemId: 'restoreBox',
            flex: 1,
            hidden: true,
        },
        {
            xtype: 'toolbar',
            docked: 'bottom',
            bodyCls: ['verticalOnMobile'],
            items: ['->',
                {
                    xtype: 'button',
                    itemId: 'loadDeletedDataButton',
                    text: 'Restore Data',
                    iconCls: 'ion-ios-close-outline',
                }, {
                    xtype: 'button',
                    itemId: 'deviceSyncButton',
                    text: 'Get device sync records',
                    iconCls: 'ion-ios-checkmark-outline',
                }, {
                    xtype: 'button',
                    itemId: 'cancelButton',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                }],
        },
    ],

});

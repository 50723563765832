Ext.define('FieldServices.view.components.failedMessage.FailedMessageInfoPopUp', {
    extend: 'FieldServices.view.components.JsonInfoBaseView',

    xtype: 'failedMessageInfoPopUp',

    requires: [
        'FieldServices.view.components.consumerError.ConsumerErrorView',
    ],

    viewModel: {
        data: {
            errorMessage: '',
            errorStack: '',
            json4: '',
        },
    },

    title: 'Error Details',
    items: [{
        xtype: 'consumerErrorView',
    }, {
        cls: ['userSelectable', 'json4'],
        scrollable: true,
        bind: {
            html: '{json4}',
        },
    }],
});

Ext.define('FieldServices.view.company.user.UsersViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.userViewModel',
    xtype: 'userViewModel',

    stores: {

        countries: {
            autoLoad: true,
        },
    },

});

Ext.define('FieldServices.view.company.select.find.FindCompanyViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.findCompanyViewModel',

    data: {
        company_id: '',
        name: '',
        company_email: '',
        email: '',
        firstname: '',
        lastname: '',
    },

    formulas: {
        isDisabledUser(get) {
            return (get('name') || get('company_id') || get('company_email'));
        },
        isDisabledCompany(get) {
            return (get('email') || get('firstname') || get('lastname'));
        },
    },

});

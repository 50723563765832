/**
 * This view is an example list of people.
 */

require('./systemView.scss');

Ext.define('FieldServices.view.system.systemView', {
    extend: 'Ext.tab.Panel',

    requires: [
        'Shared.Utils.Renderers',
        'Shared.Utils.utilities',
        'FieldServices.view.system.systemViewModel',
        'FieldServices.view.system.systemViewController',
        'FieldServices.view.system.systemMain.systemMainViewModel',
        'FieldServices.view.system.systemMain.systemMainView',
        'FieldServices.view.whiteLabel.whiteLabelViewModel',
        'FieldServices.view.widgets.user.find.FindUserView',
        'FieldServices.view.widgets.findCompany.FindCompanyView',
        'FieldServices.view.home.HomeViewController',
        'FieldServices.view.logoCollection.LogoCollection',
        'FieldServices.view.logoCollectionCategory.LogoCollectionCategory',
        'FieldServices.view.prompt.PromptsView',
        'FieldServices.view.services.Services',
        'FieldServices.view.report.ReportView',
        'FieldServices.view.system.systemView',
        'FieldServices.view.tableVersion.TableVersionView',
        'FieldServices.view.components.failedMessage.list.FailedMessageListView',
        'FieldServices.view.purchaseSetup.PurchaseSetupView',
        'FieldServices.view.knownError.KnownErrorView',
        'FieldServices.view.logs.ClientErrors',
        'FieldServices.view.logs.ApplicationErrors',
        'FieldServices.view.logs.TransactionLogs',
    ],
    requiredRole: CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_SYSTEM_PAGE,
    controller: 'systemViewController',

    xtype: 'systemView',

    defaults: {
        tab: {
            iconAlign: 'left',
        },
    },

    viewModel: 'systemViewModel',

    tabBar: {
        flex: 1,
        bodyCls: 'scrollableTabbar',
        scrollable: 'x',
    },
    ui: 'navigation',
    titleRotation: 0,
    tabRotation: 0,

    items: [
        {
            title: 'Main System View',
            itemId: 'systemMainView',
            iconCls: 'ion-ios-flag',
            layout: 'fit',
            tab: {
                ui: '-systemMainView',
            },
            items: [{
                xtype: 'systemMainView',
                itemId: 'systemMainView',
                ui: '-systemMainView',
            }],
        },
        {
            title: 'WhiteLabel',
            itemId: 'WhiteLabelTab',
            iconCls: 'ion-ios-flag',
            layout: 'fit',
            tab: {
                ui: '-whitelabel',
            },
            items: [{
                xtype: 'whiteLabel',
                itemId: 'whiteLabel',
                ui: '-whiteLabel',
            }],
        },
        {
            title: 'System Logs',
            itemId: 'LogsTab',
            iconCls: 'ion-ios-flag',
            layout: 'fit',
            tab: {
                ui: '-log',
            },
            items: [{
                xtype: 'tabpanel',
                flex: 1,
                itemId: 'systemLogs',
                items: [
                    {
                        tab: {
                            flex: 1,
                            title: 'Client Errors',
                        },
                        xtype: 'clientErrorsTable',

                        flex: 1,
                    },
                    {
                        tab: {
                            flex: 1,
                            title: 'Transactions',
                        },
                        xtype: 'transactionLogsTable',

                        flex: 1,
                    },
                    {
                        tab: {
                            flex: 1,
                            title: 'Application Errors',
                        },
                        xtype: 'applicationErrorsTable',

                        flex: 1,
                    },
                ],
            }],
        },
        {
            tab: {
                title: 'Prompt',
                ui: '-prompt',
                iconCls: 'ion-ios-flag',
            },
            layout: 'fit',
            items: [{
                xtype: 'prompt.main',
                ui: '-prompt',
            }],
        }, {
            tab: {
                title: 'Logo Collection',
                ui: '-logoCollection',
                iconCls: 'ion-ios-flag',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'logoCollection.main',
                ui: '-logoCollection',
            }],
        }, {
            tab: {
                title: 'Logo Collection Categories',
                ui: '-logoCollectionCategories',
                iconCls: 'ion-ios-flag',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'logoCollectionCategory.main',
                ui: '-logoCollectionCategories',
            }],
        }, {
            tab: {
                title: 'Table Version',
                ui: '-tableVersion',
                iconCls: 'ion-ios-flag',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'TableVersionView',
                ui: '-tableVersion',
            }],
        }, {
            tab: {
                title: 'Failed Messages',
                ui: '-failedMessages',
                iconCls: 'ion-ios-flag',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'failedMessageListView',
                ui: '-failedMessages',
            }],
        }, {
            tab: {
                title: 'Purchase Setup',
                ui: '-purchaseSetup',
                iconCls: 'ion-ios-flag',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'purchaseSetupView',
                ui: '-purchaseSetup',
            }],
        }, {
            tab: {
                title: 'KnownError',
                ui: '-knownError',
                iconCls: 'ion-earth',
            },
            iconCls: 'ion-cube',
            layout: 'fit',
            items: [{
                xtype: 'knownErrorView',
                ui: '-knownError',
            }],
        },
    ],
});

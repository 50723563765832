Ext.define('FieldServices.view.report.subscriptions.SubscriptionsViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.subscriptionsViewCtrl',
    xtype: 'subscriptionsViewCtrl',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '': {
            setSubPage: 'setSubPage',
        },
        subscriptionsActiveFormView: {
            changeFilter: 'onChangeFilterDetailList',
            exportRequested: 'onExportRequested',
        },
    },

    config: {
        companyId: null,
    },

    onChangeFilterDetailList(data) {
        const companyListView = this.getView().down('#subscriptionsActiveListView');
        companyListView.getController().loadActiveList(data);
    },

    onExportRequested() {
        const companyListView = this.getView().down('#subscriptionsActiveListView');
        companyListView.getController().exportList();
    },

});

import ComplexModel from 'shared/modules/complexData/payment';

export default Ext.define('FieldServices.model.Payment', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',
});

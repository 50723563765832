Ext.define('FieldServices.view.components.JsonInfoBaseView', {
    extend: 'Ext.Panel',

    xtype: 'jsonInfoBaseView',

    scrollable: true,
    modal: true,
    hideOnMaskTap: true,
    defaultListenerScope: true,

    keyMap: {
        ESC: {
            handler: 'onCancelPopup',
            event: 'keydown',
        },
    },

    tools: [{
        type: 'close',
        handler: 'onCancelPopup',
    }],

    onCancelPopup() {
        this.hide();
    },

});

import { ERRORS } from 'client/shared/constants';

export default {
    ERRORS,

    ADMIN_LIMITS: {
        PAGE_SIZE_BIG: 10000,
        PAGE_SIZE_MEDIUM: 500,
        PAGE_SIZE_NORMAL: 100,
        PAGE_SIZE_DEFAULT: 25,
        PAGE_SIZE_DISABLE: -1,
    },
    
    SEARCH_VALUE_MAP: {
        BOOLEAN: {
            true: true,
            false: false,
        },
    },
};

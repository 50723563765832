Ext.define('FieldServices.view.services.ServicesController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.services.main',

    config: {
        control: {
            '#restartMemcache': {
                click: 'onClickRestartMemcache',
            },
            '#flushMemcache': {
                click: 'onClickFlushMemcache',
            },
            '#startMongo': {
                click: 'onClickStartMongo',
            },
            '#stopMongo': {
                click: 'onClickStopMongo',
            },
        },
    },

    onClickRestartMemcache() {
    },

    onClickFlushMemcache() {
        FieldServices.app.callAPIEndpoing('GET', 'api/adminservices/memcache/flush')
            .then(response => {
                const responseData = Ext.JSON.decode(response.responseText);
                if (responseData.error_msg) {
                    Ext.MessageBox.alert('Error', responseData.error_msg);
                } else {
                    Ext.MessageBox.show('Success', 'Memcache flushed successfully');
                }
            })
            .catch(() => {
                Ext.MessageBox.alert('Error', 'Internal error occured with request, please check network connection');
            });
    },

    onClickStartMongo() {
    },

    onClickStopMongo() {
    },

});

Ext.define('FieldServices.view.company.document.DocumentView', {
    extend: 'Ext.Panel',
    xtype: 'documentView',

    requires: [
        'Ext.SegmentedButton',
        'FieldServices.view.company.document.DocumentViewController',
        'FieldServices.view.company.document.list.DocumentListView',
        'FieldServices.view.company.document.list.PaymentListView',
    ],

    controller: 'documentViewController',

    defaults: {
        scrollable: true,
        userSelectable: {
            bodyElement: true,
        },
        cls: 'companyPagePanel',
    },
    layout: 'vbox',
    items: [{
        xtype: 'panel',
        title: 'Documents',
        layout: 'vbox',
        flex: 2,
        items: [
            {
                xtype: 'documentListView',
                itemId: 'documentListTab',
                layout: 'center',
                cls: 'card',
            },
        ],
    }, {
        xtype: 'panel',
        title: 'Payments',
        layout: 'vbox',
        flex: 1,
        items: [
            {
                xtype: 'paymentListView',
                itemId: 'paymentListView',
                layout: 'center',
                cls: 'card',
            },
        ],

    },
    ],
});

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollectionCategory.components.LogoCollectionCategoryGrid', {
    extend: 'Ext.grid.Grid',
    xtype: 'logoCollectionCategory.grid',

    bind: {
        store: 'logoCollectionCategory',
        hidden: '{noGrid}',
    },
    scrollable: true,
    itemId: 'logoCollectionCategoryGrid',
    selModel: 'rowmodel',
    bufferedRenderer: true,

    reference: 'logoCollectionCategoryGrid',

    columns: [
        {
            text: 'name',
            dataIndex: 'name',
            width: 200,
            filter: {
                type: 'string',
            },
        },
    ],
});

/**
 * This mixin is to create getter methods for the `control` block of the controller's config. This is a legacy funcionality
 * from old DeftJS.
 *
 */

require('shared/modules/utilities/string.js');

Ext.define('Shared.mixins.getterGenerator', {
    extend: 'Ext.Mixin',
    mixinConfig: {
        before: {
            init: 'beforeInit',
            updateControl: 'beforeUpdateControl',
        },
    },
    /**
     * This function has three main jobs:
     *  * Clone the original config object and later we will work on that.
     *  * Delete all non-standard config options from the original config object(like domSelector option).
     *  * Change legacy itemId-only selectors to valid ComponentQuery selectors
     *
     *  `
     *    control: {
     *      itemId: {
     *          domSelector: 'domelement[domelementattrib=domelementattribvalu]'
     *      },
     *      itemId2 : {
     *          tap : function() {}
     *      },
     *      justGetterName: {
     *          selector : "#itemId3"
     *          tap: function() {}
     *      },
     *      onlyAGetter: true
     *    }
     *   `
     *   The above block is going to be changed to the following:
     *
     *    control: {
     *      '#itemId': {
     *      },
     *      '#itemId2' : {
     *          tap : function() {}
     *      },
     *      '#itemId3' : {
     *          tap: function() {}
     *      },
     *      onlyAGetter: true
     *    }
     *
     *    In the old DeftJS the control object's keys were direct itemIds. The new Sencha ViewController use those keys
     *    as ComponentQuery selectors(just like Ext.app.Controller#control).
     *    If you adding new control blocks, please use the new format!
     *    Also note, every object key in the inner object MUST be an event name and the function which it points to MUST
     *    be exists in controller scope!
     *    Please note `justGettName` config option. In that case a getJustGetterName() function will be generated which
     *    return components by `selector` option. This is NOT domQuery, this is a componentQuery selector, refer
     *    to Ext.ComponentQuery.query for futher information.
     *
     *    Please do not mix `selector` with `domSelector` and componentQuery based control options, for example
     *    '#itemId' : {
     *      selector: '#otherItemId',
     *      domQuery: 'div > a'
     *    }
     *
     *    This is just non-sense and the outcome of this is undefined behaviour.
     *
     * @param control The control config without any processing on it yet. Passed by reference.
     */
    beforeUpdateControl(control) {
        this.config.controlOrig = Ext.clone(control);
        for (const controlOption in control) {
            if (control[controlOption].domSelector) {
                delete control[controlOption];
                continue;
            }
            if (controlOption === 'painted' || controlOption === 'resize') {
                delete control[controlOption];
            }
            if (controlOption === 'view') {
                Object.defineProperty(control, '#', Object.getOwnPropertyDescriptor(control, controlOption));
                delete control[controlOption];
                continue;
            }
            if (controlOption[0] !== '#') {
                if (control[controlOption].selector) {
                    const { selector } = control[controlOption];
                    delete control[controlOption].selector;
                    delete control[controlOption].live;
                    Object.defineProperty(control, selector, Object.getOwnPropertyDescriptor(control, controlOption));
                } else {
                    Object.defineProperty(control, `#${controlOption}`, Object.getOwnPropertyDescriptor(control, controlOption));
                }
                delete control[controlOption];
            }
        }
    },
    /**
     * This function runs after the control block processed and child components created but before any controller
     * logic run. Here we generates our getters based the original control object, which is now this.config.controlOrig.
     */
    beforeInit() {
        const me = this;
        for (const controlOption in this.config.controlOrig) {
            if (controlOption === '#' || controlOption === 'view') {
                continue;
            }
            const getterName = me.generateGetterName(controlOption);
            if (!me[`get${getterName}`]) {
                me[`get${getterName}`] = me.generateGetter(controlOption);
            }
        }
        this.fixSpecialEvents();
    },

    /**
     * The `painted` and `resize` events are special ones and cannot apply with ViewController's control block.
     * It must be added with doAddEventListener and must give a dom element as a source.
     *
     * Gotchas:
     *  - Probably broken on live selectors and needs tremendous amount of work to make it work reliably. Do we even need that?
     *  - painted event is a very special one, thus you cannot use with domSelector. In reality this is a hack where
     *      the component get an additional component with a very short animation added and hook an event to `animationend`
     *      dom event. This is exactly the reason why we should avoid this event as delegation will never work on this, every
     *      painted event is a separate dom listener + a css animation + element. Unfortunately we rely heavily on this.
     *  - scope option not yet implemented
     */
    fixSpecialEvents() {
        const me = this;
        for (const controlOption in me.config.controlOrig) {
            for (const event in me.config.controlOrig[controlOption]) {
                if (event === 'resize' || event === 'transitionend') {
                    //
                    // Use the event listener "as-is"
                    //
                    let order;
                    let targetView;
                    const eventObj = me.config.controlOrig[controlOption][event];
                    let functionRef = eventObj;

                    //
                    // But if it is a string, then we must resolve it to the controller scope
                    //
                    if (typeof eventObj === 'string') {
                        functionRef = me[eventObj];
                    }
                    if (typeof eventObj === 'object') {
                        functionRef = me[eventObj.fn];
                        order = eventObj.order;
                    }

                    if (controlOption == '#' || controlOption == 'view') {
                        targetView = me.getView();
                    } else {
                        //
                        // Here we add listeners not to the view, but on any child component. As the initialization phase
                        // is done, we can use our getters and ad
                        //
                        targetView = me[`get${me.generateGetterName(controlOption)}`]();
                    }
                    // For a config option like
                    //  '#' : {     // or view: {
                    //     painted : "onTapHandler"
                    //  }
                    // will call a doAddListener like this:
                    // targetView.doAddListener('painted',me.onTapHandler,me,{});
                    //
                    // If the event listener is inline function, then that function is passed by its reference.
                    //
                    targetView.doAddListener(event, functionRef, me, {}, order);
                }
            }
        }
    },

    /**
     * `
     *    control: {
     *      itemId: {
     *          domSelector: 'domelement[domelementattrib=domelementattribvalu]'
     *      },
     *      itemId2 : {
     *          tap : function() {}
     *      },
     *      '#newCQFormat' : {
     *          tap : function() {}
     *      },
     *      onlyAGetter: true
     *    }
     *   `
     *
     *   This function will generate the following getters:
     *   this.getItemId();
     *   this.getItemId2();
     *   this.getNewCQFormat(); <-- Pay attention to this, as # removed intentionally!
     *   this.getOnlyAGetter();
     *
     *   If the control config contains a domSelector key, then Ext.Element instance/instances will returned by the
     *   getter function.
     *   In other cases the corresponding Ext.Component will be returned.
     *
     * @param controlOption
     * @returns {*}
     */
    generateGetterName(controlOption) {
        if (controlOption[0] == '#') {
            return controlOption.slice(1).capitalize();
        } 
        return controlOption.capitalize();
    },
    /**
     * This function does the main work as generating the actual getter functions.
     * A new option come into play there, the `live` key.
     * `
     *    control: {
     *      liveSelector: {
     *          live: true,
     *          tap : function() {}
     *      },
     *      standardSelector : {
     *          tap : function() {}
     *      }
     *   `
     *
     *   The generated getters will look something like this:
     *   this.getLiveSelector = function() {
     *      this.getView().query('#'+selector);
     *   }
     *   So, the getter will resolve the component on each call.
     *
     *
     *   var cmp = this.getView().query('#'+selector);
     *   this.getStandardSelector: Ext.bind(function() {
     *      return this;
     *   },cmp);
     *
     *   So, first the component will be resolved when this generator called, then we bind that component to the getter
     *   function, which only returns that bind.
     *
     * @param selector
     * @returns {*}
     */
    generateGetter(selector) {
        const me = this;
        const controlOption = this.config.controlOrig[selector];
        if (controlOption.live) {
            if (controlOption.domSelector || controlOption.selector) {
                // This is a live and domSelector control, so query the view's element on every getter call.
                return function () {
                    let queryComponent;
                    if (controlOption.domSelector) {
                        queryComponent = me.getView().element;
                    }
                    if (controlOption.selector) {
                        queryComponent = me.getView();
                    }
                    const elements = queryComponent.query(controlOption.domSelector || controlOption.selector);
                    switch (elements.length) {
                        case 0:
                            return null;
                        case 1:
                            // Return the Ext.Element directly
                            return Ext.get(elements[0]);
                        default:
                        {
                            if (controlOption.selector) {
                                return elements;
                            }
                            const ret = [];
                            for (const elem in elements) {
                                ret.push(Ext.get(elements[elem]));
                            }
                            // Retrun a generated array contains the multiple Ext.Element instances
                            return ret;
                        }
                    }
                };
            } 
            return Ext.bind(() => {
                // This is a live and standard control, so query the Ext component tree on every getter call.
                const cmp = me.getView().query(`#${selector}`);
                if (cmp && cmp.length > 0) {
                    if (cmp.length === 1) {
                        // Return only the component if exact match found.
                        return cmp[0];
                    } 
                    // Return the whole component array.
                    return cmp;
                }
                return null;
            });
        } 
        let cmp;
        // This is where the non-live control generators generated.
        // Same as above, but without binding actual values.
        if (controlOption.domSelector || controlOption.selector) {
            let queryComponent;
            if (controlOption.domSelector) {
                queryComponent = me.getView().element;
            }
            if (controlOption.selector) {
                queryComponent = me.getView();
            }
            const elements = queryComponent.query(controlOption.domSelector || controlOption.selector);
            let ret;
            switch (elements.length) {
                case 0:
                    ret = null;
                    break;
                case 1:
                    ret = Ext.get(elements[0]);
                    break;
                default:
                {
                    if (controlOption.selector) {
                        ret = elements;
                        break;
                    }
                    ret = [];
                    for (const elem in elements) {
                        ret.push(Ext.get(elements[elem]));
                    }
                }
            }
            return Ext.bind(function () {
                return this;
            }, ret);
        }
        const querySelector = selector.indexOf('#') === 0 || selector.length === 0 ? selector : `#${selector}`;

        cmp = me.getView().query(querySelector);

        if (cmp && cmp.length > 0) {
            if (cmp.length === 1) {
                [cmp] = cmp;
            }
        }
        return Ext.bind(function () {
            return this;
        }, cmp);
    },

});

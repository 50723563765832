Ext.define('FieldServices.view.company.device.DeviceView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.company.device.DeviceViewModel',
        'FieldServices.view.company.device.DeviceViewModelItem',
        'FieldServices.view.company.device.DeviceViewController',
        'FieldServices.view.company.device.details.DetailsView',
        'Ext.grid.cell.Check',
        'Ext.grid.column.Check',
        'FieldServices.view.components.helpTool.HelpToolView',
    ],

    xtype: 'deviceView',

    controller: 'deviceViewCtrl',
    viewModel: 'deviceViewModel',

    bind: {
        store: Ext.create('Ext.data.Store', {
            fields: ['id'],
        }),
    },

    ui: '-devices',
    cls: ['devicesGrid'],
    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
    }],

    sorters: [{
        property: 'lastUsage',
        direction: 'DESC',
    }, {
        property: 'id',
        direction: 'DESC',
    }],

    convertList: ['Device.name', 'Device.os_name', 'Device.os_version', 'User.email', 'User.firstname', 'User.lastname'],

    itemConfig: {
        viewModel: {
            type: 'deviceViewModelItem',
        },
        bind: {
            ui: '{ui}',
        },
    },

    columns: [{
        text: 'Device ID',
        dataIndex: 'device_id',
        flex: 1,
    }, {
        text: 'User Device ID',
        dataIndex: 'id',
        flex: 1,
    }, {
        text: 'Last usage',
        dataIndex: 'last_usage',
        flex: 1,
    }, {
        text: 'App version',
        dataIndex: 'app_version',
        flex: 1,
    }, {
        text: 'Display version',
        dataIndex: 'display_version',
        flex: 1,
    }, {
        text: 'Bundle ID',
        dataIndex: 'bundle_id',
        flex: 1,
    }, {
        text: 'Device Name',
        dataIndex: 'Device.name',
        flex: 1,
        filter: {
            type: 'string',
        },
    }, {
        text: 'OS name',
        dataIndex: 'Device.os_name',
        flex: 1,
    }, {
        text: 'OS version',
        dataIndex: 'Device.os_version',
        flex: 1,
    }, {
        text: 'is inactive',
        dataIndex: 'isdeleted',
        flex: 1,
    }, {
        text: 'Email',
        dataIndex: 'User.email',
        flex: 1,
    }, {
        text: 'Firstname',
        dataIndex: 'User.firstname',
        flex: 1,
        renderer: value => Ext.util.Format.htmlDecode(value),
    }, {
        text: 'Lastname',
        dataIndex: 'User.lastname',
        flex: 1,
        renderer: value => Ext.util.Format.htmlDecode(value),
    }, {
        text: '',
        menuDisabled: true,
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'button',
                text: 'Info',
                handler: 'onInfoButtonTap',
            },
        },
    }, {
        text: '',
        menuDisabled: true,
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'button',
                text: 'Deactivate',
                handler: 'onDelete',
                bind: {
                    hidden: '{record.isdeleted}',
                },
            },
        },
    }],
});

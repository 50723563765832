Ext.define('FieldServices.view.report.subscriptions.SubscriptionsView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.report.subscriptions.SubscriptionsViewController',
        'FieldServices.view.report.subscriptions.active.ActiveFormView',
        'FieldServices.view.report.subscriptions.active.ActiveListView',
    ],

    xtype: 'subscriptionsView',

    controller: 'subscriptionsViewCtrl',

    layout: 'vbox',

    items: [{
        xtype: 'subscriptionsActiveFormView',
        itemId: 'subscriptionsActiveFormView',
    }, {
        xtype: 'subscriptionsActiveListView',
        itemId: 'subscriptionsActiveListView',
        flex: 1,
    }],

});

Ext.define('FieldServices.view.system.systemViewController', {
    extend: 'Deft.mvc.ViewController',
    requires: ['FieldServices.view.message.newPushMessageView'],
    mixins: ['Deft.mixin.Injectable'],
    inject: ['socketController'],
    alias: 'controller.systemViewController',

    config: {
        control: {
            '': {
                setSubPage: 'setSubPage',
            },
            sendNotificationButton: {
                tap: 'onSendNotificationButtonTap',
            },
        },
    },
    setSubPage(subPage) {
        if (!Ext.isNumber(Number(subPage))) {
            console.warn("Couldn't found", subPage);
            return;
        }
        this.getView().setActiveItem(Number(subPage));
    },

    onSendNotificationButtonTap() {
        const addNewModal = Ext.create('FieldServices.view.message.newPushMessageView', {
            viewModel: {
                data: {
                    single: false,
                    message: '',
                    url: '',
                    mainOnly: true,
                    idField: -1,
                    csvData: [],
                },
            },
        });
        Ext.Viewport.add(addNewModal);
        addNewModal.show();
    },

});

Ext.define('UserProfileRoleColumnView', {
    xtype: 'userProfileRoleColumnView',
    extend: 'Ext.grid.column.Column',
    defaultColumns: [{
        text: 'Status',
        cell: {
            bind: {
                value: '{record.{defaultValueKey}==record.{key} ? "default" : "custom"}',
                ui: '{record.{key} ? "-on" : "-off"}',
            },
        },
    }, {
        text: '',
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'togglefield',
                inline: true,
                cls: ['toggle-small', 'toggle-nolabel'],
                bind: {
                    value: '{record.{key}}',
                },
            },
        },

    }],

    constructor(config) {
        if (config && !config.columns) {
            const columnTemplate = new Ext.util.ObjectTemplate(this.defaultColumns);
            this._addDefaultValue(config);
            config.columns = columnTemplate.apply(config);
        }
        this.callParent([config]);
    },

    _addDefaultValue(config) {
        config.defaultValueKey = `${config.key}DefaultValue`;
    },
});

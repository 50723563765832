const Bluebird = require('bluebird');

Ext.define('FieldServices.view.company.document.list.PaymentListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.paymentListViewController',
    xtype: 'paymentListViewController',

    config: {
        companyId: null,
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('paymentFilterPlugin');
    },

    async _reLoadPaymentStore() {
        await this.getGridFilterPlugin().reloadData();
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this._reLoadPaymentStore, this);
    },
});

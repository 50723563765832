Ext.define('FieldServices.view.company.purchases.extra.ExtraViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.extraViewController',
    xtype: 'extraViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '': {
            setSubPage: 'setSubPage',
            painted: 'onViewShow',
        },
        addExtraButton: {
            tap: 'onAddExtraButtonTap',
        },
        printExtraButton: {
            tap: 'onPrintExtraButton',
        },
        resetConsumptionsButton: {
            tap: 'onResetConsumptionsButtonTap',
        },
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadExtrasList, this);
    },

    onViewShow() {
        this.reloadExtrasList().catch(handleClientError);
    },

    onAddExtraButtonTap() {
        const popup = References.getExtraPopup();
        popup.show().on('onReload', () => {
            Ext.getStore('ConsumptionExtra').load();
            this.reloadExtrasList().catch(handleClientError);
        }, this, {
            single: true,
        });
        popup.getController().setCompanyId(this.getViewModel().get('companyId'));
    },

    async onResetConsumptionsButtonTap() {
        await FieldServices.app.callAPIEndpointRequest('POST', `api/admindata/fixConsumptionIds/${this.getViewModel().get('companyId')}`);
    },

    async reloadExtrasList() {
        await this.showLoader(this.getView());
        await this.getGridFilterPlugin().reloadData();
        await this.clearLoader(this.getView());
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('extraFilterPlugin');
    },

    onPrintExtraButton() {
        this.getView().saveDocumentAs({
            fileName: 'extrasList.xlsx',
        });
    },
});

/**
 * Base class for any client-side storage. Used as a superclass for
 * {@link Ext.data.proxy.Memory Memory} and {@link Ext.data.proxy.WebStorage Web Storage} proxies.
 * Do not use directly, use one of the subclasses instead.
 * @private
 */
Ext.define('Ext.data.proxy.Client', {
    extend: 'Ext.data.proxy.Proxy',
    alternateClassName: 'Ext.data.ClientProxy',

    /**
     * @property {Boolean} isSynchronous
     * `true` in this class to identify that requests made on this proxy are
     * performed synchronously
     */
    isSynchronous: true,

    /**
     * Abstract function that must be implemented by each ClientProxy subclass. This should purge
     * all record data from the client side storage, as well as removing any supporting data
     * (such as lists of record IDs)
     */
    clear: function() {
        //<debug>
        Ext.raise("The Ext.data.proxy.Client subclass that you are using has not defined " +
                  "a 'clear' function. See src/data/ClientProxy.js for details.");
        //</debug>
    }
});

Ext.define('FieldServices.view.company.info.infoPanels.IntegrationInfo', {
    extend: 'Ext.panel.Panel',
    title: 'Integration Info',
    xtype: 'integrationInfoView',

    layout: 'vbox',
    masked: {
        xtype: 'loadmask',
    },
    bind: {
        masked: '{!companyIntegrationData}',
    },
    defaults: {
        cls: 'infoPanelRow',
        bind: {
            data: '{companyIntegrationData}',
            hidden: '{!companyIntegrationData}',
        },
        flex: 1,
    },
    items: [
        { tpl: '<b>VAT: </b> <tpl if="!vat_registered">Not </tpl>Registered' },
        {
            bind: { data: '{companyIntegrationData}', hidden: '{!companyIntegrationData || !companyIntegrationData.vat_registered}' },
            tpl: '<b>VAT ID: </b>{vat_id}',
        },
        { tpl: '<b>MTD: </b> <tpl if="!mtd_enabled">Disabled<tpl else> Enabled </tpl>' },
        { tpl: '<b>CIS: </b> <tpl if="!cis_enabled">Disabled<tpl else> Enabled </tpl>' },
        { tpl: '<b>ChargeBee: </b> <tpl if="!chargebee_id">Not connected<tpl else> Connected </tpl>' },
        { tpl: '<b>Xero: </b> <tpl if="!xeroUsageEnabled">Usage disabled<tpl else><tpl if="!xeroEnabled">Disabled <tpl else> Enabled</tpl> </tpl>' },
        {
            bind: { data: '{companyIntegrationData}', hidden: '{!companyIntegrationData || !companyIntegrationData.xeroEnabled}' },
            tpl: '<b>Xero ID: </b>{xero_id} ',
        },
    ],
});

const CSVParser = require('shared/modules/csv');
const CONSTANTS = require('@powerednow/shared/constants');

Ext.define('FieldServices.view.message.newPushMessageViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.newPushMessageViewController',

    requires: [],

    control: {
        cancelButton: {
            tap: 'onCancelButtonTap',
        },
        sendButton: {
            tap: 'onSendButtonTap',
        },
        loadCsvForm: true,
        csvFile: true,
        idFieldSelector: true,
    },

    config: {},

    fixUrl(url) {
        if (!url) {
            return '';
        }
        if (url.indexOf('#') === 0) {
            return url.slice(1);
        }
        return url;
    },
    async onSendButtonTap() {
        try {
            const viewValues = this.getViewModel().data;
            const requestData = {
                message: viewValues.message,
                url: this.fixUrl(viewValues.url),
                recipients: this._getRecipientsList(),
                mainOnly: viewValues.single ? false : viewValues.mainOnly,
            };
            const checkResponse = await FieldServices.app.callAPIEndpointRequest('POST', 'api/admindata/notification/check', requestData);
            Ext.Msg.confirm(
                'Confirm send',
                `${checkResponse.user} users of ${checkResponse.company} companies on ${checkResponse.device} devices will receive this notification. Do you want to continue?`,
                btn => {
                    if (btn === 'yes') {
                        try {
                            FieldServices.app.callAPIEndpointRequest('POST', 'api/admindata/notification/send', requestData);
                        } catch (error) {
                            this.getView().destroy();
                            handleClientError(error);
                        }
                    }
                    this.getView().destroy();
                },
            );
        } catch (error) {
            handleClientError(error);
        }
    },

    onCancelButtonTap() {
        this.getView().destroy();
    },

    _getRecipientsList() {
        const viewValues = this.getViewModel().data;
        if (viewValues.single) {
            return [{
                company_id: viewValues.company_id,
                user_id: viewValues.user_id,
            }];
        }
        const csvData = this.getViewModel().get('csvData');
        return csvData
            .map(row => ({
                company_id: parseInt(row[this.getViewModel().get('idField')], 10),
                user_id: [],
            }))
            .filter(row => typeof row.company_id === 'number' && !Number.isNaN(row.company_id));
    },

    onLoadFileButtonTapped() {
        const file = this.getCsvFile();
        const input = { files: file.getFiles() };

        if (!input.files.length) {
            Ext.Msg.alert('Warning', 'No files selected yet. Tap on "Browse" and select csv file from file system before loading file');
        } else {
            this._readFile(input)
                .then(fileContent => {
                    this._parser = new CSVParser(fileContent, false, ' ');
                    const parsedData = this._parser.getParsedData();
                    this.getViewModel().set('csvData', parsedData);
                    const options = this._getFieldOptions(parsedData);
                    this.getIdFieldSelector().setOptions(options);
                })
                .catch(err => handleClientError(err));
        }
    },

    _getFieldOptions([header, firstLine]) {
        return header.reduce((options, column, index) => [...options, {
            text: `${column} | ${firstLine[index]}`,
            value: index,
        }], [{ text: '-', value: '' }]);
    },

    _readFile(input) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.error = event => {
                reject(generateClientError(CONSTANTS.ERRORS.FILE_READER_ERROR, Object.assign(event, {
                    severity: 4,
                })));
            };
            fileReader.readAsText(input.files[0]);
        });
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.User', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.User'],
    alias: 'store.User',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.User',
        storeId: 'User',
        remoteFilter: true,
        remoteSort: true,
        loadInChunks: true,
        extraKeys: {
            byUserId: 'id',
        },

    },

    proxy: {
        type: 'PoweredNowApi',
        // url: '/api/admindata/global/user',
        // url: 'http://10.0.0.56:3000/api/admindata/company-tables/User',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/User`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_NORMAL,
    // // allow the grid to interact with the paging scroller by buffering
    // buffered: true,
    //
    // // The topics-remote.php script appears to be hardcoded to use 50, and ignores this parameter, so we
    // // are forced to use 50 here instead of a possibly more efficient value.
    // pageSize: 50,
    //
    // // This web service seems slow, so keep lots of data in the pipeline ahead!
    // leadingBufferZone: 1000,
    //
    // remoteSort: true,
    // remoteFilter: true,
    //
    // // store.setSorters({property: 'code',direction: 'DESC',})
    // // store.filter([{property:'code',value:'akarmi',operation:'='}]);
});

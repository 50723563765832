Ext.define('FieldServices.view.purchaseSetup.PurchaseSetupView', {
    extend: 'Ext.grid.Grid',
    xtype: 'purchaseSetupView',
    title: 'Purchase Setup',
    layout: 'fit',
    flex: 1,
    ui: '-purchaseSetup',

    requires: [
        'FieldServices.view.purchaseSetup.PurchaseSetupViewModel',
    ],

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
    }],

    itemConfig: {
        viewModel: true,
    },

    viewModel: 'purchaseSetupViewModel',

    bind: {
        store: '{purchaseSetupChained}',
    },

    columns: [
        {
            text: 'ID',
            dataIndex: 'id',
        }, {
            text: 'Type',
            dataIndex: 'type',
        }, {
            text: 'Product Name',
            dataIndex: 'product_name',
            flex: 1,
        }, {
            text: 'Description',
            dataIndex: 'description_id',
        }, {
            text: 'Days Before',
            dataIndex: 'days_before_expires',
        }, {
            text: 'iOS Id',
            dataIndex: 'ios_product_id',
        }, {
            text: 'Android Id',
            dataIndex: 'android_product_id',
        }, {
            text: 'Emails',
            dataIndex: 'emails',
        }, {
            text: 'SMS',
            dataIndex: 'sms',
        }, {
            text: 'Postcode',
            dataIndex: 'postcode',
        }, {
            text: 'Users',
            dataIndex: 'users',
        }, {
            text: 'Team Trial Enabled',
            dataIndex: 'team_trial_enabled',
        }, {
            text: 'Team Enabled',
            dataIndex: 'team_enabled',
        }, {
            text: 'iOS Id',
            dataIndex: 'ios_product_id',
        }, {
            text: 'Android Id',
            dataIndex: 'android_product_id',
        }, {
            text: 'Document Creation',
            dataIndex: 'document_creation',
        }, {
            text: 'Document Sent ',
            dataIndex: 'document_send',
        }, {
            text: 'Customer Limit',
            dataIndex: 'customer_limit',
        }, {
            text: 'Device Switch',
            dataIndex: 'device_switch',
        }, {
            text: 'Suppliers',
            dataIndex: 'suppliers',
        }, {
            text: 'CIS',
            dataIndex: 'cis',
        }, {
            text: 'Can Approve Doc',
            dataIndex: 'can_approve_doc',
        }, {
            text: 'Products',
            dataIndex: 'products',
        }, {
            text: 'Sites',
            dataIndex: 'sites',
        }, {
            text: 'Weight',
            dataIndex: 'weight',
        }, {
            text: 'Form',
            dataIndex: 'form',
        },
    ],
});

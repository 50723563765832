/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollectionCategory.LogoCollectionCategory', {
    extend: 'Ext.panel.Panel',
    xtype: 'logoCollectionCategory.main',

    requires: [
        'FieldServices.view.logoCollectionCategory.components.LogoCollectionCategoryForm',
        'FieldServices.view.logoCollectionCategory.components.LogoCollectionCategoryGrid',
        'FieldServices.view.logoCollectionCategory.LogoCollectionCategoryController',
        'FieldServices.view.logoCollectionCategory.LogoCollectionCategoryModel',
    ],
    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },
    title: 'Logo Collection Category',
    controller: 'logoCollectionCategory.main',
    viewModel: {
        type: 'logoCollectionCategory.main',
    },
    items: [{
        xtype: 'logoCollectionCategory.grid',
        flex: 1,
    }, {
        xtype: 'logoCollectionCategory.fieldset',
    }],
    initConfig(config) {
        const self = this;
        this.callParent(arguments);
        if (config.noGrid) {
            this.getViewModel().setData({ noGrid: true });
        }
    },
});

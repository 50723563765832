Ext.define('FieldServices.overrides.FieldToggle', {
    override: 'Ext.field.Toggle',

    requires: ['Ext.drag.Info'],

    config: {
        labelWidth: null,
    },
    preventScrollToCenter: true,

    updateValue(value, oldValue) {
        this.setUi(value);

        this.callParent(arguments);
    },

    setUi(value) {
        const slider = this.getSlider();
        const thumb = slider.getThumb();
        const isOn = value;
        const onCls = slider.maxValueCls;
        const offCls = slider.minValueCls;
        const { element } = slider;

        element.addCls(isOn ? onCls : offCls);
        element.removeCls(isOn ? offCls : onCls);

        thumb.setUi(isOn ? slider.getOnThumbUi() : slider.getOffThumbUi());
    },
});

require('../../../resources/scss/pages/_companyView.scss');

Ext.define('FieldServices.view.company.CompanyView', {
    extend: 'Ext.Panel',
    requires: [
        'FieldServices.view.company.CompanyViewModel',
        'FieldServices.view.company.CompanyViewController',
        'FieldServices.view.company.select.SelectCompanyView',
        'FieldServices.view.company.info.InfoView',
        'FieldServices.view.company.settings.CompanySettingsView',
        'FieldServices.view.company.purchases.subscriptions.SubscriptionList',
        'FieldServices.view.company.purchases.extra.ExtraView',
        'FieldServices.view.company.user.UsersView',
        'FieldServices.view.company.device.DeviceView',
        'FieldServices.view.company.job.JobListView',
        'FieldServices.view.company.message.MessageView',
        'FieldServices.view.company.contact.list.ContactListView',
        'FieldServices.view.company.appointment.list.AppointmentListView',
        'FieldServices.view.company.document.DocumentView',
        'FieldServices.view.company.formDocument.FormDocumentListView',

    ],
    alias: 'widget.company',
    viewModel: 'companyViewModel',

    controller: 'companyViewController',
    layout: 'vbox',
    flex: 1,
    bind: {
        masked: '{companyViewLoadMask}',
    },
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 1,
            bind: {
                hidden: '{isCompanyLoading || company}',
            },
            defaults: {
                flex: 5,
            },
            items: [
                { xtype: 'container' },
                { html: '<h1>Company Not Found!</h1>', cls: ['companyNotFoundText'], flex: 1 },
                {
                    xtype: 'container',
                    layout: 'hbox',
                    flex: 1,
                    defaults: {
                        flex: 3,
                    },
                    items: [
                        { xtype: 'container' },
                        {
                            xtype: 'button',
                            text: 'Change Company',
                            itemId: 'changeCompanyButton',
                        },
                        { xtype: 'container' },
                    ],
                },
                { xtype: 'container' },
            ],
        },
        {
            xtype: 'container',
            layout: 'hbox',
            itemId: 'companyViewHeader',
            cls: 'companyInfoHeader',
            hidden: true,
            bind: {
                flex: '{infoHeaderFlex}',
                hidden: '{isCompanyLoading || !company}',
            },
            items: [
                {
                    xtype: 'container',
                    layout: 'hbox',
                    flex: 1,
                    bind: {
                        hidden: '{!isPortraitView}',
                    },
                    items: [
                        {
                            flex: 1,
                            bind: { data: '{selectedCompanyData}' },
                            cls: 'companyNameBar',
                            tpl: '<h1>{name} ({id})</h1>',
                        },
                        {
                            flex: 1,
                            xtype: 'button',
                            itemId: 'openInHubspotButton',
                            bind: {
                                text: '{hubspotButtonText}',
                                disabled: '{!hubspotRecordUrl}',
                            },
                        },
                        {
                            flex: 1,
                            xtype: 'button',
                            text: 'Change Company',
                            itemId: 'changeCompanyButton',
                        },
                    ],
                },
                {
                    xtype: 'container',
                    layout: 'vbox',
                    flex: 1,
                    bind: {
                        hidden: '{isPortraitView}',
                    },
                    items: [
                        {
                            flex: 1,
                            bind: { data: '{selectedCompanyData}' },
                            cls: 'companyNameBar',
                            tpl: '<h1>{name}</h1>',
                        },
                        {
                            xtype: 'container',
                            layout: 'hbox',
                            flex: 2,
                            items: [
                                {
                                    xtype: 'container',
                                    cls: 'baseCompanyDataContainer',
                                    layout: 'vbox',
                                    flex: 3,
                                    defaults: {
                                        cls: 'mainInfoRow',
                                        bind: { data: '{selectedCompanyData}' },
                                    },
                                    items: [
                                        { tpl: '<b>Company id:</b> {id}' },
                                        { tpl: '<b>Registered since:</b> {formattedCreationDate}' },
                                    ],
                                },
                                {
                                    xtype: 'container',
                                    cls: 'baseCompanyDataContainer',
                                    layout: 'vbox',
                                    flex: 3,
                                    defaults: {
                                        cls: 'mainInfoRow',
                                        bind: { data: '{selectedCompanyData}' },
                                    },
                                    items: [
                                        { tpl: '<b>Business type:</b> {businessType}' },
                                        { tpl: '<b>Company team size:</b> {teamSize}' },
                                    ],
                                },
                                {
                                    cls: 'baseCompanyDataContainer',
                                    layout: 'vbox',
                                    flex: 3,
                                    defaults: {
                                        cls: 'mainInfoRow',
                                    },
                                    items: [
                                        { bind: { html: '<b>White Label:</b> {whiteLabelName}' } },
                                        { bind: { html: '<b>Active Subscription:</b> {currentSubscriptionName}' } },
                                    ],
                                },
                                {
                                    xtype: 'container',
                                    layout: {
                                        type: 'hbox',
                                        pack: 'space-around',
                                    },
                                    flex: 2.5,
                                    items: [
                                        {
                                            xtype: 'button',

                                            itemId: 'openInHubspotButton',
                                            bind: {
                                                text: '{hubspotButtonText}',
                                                disabled: '{!hubspotRecordUrl}',
                                            },
                                        },
                                        {
                                            xtype: 'button',
                                            text: 'Change Company',
                                            itemId: 'changeCompanyButton',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

            ],

        },
        {
            xtype: 'tabpanel',
            itemId: 'companyManagementPanel',
            flex: 8,
            hidden: true,
            tabBar: {
                flex: 1,
                bodyCls: 'scrollableTabbar',
                scrollable: 'x',
            },
            bind: {
                hidden: '{isCompanyLoading || !company}',
            },
            defaults: { tab: { flex: 1, cls: 'companyNavTab' } },
            items: [
                {
                    title: 'Company info',
                    iconCls: 'fa-info',
                    tab: {
                        ui: '-companyInfo',
                    },
                    layout: 'fit',
                    items: [{
                        xtype: 'infoView2',
                    }],
                },
                {
                    tab: {
                        ui: '-companySettings',
                    },
                    title: 'Company settings',
                    iconCls: 'fa-gear',
                    layout: 'fit',
                    items: [{
                        xtype: 'companySettingsView',
                    }],
                },
                {
                    title: 'Subscriptions & Extras',
                    iconCls: 'fa-shopping-cart',
                    layout: 'fit',
                    tab: {
                        ui: '-companyPurchases',
                    },
                    items: [{
                        xtype: 'tabpanel',
                        ui: '-companyPurchases',
                        defaults: {
                        },
                        items: [
                            {
                                tab: {
                                    flex: 1,
                                    title: 'Subscriptions',
                                    ui: '-subscriptions',
                                },
                                xtype: 'subscriptionList',
                            },
                            {
                                tab: {
                                    flex: 1,
                                    title: 'Extras',
                                    ui: '-extraView',
                                },
                                xtype: 'extraView',
                            },
                        ],
                    }],
                },
                {
                    title: 'Users & Devices',
                    iconCls: 'fa-users',
                    layout: 'fit',
                    tab: {
                        ui: '-extraView',
                    },
                    items: [{
                        xtype: 'tabpanel',
                        ui: '-extraView',
                        defaults: {
                        },
                        items: [
                            {
                                tab: {
                                    flex: 1,
                                    title: 'Users',
                                    ui: '-users',
                                },
                                xtype: 'userView',
                            },
                            {
                                tab: {
                                    flex: 1,
                                    title: 'Devices',
                                    ui: '-devices',
                                },
                                xtype: 'deviceView',
                            },
                        ],
                    }],
                },
                {
                    title: 'Company Data',
                    iconCls: 'fa-folder',
                    layout: 'fit',
                    tab: {
                        ui: '-companyData',
                    },
                    items: [{
                        xtype: 'tabpanel',
                        ui: '-companyData',
                        tabBar: {
                            flex: 1,
                            bodyCls: 'scrollableTabbar',
                            scrollable: 'x',
                        },
                        defaults: {
                            tab: { flex: 1, cls: 'companyDataTab' },
                        },
                        items: [
                            {
                                tab: {
                                    title: 'Projects',
                                    ui: '-projects',
                                    iconCls: 'fa-clipboard',
                                },
                                ui: '-projects',
                                xtype: 'jobListView',
                            },
                            {
                                tab: {
                                    title: 'Customers',
                                    ui: '-customers',
                                    iconCls: 'fa-user',
                                },
                                ui: '-customers',
                                xtype: 'customerListView',
                            },
                            {
                                tab: {
                                    title: 'Messages',
                                    ui: '-messages',
                                    iconCls: 'fa-envelope',
                                },
                                ui: '-messages',
                                xtype: 'messageView',
                            },
                            {
                                tab: {
                                    title: 'Forms & Certificates',
                                    ui: '-formDocuments',
                                    iconCls: 'fa-bookmark',
                                },
                                ui: '-formDocuments',
                                xtype: 'formDocumentListView',
                            },
                            {
                                tab: {
                                    title: 'Documents',
                                    ui: '-documents',
                                    iconCls: 'fa-file-text',
                                },
                                ui: '-documents',
                                xtype: 'documentListView',
                            },
                            {
                                tab: {
                                    title: 'Payments',
                                    ui: '-extraView',
                                    iconCls: 'fa-gbp',
                                },
                                ui: '-extraView',
                                xtype: 'paymentListView',
                            },
                            {
                                tab: {
                                    title: 'Appointments',
                                    ui: '-subscriptions',
                                    iconCls: 'fa-calendar',
                                },
                                ui: '-subscriptions',
                                xtype: 'appointmentListView',
                            },
                        ],
                    }],
                },
            ],
        },
    ],
});

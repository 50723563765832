Ext.define('FieldServices.view.company.info.clientError.clientErrorInfoRowViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.clientErrorInfoRowViewModel',
    formulas: {
        cardNumber: {
            bind: {
                record: '{record}',
            },
            async get({ record }) {
                /**
                 * It is not so nice, but `dependantStore` option seems to be broken on `clientErrorStore`,
                 * but I have to be sure knownError is loaded before I try to access it. I can't do this in
                 * getKnownErrorModel() as it has to be sync atm.
                 */
                await Ext.getStore('KnownError').waitToLoad();
                const knownErrorModel = record.getKnownErrorModel();
                return knownErrorModel ? knownErrorModel.data.card_number : false;
            },
        },
    },
});

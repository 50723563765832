import DOCUMENTS from '@powerednow/shared/constants/document';
import ADMIN_CONSTANTS from 'client/admin/constants';

Ext.define('FieldServices.view.company.document.list.DocumentListView', {
    extend: 'Ext.grid.Grid',
    ui: '-documents',

    requires: [
        'FieldServices.view.company.document.list.DocumentListItemViewModel',
        'FieldServices.view.company.document.list.DocumentListViewController',
        'FieldServices.view.company.document.edit.DocumentEditView',
        'Ext.grid.plugin.RowOperations',
    ],

    xtype: 'documentListView',
    controller: 'documentListController',
    viewModel: true,

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'documentFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/DocumentWithHeaders',
            params: {
                sort: [{
                    property: 'isdeleted',
                    direction: 'ASC',
                }, {
                    property: 'displayNumber',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }],
                requiredFields: ['company_id', 'isdeleted', 'is_archived'],
            },
        },
        additionalFilterFields: [{
            xtype: 'textfield',
            property: 'CustomerContactFirstName',
            placeholder: 'Firstname',
        }, {
            xtype: 'textfield',
            property: 'CustomerContactLastName',
            placeholder: 'Lastname',
        }, {
            xtype: 'textfield',
            property: 'CustomerCompany',
            placeholder: 'Company',
        }, {
            xtype: 'textfield',
            property: 'type',
            placeholder: 'Type',
            searchValueMap: DOCUMENTS.TYPES_VALUES,
        }],
        dateRangeFilters: [{
            property: 'dt_created',
            label: 'Created:',
        }],
    }, {
        type: 'gridexporter',
    }],

    titleBar: {
        shadow: false,
        items: [{
            align: 'right',
            xtype: 'button',
            text: 'Action ...',
            stretchMenu: true,
            arrow: false,
            menu: {

                indented: false,
                items: [{
                    text: 'Export to xls',
                    itemId: 'exportToXls',
                    cfg: {
                        type: 'excel07',
                        ext: 'xlsx',
                        includeGroups: true,
                        includeSummary: true,
                    },
                }, {
                    text: 'Remove Xero link',
                    itemId: 'removeXeroLink',
                }, {
                    text: 'Unlock',
                    itemId: 'unlockActionButton',
                    status: 0,
                    cfg: {
                        type: 'excel03',
                        ext: 'xml',
                        includeGroups: true,
                        includeSummary: true,
                    },
                }, {
                    text: 'Lock',
                    itemId: 'lockActionButton',
                    status: 1,
                    cfg: {
                        type: 'excel03',
                        ext: 'xml',
                        includeGroups: true,
                        includeSummary: true,
                    },
                }],
            },
        }],
    },
    emptyText: 'empty',

    itemConfig: {
        viewModel: {
            type: 'documentListItemViewModel',
        },
        bind: {
            ui: '{ui}',
        },
    },

    columns: [{
        xtype: 'checkcolumn',
        headerCheckbox: true,
        gridWithFilter: {
            skipFromFilter: true,
        },
        dataIndex: 'isCheck',
    }, {
        text: 'Id',
        dataIndex: 'id',
        minWidth: '120',
    }, {
        text: 'Document number',
        dataIndex: 'documentnumber',
    }, {
        text: 'Display number',
        dataIndex: 'displaynumber',
    }, {
        text: 'Type',
        dataIndex: 'type',
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            bind: '{typeName}',
        },
    }, {
        text: 'Status',
        dataIndex: 'status',
        gridWithFilter: {
            searchValueMap: DOCUMENTS.STATUS,
        },
        cell: {
            bind: '{statusName}',
        },
        width: 70,
    }, {
        text: 'Created',
        dataIndex: 'dt_created',
        gridWithFilter: {
            skipFromFilter: true,
        },
    }, {
        text: 'Job',
        dataIndex: 'JobDescription',
        flex: 1,
        cell: {
            bind: '{jobDescription}',
        },
    }, {
        text: 'isDeleted Job',
        dataIndex: 'JobIsDeleted',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        cell: {
            bind: {
                value: '{jobIsDeletedString}',
                ui: '{isDeletedJobCellUi}',
            },
        },
    }, {
        text: 'Customer',
        dataIndex: 'CustomerName',
        flex: 1,
        cell: {
            bind: '{customerName}',
        },
        gridWithFilter: {
            skipFromFilter: true,
        },
    }, {
        text: 'total',
        dataIndex: 'total',
        gridWithFilter: {
            operator: '=',
            valueValidator: Ext.Number.parseFloat,
        },
        width: 50,
    }, {
        text: 'customer id',
        dataIndex: 'customer_id',
    }, {
        text: 'is locked',
        dataIndex: 'islocked',
        width: 50,
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        cell: {
            bind: {
                value: '{isLockedString}',
            },
        },
    }, {
        text: 'locked date',
        dataIndex: 'dt_locked',
    }, {
        text: 'Last activity',
        dataIndex: 'job_id',
        minWidth: '150',
        gridWithFilter: {
            skipFromFilter: true,
        },
        cell: {
            bind: {
                value: '{jobLastActivity}',
            },
        },
    }, {
        text: 'confirmed',
        dataIndex: 'confirmed',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        width: 50,
        cell: {
            bind: {
                value: '{confirmedString}',
            },
        },
    }, {
        text: 'archived',
        dataIndex: 'is_archived',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        width: 50,
        cell: {
            bind: {
                value: '{archivedString}',
            },
        },
    }, {
        text: 'is deleted',
        dataIndex: 'isdeleted',
        gridWithFilter: {
            searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
        },
        width: 50,
        cell: {
            bind: {
                value: '{isDeletedString}',
            },
        },
    }],
});

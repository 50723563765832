Ext.define('FieldServices.view.report.subscriptions.active.ActiveFormView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.subscriptions.active.ActiveFormViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
        'Ext.form.field.Tag',
        'FieldServices.view.report.subscriptions.active.ActiveFormViewModel',
    ],

    xtype: 'subscriptionsActiveFormView',

    controller: 'subscriptionsActiveFormViewController',

    scrollable: true,

    viewModel: 'subscriptionsActiveMain',
    layout: 'hbox',
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 1,
            items: [
                {
                    xtype: 'dateRangeFilter',
                    title: 'Expires Date:',
                    name: 'expires_date',
                    tableName: 'Purchases',
                    mode: 'date',
                    layout: 'hbox',
                }, {
                    xtype: 'dateRangeFilter',
                    title: 'Purchase Date:',
                    name: 'purchase_date',
                    tableName: 'Purchases',
                    mode: 'date',
                    layout: 'hbox',
                },
            ],
        },
        {
            xtype: 'container',
            layout: 'vbox',
            flex: 1,
            items: [
                {
                    xtype: 'selectfield',
                    reference: 'purchaseOrigin',
                    name: 'purchase_origin',
                    tableName: 'Purchases',
                    label: 'Purchase Origin:',
                    labelAlign: 'top',
                    labelWidth: 'auto',
                    autoHideInputMask: true,
                    bind: {
                        store: '{PurchaseOrigins}',
                        isFilter: '{purchaseOrigin.value!=="all"}',
                    },
                    displayField: 'key',
                    filterOperator: '=',
                    valueField: 'value',
                },
                {
                    xtype: 'whiteLabelFilter',
                    includeTest: false,
                },

            ],
        },
        {
            xtype: 'container',
            docked: 'bottom',
            items: [{
                xtype: 'button',
                text: 'Filter',
                iconCls: 'ion-ios-checkmark-outline',
                cls: 'btn',
                handler: 'onChangeFilterInActiveSubscriptions',
            }, {
                xtype: 'button',
                text: 'xls',
                iconCls: 'ion-document',
                handler: 'onDownloadCsvFile',
                right: '0',
            }],
        },
    ],

});

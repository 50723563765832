Ext.define('FieldServices.view.company.device.details.restore.RestoreView', {
    extend: 'Ext.Panel',
    requires: [
        'FieldServices.view.company.device.details.restore.RestoreViewController',
    ],

    xtype: 'restoreView',

    controller: 'restoreViewController',

    scrollable: true,

    title: 'Restore',
    layout: 'vbox',

    confirmTpl: [
        '<h3>Are you sure you want to restore these?</h3>',
        '<table>',
        '<tr><td>From:</td><td>{from}</td></tr>',
        '<tr><td>To:</td><td>{to}</td></tr>',
        '</table>',
        '<table><thead><tr><th>tableName</th><th>count</th></thead>',
        '<tpl for="elements">',
        '<tr><td>{table}:</td><td>{count}</td></tr>',
        '</tpl></table>',
    ],

    items: [
        {
            xtype: 'container',
            scrollable: true,
            layout: 'hbox',
            bodyCls: ['verticalOnMobile'],
            items: [{
                xtype: 'formpanel',
                flex: 1,
                layout: {
                    type: 'hbox',
                    align: 'middle',
                },

                items: [{
                    flex: 2,
                    xtype: 'dateRangeFilter',
                    itemId: 'filterRange',
                    title: 'Sync date range:',
                    name: 'date',
                    layout: 'hbox',
                    labelAlign: 'top',
                    dateMode: 'datetime',
                    dbType: 'mongo',
                }, {

                    xtype: 'button',
                    itemId: 'filterButton',
                    text: 'Filter',
                    flex: 0.5,
                }, { flex: 1, cls: 'hiddenOnMobile' }],
            }, {
                xtype: 'button',
                itemId: 'restoreButton',
                text: 'Restore (from - to)',
                ui: '-primary',
            }],
        }, {
            xtype: 'grid',
            itemId: 'deletedRecordsList',
            plugins: [{
                type: 'gridcolumnresizing',
            }],
            store: 'Mongos',
            flex: 1,

            emptyText: 'empty',

            columns: [{
                text: '_id',
                dataIndex: '_id',
                width: 200,
            }, {
                text: 'deletedAt',
                dataIndex: 'date',
                width: 200,
            }, {
                text: 'method',
                dataIndex: 'method',
            }, {
                text: 'tableName',
                dataIndex: 'tableName',
                width: 150,
            }, {
                text: 'date',
                dataIndex: 'date',
                renderer: 'rendererDateToIso',
                width: 200,
            }, {
                text: '',
                cell: {
                    xtype: 'widgetcell',
                    widget: {
                        xtype: 'button',
                        text: 'from',
                        handler: 'onFromButtonTap',
                    },
                },
            }, {
                text: '',
                cell: {
                    xtype: 'widgetcell',
                    widget: {
                        xtype: 'button',
                        text: 'to',
                        handler: 'onToButtonTap',
                    },
                },
            }],
        },
    ],

});

/**
 * Created by krisztiankormoczi on 2017. 05. 19..
 */
Ext.define('FieldServices.mixins.subPage', {
    extend: 'Ext.Mixin',
    setSubPage(subPage) {
        if (!Ext.isNumber(Number(subPage))) {
            console.warn("Couldn't found", subPage);
            return;
        }
        this.getView().setActiveItem(Number(subPage));
    },
});

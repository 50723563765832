Ext.define('FieldServices.view.company.job.JobListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.jobListController',
    xtype: 'jobListController',

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadData, this);
    },

    reloadData() {
        this.getView().getPlugin('jobFilterPlugin').reloadData();
    },
});

import { COHORTS } from 'shared/constants';

Ext.define('FieldServices.view.report.cohort.CohortReportViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.cohortReport',

    fixedColumnDefs: [
        {
            text: 'Period',
            dataIndex: 'period',
            width: 80,
            height: 80,
            cls: 'rotatedGridHeader',
        },
        {
            text: 'Registered Companies',
            dataIndex: 'registrationCount',
            width: 80,
            height: 80,
            cls: 'rotatedGridHeader',
        },
    ],

    tableStore: Ext.create('Ext.data.Store', { data: [] }),
    chartStore: Ext.create('Ext.data.Store', { data: [] }),

    data: {
        dataLoaded: false,
        apiResponse: null,
        dataMode: COHORTS.TABS.SUB,
        isCumulative: false,
        isPercentage: false,
        showChart: false,

    },
    formulas: {
        emptyText: {
            bind: '{dataLoaded}',
            get(dataLoaded) {
                return dataLoaded ? 'No results found for selected filters' : 'Click "load" to get the reports';
            },
        },
        reportTableStore: {
            bind: '{reportData}',
            get(storeData) {
                this.tableStore.setData(storeData);
                return this.tableStore;
            },
        },
        reportData: {
            bind: {
                apiResponse: '{apiResponse}',
                dataMode: '{dataMode}',
                isCumulative: '{isCumulative}',
                isPercentage: '{isPercentage}',
            },
            get({
                apiResponse, dataMode, isCumulative, isPercentage,
            }) {
                const processedData = [];
                if (!apiResponse || !apiResponse.length) {
                    return processedData;
                }
                const columnPeriods = apiResponse.map(responseRow => responseRow.period).sort();
                apiResponse.forEach(responseRow => {
                    const reportRow = {
                        period: responseRow.period.split('_')[0],
                        registrationCount: responseRow.registrationCount,
                    };
                    let cumulativeValue = 0;
                    columnPeriods.forEach(periodKey => {
                        let reportColumnValue = '';
                        const valuesInColumnPeriod = responseRow[periodKey];
                        if (valuesInColumnPeriod) {
                            const processedPeriodValue = this.getColumnValueByDataMode(valuesInColumnPeriod, dataMode);
                            cumulativeValue += processedPeriodValue;
                            reportColumnValue = isCumulative ? cumulativeValue : processedPeriodValue;
                            if (isPercentage) {
                                reportColumnValue = `${((reportColumnValue / responseRow.registrationCount) * 100).toFixed(2)} %`;
                            }
                        }
                        reportRow[periodKey.split('_')[0]] = reportColumnValue;
                    });
                    processedData.push(reportRow);
                });
                return processedData;
            },
        },
        reportTableColumns: {
            bind: '{reportData}',
            get(reportData) {
                if (!reportData || !reportData.length) {
                    return [];
                }
                const columnDefs = [...this.fixedColumnDefs];
                const reportPeriods = reportData.map(reportRow => reportRow.period);
                reportPeriods.forEach(periodKey => {
                    const config = {
                        text: periodKey,
                        dataIndex: periodKey,
                        width: 70,
                        height: 80,
                        cls: 'rotatedGridHeader',
                    };
                    columnDefs.push(config);
                });
                return columnDefs;
            },
        },

        chartFields: {
            bind: '{reportData}',
            get(reportData) {
                if (!reportData || !reportData.length) {
                    return [];
                }
                return Ext.clone(reportData.map(reportRow => reportRow.period));
            },
        },
        chartSeries: {
            bind: {
                fields: '{chartFields}',
                loaded: '{dataLoaded}',
            },
            get({ fields, loaded }) {
                if (!loaded) {
                    return [];
                }
                return this._getChartSeries(fields);
            },
        },
        chartAxes: {
            bind: {
                fields: '{chartFields}',
                loaded: '{dataLoaded}',
            },
            get({ fields, loaded }) {
                if (!loaded) {
                    return [];
                }
                return this._getChartAxes(fields);
            },
        },
        chartStore: {
            bind: {
                data: '{reportData}',
                fields: '{chartFields}',
            },
            get({ data, fields }) {
                this.chartStore.setData(this.transformDataForChart(fields, data));
                return this.chartStore;
            },
        },
    },

    getColumnValueByDataMode(value, dataMode) {
        if (dataMode === COHORTS.TABS.NET) {
            return value.subscription.length - value.cancelled.length;
        }
        return value[dataMode].length;
    },

    transformDataForChart(fields, dataRows) {
        const data = [];
        fields.forEach(period => {
            const periodValues = { period };
            dataRows.forEach(dataRow => {
                const numericValue = dataRow[period].replace ? dataRow[period].replace(' %', '') : dataRow[period];
                periodValues[dataRow.period] = numericValue === '' ? undefined : numericValue;
            });
            data.push(periodValues);
        });
        return data;
    },

    _getChartSeries(data) {
        const series = [];
        data.forEach(elem => {
            series.push({
                type: 'line',
                xField: 'period',
                yField: elem,
                title: elem,
                tooltip: {
                    trackMouse: true,
                    width: 70,
                    height: 28,
                    html: elem,
                },
            });
        });
        return series;
    },

    _getChartAxes(fields) {
        return [{
            type: 'numeric',
            position: 'left',
            fields,
        }, {
            type: 'category',
            position: 'bottom',
            fields: 'period',
        }];
    },
});

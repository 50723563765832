/*
 * File: app/view/Dashboard.js
 *
 *
 */

Ext.define('FieldServices.view.dashboard.DashboardController', {
    extend: 'Deft.mvc.ViewController',
    alias: 'controller.FieldServices.view.dashboard.DashboardController',

    control: {
        dashboardContent: true,
        logoutButton: {
            tap: 'onLogoutBtnClick',
        },
    },

    config: {
        locationService: null,
        socketService: null,
        subscriptionService: null,
    },
    routes: {
        'show/:page/:subpage/:param?': {
            action: 'showPage',
            conditions: {
                ':page': '(.*)',
                ':subPage': '(.*)!',
                ':param': '(.*)!',
            },
        },
    },
    onLogoutBtnClick() {
        AuthManager.logout();
    },
    showPage(pageName, subPage, param) {
        const dashboardContent = this.getDashboardContent();
        const page = dashboardContent.down(`#${pageName}`);
        if (page) {
            dashboardContent.setActiveItem(page);
            const activePage = dashboardContent.getActiveItem();
            activePage.fireEventedAction('setSubPage', [subPage, param]);
        } else {
            Ext.Msg.alert('Invalid page', 'Required page not found');
        }
    },
});

Ext.define('FieldServices.overrides.FormPanel', {
    override: 'Ext.form.Panel',

    validatorConfig: {
        message: 'Empty parameter(s).',
        isValueValidFn: value => !['', null].includes(value),
        invalidCls: 'invalidElement',
    },

    getFilters(includeDisabled = false) {
        return Object.values(this.getFields())
            .filter(item => item.getFilters)
            .reduce((filters, item) => [...filters, ...item.getFilters()], [])
            .filter(filter => includeDisabled || !filter.isDisabled);
    },

    async validate() {
        const isValid = this._areAllFieldsValid();

        this._removeInvalidCls();
        if (!isValid) {
            this._highlightInvalidFields();
            await this._showInvalidFieldsAlert();
        }
        return { isValid };
    },

    _showInvalidFieldsAlert() {
        return new Promise(resolve => {
            Ext.Msg.alert('alert', this._getInvalidFieldsMessage(), resolve);
        });
    },

    _highlightInvalidFields() {
        this._getInvalidFields().forEach(field => {
            field.el.addCls(this.validatorConfig.invalidCls);
        });
    },

    _areAllFieldsValid() {
        const invalidFields = this._getInvalidFields();
        return invalidFields.length === 0;
    },

    _removeInvalidCls() {
        this._getFields().forEach(elem => {
            elem.el.removeCls(this.validatorConfig.invalidCls);
        });
    },

    _getInvalidFields() {
        return this._getFields()
            .filter(field => !this._isValid(field));
    },

    _getFields() {
        const form = this.getValues();
        return Object.keys(form).map(name => this.down(`[name=${name}]`));
    },

    _isValid(field) {
        const form = this.getValues();
        const { isValueValidFn } = this.validatorConfig;
        const fieldConfig = field.initialConfig;

        if (fieldConfig.isRequired === true) {
            return isValueValidFn(form[fieldConfig.name]);
        }
        return true;
    },

    _getInvalidFieldsMessage() {
        const { message } = this.validatorConfig;
        const fieldNames = this._getInvalidFields().map(field => field.initialConfig.label);
        return `${message} <br>${fieldNames.join(', <br>')}`;
    },
});

Ext.define('FieldServices.view.components.failedMessage.list.FailedMessageListView', {
    extend: 'Ext.grid.Grid',
    xtype: 'failedMessageListView',
    ui: '-failedMessages',

    controller: 'failedMessageListViewController',
    itemId: 'failedMessageList',

    itemConfig: {
        viewModel: true,
    },

    requires: [
        'FieldServices.view.components.failedMessage.list.FailedMessageListViewController',
        'FieldServices.view.components.failedMessage.FailedMessageInfoPopUp',
        'Ext.grid.plugin.PagingToolbar',
    ],

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
    }, {
        type: 'pagingtoolbar',
    }],

    titleBar: {
        shadow: false,
        items: [{
            align: 'left',
            xtype: 'button',
            text: 'resend selected',
            handler: 'onReSend',
        }],
    },

    bind: {
        store: 'Mongos',
    },

    columns: [{
        xtype: 'checkcolumn',
        headerCheckbox: true,
        dataIndex: 'isChecked',
        text: 'Selection',

    }, {
        text: 'ID',
        dataIndex: '_id',
    }, {
        text: 'companyId',
        dataIndex: 'companyId',
    }, {
        text: 'Queue Name',
        dataIndex: 'queueName',
    }, {
        text: 'Error message',
        dataIndex: 'error.message',
        renderer(value) {
            if (Ext.isObject(value)) {
                return JSON.stringify(value);
            }
            return value;
        },
        flex: 1,
    }, {
        text: 'Subject',
        dataIndex: 'message.options.subject',
        flex: 1,
    }, {
        text: 'To',
        dataIndex: 'message.options.to[0].email',
    }, {
        text: 'Error message prompt',
        dataIndex: 'error.message.prompt',
        flex: 1,
    }, {
        text: 'dtCreated',
        dataIndex: 'dtCreated',
    }, {
        text: '',
        cell: {
            xtype: 'widgetcell',
            widget: {
                xtype: 'button',
                text: 'Info',
                handler: 'onInfoButtonTap',
            },
        },
    }],
    dynamicEventHandlers: ['onInfoButtonTap'],
});

/**
 * @private
 */
const PromptValue = require('client/app/FieldServices/modules/promptValue');

Ext.define('Shared.Components.field.TimeField', {
    extend: 'Ext.field.Select',
    xtype: 'powerednow.timefield',

    config: {
        increment: 15,
        format: 'G:i', // Use format: 'g:i A' for AM/PM format
        minValue: null,
        maxValue: null,
        useDate: true,
    },

    initDate: new Date(2008, 0, 1),
    initDateStr: '1/1/2008',
    initDateFormat: 'j/n/Y',

    initialize() {
        this.callParent();
        this.refreshOptions();
    },

    reConfigureItems() {
        if (!this.isConfiguring) {
            this.refreshOptions();
        }
    },
    applyLabel(value) {
        if (value instanceof PromptValue) {
            return value.translatedValue;
        }
        return value;
    },
    updateIncrement() {
        this.reConfigureItems();
    },

    updateMinValue() {
        this.reConfigureItems();
    },

    updateMaxValue() {
        this.reConfigureItems();
    },

    applyMinValue(value) {
        let newValue = value;
        if (typeof value === 'string') {
            newValue = this._convertStringValueToDate(value);
        }
        return newValue;
    },

    applyMaxValue(value) {
        let newValue = value;
        if (typeof value === 'string') {
            newValue = this._convertStringValueToDate(value);
        }
        return newValue;
    },

    applyValue(value) {
        let record = null;
        let h; let m; let range; let len; let i; let item; let 
            d;

        if (value) {
            if (value.isModel) {
                value = value.data.value;
            }
            if (typeof value === 'string') {
                value = this._convertStringValueToDate(value);
            }
            h = value.getHours();
            m = value.getMinutes();

            range = this.getStore().getRange();

            for (i = 0, len = range.length; i < len; ++i) {
                item = range[i];
                d = item.data.value;
                if (h === d.getHours() && m === d.getMinutes()) {
                    record = item;
                    break;
                }
            }
        }
        return record || value;
    },

    updateValue(value, oldValue) {
        let inputValue;
        let selection;
        if (value && value.isModel) {
            inputValue = value.data.text;
            selection = value;
        } else {
            inputValue = Ext.Date.format(value, this.getFormat());
            selection = null;
        }
        this.setInputValue(inputValue);
        this.setSelection(selection);
        if (!this.isConfiguring) {
            const newValue = value && value.data ? value.data.value : value;
            oldValue = oldValue && oldValue.data ? oldValue.data.value : null;
            this.fireEvent('change', this, newValue, oldValue);
        }
    },

    getValue() {
        let value = this.callParent();
        if (value && value.isModel) {
            value = this.getUseDate() ? value.data.value : value.data.text;
        }
        return value;
    },

    _convertStringValueToDate(stringValue) {
        return Ext.Date.parse(
            `${this.initDateStr} ${stringValue}`,
            `${this.initDateFormat} ${this.getFormat()}`,
        );
    },

    privates: {
        refreshOptions() {
            const me = this;
            const D = Ext.Date;
            const min = me.getMinValue();
            const max = me.getMaxValue();
            const increment = me.getIncrement();
            const format = me.getFormat();
            let current = D.clone(this.initDate);
            const options = [];
            let end;

            if (max) {
                end = D.clone(current);
                end.setHours(max.getHours());
                end.setMinutes(max.getMinutes());
            } else {
                end = D.add(current, D.DAY, 1);
            }

            if (min) {
                current.setHours(min.getHours());
                current.setMinutes(min.getMinutes());
            }

            while (current < end) {
                options.push({
                    value: current,
                    text: D.format(current, format),
                });
                current = D.add(current, D.MINUTE, increment);
            }
            me.setOptions(options);
        },
    },
});

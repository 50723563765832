Ext.define('FieldServices.view.company.document.DocumentViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.documentViewController',
    xtype: 'documentViewController',

    control: {
        documentListTab: {},

    },

    config: {
        companyId: null,
    },
});

Ext.define('FieldServices.view.report.cohort.CohortMainView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.report.cohort.CohortMainViewController',
        'FieldServices.view.report.cohort.CohortContentView',
    ],

    xtype: 'cohortMainView',

    controller: 'cohortMainViewController',

    layout: 'fit',

    items: [{
        xtype: 'cohortContentView',
        itemId: 'cohortContentView',
    }],

});

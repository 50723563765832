Ext.define('FieldServices.view.components.helpTool.HelpToolContentViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.helpToolContentViewController',
    xtype: 'helpToolContentViewController',

    onClose() {
        this._closePopup();
    },

    _closePopup() {
        this.getView().hide();
    },

});

// Shared
import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { ADMIN_AUDIT_TRAIL } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.company.purchases.subscriptions.details.DetailsViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.subscriptionsDetailsViewController',
    xtype: 'subscriptionsDetailsViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        closeButton: { tap: 'onTapCloseButton' },
        infoButton: { tap: 'onInfoButton' },
        subscriptionContent: true,
        eventHistoryList: {
            painted: '_loadEventHistory',
        },
        submitDeleteForm: { tap: 'onSubmitDeleteForm' },
        submitExpiresDateButton: { tap: 'onSubmitExpiresDateButton' },
        submitRefundButton: { tap: 'onSubmitRefundButton' },
    },

    config: {
        companyId: null,
        subscriptionRecord: null,
    },

    init(...args) {
        this.callParent(args);
    },

    onCloseSubscriptionsDetailsPanel() {
        this._close();
    },

    onInfoButton() {
        this._loadValidationDetails();
    },

    onTapCloseButton() {
        this._close();
    },

    async onSubmitDeleteForm() {
        const validationResult = await this.getView().down('#deleteForm').validate();
        if (!validationResult.isValid) {
            return;
        }

        const data = this._getRecordAndFormData('deleteForm');

        if (!data.id || !data.company_id) {
            Ext.Msg.alert('alert', 'Empty parameter(s). Please call the developer');
            return;
        }

        await this.showLoader(this.getView());
        const deletingData = {
            id: data.id,
            company_id: data.company_id,
        };
        this._callApi('DELETE', deletingData, ADMIN_AUDIT_TRAIL.EVENT_TYPES.DELETE_SUBSCRIPTION, data)
            .then(() => this._close())
            .catch(err => handleClientError(err))
            .finally(() => {
                this.clearLoader(this.getView());
            });
    },

    async onSubmitExpiresDateButton() {
        const validationResult = await this.getView().down('#expiresDateForm').validate();
        if (!validationResult.isValid) {
            return;
        }
        const data = this._getRecordAndFormData('expiresDateForm');
        const puttingData = {
            id: data.id,
            company_id: data.company_id,
            expires_date: data.new_expires_date,
        };
        if (!data.id || !data.company_id) {
            Ext.Msg.alert('alert', 'Empty parameter(s). Please call the developer');
            return;
        }
        this._callApi('PUT', puttingData, ADMIN_AUDIT_TRAIL.EVENT_TYPES.CHANGE_EXPIRES_DATE, data)
            .then(() => this._close())
            .catch(err => handleClientError(err));
    },

    async onSubmitRefundButton() {
        const validationResult = await this.getView().down('#refundForm').validate();
        if (!validationResult.isValid) {
            return;
        }

        const data = this._getRecordAndFormData('refundForm');

        this._sendEvent(ADMIN_AUDIT_TRAIL.EVENT_TYPES.REFUND_SUBSCRIPTION, data, data).catch(err => handleClientError(err));
    },

    renderEventType(value) {
        return getKeyByValue(ADMIN_AUDIT_TRAIL.EVENT_TYPES, value);
    },

    _callApi(method, detailsData, eventType, purchaseData) {
        return FieldServices.app.callAPIEndpointRequest(method, `api/admindata/company-tables/Purchases/${this.getCompanyId()}`, detailsData)
            .then(response => {
                if (!response) {
                    throw new Error('Bed response in Purchases');
                }
                return this._sendEvent(eventType, purchaseData, detailsData);
            });
    },

    _close() {
        this.getView().close();
    },

    _loadEventHistory() {
        const { data } = this.getSubscriptionRecord();
        FieldServices.app.callAPI({
            method: 'GET',
            url: 'api/admindata/eventLog/list',
            params: { data: JSON.stringify(data) },
        })
            .then(response => {
                const store = Ext.create('Ext.data.Store', {}).setData(response);
                this.getEventHistoryList().setStore(store);
            })
            .catch(err => handleClientError(err));
    },

    _getRecordAndFormData(formId) {
        const data = this.getView().down(`#${formId}`).getValues();
        Object.assign(data, this.getSubscriptionRecord().data);
        return data;
    },

    _sendEvent(eventType, purchaseData, detailsData) {
        return FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/eventLog/add',
            jsonData: {
                event_type: eventType,
                linked_company_id: purchaseData.company_id,
                linked_id: purchaseData.id,
                linked_table_name: 'Purchases',
                note: purchaseData.note,
                details: {
                    data: detailsData,
                },
            },
        });
    },

    updateSubscriptionRecord() {
        const recordData = this.getSubscriptionRecord().data;
        const data = this._removePurchaseSetup(recordData);
        this.getView().getViewModel().setData(data);
        this._setSubscriptionDataToView();
    },

    _removePurchaseSetup(recordData, includeKeys = ['PurchaseSetupIOS.', 'PurchaseSetupAndroid.']) {
        return Object.keys(this.getSubscriptionRecord().data).reduce((prev, key) => {
            if (includeKeys.every(includeKey => !key.includes(includeKey))) {
                Object.assign(prev, { [key]: recordData[key] });
            }
            return prev;
        }, {});
    },

    _setSubscriptionDataToView() {
        const { data } = this.getSubscriptionRecord();
        const echoKeys = ['id', 'product', 'expiresDateView', 'purchaseDateView', 'purchaseOriginName'];
        const html = this._getShowHtmlData(echoKeys.reduce((prev, key) => {
            Object.assign(prev, { [key]: data[key] });
            return prev;
        }, {}));
        this.getView().down('#subscriptionData').setHtml(html);
    },

    _loadValidationDetails() {
        const recordData = this.getSubscriptionRecord().data;
        if (!recordData) {
            return;
        }
        if (!recordData.companyId) {
            recordData.companyId = recordData.company_id;
        }
        this.showLoader(this.getView());
        FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/company/checkValidationDetails/0',
            jsonData: recordData,
        })
            .then(response => {
                this.clearLoader(this.getView());

                const showData = this._getShowHtmlData(response);

                const itemId = 'subscriptionInfo';
                const subInfo = this.getView().down(`#${itemId}`);
                subInfo.setHtml(`<pre>${showData}</pre>`);
                this.getSubscriptionContent().setActiveItem(0);
            })
            .catch(err => handleClientError(err));
    },

    _getShowHtmlData(rawData) {
        let showData = {};
        try {
            showData = JSON.stringify(rawData, null, '\t');
        } catch (err) {
            showData = { error: err.message };
            handleClientError(err);
        }
        return showData;
    },

});

Ext.define('FieldServices.overrides.ViewController', {
    override: 'Ext.app.ViewController',
    requires: [
        'Shared.mixins.controllerConfig', 'Shared.mixins.getterGenerator',
    ],
    mixins: ['Shared.mixins.controllerConfig', 'Shared.mixins.getterGenerator'],

}, function () {
    const Controller = this;

    Controller.onExtended((cls, data) => {
        if (!data.alias) {
            data.alias = `controller.${cls.$className}`;
        }
    });
});

Ext.define('FieldServices.view.report.cohort.CohortContentView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.report.cohort.CohortContentViewController',
        'FieldServices.view.report.cohort.filter.CohortFilterView',
        'FieldServices.view.report.cohort.CohortReportViewModel',
        'Ext.chart.axis.Category',
        'Ext.chart.axis.Numeric',
        'Ext.chart.series.Area',
        'Ext.chart.series.Line',
        'Ext.chart.interactions.CrossZoom',
        'Ext.chart.interactions.ItemHighlight',
    ],

    xtype: 'cohortContentView',

    controller: 'cohortContentViewController',
    viewModel: 'cohortReport',
    listeners: {
        painted: 'onPaintedCohortContentView',
    },

    layout: {
        type: 'vbox',
        align: 'stretch',
    },

    items: [
        {
            xtype: 'cohortFilterView',
            itemId: 'cohortFilterView',
        },
        {
            xtype: 'titlebar',
            title: 'Report Results',
            items: [
                {
                    xtype: 'formpanel',
                    layout: 'hbox',
                    itemId: 'dataModeSelector',
                    defaults: {
                        labelWidth: 'auto',
                        listeners: {
                            check: 'updateDataMode',
                        },
                        bind: {
                            disabled: '{!reportTableColumns.length}',
                        },
                    },
                    items: [
                        {
                            xtype: 'radiofield',
                            name: 'valueKey',
                            value: CONSTANTS.COHORTS.TABS.SUB,
                            label: 'Subscriptions',
                            checked: true,
                        }, {
                            xtype: 'radiofield',
                            name: 'valueKey',
                            value: CONSTANTS.COHORTS.TABS.CANCELLED,
                            label: 'Cancelled',
                        },
                        {
                            xtype: 'radiofield',
                            name: 'valueKey',
                            value: CONSTANTS.COHORTS.TABS.NET,
                            label: 'Net',
                        },
                    ],
                },
                {
                    xtype: 'formpanel',
                    align: 'right',
                    layout: 'hbox',
                    items: [
                        {
                            xtype: 'checkboxfield',
                            itemId: 'isChartCheckbox',
                            name: 'isChart',
                            label: 'show chart',
                            labelWidth: 'auto',
                            bind: {
                                checked: '{showChart}',
                                disabled: '{!reportTableColumns.length}',
                            },
                        },
                        {
                            xtype: 'button',
                            text: 'xlsx',
                            iconCls: 'ion-document',
                            handler: 'onDownloadXlsFile',
                            bind: { disabled: '{!reportTableColumns.length}' },
                        },
                    ],
                },

            ],
        },
        {
            xtype: 'container',
            layout: {
                type: 'hbox',
            },
            flex: 1,
            items: [
                {
                    xtype: 'grid',
                    itemId: 'cohortContentTable',
                    flex: 1,
                    selectable: false,
                    plugins: [{
                        type: 'gridcolumnresizing',
                    }, {
                        type: 'gridexporter',
                    }],

                    bind: {
                        emptyText: '{emptyText}',
                        store: '{reportTableStore}',
                        columns: '{reportTableColumns}',
                    },
                },
                {
                    xtype: 'cartesian',
                    itemId: 'chortContentChart',
                    flex: 1,
                    interactions: ['crosszoom'],
                    legend: {
                        type: 'sprite',
                        position: 'bottom',
                        marker: {
                            size: 15,
                        },
                    },
                    bind: {
                        hidden: '{!showChart || !reportTableColumns.length}',
                        store: '{chartStore}',
                        axes: '{chartAxes}',
                        series: '{chartSeries}',
                    },
                },
            ],
        },
    ],
});

module.exports = [require('./period.tpl.html'), {
    getSign(growthPercent) {
        const signs = {
            [-1]: 'minus',
            0: 'none',
            1: 'plus',
        };
        return signs[Math.sign(growthPercent)];
    },
}];

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollection.components.LogoCollectionGrid', {
    extend: 'Ext.grid.Grid',
    xtype: 'logoCollection.grid',
    requires: ['Ext.grid.cell.Cell',
        'Ext.grid.plugin.ColumnResizing',
        'Ext.grid.plugin.RowExpander',
    ],
    bind: {
        store: '{logoCollection}',
        hidden: '{noGrid}',
    },
    scrollable: true,
    itemId: 'logoCollectionGrid',
    selModel: 'rowmodel',

    bufferedRenderer: true,

    reference: 'logoCollectionGrid',
    plugins: [{
        type: 'columnresizing',
    }, {
        type: 'rowexpander',

    }, {
        type: 'grideditable',
    }],
    columns: [
        {
            text: 'CDNUrl',
            cell: {
                xtype: 'gridcell',
                encodeHtml: false,
            },
            renderer(value, record) {
                return `<img style="height:30px;" src="${record.data.CDNUrl}" />`;
            },
        },
        {
            text: 'name',
            dataIndex: 'name',
            flex: 1,
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Country',
            dataIndex: 'country_id',
            width: 150,
            renderer: 'countryRenderer',
        },
        {
            text: 'Category',
            dataIndex: 'category',
            width: 200,
            renderer: 'logoCollectionCategoryRenderer',
        },
    ],
});

Ext.define('FieldServices.view.company.info.activityInfo.ActivityInfoView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.company.info.activityInfo.ActivityInfoViewController',
        'FieldServices.view.company.info.activityInfo.ActivityInfoViewModel',
    ],

    viewModel: 'activityInfoViewModel',

    alias: 'widget.activityInfoView',
    xtype: 'activityInfoView',

    controller: 'activityInfoViewController',

    layout: 'hbox',

    title: 'Activity',
    bodyCls: ['verticalOnMobile'],
    items: [
        {
            flex: '1 1 auto',
            xtype: 'dataview',
            itemId: 'normal',
            bind: {
                store: '{normalStore}',
            },
            itemTpl: require('./normal.tpl.html'),
        },
        {
            flex: '1 1 auto',
            xtype: 'dataview',
            itemId: 'period',
            bind: {
                store: '{periodStore}',
            },
            itemTpl: require('./period.tpl.js'),
        },
    ],
});

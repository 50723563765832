Ext.define('FieldServices.overrides.CartesianChart', {
    override: 'Ext.chart.CartesianChart',

    performLayout() {
        if (!this.isPainted()) {
            if (!this.events || !this.events.painted || !this.events.painted.listeners.length) {
                this.on('painted', this.performLayout, this, { single: true });
            }
            return;
        }
        this.callParent();
    },
});

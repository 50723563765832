/**
 * Created by steveetm1 on 08/08/15.
 */
import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.Countries', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.proxy.PoweredNowApi'],
    alias: 'store.countries',
    config: {
        autoLoad: false,
        storeId: 'countries',
    },
    storeId: 'countries',

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/country`,
    },
});

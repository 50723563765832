/**
 * Created by steveetm1 on 08/08/15.
 */
import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.Languages', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.proxy.PoweredNowApi'],

    alias: 'store.languages',
    config: {
        storeId: 'languages',
        autoLoad: false,
    },
    storeId: 'languages',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/global/language`,
    },
});

import ADMIN_CONSTANTS from 'client/admin/constants';
import ACTION_CONSTANTS from '@powerednow/shared/constants/action';

Ext.define('FieldServices.view.company.appointment.list.AppointmentListView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.company.appointment.list.AppointmentListItemViewModel',
        'FieldServices.view.company.appointment.list.AppointmentListViewController',
        'FieldServices.view.company.appointment.details.AppointmentDetailsView',
    ],

    xtype: 'appointmentListView',

    controller: 'appointmentListController',

    itemConfig: {
        viewModel: {
            type: 'appointmentListItemViewModel',
        },
    },

    titleBar: {
        shadow: false,
    },

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
        id: 'appointmentFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/company-tables/Action',
            params: {
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }, {
                    property: 'type',
                    operator: 'in',
                    value: [
                        ACTION_CONSTANTS.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                        ACTION_CONSTANTS.ACTION_TYPES.ACTION_TYPE_HOLIDAY_SICKNESS_OR_OTHER,
                        ACTION_CONSTANTS.ACTION_TYPES.ACTION_TYPE_SIMPLE,
                        ACTION_CONSTANTS.ACTION_TYPES.ACTION_TYPE_REMINDER,
                    ],
                }],
                requiredFields: ['company_id', 'isdeleted'],
            },
        },
        additionalFilterFields: [{
            xtype: 'selectfield',
            property: 'type',
            label: 'Type:',
            operator: '=',
            options: [],
            required: false,
        }],
        dateRangeFilters: [{
            property: 'dt_created',
            label: 'Created:',
        }],
    }, {
        type: 'gridexporter',
    }],

    emptyText: 'empty',

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'Action',
            dataIndex: 'action',
            minWidth: '250',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        }, {
            text: 'Project id',
            dataIndex: 'job_id',
            minWidth: '120',
        }, {
            text: 'Project name',
            dataIndex: 'job_id',
            minWidth: '250',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
            cell: {
                bind: {
                    value: '{jobName}',
                },
            },
        }, {
            text: 'Customer name',
            dataIndex: 'job_id',
            minWidth: '250',
            gridWithFilter: {
                skipFromFilter: true,
            },
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
            cell: {
                bind: {
                    value: '{customerName}',
                },
            },
        }, {
            text: 'Customer id',
            dataIndex: 'job_id',
            minWidth: '120',
            gridWithFilter: {
                skipFromFilter: true,
            },
            cell: {
                bind: {
                    value: '{customerId}',
                },
            },
        }, {
            text: 'Type',
            dataIndex: 'type',
            minWidth: '180',
            cell: {
                bind: {
                    value: '{typeString}',
                },
            },
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'Appointment type',
            dataIndex: 'appointmenttype_id',
            minWidth: '180',
            renderer: (value, appointmentData) => {
                const store = Ext.getStore('AppointmentType');
                const record = store.findRecord('id', value);
                const appointmentType = record?.data.name ? record.data.name : appointmentData.data.action; 
                return Ext.util.Format.htmlDecode(appointmentType);
            },

            gridWithFilter: {
                searchValueMap: () => Ext.getStore('AppointmentType').getRecords().reduce((searchMap, typeRecord) => {
                    const { name, id } = typeRecord.data;
                    searchMap[name] = id;
                    return searchMap;
                }, {}),
            },
        }, {
            text: 'Start',
            dataIndex: 'dt_start',
            minWidth: '150',
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'End',
            dataIndex: 'dt_end',
            minWidth: '150',
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'Created',
            dataIndex: 'dt_created',
            minWidth: '150',
            gridWithFilter: {
                skipFromFilter: true,
            },
        }, {
            text: 'Last activity',
            dataIndex: 'job_id',
            minWidth: '150',
            gridWithFilter: {
                skipFromFilter: true,
            },
            cell: {
                bind: {
                    value: '{jobLastActivity}',
                },
            },
        }, {
            text: 'Is sent',
            dataIndex: 'issent',
            renderer: 'rendererIsSent',
            minWidth: '120',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isSentString}',
                },
            },
        }, {
            text: 'Is deleted',
            dataIndex: 'isdeleted',
            renderer: 'rendererIsDeleted',
            minWidth: '120',
            gridWithFilter: {
                searchValueMap: ADMIN_CONSTANTS.SEARCH_VALUE_MAP.BOOLEAN,
            },
            cell: {
                bind: {
                    value: '{isDeletedString}',
                },
            },
        }, {
            text: '',
            minWidth: '100',
            gridWithFilter: {
                skipFromFilter: true,
            },
            cell: {
                xtype: 'widgetcell',
                widget: {
                    xtype: 'button',
                    text: 'Info',
                    handler: 'onInfoButtonTap',
                },
            },
        }, {
            text: '',
            minWidth: '100',
            gridWithFilter: {
                skipFromFilter: true,
            },
            cell: {
                xtype: 'widgetcell',
                widget: {
                    xtype: 'button',
                    text: 'Restore',
                    handler: 'onRestoreButtonTap',
                    bind: {
                        hidden: '{!record.isdeleted}',
                    },
                },
            },
        },
    ],
});

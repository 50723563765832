/**
 * Created by steveetm1 on 11/08/15.
 */
Ext.define('FieldServices.view.system.systemViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.systemViewModel',

    data: {

    },
});

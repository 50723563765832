Ext.define('FieldServices.view.report.action.PurchaseActionDetailView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.report.action.detail.PurchaseActionDetailFormView',
        'FieldServices.view.report.action.detail.PurchaseActionDetailListView',
        'FieldServices.view.report.action.PurchaseActionDetailViewController',
    ],

    xtype: 'purchaseActionDetailView',
    alias: 'widget.purchaseActionDetailView',

    controller: 'purchaseActionDetailViewController',

    layout: 'vbox',
    // flex: 1,

    items: [
        {
            xtype: 'purchaseActionDetailFormView',
            itemId: 'purchaseActionDetailFormView',
        }, {
            xtype: 'purchaseActionDetailListView',
            itemId: 'purchaseActionDetailListView',
            flex: 2,
        }],

});

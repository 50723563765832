require('./FieldCheckbox.scss');

Ext.define('FieldServices.overrides.FieldCheckbox', {
    override: 'Ext.field.Checkbox',

    getFilters() {
        const filters = [];
        const property = this.fieldName ? this.fieldName : this.getName();
        const value = this.filterValue ? this.filterValue : this.getValue();
        const operator = this.filterOperator;
        if (this.isFilter !== false && this.isChecked() && operator) {
            filters.push({
                value, operator, property, isDisabled: !!this.getDisabled(),
            });
        }
        return filters;
    },
});

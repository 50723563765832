const _ = require('lodash');
const moment = require('moment');

export default Ext.define('FieldServices.view.report.cohort.filter.CohortFilterViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.cohortFilterViewController',
    xtype: 'cohortFilterViewController',

    control: {
        purchaseSetupSelectField: true,
        businessTypesIdField: true,
        countrySelectField: true,
        partnerSelectField: true,
        dateRangeFilter: true,
        filterButton: {
            tap: 'onPressFilterInCohort',
        },
        contentFilterButtons: {
            selector: '[valueKey]',
            tap: 'onLoadDataBtn',
        },
        cohortsFormat: {
            change: 'onCohortsFormatChange',
        },
        '#': {
            activate: 'onActivate',
        },
    },

    config: {
        UK_Id: 234,
        selectDefaultValue: {
            text: 'Any',
            value: '',
        },
        dateRangeNames: ['from', 'to'],
        outputDateFormat: 'YYYY-MM-DD HH:mm:ss',
    },

    init(...args) {
        this.callParent(args);
    },

    getSelectDefaultValue() {
        return Ext.clone(this.callParent());
    },

    onActivate() {
        this._setDateRangeFields();
        this._setCohortsFormat();
        this._setPurchaseSetupField();
        this._setCountryField();
        this._setBusinessTypesIdField();
        this._loadFiltersDataFromDb();
    },

    onPressFilterInCohort() {
        const filterData = this.getView().getFilters();

        this.getView().fireEvent('changeFilterInCohort', { filterData });
    },

    onLoadDataBtn(elem) {
        this.getView().fireEvent('tapOtherButton', {
            buttonItemId: elem.getItemId(),
            columnTemplate: _.get(elem, 'config.columnTemplate'),
            valueKey: _.get(elem, 'config.valueKey'),
        });
    },

    onCohortsFormatChange(elem) {
        const dateRangeUnit = elem.getSelection().getData().text;
        const data = CONSTANTS.COHORTS.TYPE[dateRangeUnit];

        this.getDateRangeNames().forEach(name => {
            this._setDateFieldUnit(name, data);
        });
    },

    _setDateFieldUnit(name, data) {
        this.getDateRangeFilter().setDateFormat(data.dateFormat);
    },

    _setDateRangeFields() {
        const fromDate = moment().subtract(1, 'years').startOf('day');
        const toDate = moment().startOf('day');
        this.getDateRangeFilter().setDateRange({ from: fromDate, to: toDate });
    },

    _setCohortsFormat() {
        const options = Object.keys(CONSTANTS.COHORTS.TYPE).reduce((prev, key) => [...prev, {
            text: key,
            value: CONSTANTS.COHORTS.TYPE[key].queryFormat,
        }], []);

        const cohortsFormatView = this.getView().down('#cohortsFormat');
        cohortsFormatView.setOptions(options);
        cohortsFormatView.setValue('%Y-%m');
    },

    _setPurchaseSetupField() {
        Ext.getStore('PurchaseSetup').waitToLoad()
            .then(purchaseSetup => {
                if (!purchaseSetup) {
                    return;
                }
                const consumption = CONSTANTS.PURCHASE_SETUP.TYPE.CONSUMPTION;
                const subscriptions = purchaseSetup.filter(item => item.getData().type !== consumption);
                const options = subscriptions.reduce((prev, item) => [...prev, {
                    text: `${item.getData().product_name} - ${item.getData().ios_product_id}`,
                    value: `^${item.getData().ios_product_id}$|^${item.getData().android_product_id}$`,
                }], this._getDefaultPurchaseSetup());

                this.getPurchaseSetupSelectField().setOptions(options);
                this.getPurchaseSetupSelectField().setValue(this.getPurchaseSetupSelectField().getStore().getAt(0));
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _getDefaultPurchaseSetup() {
        return [
            this.getSelectDefaultValue(),
            ...this._getFixPurchaseSetupSelectFields(),
        ];
    },

    _getFixPurchaseSetupSelectFields() {
        return Object.keys(CONSTANTS.PURCHASE_SETUP.GROUPS).map(key => ({
            text: CONSTANTS.PURCHASE_SETUP.GROUPS[key].text,
            value: CONSTANTS.PURCHASE_SETUP.GROUPS[key].MATCH,
        }));
    },

    _setBusinessTypesIdField() {
        Ext.getStore('BusinessTypes').waitToLoad()
            .then(businessTypes => {
                if (!businessTypes) {
                    return;
                }
                const options = businessTypes.reduce((prev, item) => [...prev, {
                    text: `${item.getData().type}`,
                    value: item.getData().id,
                }], [this.getSelectDefaultValue()]);

                this.getBusinessTypesIdField().setOptions(options);
                this.getBusinessTypesIdField().setValue(this.getBusinessTypesIdField().getStore().getAt(0));
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _setCountryField() {
        Ext.getStore('Country').waitToLoad()
            .then(country => {
                if (!country) {
                    return;
                }

                country.sort((a, b) => String(b.name || '').localeCompare(a.name));

                const options = country.reduce((prev, item) => [...prev, {
                    text: `${item.getData().name} - ${item.getData().code}`,
                    value: item.getData().id,
                }], [this.getSelectDefaultValue()]);

                this.getCountrySelectField().setOptions(options);
                this.getCountrySelectField().setValue(this.getUK_Id());
            })
            .catch(err => {
                handleClientError(err);
            });
    },

    _loadFiltersDataFromDb() {
        this._callSalesApi('mediaSource', 'mediaSourceSelectField');
        this._callSalesApi('adSetName', 'adSetNameSelectField');
        this._callSalesApi('teamSize', 'teamSizeField');
    },

    _callSalesApi(groupName, itemId) {
        const method = 'getCompanyByGroupName';
        const view = this.getView();
        return FieldServices.app.callAPI({
            url: `api/admindata/sales/${method}`,
            method: 'GET',
            params: {
                groupName: JSON.stringify(groupName),
            },
            headers: {},
        })
            .then(response => {
                if (!response) {
                    return;
                }
                const options = response
                    .filter(item => item[groupName] !== '')
                    .reduce((prev, item) => [...prev, {
                        text: item[groupName],
                        value: item[groupName],
                    }], [this.getSelectDefaultValue()]);
                const selectField = view.down(`#${itemId}`);
                selectField.setOptions(options);
                selectField.setValue(selectField.getStore().getAt(0));
            })
            .catch(err => {
                handleClientError(err);
            });
    },

});

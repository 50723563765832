Ext.define('FieldServices.view.components.consumerError.ConsumerErrorView', {
    extend: 'Ext.panel.Panel',

    xtype: 'consumerErrorView',

    layout: 'vbox',
    flex: 1,

    items: [{
        xtype: 'title',
        margin: 10,
        bind: {
            title: '{errorMessage}',
        },
    }, {
        itemId: 'errorStack',
        xtype: 'textareafield',
        grow: true,
        anchor: '100%',
        margin: 10,
        height: 300,
        readOnly: true,
        bind: {
            value: '{errorStack}',
        },
    }],
});

Ext.define('FieldServices.view.company.select.FindCompanyViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.findCompanyCtrl',
    xtype: 'findCompanyCtrl',

    config: {
        filterRename: {
            company_id: 'id',
            company_email: 'email',
        },
    },

    async getConditionalFilters() {
        const filters = [];
        const isCompany = this._isCompany();
        const isWhitelabelAdmin = !(await AuthManager.userHasRole(CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_ANY_WHITELABEL));
        const whiteLabelAdminFilter = {
            property: 'whitelabel_id',
            operator: '=',
            value: AuthManager.getCurrentAdminData().companyWhiteLabel,
        };
        if (isWhitelabelAdmin && isCompany) {
            filters.push(whiteLabelAdminFilter);
        }

        if (!isCompany) {
            filters.push({
                property: 'isdeleted',
                operator: 'in',
                value: [true, false],
            });
        }

        if (!isCompany && isWhitelabelAdmin) {
            const companyToFilter = await Ext.getStore('CompanyListByCompany');
            companyToFilter.filter(whiteLabelAdminFilter);
            await companyToFilter.waitToLoad();
            const allowedCompanyIds = companyToFilter.getRecords().map(record => record.get('id'));
            filters.push({
                property: 'company_id',
                operator: 'in',
                value: allowedCompanyIds,
            });
        }
        return filters;
    },

    async onSetCompanySubmit(...args) {
        const filters = [];
        const isCompany = this._isCompany();

        const formValues = this.getView().getValues();
        let isEmptyForm = true;

        Object.keys(formValues).forEach(key => {
            const rawValue = (formValues[key] || '').trim();
            if (rawValue) {
                isEmptyForm = false;

                const isId = key === 'company_id';
                const value = isId ? rawValue : encodeURIComponent(`%${rawValue}%`);
                const operator = isId ? '=' : 'like';
                filters.push({
                    //
                    // "id" cannot use as name for textfield in form.Panel, because extJS will submit the form on enter key press.
                    //
                    property: this.getFilterRename()[key] ? this.getFilterRename()[key] : key,
                    value,
                    operator,
                });
            }
        });
        const conditionalFilters = await this.getConditionalFilters();
        filters.push(...conditionalFilters);

        if (isEmptyForm) {
            Ext.Msg.alert('alert', 'The company form is empty.');
            return;
        }

        const componentName = isCompany ? 'Company' : 'User';
        const store = this.getView()
            .up()
            .down(`#selectCompanyList${componentName}`)
            .getStore();

        store.clearFilter();
        store.filter(filters);
        this.getView().fireEvent('search');
    },

    _isCompany() {
        const fields = this.getView().getFields();
        return Object.keys(fields).some(key => {
            const field = fields[key];
            return field.getValue() !== '' && field.isCompany === true;
        });
    },

});

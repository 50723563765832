import EmailValidator from '@powerednow/shared/modules/validation/emailValidator';

Ext.define('FieldServices.view.company.settings.productExport.ProductExportViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.productExportViewModel',

    data: {
        exportEmail: '',
    },
    emailValidator: new EmailValidator(),
    formulas: {
        exportBtnDisabled: {
            bind: '{exportEmail}',
            get(email) {
                return email === '' || !this.emailValidator.isValidEmail(email);
            },
        },
    },
});

import WhiteLabelConstants from '@powerednow/shared/constants/whiteLabel';

Ext.define('FieldServices.view.whiteLabel.whiteLabelListView', {
    extend: 'Ext.grid.Grid',
    xtype: 'whiteLabelListView',
    title: 'White Label Partners',
    layout: 'fit',

    flex: 1,

    titleBar: {
        items: [{
            align: 'right',
            xtype: 'button',
            text: 'Create New Partner',
            handler: 'onCreateNewTap',
        }],
    },

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            flex: 1,
        },
        {
            text: 'Name',
            dataIndex: 'name',
            flex: 1,
        },
        {
            text: 'Status',
            dataIndex: 'whitelabel_status',
            flex: 1,
            renderer: statusId => WhiteLabelConstants.STATUS[statusId.toString()],
        },
        {
            text: 'Desktop url',
            dataIndex: 'desktop_url',
            flex: 1,
        },
    ],
});

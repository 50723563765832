import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.AppointmentType', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.AppointmentType'],
    alias: 'store.AppointmentType',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.AppointmentType',
        storeId: 'AppointmentType',
        remoteFilter: true,
        remoteSort: true,
    },

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/AppointmentType`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },
});

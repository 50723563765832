Ext.define('FieldServices.view.company.user.UsersViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.userViewController',
    xtype: 'userViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '#': {
            setSubPage: 'setSubPage',
            painted: 'onPainted',
        },
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this._loadOnlineUsers, this);
    },

    async onPainted() {
        await this._loadOnlineUsers();
    },

    async _loadOnlineUsers() {
        const onlineUsers = await Utilities.getOnlineUsers(this.getViewModel().get('company.id'));
        this.getView().down('#onlineUsers').setData({
            json4: JSON.stringify(onlineUsers, null, 4),
        });
        this.getViewModel().setData({ onlineUsersCount: onlineUsers.length });
    },

});

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollectionCategory.components.LogoCollectionCategoryForm', {
    extend: 'Ext.form.FieldSet',
    xtype: 'logoCollectionCategory.fieldset',
    layout: {
        type: 'hbox',
        align: 'stretchmax',
    },
    defaults: {
        padding: 10,
    },
    fileUpload: true,
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            items: [{
                xtype: 'textfield',
                itemId: 'name',
                bind: {
                    value: '{currentLogoCollectionCategory.name}',
                },
                fieldLabel: 'name',
            }, {
                xtype: 'container',
                layout: 'hbox',
                items: [{
                    xtype: 'button',
                    bind: {
                        text: '{buttonText}',
                    },
                    cls: [
                        'btn',
                        'btn--primary',
                    ],
                    itemId: 'actionBtn',
                }, {
                    xtype: 'button',
                    text: 'Clear selection',
                    bind: {
                        hidden: '{!updateMode}',
                    },
                    cls: [
                        'btn',
                        'btn--primary',
                    ],
                    itemId: 'clearBtn',
                }],
            }],
        },
    ],
});

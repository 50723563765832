Ext.define('FieldServices.view.BaseViewController', {
    extend: 'Ext.app.ViewController',

    /**
     *  * Masks the list and prevents interaction with it. (loading spinner)
     *
     * @param list - List element you want to mask
     * @param expires - default value 360 (4m)
     */
    async showLoader(list, expires = 360) {
        await this._requestAnimationFrame();
        list.setMasked({
            xtype: 'loadmask',
        });

        if (expires > 0) {
            setTimeout(() => {
                this.clearLoader(list);
            }, expires * 1000);
        }
        await this._requestAnimationFrame();
    },

    _requestAnimationFrame() {
        return new Promise((resolve, reject) => {
            window.requestAnimationFrame(resolve);
        });
    },

    /**
     * Clears the mask from the list and enables interaction.
     *
     * @param list - List element you want to remove the mask from
     */
    async clearLoader(list) {
        await this._requestAnimationFrame();
        list.setMasked(false);
    },

    /**
     * get form values from view
     * @param formView
     * @return {*}
     */
    getFromValueAsArray(formView) {
        const formDataValues = formView.getValues();
        return Object.keys(formDataValues).reduce((filters, key) => {
            if (formDataValues) {
                filters.push({ fieldName: key, value: formDataValues[key] });
            }
            return filters;
        }, []);
    },

    rendererIsDeleted(value, record, title, row) {
        row.toggleClsOnRow('grid_row_isDeleted', (value === true || value === 1 || value === 'true'));
        return value;
    },

    rendererIsActive(value, record, title, row) {
        row.toggleClsOnRow('grid_row_active', (value === false || value === 0 || value === 'false'));
        return value;
    },

    rendererDateToIso(value) {
        return shared.Utils.Renderers.getDateRendererWithoutExt(value, 'Y-m-d H:i:s');
    },

    addIsDeletedClass(value, record, title, row) {
        if (value === true || value === 1) {
            row.addClsToRow('grid_row_isDeleted');
        }
        return value;
    },
});

/*
 * File: app/view/Dashboard.js
 *
 *
 */
import ROLES from '@powerednow/shared/constants/roles';

require('./NavBar.scss');

Ext.define('FieldServices.view.dashboard.navbar.NavBar', {
    extend: 'Ext.SegmentedButton',
    requires: ['FieldServices.view.dashboard.navbar.NavBarController'],
    viewModel: {
        formulas: {
            hideReportTab: {
                bind: '{isVerticalView}',
                async get(isVerticalView) {
                    return isVerticalView || !(await AuthManager.userHasRole(ROLES.PERMISSIONS.CAN_ACCESS_REPORTS));
                },
            },
            hideSystemTab: {
                async get() {
                    return !(await AuthManager.userHasRole(ROLES.PERMISSIONS.CAN_ACCESS_SYSTEM_PAGE));
                },
            },
            isVerticalView: {
                get() {
                    return Ext.Viewport.getOrientation() === 'portrait';
                },
            },
            containerWidth: {
                bind: '{isVerticalView}',
                get(isVerticalView) {
                    return isVerticalView ? '100%' : 95;
                },
            },
            containerLayout: {
                bind: '{isVerticalView}',
                get(isVerticalView) {
                    return {
                        type: isVerticalView ? 'hbox' : 'vbox',
                        pack: 'start',
                        align: 'stretch',
                    };
                },
            },
        },
    },
    controller: 'FieldServices.view.dashboard.navbar.NavBarController',
    xtype: 'dashboard.navbar',
    ui: '-navigation',
    bind: {
        width: '{containerWidth}',
        layout: '{containerLayout}',
        vertical: '{isVertical}',
    },
    itemId: 'navButton',
    defaults: {
        xtype: 'tab',
        iconAlign: 'top',
        ui: [
            '-sideNavigation',
            '-l',
        ],
        flex: null,
        height: 70,
    },

    items: [{
        promptId: 'TabletSideNav.Home',
        iconCls: 'fa-home',
        itemId: 'homePanelBtn',
        text: 'Home',
        hash: 'show/Home/1',
    }, {
        promptId: 'TabletSideNav.Company',
        iconCls: 'fa-building-o',
        itemId: 'companyPanelBtn',
        text: 'Company',
        hash: 'show/Company/0',
    }, {
        promptId: 'TabletSideNav.User',
        itemId: 'logsPanelBtn',
        iconCls: 'fa-file-text-o',
        text: 'Logs',
        hash: 'show/companyLogs/0',
    },
    {
        promptId: 'TabletSideNav.User',
        itemId: 'userPanelBtn',
        iconCls: 'fa-line-chart',
        text: 'Reports',
        hash: 'show/Reports/0',
        bind: {
            hidden: '{hideReportTab}',
        },
    }, {
        promptId: 'TabletSideNav.Log',
        iconCls: 'fa-gears',
        itemId: 'systemBtn',
        text: 'System',
        hash: 'show/systemView/0',
        bind: {
            hidden: '{hideSystemTab}',
        },
    }],
});

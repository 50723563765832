import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.AdminUserProfile', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.UserProfile'],
    alias: 'store.AdminUserProfile',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.UserProfile',
        storeId: 'AdminUserProfile',
        remoteFilter: false,
        remoteSort: true,
        filters: [{
            property: 'internal',
            operator: '=',
            value: true,
        }, {
            property: 'id',
            operator: '!=',
            value: CONSTANTS.USER_PROFILE.PORTAL_USER,
        }],
    },

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/UserProfile`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_NORMAL,
});

Ext.define('FieldServices.view.company.select.find.FindCompanyView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.company.select.find.FindCompanyViewController',
        'FieldServices.view.company.select.find.FindCompanyViewModel',
    ],
    xtype: 'findCompany',
    layout: {
        type: 'vbox',
        align: 'center',
    },
    minHeight: '240px',
    controller: 'findCompanyCtrl',
    viewModel: 'findCompanyViewModel',

    keyMap: {
        ENTER: {
            handler: 'onSetCompanySubmit',
            scope: 'controller',
            event: 'keydown',
        },
    },
    keyMapTarget: 'el',

    defaults: {
        width: '100%',
    },
    items: [{
        xtype: 'container',
        layout: {
            type: 'hbox',
            align: 'stretch',
        },
        defaults: {
            xtype: 'fieldset',
            flex: 1,
            layout: 'vbox',
            defaultType: 'textfield',
            defaults: {
                value: '',
                labelAlign: 'left',
                labelWidth: 150,
                autoComplete: false,
            },
        },
        items: [{
            title: 'by company data',
            items: [{
                //
                // "id" cannot use as name for textfield in form.Panel, because extJS will submit the form on enter key press.
                //
                name: 'company_id',
                label: 'Company ID',
                isCompany: true,
                bind: {
                    disabled: '{isDisabledCompany}',
                    value: '{company_id}',
                },
            }, {
                name: 'name',
                label: 'Company name',
                isCompany: true,
                bind: {
                    disabled: '{isDisabledCompany}',
                    value: '{name}',
                },
            }, {
                name: 'company_email',
                label: 'Company email',
                isCompany: true,
                bind: {
                    disabled: '{isDisabledCompany}',
                    value: '{company_email}',
                },
            }],
        }, {
            title: 'by user data',
            items: [{
                name: 'email',
                label: 'Login email',
                bind: {
                    disabled: '{isDisabledUser}',
                    value: '{email}',
                },
            }, {
                name: 'firstname',
                label: 'First name',
                bind: {
                    disabled: '{isDisabledUser}',
                    value: '{firstname}',
                },
            }, {
                name: 'lastname',
                label: 'Last name',
                bind: {
                    disabled: '{isDisabledUser}',
                    value: '{lastname}',
                },
            }],
        }],
    }, {
        xtype: 'button',
        text: 'Search',
        handler: 'onSetCompanySubmit',
        width: '100%',
    }],
});

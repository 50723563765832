/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.services.Services', {
    extend: 'Ext.panel.Panel',
    xtype: 'services.main',

    requires: [
        'FieldServices.view.services.ServicesController',
        'FieldServices.view.services.ServicesModel',
        'Ext.grid.column.Widget',

    ],
    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },
    title: 'Manage services',
    controller: 'services.main',
    viewModel: {
        type: 'services.main',
    },
    items: [{
        xtype: 'grid',
        columns: [{
            text: 'Service',
            dataIndex: 'service',
        }, {
            text: 'Commands',
            // xtype: 'widgetcolumn',
            width: 150,
            widget: {
                xtype: 'container',
                itemId: 'widgetContainer',
                items: [],
            },
            onWidgetAttach: (column, widget, record) => {
                widget.removeAll();
                if (record.data.commands && record.data.commands.length > 0) {
                    record.data.commands.forEach(command => {
                        widget.add(command);
                    });
                }
            },

        }],
        bind: {
            store: '{services}',
        },
    }],
});

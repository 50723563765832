Ext.define('FieldServices.view.prompt.list.PromptsListController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.prompt.main',

    control: {
        '#filterValue': {
            change: 'onFilterValueChange',
        },
    },

    /**
     * handling filterValue field change's event
     * @param field
     * @param newValue
     */
    onFilterValueChange(field, newValue) {
        this._filterGrid(newValue);
    },

    _filterGrid(filterValue) {
        const filterField = this.lookup('filterField');
        const gridStore = this.lookup('promptsGrid').getStore();
        const filterFieldValue = filterField.getValue();

        if (filterFieldValue === 'all') {
            return;
        }

        if (filterValue === '') {
            this._clearFilters();
            return;
        }

        this._addFilterToStore(gridStore, filterFieldValue, filterValue);
    },

    _addFilterToStore(store, filterProperty, filterValue) {
        if (filterValue) {
            const matcher = new RegExp(Ext.String.escapeRegex(filterValue), 'i');
            store.addFilter({
                id: 'promptListFilter',
                filterFn(record) {
                    return matcher.test(record.get(filterProperty));
                },
                property: filterProperty,
            });
        }
    },

    _clearFilters() {
        const filterValue = this.lookup('filterValue');
        const gridStore = this.lookup('promptsGrid').getStore();

        filterValue.setValue('');
        this._removeAllFiltersFromStore(gridStore);
    },

    _removeAllFiltersFromStore(store) {
        if (store.getFilters()) {
            store.getFilters().each(filter => {
                store.removeFilter(filter);
            });
        }
    },

});

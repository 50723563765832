import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { CONTACT_TO_ADDRESS } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.company.contact.info.ContactInfoViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.contactInfoViewModel',
    xtype: 'contactInfoViewModel',

    data: {
        dataChanged: false,
    },

    formulas: {
        typeName(get) {
            return getKeyByValue(CONTACT_TO_ADDRESS.TYPE, get('record.type'));
        },
        saveButtonClass(get) {
            return get('dataChanged') ? 'priority-button' : '';
        },
    },
});

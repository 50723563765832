/**
 * The main application class. An instance of this class is created by app.js when it
 * calls Ext.application(). This is the ideal place to handle application launch and
 * initialization details.
 */
Ext.define('FieldServices.Application', {
    extend: 'Ext.app.Application',
    mixins: ['Shared.mixins.ApiRequest', 'Shared.mixins.LogoutInactiveUser'],

    name: 'FieldServices',

    requires: [
        'Ext.Toast',
        'Ext.util.LocalStorage',
        'FieldServices.model.override.Model',
        'FieldServices.view.BaseViewModel',
        'FieldServices.view.login.Login',
        'FieldServices.view.dashboard.Dashboard',
        'FieldServices.controller.References',
        'FieldServices.controller.Renderer',
        'FieldServices.controller.Socket',
        'FieldServices.controller.Utilities',
        'FieldServices.controller.AuthManager',

        'Ext.grid.plugin.PagingToolbar',
        'Ext.behavior.Draggable',
        'Ext.window.Toast',
    ],
    stores: [
        'User',
        'Company',
        'CompanySettings',
        'CompanyIntegration',
        'Country',
        'Document',
        'Form',
        'FormDocument',
        'Prompts',
        'Purchases',
        'PurchaseSetup',
        'PurchaseAction',
        'Countries',
        'Languages',
        'LogoCollection',
        'LogoCollectionCategory',
        'Mongos',
        'SubscriptionStats',
        'Feature',
        'Job',
        'BusinessTypes',
        'Message',
        'UserProfile',
        'TableVersion',
        'PurchasesGrouped',
        'PaymentType',
        'PaymentToInvoice',
        'Payment',
        'Customer',
        'Contact',
        'ContactToAddress',
        'ContactMethod',
        'Address',
        'AdminAuditTrail',
        'ConsumptionExtra',
        'PurchaseOrigins',
        'PurchasesInfo',
        'Roles',
        'UserProfileRole',
        'UserUserProfile',
        'UserProfileRoleCustom',
        'UsageHistory',
        'KnownError',
        'Aggregate',
        'WhiteLabel',
        'WhiteLabelMetrics',
        'SettingsKeys',
        'AdminUserProfile',
        'AppointmentType',
    ],
    listeners: {
        'global:storeLoaded': 'anyStoreLoaded',
    },
    forcedRelogin: false,

    anyStoreLoaded(store, records, successful, eOpts) {
        if (!eOpts._response) {
            return;
        }
        const response = Ext.JSON.decode(eOpts._response.responseText);
        if (Number(response.error_code) === 1001) {
            this.forceRelogin();
        }
    },

    forceRelogin() {
        const message = {
            title: 'Authentication error',
            text: 'Authentication failed, please re-login into application',
        };
        if (!this.forcedRelogin) {
            this.forcedRelogin = true;
            shared.Utils.utilities.forceRelogin(message);
        }
    },

    configureInjectors() {
        Deft.Injector.configure({
            socketController: {
                className: 'FieldServices.controller.Socket',
                eager: true,
                singleton: true,
            },
        });
    },

    launch() {
        AuthManager.on('login', ()=> {
            this.startActivityTracking();
        });
        AuthManager.on('logout', ()=> {
            this.stopActivityTracking();
        });

        Ext.Viewport.setViewModel('baseViewModel');
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');
        this.configureInjectors();
        if (adminToken) {
            this.addHeaderToProxies();
            this._showDashboard();
        }
        storage.release();
    },

    /**
     * ServicesController call
     *
     * @param method
     * @param url
     * @param message
     * @returns {Ext.Promise}
     */
    callAPIEndpoint(method, url, message, headers, useAuth = true) {
        return this._callAPIEndpoint({
            method,
            jsonData: message,
            headers,
            url,
            useAuth,
        });
    },

    _callAPIEndpoint({
        url,
        method = 'GET',
        formData,
        jsonData = formData ? null : {},
        headers = {},
        params, timeout = 30000,
        useAuth = true,
    }) {
        if (formData) {
            if (jsonData) {
                throw new Error("Can not send 'formData' and 'jsonData' at the same time!");
            }

            headers['Content-Type'] = null;
        }
        const newHeaders = headers;
        const serverUrl = require('config.js').SERVER_URL;
        const fullUrl = url.indexOf(serverUrl) === 0 ? url : `${serverUrl}${url}`;
        if (useAuth) {
            newHeaders[CONSTANTS.HTTP.AUTHENTICATION_HEADER_TOKEN] = this.getAdminToken();
        }
        const options = {
            url: fullUrl,
            defaultPostHeader: 'application/json',
            headers: newHeaders,
            method,
            params,
            jsonData,
            timeout,
            rawData: formData,
            disableCaching: false,
        };

        return this._request(options);
    },

    callAPI(options) {
        return this._callAPIEndpoint(options);
    },

    /**
     *
     * @param tableName
     * @param companyId
     * @param options {jsonData: ... }
     * @returns {*}
     */
    updateCompanyTable(tableName, companyId, options = {}) {
        if (!tableName || !companyId) {
            throw new Error('Not defined tableName or companyId in updateCompanyTable');
        }
        let url = `api/admindata/company-tables/${tableName}/${companyId}`;
        if (options.url) {
            url = `${url}/${options.url}`;
        }
        const timeout = options.timeout ? options.timeout : CONSTANTS.NETWORK.TIMEOUT.MEDIUM;

        return FieldServices.app.callAPI(Object.assign(options, { method: 'PUT', url, timeout }))
            .catch(err => {
                handleClientError(err);
            });
    },

    getAdminToken() {
        const storage = Ext.util.LocalStorage.get('auth');
        return storage.getItem('adminToken');
    },

    callAPIEndpointRequest(method, api, message, headers) {
        return this.callAPIEndpoint(method, api, message, headers);
    },

    callAPIEndpointGetCsvUrl(method, api, message) {
        const headers = this.getCsvHeaders();
        return this.callAPIEndpointRequest(method, api, message, headers);
    },

    getCsvHeaders() {
        return {
            'x-response-formatter': 'CSV',
            'x-return-value-content-type': 'text/csv',
            'x-return-value-content-disposition': 'attachment',
            'x-request-tmp-url': 'YES',
        };
    },

    addHeaderToProxies() {
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');

        if (adminToken) {
            Ext.StoreManager.items.forEach(store => {
                const proxy = store.getProxy();
                if (proxy && proxy.setHeaders) {
                    proxy.setHeaders({
                        [CONSTANTS.HTTP.AUTHENTICATION_HEADER_TOKEN]: adminToken,
                    });
                }
            });
            Socket.connectSocket();
        } else {
            this.forceRelogin();
        }
        storage.release();
    },

    _showDashboard() {
        References.getLoginPage().setKeyMap(null);
        References.getMainContainer().setActiveItem(References.getDashboard().show());
    },

    goToHome() {
        FieldServices.app.redirectTo('show/Home/');
    },

    onAppUpdate() {
        Ext.Msg.confirm('Application Update', 'This application has an update, reload?', choice => {
            if (choice === 'yes') {
                window.location.reload();
            }
        });
    },

});

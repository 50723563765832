Ext.define('FieldServices.view.report.monthlyReports.MonthlyReportsView', {
    extend: 'Ext.tab.Panel',

    requires: [
        'FieldServices.view.report.monthlyReports.MonthlyReportsViewController',
        'FieldServices.view.report.monthlyReports.MonthlyReportsViewModel',
        'FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedView',
        'FieldServices.view.report.monthlyReports.charts.MonthlyReportsChartsView',
    ],

    alias: 'widget.monthlyReports',

    viewModel: 'monthlyReports',
    controller: 'monthlyReportsViewController',
    defaults: {
        tab: {
            iconAlign: 'top',
            flex: 1,
        },
    },
    header: {
        layout: {
            align: 'stretchmax',
        },
        title: {
            text: 'PNAdmin',
            flex: 0,
        },
        iconCls: 'ion-ios-list-outline',
    },
    tabBar: {
        flex: 1,
        layout: {
            align: 'stretch',
            overflowHandler: 'none',
        },
    },
    ui: 'navigation',
    tabBarPosition: 'top',
    headerPosition: 'top',
    tabBarHeaderPosition: 1,
    titleRotation: 0,
    tabRotation: 0,
    items: [
        {
            title: 'Detailed Reports',
            layout: 'fit',
            items: [{
                xtype: 'monthlyReportsDetailed',
                itemId: 'detailedReportsPage',
            }],
        },
        {
            title: 'Charts',
            layout: 'fit',
            items: [{
                xtype: 'monthlyReportsCharts',
                listeners: {
                    chartItemClick: 'redirectToDetailedReport',
                },
            }],
        },
    ],

});

import ComplexModel from 'shared/modules/complexData/customer';

import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { DOCUMENT } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.model.Customer', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',

    config: {
        fields: [{
            name: 'customerName',
            calculate: recordData => getKeyByValue(DOCUMENT.STATUS, recordData.status),
        }],
    },
});

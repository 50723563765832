/**
 * Created by steveetm1 on 11/08/15.
 */
Ext.define('FieldServices.view.logoCollection.LogoCollectionModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.logoCollection.main',

    stores: {
        logoCollection: {
            type: 'LogoCollection',
            autoLoad: false,
        },
        countries: {
            type: 'countries',
            autoLoad: true,
        },
    },
    formulas: {
        currentLogoCollection: {
            bind: {
                bindTo: '{logoCollectionGrid.selection}',
                deep: true,
            },
            get(logoCollection) {
                if (logoCollection) {
                    return logoCollection.clone();
                }
                return null;
                /**
                 * If we don't want a 'two-way' binding, for example modify the selected records in the form *without* writing back the results to the grid on-the-fly, then we must
                 * clone the data propery from logoCollection.
                 *
                 * Cloning the logoCollection object is NOT working!
                 */
                // return Ext.clone(logoCollection.data);
            },
        },
        /**
         * You can use this in binding configuration to check wheter the state of the view is in update(record selected in grid)
         * @param get function to query formulas and data from viewModel
         * @returns {boolean}
         */
        updateMode(get) {
            return Boolean(get('currentLogoCollection'));
        },
        /**
         * Dynamically modify the text on the actionButton to represent the state of the view.
         * Not too much sense btw, demo purposes right now.
         *
         * @param get function to query formulas and data from viewModel
         * @returns {*}
         */
        buttonText(get) {
            if ((get('currentLogoCollection'))) {
                return 'Update logoCollection record';
            }
            return 'Save new logoCollection record';
        },

        uploadButtonText(get) {
            if ((get('currentLogoCollection'))) {
                return 'Update image';
            }
            return 'Upload image';
        },
    },
    data: {
        /**
         * By default we want to show both the grid and the form in our views.
         * Pass the noGrid config variable upon component creation to hide the grid.
         */
        noGrid: false,
    },
});

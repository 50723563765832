Ext.define('FieldServices.overrides.grid.Row', {
    override: 'Ext.grid.Row',

    refresh(context) {
        const contextToUpdate = context || {};
        this.callParent([contextToUpdate]);

        if (this.onRowRefresh) {
            this.onRowRefresh(contextToUpdate.record);
        }
    },
});

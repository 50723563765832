export class UserActivityMonitor {
    constructor(cooldownTimeout) {
        this.cooldownTimeout = cooldownTimeout;
        //
        // mousemove and scroll may also mean the user is active, but for our current use-case(autosave) those events
        // are meaningless. On a later iteration on this class we may have add our custom events which fire
        // when to user migrates to a specific state e.g. UserActivityMonitor.on('mayWentInactive'), .on('mayChangedContent')
        // etc.
        //
        const contentChangeEventsToWatch = ['mousedown', 'keydown', 'touchstart'];
        contentChangeEventsToWatch.forEach(eventName => document.addEventListener(eventName, this._recordContentChangeActivity.bind(this), true));

        const userActivityEventsToWatch = ['mousemove', 'scroll'];
        userActivityEventsToWatch.forEach(eventName => document.addEventListener(eventName, this._recordActivity.bind(this), true));
    }

    _recordActivity() {
        this.lastActivity = Date.now();
    }

    _recordContentChangeActivity() {
        this.lastContentChangeActivity = Date.now();
    }

    get isActive() {
        return ((Date.now() - this.lastActivity) < this.cooldownTimeout) || this.mayChangedContent;
    }

    get mayChangedContent() {
        return (Date.now() - this.lastContentChangeActivity) < this.cooldownTimeout;
    }
}

export default new UserActivityMonitor(2000);

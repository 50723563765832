require('./Login.scss');

const poweredNowLogo = require('client/admin/resources/img/vector/pn_logo_small.svg');

Ext.define('FieldServices.view.login.Login', {
    extend: 'Ext.Container',
    alias: 'widget.loginview',
    requires: [
        'FieldServices.view.login.LoginController',
        'Ext.Image',
        'Ext.grid.RowHeader',
    ],

    keyMap: {
        ENTER: {
            handler: 'onEnterKey',
            scope: 'controller',
            event: 'keydown',
        },
    },
    viewModel: true,
    controller: 'view.login',
    ui: '-login',
    layout: {
        type: 'vbox',
        pack: 'center',
        align: 'center',
    },
    items: [{
        xtype: 'image',
        src: poweredNowLogo,
        height: 150,
        width: '100%',
    }, {
        xtype: 'container',
        cls: 'loginTitle',
        html: '<h1>Powered Now Admin</h1>',
    }, {
        xtype: 'formpanel',
        ui: [
            '-login',
            '-rounded',
        ],
        width: 450,
        maxWidth: '90%',
        title: {
            text: 'Log In',
            textAlign: 'center',
        },
        defaults: {
            flex: 1,
        },
        items: [{
            xtype: 'fieldset',
            items: [{
                xtype: 'label',
                html: 'Login failed. Please enter the correct credentials.',
                itemId: 'signInFailedLabel',
                hidden: true,
                hideAnimation: 'fadeOut',
                showAnimation: 'fadeIn',
                style: 'color:white;margin:5px',
            }, {
                xtype: 'textfield',
                placeholder: 'Email',
                reference: 'emailField',
                itemId: 'email',
                name: 'email',
                required: true,
                label: 'Email',
                labelAlign: 'placeholder',
            }, {
                xtype: 'textfield',
                placeholder: 'Password',
                reference: 'passwordField',
                inputType: 'password',
                itemId: 'password',
                name: 'password',
                required: true,
                label: 'Password',
                labelAlign: 'placeholder',
            }, {
                xtype: 'button',
                itemId: 'loginButton',
                text: 'Login',
                width: '100%',
                bind: {
                    disabled: '{!passwordField.value || !emailField.value}',
                },
                ui: [
                    '-primary',
                    '-l',
                ],
            }],
        }],
    }, {

        xtype: 'panel',
        docked: 'bottom',
        itemId: 'adminAppVersion',
        html: '-',
    }],
});

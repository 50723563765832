import { ACTION, CONTACT_TO_ADDRESS } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.company.appointment.list.AppointmentListItemViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.appointmentListItemViewModel',
    xtype: 'appointmentListItemViewModel',

    formulas: {
        typeString: {
            bind: '{record.type}',
            get(type) {
                return type ? ACTION.ACTION_TYPES_STRINGS[type] : '';
            },
        },
        isSentString: {
            bind: '{record.issent}',
            get(issent) {
                return this.booleanToString(issent);
            },
        },
        isDeletedString: {
            bind: '{record.isdeleted}',
            get(isdeleted) {
                return this.booleanToString(isdeleted);
            },
        },
        jobLastActivity: {
            bind: '{record.job_id}',
            get(job_id) {
                const record = Ext.getStore('Job').findRecord('id', job_id);
                return record?.data.lastActivity ?? '';
            },
        },
        jobName: {
            bind: '{record.job_id}',
            get(job_id) {
                const record = Ext.getStore('Job').findRecord('id', job_id);
                return record?.data.description ?? '';
            },
        },
        customerName: {
            bind: '{record.job_id}',
            get(job_id) {
                const job = Ext.getStore('Job').findRecord('id', job_id)?.data;
                if (job) {
                    const customer = Ext.getStore('Customer').findRecord('id', job.customer_id)?.data;

                    if (customer && customer.company?.trim()) {
                        return customer.company;
                    }

                    const contactToAddress = Ext.getStore('ContactToAddress')
                        .getRecords()
                        .find(record => record.data.customer_id === job.customer_id && record.data.type === CONTACT_TO_ADDRESS.TYPE.MAIN)
                        ?.data;

                    if (contactToAddress) {
                        const contact = Ext.getStore('Contact').findRecord('id', contactToAddress.contact_id)?.data;
                        return contact ? `${contact.firstname} ${contact.lastname}` : '';
                    }
                }
                return '';
            },
        },
        customerId: {
            bind: '{record.job_id}',
            get(job_id) {
                const job = Ext.getStore('Job').findRecord('id', job_id)?.data;
                return job?.customer_id ?? '';
            },
        },
    },
});

const _ = require('lodash');

Ext.define('FieldServices.view.prompt.edit.PromptsFormViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.prompt.form',

    init() {
        Ext.getStore('languages').load();
    },

    /**
     * This function maps the countryId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */
    countryRenderer(value) {
        return (value && _.get(Ext.getStore('countries').getById(value), 'data.name')) || '';
    },

    /**
     * This function maps the languageId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */

    languageRenderer(value) {
        return Ext.getStore('languages').getById(value).data.title;
    },

    onCancelUpdate() {
        const view = this.getView();

        view.getViewModel().get('currentPrompt').reject();

        view.hide();
    },

    onSubmitUpdate() {
        const view = this.getView();

        const isBreak = !this._uploadRecord();

        if (isBreak) {
            return;
        }

        view.hide();
    },

    _uploadRecord() {
        const store = Ext.getStore('prompts');

        const view = this.getView();
        const viewModel = view.getViewModel();
        const viewValues = view.getValues();

        if (viewValues.language_id === null) {
            Ext.Msg.alert('Alert', 'Select a Language');
            return false;
        }

        const currentRecord = viewModel.get('currentPrompt');

        currentRecord.set(viewValues);

        if (!currentRecord.validate().isValid()) {
            currentRecord.reject();
            return false;
        }

        if (currentRecord.phantom) {
            store.add(currentRecord);
        }
        store.sync({
            callback: () => {
                store.reload();
            },
        });

        return true;
    },

});

Ext.define('FieldServices.view.report.subscriptions.active.ActiveFormViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.subscriptionsActiveFormViewController',
    xtype: 'subscriptionsActiveFormViewController',

    config: {
        shows: null,
    },

    onChangeFilterInActiveSubscriptions() {
        this._changeFilter();
    },

    onDownloadCsvFile() {
        this.getView().fireEvent('exportRequested', {});
    },

    _changeFilter() {
        const filterData = this.getView().getFilters();
        this.getView().fireEvent('changeFilter', { filterData });
    },

});

Ext.define('FieldServices.view.report.cohort.CohortMainViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.cohortMainViewController',
    xtype: 'cohortMainViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '#': {
            setSubPage: 'setSubPage',
        },
    },

});

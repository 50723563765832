import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.LogoCollectionCategory', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.LogoCollectionCategory'],
    alias: 'store.LogoCollectionCategory',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.LogoCollectionCategory',
        storeId: 'logoCollectionCategory',
    },

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/logoCollectionCategory`,
        appendId: false,
    },
});

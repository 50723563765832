Ext.define('FieldServices.view.components.helpTool.HelpToolView', {
    extend: 'Ext.Tool',
    requires: [
        'FieldServices.view.components.helpTool.HelpToolContentView',
        'FieldServices.view.components.helpTool.HelpToolViewController',
    ],
    controller: 'helpToolViewController',

    xtype: 'helpTool',

    type: 'help',

    cls: 'helpTool',

});

/*
 * File: app/controller/References.js
 *
 *
 */

Ext.define('FieldServices.controller.References', {
    extend: 'Ext.app.Controller',
    singleton: true,
    alternateClassName: [
        'References',
    ],
    config: {
        refs: {
            promptPopup: {
                autoCreate: true,
                selector: '#Prompt',
                xtype: 'prompt.fieldset',
                floated: true,
                height: 400,
                width: 600,
                centered: true,
                noGrid: true,
                viewModel: new Ext.app.ViewModel({
                    currentPrompt: null,
                    updateMode: false,
                }),
            },
            mongoPopup: {
                autoCreate: true,
                selector: '#Mongo',
                xtype: 'mongo.popupList',
                floated: true,
                height: '60%',
                width: '90%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentMongo: null,
                }),
            },
            userPopup: {
                autoCreate: true,
                selector: '#UserEdit',
                xtype: 'userEditView',
                floated: true,
                height: '60%',
                width: '90%',
                centered: true,
            },
            documentPopup: {
                autoCreate: true,
                selector: '#DocumentEdit',
                xtype: 'documentEditView',
                floated: true,
                height: '60%',
                width: '90%',
                centered: true,
            },
            selectCompanyPopup: {
                autoCreate: true,
                selector: '#SelectCompanyPopup',
                xtype: 'selectCompanyBox',
                height: '65%',
                width: '50%',
            },
            extraPopup: {
                autoCreate: true,
                selector: '#ExtraAdd',
                xtype: 'addExtraView',
                floated: true,
                width: '40%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            subscriptionsDetailsPopup: {
                autoCreate: true,
                selector: '#SubscriptionsDetails',
                xtype: 'subscriptionsDetailsView',
                floated: true,
                height: '50%',
                width: '50%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            chargeBeePopup: {
                autoCreate: true,
                selector: '#ChargeBeePopup',
                xtype: 'chargeBeeView',
                floated: true,
                height: '60%',
                width: '90%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            settingsPopup: {
                autoCreate: true,
                selector: '#SettingsPopup',
                xtype: 'settingsView',
                floated: true,
                height: '70%',
                width: '90%',
                centered: true,
            },
            productsExportPopup: {
                autoCreate: true,
                selector: '#productsExport',
                xtype: 'productExportView',
                floated: true,
                height: '30%',
                width: '40%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            subscriptionPopup: {
                autoCreate: true,
                selector: '#SubscriptionAdd',
                xtype: 'addSubscriptionView',
                floated: true,
                width: '40%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            devicePopup: {
                autoCreate: true,
                selector: '#DeviceDetails',
                xtype: 'deviceDetailsView',
                floated: true,
                height: '90%',
                width: '90%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            appointmentPopup: {
                autoCreate: true,
                selector: '#AppointmentDetails',
                xtype: 'appointmentDetailsView',
                floated: true,
                height: '90%',
                width: '90%',
                centered: true,
                viewModel: new Ext.app.ViewModel({
                    currentExtra: null,
                }),
            },
            contactPopup: {
                autoCreate: true,
                selector: '#ContactDetails',
                xtype: 'contactInfoView',
                floated: true,
                height: '95%',
                width: '90%',
                centered: true,
            },
            contactImportPopup: {
                autoCreate: true,
                selector: '#ContactImport',
                xtype: 'contactImportView',
                floated: true,
                height: '60%',
                width: '75%',
                centered: true,
            },
            profileRolePopup: {
                autoCreate: true,
                selector: '#ProfileRolePopup',
                xtype: 'userProfileView',
                floated: true,
                height: '95%',
                width: '90%',
                centered: true,
            },
            helpToolPopup: {
                autoCreate: true,
                selector: '#ProfileRolePopup',
                xtype: 'helpToolContent',
                floated: true,
                height: '95%',
                width: '90%',
                centered: true,
            },
            failedMessageInfoPopUp: {
                autoCreate: true,
                selector: '#failedMessageInfoPopUp',
                xtype: 'failedMessageInfoPopUp',
                floated: true,
                height: '95%',
                width: '90%',
                centered: true,
            },
            formDocumentInfoPopUp: {
                autoCreate: true,
                selector: '#formDocumentInfoPopUp',
                xtype: 'formDocumentInfoPopUp',
                floated: true,
                height: '95%',
                width: '90%',
                centered: true,
            },
            dashboard: {
                autoCreate: true,
                xtype: 'dashboard',
                selecter: '#Dashboard',
            },
            mainContainer: {
                selector: '#mainContainer',
            },
            loginPage: {
                selector: '#loginView',
            },
        },
    },
});

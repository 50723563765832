Ext.define('FieldServices.view.dashboard.Dashboard', {
    extend: 'Ext.Panel',
    alias: 'widget.dashboard',
    controller: 'FieldServices.view.dashboard.DashboardController',
    layout: 'fit',
    requires: [
        'FieldServices.view.whiteLabel.whiteLabelView',
        'FieldServices.view.dashboard.DashboardController',
        'FieldServices.view.dashboard.navbar.NavBar',
        'FieldServices.view.logs.ClientErrors',
        'FieldServices.view.logs.TransactionLogs',
    ],
    viewModel: {
        formulas: {
            navBarDockPosition: {
                get() {
                    if (Ext.Viewport.getOrientation() === 'portrait') {
                        return 'bottom';
                    }
                    return 'left';
                },
            },
        },
    },
    config: {

        itemId: 'Dashboard',
        layout: 'vbox',
        animation: null,
        showAnimation: null,
        hideAnimation: null,
        items: [
            {
                xtype: 'dashboard.navbar',
                bind: {
                    docked: '{navBarDockPosition}',
                },
                flex: 1,
            },
            {
                xtype: 'panel',
                layout: 'hbox',
                flex: 1,
                header: {
                    title: 'Powered Now Admin',
                    cls: 'dashboardHeader',
                    items: [{
                        xtype: 'button',
                        iconCls: 'ion-log-out',
                        itemId: 'logoutButton',
                        text: 'Logout',
                    }],
                },
                items: [
                    {
                        xtype: 'container',
                        layout: 'card',
                        itemId: 'dashboardContent',
                        flex: 1,
                        items: [{
                            xtype: 'home',
                            itemId: 'Home', // hash: 'showHome'
                            flex: 1,
                        }, {
                            xtype: 'container',
                            layout: 'card',
                            flex: 1,
                            items: [{
                                xtype: 'company',
                                itemId: 'Company',
                                flex: 1,
                            }],
                        },
                        {
                            xtype: 'container',
                            layout: 'card',
                            flex: 1,
                            items: [{
                                xtype: 'tabpanel',
                                flex: 1,
                                itemId: 'companyLogs',
                                items: [
                                    {
                                        tab: {
                                            flex: 1,
                                            title: 'Client Errors',
                                        },
                                        xtype: 'clientErrorsTable',
                                        showOnlyCompanyData: true,

                                        flex: 1,
                                    },
                                    {
                                        tab: {
                                            flex: 1,
                                            title: 'Transactions',
                                        },
                                        xtype: 'transactionLogsTable',
                                        showOnlyCompanyData: true,

                                        flex: 1,
                                    },
                                ],
                            }, {

                            }],
                        }, {
                            xtype: 'container',
                            layout: 'card',
                            flex: 1,
                            items: [{
                                xtype: 'report',
                                itemId: 'Reports',
                                flex: 1,
                            }],
                        },
                        {
                            xtype: 'systemView',
                            itemId: 'systemView',
                        }],
                    },
                ],
            },

        ],
    },

});

import ADMIN_CONSTANTS from 'client/admin/constants';

const { SERVER_URL } = require('config.js');

export default Ext.define('FieldServices.store.activity.Period', {
    extend: 'Ext.data.Store',
    alias: 'store.PeriodActivity',

    requires: ['FieldServices.model.activity.Period'],

    config: {
        autoLoad: false,
        storeId: 'PeriodActivity',
        remoteFilter: true,
        remoteSort: true,
        model: 'FieldServices.model.activity.Period',
    },

    proxy: {
        type: 'rest',
        url: `${SERVER_URL}api/admindata/company-activity?type=period`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
    },

    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_BIG,

});

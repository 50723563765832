import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { MESSAGES } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.components.contactMethod.ContactMethodViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.contactMethodModel',
    xtype: 'contactMethodModel',

    fields: [{
        name: 'contactMethodTypeId',
    }, {
        name: 'contactMethodTypeName',
        calculate: data => getKeyByValue(MESSAGES.TYPES, data.contactMethodTypeId),
    }],

});

// FieldServices.widgets.components.DateTimeField
Ext.define('Shared.Components.field.DateTimeField', {
    extend: 'Ext.field.DatePicker',
    alias: 'widget.datetimefield',

    requires: [
        'Ext.picker.Date',
        'Ext.Button',
    ],
    type: 'floated',
    picker: 'floated',
    validateOnEvent: 'change',
    preventScrollToCenter: true,
    twoWayBindable: {
        value: 1,
        localisedFormattedValue: 1,
    },
    publishes: {
        value: 1,
        localisedFormattedValue: 1,
    },

    statics: {

        /**
         * Defines the datetime picker if not yet defined
         */
        definePicker() {
            if (FieldServices.view.DateTimePicker) {
                return;
            }

            Ext.define('FieldServices.view.DateTimePicker', {
                extend: 'Ext.picker.Date',
                alias: 'widget.datetimepicker',
                statics: {
                    globalInstance: null,
                },
                side: null,
                config: {
                    //
                    // Please check comments in client/app/FieldServices/overrides/PickerSlot.js
                    // We don't want CSS to change when we are on iOS device.
                    //
                    doneButton: {
                        ui: 'action',
                        align: 'right',
                        text: 'Done',
                        pressedCls: '',
                        pressingCls: '',
                        cls: 'pseudo-disabled',
                    },
                    cancelButton: {
                        align: 'left',
                        text: '',
                        pressedCls: '',
                        pressingCls: '',
                        cls: 'pseudo-disabled pn-cancel',
                    },
                    useTitles: true,

                    cls: 'timePicker-Component',
                    height: 300,
                    showAnimation: false, // reset animation because css is rendered after it and looks stupid
                    hideAnimation: false, // reset animation because css is rendered after it and looks stupid
                    hourText: 'Hour',
                    minuteText: 'Minute',

                    yearFrom: new Date().getFullYear() - 10,

                    yearTo: new Date().getFullYear() + 50,

                },
                updateSlotOrder(oldSlotOder, newSlotOrder) {
                    if (!Ext.Array.equals(oldSlotOder || [], newSlotOrder || [])) {
                        this.createSlots();
                    }
                },
                /**
                 * This is by purpose! As we have a shared, single instance of date picker we never want to destroy it.
                 * If we let this to do default behaviour, a simple call to DateTimeField.delete() will remove its picker,
                 * which is the shared one.
                 *
                 * We could also patch it to recreate picker, but it is pointless to destroy it, for example when we remove
                 * a modal, which destorys all elements in it.
                 *
                 */
                destroy() {
                    this.callParent(arguments);
                },
                getDaySlot() {
                    const me = this;
                    if (this.daySlot && this.daySlot.isDestroyed) {
                        this.daySlot = null;
                    }
                    return this.callParent(arguments);
                },
                getValueAsObject() {
                    let value = this.getValue(true);
                    if (Ext.isDate(value)) {
                        value = {
                            day: value.getDate(),
                            month: value.getMonth() + 1,
                            year: value.getFullYear(),
                            hour: value.getHours(),
                            minute: value.getMinutes(),
                        };
                    }
                    return value;
                },
                onDoneButtonTap() {
                    const oldValue = Ext.Date.parse(this.ownerField.getValue(), this.ownerField.getDateFormat());
                    const newValue = this.getValue(true);

                    if (!Ext.Date.isEqual(oldValue, newValue)) {
                        this.fireEvent('change', this, newValue);
                    }

                    this.hide();
                    Ext.util.InputBlocker.unblockInputs();
                },
                /**
                 * The problem is that the new Ext.field.Date uses Ext.panel.Date as its picker while we originally
                 * used a `real` picker extension.
                 * Hence the new DateField expects navigateTo to be exists on its picker, but the picker was our old one
                 *
                 * Also, the new Date panel is a real panel, every month on its separate page, etc.
                 *
                 * This function simply calls setValue as we don't need any fancy logic to switch between months or
                 * anything.
                 * @param value
                 */
                navigateTo(value) {
                    this.setValue(value, false);
                },
                setValue(value, animated) {
                    if (Ext.isDate(value)) {
                        value = {
                            day: value.getDate(),
                            month: value.getMonth() + 1,
                            year: value.getFullYear(),
                            hour: value.getHours(),
                            minute: value.getMinutes(),
                        };
                    }
                    this.callSuper([value, false]);
                },

                setValueWithoutAnim(value) {
                    this.setValue(value);
                },

                getValue(useDom) {
                    const values = {};
                    const { items } = this.getItems();
                    const ln = items.length;
                    let daysInMonth;
                    let day;
                    let month;
                    let year;
                    let hour;
                    let minute;
                    let item;
                    let i;

                    for (i = 0; i < ln; i++) {
                        item = items[i];
                        if (item instanceof Ext.picker.Slot) {
                            values[item.getName()] = item.getValue(useDom);
                        }
                    }

                    year = Ext.isNumber(values.year) ? values.year : 1;
                    month = Ext.isNumber(values.month) ? values.month : 1;
                    day = Ext.isNumber(values.day) ? values.day : 1;
                    hour = Ext.isNumber(values.hour) ? values.hour : 1;
                    minute = Ext.isNumber(values.minute) ? values.minute : 1;

                    if (month && year && month && day) {
                        daysInMonth = this.getDaysInMonth(month, year);
                    }
                    day = (daysInMonth) ? Math.min(day, daysInMonth) : day;
                    return new Date(year, month - 1, day, hour, minute);
                },

                updateHourText() {
                    // to be implemented if needed
                },

                updateMinuteText() {
                    // to be implemented if needed
                },

                /**
                 * Generates all slots for all years specified by this component, and then sets them on the component
                 * @private
                 */
                createSlots() {
                    const me = this;
                    const slotOrder = me.getSlotOrder();
                    let yearsFrom = me.getYearFrom();
                    const yearsTo = me.getYearTo();
                    const years = [];
                    const days = [];
                    const months = [];
                    const hours = [];
                    const minutes = [];
                    const reverse = yearsFrom > yearsTo;
                    let ln;
                    let i;
                    let daysInMonth;

                    while (yearsFrom) {
                        years.push({
                            text: yearsFrom,
                            value: yearsFrom,
                        });

                        if (yearsFrom === yearsTo) {
                            break;
                        }

                        if (reverse) {
                            yearsFrom--;
                        } else {
                            yearsFrom++;
                        }
                    }

                    daysInMonth = me.getDaysInMonth(1, new Date().getFullYear());

                    for (i = 0; i < daysInMonth; i++) {
                        days.push({
                            text: i + 1,
                            value: i + 1,
                        });
                    }

                    for (i = 0, ln = Ext.Date.monthNames.length; i < ln; i++) {
                        months.push({
                            text: Ext.Date.monthNames[i],
                            value: i + 1,
                        });
                    }

                    for (i = 0, ln = 24; i < ln; i++) {
                        hours.push({
                            text: i < 10 ? `0${i}` : i,
                            value: i,
                        });
                    }

                    for (i = 0, ln = 60; i < ln; i++) {
                        minutes.push({
                            text: i < 10 ? `0${i}` : i,
                            value: i,
                        });
                    }

                    const slots = [];

                    slotOrder.forEach(item => {
                        slots.push(me.createSlot(item, days, months, years, hours, minutes));
                    });

                    me.setSlots(slots);
                },

                createSlot(name, days, months, years, hours, minutes) {
                    switch (name) {
                        case 'year':
                            return {
                                name: 'year',
                                align: 'center',
                                data: years,
                                title: this.getYearText(),
                                flex: 3,
                            };
                        case 'month':
                            return {
                                name,
                                align: 'right',
                                data: months,
                                title: this.getMonthText(),
                                flex: 4,
                            };
                        case 'day':
                            return {
                                name: 'day',
                                align: 'center',
                                data: days,
                                title: this.getDayText(),
                                flex: 2,
                            };
                        case 'hour':
                            return {
                                name: 'hour',
                                align: 'center',
                                data: hours,
                                title: this.getHourText(),
                                flex: 2,
                            };
                        case 'minute':
                            return {
                                name: 'minute',
                                align: 'center',
                                data: minutes,
                                title: this.getMinuteText(),
                                flex: 2,
                            };
                    }
                },

            }, () => {
                FieldServices.view.DateTimePicker.globalInstance = Ext.create('FieldServices.view.DateTimePicker');
            });
        },

        /**
         * Checks if html5 datetime-local input is supported
         */
        supportsNativeInput() {
            // this === FieldServices.widgets.components.DateTimeField

            // ice this as it's causing issues for testing in chrome
            if (Ext.os.is.Android || Ext.os.is.Desktop) {
                // unfortunately android doesnt fully support it yet (2013/07)
                // It was fortunate that android did not support it, as now (2015/07) they managed to build a broken datetime picker.
                // Just let our custom picker run, it is better, faster, cooler than the native one on android.
                return false;
            }

            if (this._supportsNativeInput !== undefined) {
                return this._supportsNativeInput;
            }

            const testInput = document.createElement('input');

            testInput.setAttribute('type', 'datetime');

            if (testInput.type !== 'datetime-local') {
                // not supported -> use a custom sencha datetime picker
                this._supportsNativeInput = false;
                return this._supportsNativeInput;
            }

            // supported -> use html5 input if useNative config is true
            this._supportsNativeInput = true;
            return this._supportsNativeInput;
        },
    },

    config: {
        ui: 'select',
        useNative: 'true',
        label: 'Field',
        dateFormat: undefined,
        mode: 'datetime',
        component: {},
        filterOperator: '=',
        type: 'floated',
        picker: 'floated',
        shouldValueFormattedByMode: false,
        localisedFormattedValue: null,

    },

    getSystemDateFormat() {
        return 'd-m-Y';
    },

    applyDateFormat(newValue) {
        if (!newValue) {
            return this.getSystemDateFormat();
        }
        return newValue;
    },

    /**
     * get date/time/datetime format based on country
     * @returns {string}
     */
    getLocalFormat() {
        const self = this;
        let nativeFix = ' ';
        let format = '';

        if (self._useNativeInput) {
            nativeFix = 'T';
        }

        switch (self._mode) {
            case 'time':
                format = 'H:i';

                break;
            case 'date':
                format = this.getSystemDateFormat();

                break;
            case 'datetime':
                format = `${this.getSystemDateFormat() + nativeFix}H:i`;

                break;
            case 'monthday':
                format = 'm-d';

                break;
            case 'yearmonth':
                format = 'Y-m';

                break;
            case 'year':
                format = 'Y';

                break;
            default:
                format = `Y-m-d${nativeFix}H:i`;
        }

        return format;
    },

    getSlotOrder(mode) {
        switch (mode) {
            // todo add slotorder to picker config
            case 'datetime':
                var slotOrder = ['month', 'day', 'year', 'hour', 'minute'];
                break;

            case 'date':
                slotOrder = ['month', 'day', 'year'];
                break;

            case 'monthday':
                slotOrder = ['month', 'day'];
                break;

            case 'time':
                slotOrder = ['hour', 'minute'];
                break;

            case 'yearmonth':
                slotOrder = ['year', 'month'];
                break;
            case 'year':
                slotOrder = ['year'];
                break;

            default:

                slotOrder = ['month', 'day', 'year', 'hour', 'minute'];
                console.log('DateTimeField::constructor() invalid mode', this._mode);
        }
        return slotOrder;
    },
    updateMode(mode) {
        this.setDateFormat(this.getLocalFormat());
        if (!this.initialized || this.destroyed) {
            return;
        }
        if (this._useNativeInput) {
            const componentType = this.findComponentType(mode);
            this.el.dom.type = this.config.component.type = componentType;
        }
    },
    constructor(constructorConfig) {
        const self = this;

        this._useNativeInput = this.useNativeInput();

        if (this._useNativeInput) {
            this.config.picker = false;
            this.config.ui = 'text';
            const componentType = this.findComponentType(constructorConfig.mode || this.config.mode);
            this.config.component.type = componentType;
            // this is required as of sdk 2.3 so the input is not obscured by a mask
            this.config.component.useMask = false;
        }
        this.callParent(arguments);
        if (!this._useNativeInput) {
            Shared.Components.field.DateTimeField.definePicker();
        } else {
            this.inputElement.dom.type = this.config.component.type;
        }
        this.inputElement.dom.addEventListener('change', event => {
            self.onUserChange(event);
        });
    },

    initialize() {
        /* this._useNativeInput = this.useNativeInput();

         if (!this._useNativeInput) {

         // use picker
         FieldServices.widgets.components.DateTimeField.definePicker();

         } else {

         //this.config.picker = false;
         //this.setPicker(false);
         }
         */

        this.callParent();

        /*
         if (this._useNativeInput) {
         this.getComponent().on({
         scope: this,
         change: 'onInputChange',
         blur  : 'onInputBlur'
         });
         }
         */
    },

    /**
     * This gets called when the user changes the input field.
     * Without adding this method, the "change" event of the component
     * wouldn't be triggered.
     */
    onUserChange(event) {
        this.setValue(this.getValue());
    },

    /**
     * @private
     * Note: this could be static.
     */
    findComponentType(mode) {
        let componentType = null;

        switch (mode) {
            case 'datetime':
                // 'datetime' contains timezone info, 'datetime-local' doesn't
                componentType = 'datetime-local';
                break;

            case 'monthday':
                // 'datetime' contains timezone info, 'datetime-local' doesn't
                componentType = 'datetime-local';
                break;

            case 'date':
                componentType = 'date';
                break;

            case 'time':
                componentType = 'time';
                break;
        }

        return componentType;
    },

    /**
     * @private
     */
    useNativeInput() {
        return false;
        if (!this.config.useNative) {
            // useNative is false
            return false;
        }

        if (Shared.Components.field.DateTimeField.supportsNativeInput()) {
            // supported and useNative is true
            return true;
        }

        // not supported
        return false;
    },

    /**
     * @return the stored date string (converted to UTC)
     */
    getDateString() {
        const date = Ext.clone(this.getValue());

        if (date) {
            date.convertToUTC();
            return Ext.Date.format(date, 'Y-m-d H:i:s');
        }

        return '';
    },

    setValue(value) {
        this.callParent([value]);
        const formattedValue = Ext.Date.format(value, this.getLocalFormat());
        if (!this._skipSetLocalisedFormattedValue) {
            this._skipSetValue = true;
            this.setLocalisedFormattedValue(formattedValue);
            this._skipSetValue = false;
            this.publishState('localisedFormattedValue', formattedValue);
        }
    },
    setLocalisedFormattedValue(value) {
        this.callParent([value]);
        const dateObjectLocalFormat = Ext.Date.parse(value, this.getLocalFormat());
        if (!this._skipSetValue) {
            this._skipSetLocalisedFormattedValue = true;
            this.setValue(dateObjectLocalFormat);
            this._skipSetLocalisedFormattedValue = false;
            this.publishState('value', dateObjectLocalFormat);
        }
    },

    getValue() {
        const originalValue = this.callParent(arguments);
        if (!this.getShouldValueFormattedByMode()) {
            return originalValue;
        }
        return Ext.Date.format(originalValue, this.getLocalFormat());
    },
    /**
     * Converts date to local timezone and sets the value.
     * @param date can be a Date object or a stored date string, in UTC in both cases.
     * if it's falsy ("", null, undefined), we reset the control
     */
    setUTCDate(date) {
        if (!date) {
            this.setValue(null);
            return;
        }

        if (typeof date === 'string' || date instanceof String) {
            const trimmedDate = Ext.util.Format.trim(date);

            if (trimmedDate === '' || trimmedDate === '0000-00-00 00:00:00') {
                // empty string
                this.setValue(null);
                return;
            }

            date = Utils.parseDate(trimmedDate);
        }

        // at this point date is surely a Date object

        const localDate = date.convertToLocalCopy();
        this.setValue(localDate);
    },

    applyValue(newValue, oldValue) {
        let value = newValue;

        if (!value) {
            return null;
        }

        if (typeof value === 'string') {
            const inputDateString = value.split(':').slice(0, 2).join(':');
            if (inputDateString !== '') {
                if (this._mode === 'time') {
                    value = Ext.Date.parse(Utils.fixTime(inputDateString), 'H:i');
                } else {
                    value = Ext.Date.parse(inputDateString, this.getLocalFormat());
                }
            }
        }

        if (this._mode !== 'time') {
            if (!Ext.isDate(value) && !Ext.isObject(value)) {
                value = null;
            }

            if (!Ext.isDate(value) && Ext.isObject(value)) {
                value = new Date(value.year, value.month - 1, value.day, value.hours, value.minutes);
            }
        }

        value = this.callParent([value, oldValue]);

        return value;
    },

    getCustomPicker() {
        this._picker = Ext.create('FieldServices.view.DateTimePicker');

        this._picker.setSlotOrder(this.getSlotOrder(this.getMode()));
        if (this._picker && this._picker.isPicker) {
            this._picker._value = this._value;
            this._picker.setValue(this._picker._value);
        }
        this.beforePickerShow();
        // To be sure we overwrote the `picker` config everywhere. This is basically removes the auto initialization
        // so the original applyPicker/setPicker won't called, which would create a datePanel.
        this.setPicker(this._picker);
        return this._picker;
    },
    beforePickerShow() {
        const picker = this._picker;
        picker.on({
            scope: this,
            change: 'onPickerChange',
            hide: 'onPickerHide',
        });
    },
    /**
     * This is again not really nice, but this only overrides the datepicker functionality.
     * The problem is, that we have some kind of custom picker logic which wasn't there in <6.5 and when we tap
     * on the expand icon it follows a different codepath which again triggers some new functionality from the new
     * framework. These two are creates some kind of race condition(we show the picker faster, the new code tries to
     * show it, but checks for `expanded` flag, which is not maintained, but we set that to true, so the expand tap
     * will hide the picker immediately.)
     *
     * The only reasonable solution there is a complete rework of our datepicker.
     * @returns {boolean}
     */
    onExpandTap() {
        return false;
    },
    onPickerHide() {
        const picker = this._picker;
        picker.un('change', 'onPickerChange', this);
        picker.un('hide', 'onPickerHide', this);
    },
    //
    // copypasted from Ext.field.DatePicker::onFocus
    //
    onFocus(e) {
        const component = this;
        this.fireEvent('focus', this, e);

        // if picker is false -> don't call getPicker
        if (this._useNativeInput) {
            return;
        }

        if (Ext.os.is.Android4) {
            component.inputElement.dom.focus();
        }
        component.inputElement.dom.blur();

        if (this.getReadOnly()) {
            return false;
        }

        this.isFocused = true;
        this._showCustomerPicker();
    },

    _showCustomerPicker() {
        const picker = this.getCustomPicker();
        if (!this._useNativeInput) {
            picker.setSlotOrder(this.getSlotOrder(this.getMode()));
            if (picker && picker.isPicker) {
                picker._value = this._value;
                picker.setValue(picker._value);
            }
            this.beforePickerShow();
            picker.setZIndex(1000);
            picker.show();
        }
    },

    getFilters() {
        const filters = [];
        const property = this.fieldName ? this.fieldName : this.name;
        const value = Ext.Date.format(this.getValue(), 'Y-m-d H:i:s');
        if (value) {
            const operator = this.filterOperator || this.getConfig('filterOperator');
            filters.push({
                value, operator, property, isDisabled: this.getDisabled(), 
            });
        }
        return filters;
    },

});

const { default: SUBSCRIPTION } = require('shared/constants/subscription');

Ext.define('FieldServices.store.PurchaseOrigins', {
    extend: 'Ext.data.Store',
    fields: ['value', 'key'],
    alias: 'store.PurchaseOrigins',
    data: [
        { value: 'all', key: ' -- All --' },
        ...Object.entries(SUBSCRIPTION.PURCHASE_ORIGIN_ID)
            .filter(([key]) => key !== 'BRAINTREE')
            .reduce((filters, [key, value]) => {
                filters.push({
                    value: value.toString(),
                    key,
                });
                return filters;
            }, []),
    ],

});

Ext.define('FieldServices.view.report.monthlyReports.detailed.grids.NewRegistrationsInMonthView', {
    extend: 'Ext.grid.Grid',

    xtype: 'newRegistrationsInMonthView',

    loadStoreWhenVisible: true,
    variableHeights: true,
    plugins: [{
        type: 'gridexporter',
    }],

    bind: {
        masked: '{monthlyDataLoading}',
    },
    store: 'NewRegistrationsInMonth',
    itemConfig: {
        viewModel: true,
    },
    showStatus: true,
    constructor(config) {
        this.callParent(arguments);
        this.store.on('datachanged', this.updateCountInTabTitle.bind(this));
    },
    updateCountInTabTitle() {
        const baseTitle = this.tab.config.title;
        const countText = this.store.isLoaded() ? this.store.count() : '-';
        this.tab.setTitle(`${baseTitle} (${countText})`);
    },
    columns: [
        {
            text: 'Company ID',
            dataIndex: 'company_id',
            flex: 0.5,
        },
        {
            text: 'Company Name',
            dataIndex: 'companyName',
            flex: 1,
            renderer: value => {
                return Ext.util.Format.htmlDecode(value);
            },
        },
        {
            text: 'Team Size',
            dataIndex: 'teamSize',
            flex: 0.5,
        },
        {
            text: 'Registration Date',
            dataIndex: 'dt_created',
            flex: 0.5,
            renderer: value => shared.Utils.Renderers.getDateRenderer('Y-m-d')(value, 'C'),
        },
        {
            text: 'Campaign Name',
            dataIndex: 'campaignName',
            flex: 1,
        },
        {
            text: 'Media Source',
            dataIndex: 'mediaSource',
            flex: 1,
        },
        {
            text: 'Main User',
            renderer: (value, record)  => {
                return Ext.util.Format.htmlDecode(`${record.data.MainUser.firstName} ${record.data.MainUser.lastName}`);
            },
            flex: 1,
        },
        { text: 'Main Email', dataIndex: 'MainUser.email', flex: 1 },
    ],

});

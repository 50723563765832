/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollection.components.LogoCollectionForm', {
    extend: 'Ext.form.FieldSet',
    xtype: 'logoCollection.fieldset',
    layout: {
        type: 'hbox',
        align: 'stretchmax',
    },
    defaults: {
        padding: 10,
    },
    fileUpload: true,
    items: [
        {
            xtype: 'container',
            layout: 'vbox',
            items: [{
                xtype: 'textfield',
                itemId: 'CDNUrl',
                hidden: true,
                bind: {
                    value: '{currentLogoCollection.CDNUrl}',
                },
                fieldLabel: 'CDN URL',
            }, {
                xtype: 'image',
                maxWidth: 60,
                minHeight: 60,
                bind: {
                    src: '{currentLogoCollection.CDNUrl}',
                },
            }, {
                xtype: 'button',
                itemId: 'uploadButton',
                bind: {
                    text: '{uploadButtonText}',
                },
            }, {
                xtype: 'textfield',
                bind: {
                    value: '{currentLogoCollection.name}',
                },
                itemId: 'logoName',
                fieldLabel: 'Name',

            }, {
                xtype: 'selectfield',
                allowBlank: false,
                editable: false,
                itemId: 'countryId',
                bind: {
                    store: '{countries}',
                    value: '{currentLogoCollection.country_id}',
                },
                displayField: 'name',
                valueField: 'id',
                fieldLabel: 'Country',
            }, {
                xtype: 'selectfield',
                allowBlank: false,
                editable: false,
                itemId: 'categoryId',
                bind: {
                    store: 'logoCollectionCategory',
                    value: '{currentLogoCollection.category}',
                },
                displayField: 'name',
                valueField: 'id',
                fieldLabel: 'Category',
            }, {
                xtype: 'container',
                layout: 'hbox',
                items: [{
                    xtype: 'button',
                    bind: {
                        text: '{buttonText}',
                    },
                    cls: [
                        'btn',
                        'btn--primary',
                    ],
                    itemId: 'actionBtn',
                }, {
                    xtype: 'button',
                    text: 'Clear selection',
                    bind: {
                        hidden: '{!updateMode}',
                    },
                    cls: [
                        'btn',
                        'btn--primary',
                    ],
                    itemId: 'clearBtn',
                },
                {
                    xtype: 'button',
                    text: 'Delete logo',
                    bind: {
                        hidden: '{!updateMode}',
                    },
                    cls: [
                        'btn',
                        'btn--primary',
                    ],
                    itemId: 'deleteBtn',
                }],
            }],
        },
    ],
});

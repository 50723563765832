  Ext.define('FieldServices.view.company.select.list.SelectCompanyListView', {
    extend: 'Ext.Container',

    requires: [
        'FieldServices.view.company.select.list.SelectCompanyListViewController',
    ],

    xtype: 'selectCompanyListBox',
    controller: 'selectCompanyListCtrl',

    items: [
        {
            xtype: 'grid',

            itemId: 'selectCompanyListUser',
            store: {
                type: 'User',
                storeId: 'CompanyListByUser',
            },

            layout: 'fit',
            flex: 1,
            hidden: true,

            infinite: true,

            title: 'click the user',
            columns: [
                {
                    text: 'Company ID',
                    dataIndex: 'company_id',
                    flex: 1,
                },
                {
                    text: 'First name',
                    dataIndex: 'firstname',
                    flex: 1,
                    renderer: value => Ext.util.Format.htmlDecode(value),
                },
                {
                    text: 'Last name',
                    dataIndex: 'lastname',
                    flex: 1,
                    renderer: value => Ext.util.Format.htmlDecode(value),
                },
                {
                    text: 'Email',
                    dataIndex: 'email',
                    flex: 1,
                },
            ],

        },
        {
            xtype: 'grid',
            itemId: 'selectCompanyListCompany',
            store: {
                type: 'Company',
                storeId: 'CompanyListByCompany',
            },

            layout: 'fit',
            flex: 1,
            hidden: true,

            title: 'Please, Click the company',
            columns: [
                {
                    text: 'Company ID',
                    dataIndex: 'id',
                },
                {
                    text: 'Company name',
                    dataIndex: 'name',
                    flex: 1,
                    renderer: value => Ext.util.Format.htmlDecode(value),
                },
                {
                    text: 'Company email',
                    dataIndex: 'email',
                    flex: 1,
                },
                {
                    text: 'Company phone',
                    dataIndex: 'phone',
                    flex: 1,
                },
            ],
        },
    ],

});

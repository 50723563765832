import ComplexModel from 'shared/modules/complexData/contactToAddress';

import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { CONTACT_TO_ADDRESS } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.model.ContactToAddress', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',

    config: {
        fields: [{
            name: 'type',
        }, {
            name: 'typeName',
            calculate: data => getKeyByValue(CONTACT_TO_ADDRESS.TYPE, data.type),
        }],
    },

});

Ext.define('FieldServices.controller.Utilities', {
    singleton: true,
    alternateClassName: [
        'Utilities',
        'Utils',
    ],

    // separate window
    openNewWindow(url) {
        return window.open(url, '_blank', 'noopener');
    },

    // This window can also freeze the original window
    openNewTab(url) {
        return window.open(url, '_blank');
    },

    getVersion() {
        const { appVersion } = window;

        if (!appVersion) {
            console.error('window.appVersion is not defined');
        }

        return appVersion;
    },

    getDisplayVersion() {
        const { displayVersion } = window;

        if (!displayVersion) {
            console.error('window.displayVersion is not defined');
        }

        return displayVersion;
    },

    forceLogoutAllUser(companyId) {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');

        Socket.emit('actionTrigger', {
            companyId,
            eventName: 'forceLogoutAllUser',
            roomName: `c${companyId}`,
            response: {
                eventName: 'actionTrigger',
                data: {
                    roomName: 'cadmin',
                    eventName: 'forceLogoutAllUserDone',
                    companyId,
                    adminCompanyId: adminUserData.companyId,

                },
            },
        });

        Ext.toast({
            message: 'Force logout is sent',
            timeout: 3000,
        });
    },

    forceLogoutOneUser(companyId, userId) {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');
        Socket.emit('actionTrigger', {
            companyId,
            eventName: 'forceLogoutOneUser',
            roomName: `c${companyId}`,
            response: {
                eventName: 'actionTrigger',
                data: {
                    roomName: 'cadmin',
                    eventName: 'forceLogoutOneUserDone',
                    companyId,
                    userId,
                    adminCompanyId: adminUserData.companyId,
                },
            },
        });

        Ext.toast({
            message: 'Force logout is sent',
            timeout: 3000,
        });
    },

    /**
     *
     * @param companyId if it is 0 then return all online users
     * @returns {Promise}
     */
    async getOnlineUsers(companyId) {
        const rawData = await Socket.emit('connections', {
            companyId,
            eventName: 'connectionsData',
            roomName: 'cadmin',
        });

        return rawData ? Object.keys(rawData).map(socketId => ({ ...rawData[socketId], socketId })) : [];
    },

    /**
     * Returns wether the device is online ATM
     *
     * @returns {*}
     */
    isOnline() {
        if (Ext.device) {
            return Ext.device.Connection.isOnline();
        }
        return navigator.onLine;
    },

    _getHelpContext() {
        return require.context('../../FieldServices', true, /(md|tpl\.html)$/);
    },

    requireTemplate(fileName) {
        if (/^(\.|\/)/.test(fileName)) {
            throw new Error(`fileName is not valid. ${fileName} not started "." or "/"`);
        }
        const file = `./${fileName}`;
        return this._getHelpContext()(file);
    },
});

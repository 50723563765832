Ext.define('FieldServices.overrides.data.Field', {
    override: 'Ext.data.Field',

    /**
     * Default constructor
     *
     * @returns {*|void}
     */
    constructor() {
        const config = arguments[0];
        const self = this;

        if (config) {
            const isJSON = config.isJSON === 'true' || config.isJSON === true;
            if (isJSON) {
                // without this, the value might become undefined,
                // causing a local sql syntax error in the sql proxy (number of ?'s not matching fields)
                // so it cannot be undefined. It can be "" or even null.
                // that issue is likely to happen only when the server db doesn't have a field
                // that the app model has as it's field.

                if (!config.defaultValue) {
                    config.defaultValue = '';
                }

                if (config.convert) {
                    // Warning because the point of the isJSON flag is to override convert with code
                    // Either use a custom convert and don't use isJSON, or use isJSON and the generated convert.
                    console.warn('Warning! You have specified a convert function but also set isJSON!');
                } else {
                    config.convert = self.convertJSON;
                }
                this.deconvert = self.deconvertJSON;
            }
        }

        return this.callParent(arguments);
    },

    /**
     * Decode a data field stored as JSON string to a data object
     *
     * @param value
     * @param record
     * @returns {*}
     */
    convertJSON(value, record) {
        //
        // Safely parse JSON, return {} for invalid input
        //
        if (typeof value === 'object') {
            return value;
        }
        return shared.Utils.utilities.parseJSON(value) || {};
    },

    /**
     * Encode a data object to a JSON string to be stored in the DB.
     *
     * @param data
     * @param name
     * @param value
     */
    deconvertJSON(data, name, value) {
        //
        // The pair for the field's "convert" function. This is called upon writing (sync).
        // Unfortunately field's cannot have additional methods, so we add this to the model
        //
        // This works with the enhanced "SQL.js" proxy (getRecordData function),
        // it checks if the method exists and calls it if so
        //
        // Specifically for this Model:
        // For the JobTimeline, we convert the 'data' field to json string
        // warning: this data is not be confused with the record's data property,
        // this is just a field inside that property that's called also data.
        //
        data[name] = Ext.encode(value);
    },

});

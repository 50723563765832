const _ = require('lodash');

Ext.define('FieldServices.view.components.failedMessage.list.FailedMessageListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.failedMessageListViewController',
    xtype: 'failedMessageListViewController',

    control: {
        '#': {
            painted: 'onPainted',
            infoButtonTap: 'onInfoButtonTap',
        },
    },

    onPainted() {
        this._remoteFilterStore();
    },

    onInfoButtonTap(element) {
        this._showErrorDetailsView(element);
    },

    _showErrorDetailsView(element) {
        const record = element.ownerCmp.getRecord();
        const failedMessageInfoPopUp = References.getFailedMessageInfoPopUp();
        const consumerErrorParserMap = this._getConsumerErrorParserMap();
        const { data: recordData } = record;
        const { queueName, error } = recordData;

        const parser = consumerErrorParserMap[queueName];

        failedMessageInfoPopUp.show();
        failedMessageInfoPopUp.lookupViewModel().setData({
            json4: JSON.stringify(
                Ext.clone(parser
                    ? parser.call(this, recordData)
                    : recordData),
                null,
                4,
            ),
            errorMessage: JSON.stringify(error.message),
            errorStack: error.stack,
        });
    },

    _getConsumerErrorParserMap() {
        return {
            email: recordData => {
                const pathToHtml = 'message.options.html';
                const html = _.get(recordData, pathToHtml);
                if (html) {
                    const escapedHtml = Ext.util.Format.htmlEncode(html);
                    const clonedRecordData = Ext.clone(recordData);
                    _.set(clonedRecordData, pathToHtml, escapedHtml);
                    return clonedRecordData;
                }
                return recordData;
            },
        };
    },

    onReSend() {
        const grid = this.getView();
        const selectedList = grid.getStore().getData().items.filter(item => item.data.isChecked);
        Ext.Msg.confirm('Resend', `Are you sure you want to resend the selected (${selectedList.length}) items?`, async btn => {
            if (btn === 'yes') {
                await this._resendSelectedElements(selectedList);
            }
        });
    },

    _remoteFilterStore() {
        const store = this.getView().getStore();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);

        store.clearFilter();
        store.getProxy()
            .setExtraParam('excludes', JSON.stringify(['message.options.attachments']));
        store.getProxy()
             .setExtraParam('totalNeeded', JSON.stringify(true));

        store.filter([
            {
                id: 'tableName', property: 'table', value: 'messagequeuefails', operator: '=',
            }]);
    },

    async _resendSelectedElements(selectedList) {
        const messagesIds = selectedList.map(item => item.data._id);
        if (messagesIds) {
            try {
                await FieldServices.app.callAPI({
                    method: 'POST',
                    url: 'api/admindata/failedMessage/resendFailedMessages',
                    jsonData: {
                        messagesIds,
                    },
                    timeout: CONSTANTS.NETWORK.TIMEOUT.DEFAULT,
                });
                this._remoteFilterStore();
            } catch (err) {
                handleClientError(err);
            }
        }
    },
});

import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { CONTACT_TO_ADDRESS } from '@powerednow/shared/constants';

Ext.define('FieldServices.view.components.contactToAddress.ContactToAddressModel', {
    extend: 'FieldServices.model.override.Model',

    alias: 'model.contactToAddressModel',
    xtype: 'contactToAddressModel',

    fields: [{
        name: 'type',
    }, {
        name: 'typeName',
        calculate: data => getKeyByValue(CONTACT_TO_ADDRESS.TYPE, data.type),
    }],

});

/**
 * @class listViewController
 * @fires submit
 *
 *
 */
Ext.define('FieldServices.view.company.select.SelectCompanyListViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.selectCompanyListCtrl',
    xtype: 'selectCompanyListCtrl',

    control: {
        selectCompanyListUser: {
            itemtap: 'onCompanySelected',
            datachanged: '_showList',
        },
        selectCompanyListCompany: {
            itemtap: 'onCompanySelected',
            datachanged: '_showList',
        },
    },

    columns: [{
        text: 'userId',
        dataIndex: 'userId',
    }],

    init() {
        const companyList = this.getView().down('#selectCompanyListCompany');
        const userList = this.getView().down('#selectCompanyListUser');
        //
        // storeIds are added to prevent the StoreManager replacing the global `Company` & `User` stores
        //
        companyList.setStore(Ext.create('FieldServices.store.Company', { storeId: 'forCompanySearch' }));
        userList.setStore(Ext.create('FieldServices.store.User', { storeId: 'forUserSearch' }));

        const userListView = this.getView().down('#selectCompanyListUser');
        userListView.relayEvents(userListView.getStore(), ['datachanged']);

        const companyListView = this.getView().down('#selectCompanyListCompany');
        companyListView.relayEvents(companyListView.getStore(), ['datachanged']);
    },

    onCompanySelected(dataview, index, target, record, e, eOpts) {
  
        const companyId = record.data.company_id ? record.data.company_id : record.data.id;
        /**
         * @event submit
         * Fires when the tool is clicked
         * @param {Ext.panel.Tool} this
         * `Ext.panel.Panel`, this is set to the owning panel. This value comes from the
         * `toolOwner` config.
         */
        this.getView().fireEvent('companySelected', companyId);
    },

    _showList(store) {
        this.getView().query('grid').forEach(grid => {
            if (store === grid.getStore()) {
                grid.show();
            } else {
                grid.hide();
            }
        });
    },

});

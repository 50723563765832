Ext.define('FieldServices.view.company.contact.list.ContactListItemViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.contactListItemViewModel',
    xtype: 'contactListItemViewModel',

    formulas: {
        isDeletedString: {
            bind: '{record.isdeleted}',
            get(isdeleted) {
                return this.booleanToString(isdeleted);
            },
        },

        isCustomerString: {
            bind: '{record.is_customer}',
            get(islocked) {
                return this.booleanToString(islocked);
            },
        },
    },
});

Ext.define('FieldServices.view.company.formDocument.formDocumentListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.formDocumentListViewController',
    xtype: 'formDocumentListViewController',

    control: {
        selectStatusField: {
            change: 'onStatusChange',
        },
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadFormDocuments, this);
    },

    async reloadFormDocuments() {
        await this.showLoader(this.getView());
        this.getGridFilterPlugin().reloadData();
        await this.clearLoader(this.getView());
    },
    async _loadDependantStore() {
        return new Promise(resolve => Ext.getStore('Form').load(resolve));
    },

    onInfoButtonTap(elem) {
        const record = elem.ownerCmp.getRecord();
        const formDocumentInfoPopUp = References.getFormDocumentInfoPopUp();

        formDocumentInfoPopUp.show();
        formDocumentInfoPopUp.lookupViewModel().setData({
            json4: JSON.stringify(
                record.data.form_data,
                null,
                4,
            ),
        });
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('formDocumentFilterPlugin');
    },

    async onStatusChange(selectField, value) {
        const record = selectField.ownerCmp.getRecord();
        if (!record || selectField.ownerCmp.refreshContext) {
            return;
        }
        const oldStatus = parseInt(record.modified && record.modified.status !== undefined ? record.modified.status : record.data.status, 10);
        const newStatus = parseInt(value, 10);

        const statusChanged = record.dirty && oldStatus !== newStatus;
        if (statusChanged) {
            await this.showLoader(this.getView());
            await FieldServices.app.callAPI({
                method: 'PUT',
                url: `api/admindata/company-tables/FormDocument/${record.data.company_id}`,
                jsonData: {
                    id: record.data.id,
                    status: newStatus,
                },
            });
            await this.getGridFilterPlugin().reloadData();
            await this.clearLoader(this.getView());
        }
    },
});

Ext.define('FieldServices.view.company.message.MessageViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.messageViewController',
    xtype: 'messageViewController',

    control: {
        '#reSendButton': {
            tap: 'onReSendButtonTap',
        },
        messageList: true,
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadMessages, this);
    },

    async reloadMessages() {
        const view = this.getView();
        if (view.isPainted()) {
            await this.showLoader(view);
            await this.getMessageList().getPlugin('messageFilterPlugin').reloadData();
            await this.clearLoader(view);
        }
    },

    async onReSendButtonTap(elem) {
        const messageId = elem.ownerCmp.getRecord().data.id;
        if (messageId) {
            await this.showLoader(this.getView());

            await this._forceSend(messageId);

            await this.clearLoader(this.getView());
        }
    },

    _forceSend(messageId) {
        return FieldServices.app.callAPI({
            method: 'POST',
            url: 'api/admindata/message',
            jsonData: {
                messagesIds: [messageId],
                companyId: this.getView().lookupViewModel().get('companyId'),
            },
            timeout: CONSTANTS.NETWORK.TIMEOUT.MEDIUM,
        })
            .catch(err => {
                handleClientError(err);
            });
    },
});

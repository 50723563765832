import ComplexModel from 'shared/modules/complexData/purchases';

import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { SUBSCRIPTION } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.model.Purchases', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',
    config: {
        fields: [{
            name: 'product_name',
            calculate: data => {
                const purchaseSetup = Ext.getStore('PurchaseSetup');
                const index = purchaseSetup.findBy(record => record.get('ios_product_id') === data.product);
                const purchaseSetupRecord = purchaseSetup.data.items[index];
                return purchaseSetupRecord && purchaseSetupRecord.getData && purchaseSetupRecord.getData().product_name;
            },
        }, {
            name: 'expiresDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d H:i:s')(data.expires_date),
        }, {
            name: 'purchaseDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d H:i:s')(data.purchase_date),
        }, {
            name: 'purchaseOriginName',
            calculate: data => getKeyByValue(SUBSCRIPTION.PURCHASE_ORIGIN_ID, parseInt(data.purchaseOrigin, 10)),
        }],
    },
});

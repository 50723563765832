export default Ext.define('FieldServices.view.company.purchases.subscriptions.SubscriptionListController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.subscriptionListController',
    xtype: 'subscriptionListController',

    control: {
        addSubscriptionBtn: {
            tap: 'showAddSubscriptionPopup',
        },
    },

    showAddSubscriptionPopup() {
        const popup = References.getSubscriptionPopup().show()
            .on('dataSent', () => {
                Ext.getStore('PurchasesGrouped').load();
                Ext.getStore('PurchasesInfo').load();
                this.getView().refresh();
            }, this, {
                single: true,
            });
        popup.setTitle('New Subscription');
        popup.getController().setCompanyId(this.getViewModel().get('selectedCompanyData').id);
    },

    showDetailsPopup(record) {
        const singleOptions = { single: true };
        const popup = References.getSubscriptionsDetailsPopup().show()
            .on('close', () => {
                this.getView().getStore().getSource().load();
            }, this, singleOptions);
        popup.getController().setCompanyId(this.getViewModel().get('selectedCompanyData').id);
        popup.getController().setSubscriptionRecord(record);
    },

    onDetailsTap(view, index, target, record) {
        this.showDetailsPopup(record);
    },
});

/**
 *
 * @class Override.Button
 * @extends Ext.Button
 *
 */
require('./Button.scss');

Ext.define('FieldServices.overrides.Button', {
    override: 'Ext.Button',
});

Ext.define('FieldServices.view.company.info.clientError.ClientErrorInfoViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.clientErrorInfoViewController',
    xtype: 'clientErrorInfoViewController',

    control: {
        clientErrorOfCompanyId: {
            addToKnownErrorsButtonTap: 'onAddToKnownErrorsButtonTap',
            showPNCardButtonTap: 'onShowPNButtonTap',
        },
    },
    onAddToKnownErrorsButtonTap(button, viewModel) {
        const recordData = viewModel.get('record').data;
        Ext.getStore('KnownError').add({
            stack: recordData.stackTraces[0],
            hashed_stack: recordData.hashedStack,
            is_harmful: true,
            min_app_version: recordData.appVersions[0],
        });
        Ext.Msg.alert('Changes not yet saved!', `A new entry has been added to the KnownError store,
        but you must check and save the store manually at the KnownError management screen!`);
    },
    onShowPNButtonTap(button, viewModel) {
        const record = viewModel.get('record');
        const knownErrorRecord = record.getKnownErrorModel();
        if (knownErrorRecord) {
            Utilities.openNewWindow(`https://powerednow.myjetbrains.com/youtrack/issue/${knownErrorRecord.data.card_number}`);
        }
    },
});

require('./CellBase.scss');

Ext.define('FieldServices.overrides.grid.CellBase', {
    override: 'Ext.grid.cell.Base',

    addClsToRow(className) {
        this._getGridRow().addCls(className);
    },

    removeClsToRow(className) {
        this._getGridRow().removeCls(className);
    },

    toggleClsOnRow(...args) {
        this._getGridRow().toggleCls(...args);
    },

    _getGridRow() {
        return this.up('gridrow');
    },

    refreshValue(context) {
        const me = this;
        const { record } = context;
        const { dataIndex } = context;
        let value; let dirty; let
            modified;

        if (context.summary) {
            value = me.summarize(context);
        } else if (record && dataIndex) {
            // ----- >>>> OVERRIDE
            if (dataIndex.indexOf('.') !== -1) {
                value = Renderer.deepDataIndex(value, record, dataIndex);
            } else {
                value = record.get(dataIndex);
            }
            // <<<<< ---- OVERRIDE
            modified = record.modified;
            dirty = !!(modified && modified.hasOwnProperty(dataIndex));

            if (dirty !== me.$dirty) {
                me.toggleCls(me.dirtyCls, dirty);

                me.$dirty = dirty;
            }
        }

        return value;
    },
});

import {memoizeRunningPromise} from "@powerednow/shared/decorators";

Ext.define('FieldServices.controller.AuthManager', {
    extend: 'Ext.app.Controller',
    alternateClassName: ['AuthManager'],
    singleton: true,

    loggedInUserRoles: [],
    adminProfileAccessMap: null,

    getAuthHeader() {
        const adminToken = this._getAdminToken();
        return { [CONSTANTS.HTTP.AUTHENTICATION_HEADER_TOKEN]: adminToken };
    },

    async loadAdminRoles() {
        const apiResponse = await FieldServices.app.callAPIEndpointRequest('GET', 'api/admindata/adminPermissions/getCurrentAdminRoles');
        if (!apiResponse.success) {
            Ext.Msg.alert('Application Update', 'The admin application received an update that needs you to login again.', () => {
                this.logout();
            });
        }
        if (this.loggedInUserRoles?.length === 0) {
          this.fireEvent('login');
        }
        this.loggedInUserRoles = apiResponse.roles;
    },

    async getAdminProfileAccessMap() {
        if (!this.adminProfileAccessMap) {
            this.adminProfileAccessMap = await FieldServices.app.callAPIEndpointRequest('GET', 'api/admindata/adminPermissions/getAdminProfilesWithAccessStatus');
        }
        return this.adminProfileAccessMap;
    },

    getCurrentAdminData() {
        return Ext.util.LocalStorage.get('auth').getObject('userData');
    },

    @memoizeRunningPromise
    async userHasRole(role) {
        if (!this.loggedInUserRoles.length) {
            this.loadAdminRoles().then(() => {
                this.validateStoredRoles();
            }).catch(handleClientError);
        }
        this.validateStoredRoles();
        const { userRoles } = this.getCurrentAdminData();
        return this.loggedInUserRoles.length ? this.loggedInUserRoles.includes(role) : userRoles.includes(role);
    },

    validateStoredRoles() {
        const { userRoles } = this.getCurrentAdminData();
        if (!userRoles) {
            return;
        }
        if (!this.loggedInUserRoles.length) {
            return;
        }
        if (JSON.stringify(userRoles) !== JSON.stringify(this.loggedInUserRoles)) {
            Ext.Msg.alert('Permissions changed', 'Your admin permissions have been changed, please log in again!', () => {
                this.logout();
            });
        }
    },

    logout() {
        Ext.util.LocalStorage.get('auth').clear('adminToken');
        Ext.util.LocalStorage.get('auth').clear('userData');
        this.loggedInUserRoles = [];
        const { href } = window.location;
        this.fireEvent('logout');
        window.location = href.substring(0, href.indexOf('#'));
    },

    _getAdminToken() {
        const storage = Ext.util.LocalStorage.get('auth');
        return storage.getItem('adminToken');
    },
});

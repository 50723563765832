/**
 * This class is the main view for the application. It is specified in app.js as the
 * "mainView" property. That setting causes an instance of this class to be created and
 * added to the Viewport container.
 *
 */
require('./HomeView.scss');

Ext.define('FieldServices.view.home.HomeView', {
    extend: 'Ext.Panel',

    requires: [
        'Shared.Utils.Renderers',
        'Shared.Utils.utilities',
        'FieldServices.view.home.HomeViewController',
        'FieldServices.view.company.CompanyView',
        'FieldServices.view.purchaseSetup.PurchaseSetupView',
        'FieldServices.view.logoCollection.LogoCollection',
        'FieldServices.view.logoCollectionCategory.LogoCollectionCategory',
        'FieldServices.view.prompt.PromptsView',
        'FieldServices.view.services.Services',
        'FieldServices.view.report.ReportView',
        'FieldServices.view.system.systemView',
        'FieldServices.view.tableVersion.TableVersionView',
        'FieldServices.view.components.failedMessage.list.FailedMessageListView',
        'FieldServices.view.purchaseSetup.PurchaseSetupView',
        'FieldServices.view.knownError.KnownErrorView',
        'FieldServices.view.logs.popup.MongoPopupView',
    ],
    alias: 'widget.home',
    controller: 'home',

    ui: 'navigation',

    items: [],
});

const Bluebird = require('bluebird');
const debug = require('debug')('store');

Ext.define('shared.overrides.data.Store', {
    override: 'Ext.data.Store',
    mixins: ['Shared.mixins.ApiRequest'],

    config: {
        deviceOnly: false,
        useSummaryRecord: false, // Prevents automatic grouping, set to true if the summary record is needed
        loadInChunks: false, // We don't use this for anything
        loadChunkSize: 500,
        requestParameterKeyMap: [{
            extKey: 'filters',
            serverKey: 'filter',
        }, {
            extKey: 'sorters',
            serverKey: 'sorters',
        }],
    },

    getJSONFieldNames() {
        return this.model.getFields()
            .filter(field => field.isJSON)
            .map(field => field.name);
    },

    /**
     * ATM we _must_ not end up there from non-admin code. This function can't handle local proxies, always downloads
     * data from server.
     *
     * @param requestOptions
     * @returns {Promise<shared.overrides.data.Store>}
     */
    async loadAsync(requestOptions = {}) {
        if (this.isLoaded()) {
            return this;
        }
        if (this.isLoading()) {
            await this._getRecordsOnLoad();
        } else {
            await this.reloadAsync(requestOptions);
        }
        return this;
    },
    /**
     * This function can't used for client as we don't always want to load dependant stores.
     * E.g. for showing diary items we need `Action` and `Job` stores, but `Job` has dependencies for `JobItems`, which
     * is not required for `Action`
     *
     * @param requestOptions
     * @returns {Promise<shared.overrides.data.Store>}
     */
    async reloadAsync(requestOptions = {}) {
        this.loading = true;
        //
        // Why only for device only, for client we need directLoadAsync for every store.
        //
        if (this.getDeviceOnly()) {
            await this.directLoadAsync();
            return this;
        }
        await this._loadDependantStores();

        const records = await this.downloadStore(requestOptions);

        this.loadData(records);
        this.fireEvent('load', this, records, true, new Ext.data.operation.Read());

        return this;
    },

    /**
     * This is somewhat similar what our client-side proxy loading looks like, but the local proxy can't return
     * data from server, so SERVER_ERROR can't occur.
     * @returns {bluebird}
     */
    directLoadAsync() {
        return new Bluebird((resolve, reject) => {
            this.load({
                scope: this,
                callback: (records, operation, success) => {
                    const isServerReturnedError = this._isErrorReturnedFromServer(records);
                    if (!success || isServerReturnedError) {
                        const serverErrorData = {
                            severity: 8,
                        };
                        if (isServerReturnedError) {
                            Object.assign(serverErrorData, {
                                code: records[0].data.error_code,
                                message: records[0].data.error_msg,
                            });
                        }
                        return reject(generateClientError(CONSTANTS.ERRORS.SERVER_ERROR, serverErrorData));
                    }
                    return resolve();
                },
            });
        });
    },

    async waitToLoad() {
        if (this.isLoading() || this.hasPendingLoad()) {
            await this._awaitEvent('load', { single: true });
        } else {
            await this.loadAsync();
        }
        return this.getRecords();
    },

    syncAsync(options = {}) {
        return new Bluebird((resolve, reject) => {
            const result = this.sync(Object.assign(options, {
                success: resolve,
                failure: reject,
            }));
            if (!result.isSyncing) {
                resolve();
            }
        });
    },

    getRecords() {
        return (this.getData && this.getData() && this.getData().items) || [];
    },

    revertModifications() {
        this.getModifiedRecords().forEach(record => (record.phantom ? record.drop() : record.reject()));
    },

    getV8Url() {
        const storeName = this.id;
        const companyId = AuthManager.getCompanyId();
        const url = `data/bycompany/${companyId}/${storeName}`;
        return `${StoreUtils.API_BASE_URL}${url}`;
    },
    /**
     * Return the API url of given store
     *
     * @param storeName
     * @returns {*}
     * @private
     */
    getUrl() {
        const storeName = this.id;
        const store = StoreUtils.storesByName[storeName];
        const companyId = AuthManager.getCompanyId();

        let url;

        //
        // store is not defined locally in case of Onlinesync table
        //
        if (!store) {
            url = `v2/data/bycompany/${companyId}/${storeName}`;
        } else if (store.type !== 'const' && store.type !== 'local') {
            //
            // dynamic store
            //
            url = `v2/data/bycompany/${companyId}/${storeName}`;
        } else if (store.type === 'const') {
            //
            // global store
            //
            url = `data/global/${storeName}`;
        } else {
            return null;
        }
        //
        // prompt and logoCollection stores are language-specific.
        //
        if (store && Ext.getStore(storeName).isLanguageSpecific()) {
            url += `/${Utilities.getLanguageCode()}`;
        }

        return `${StoreUtils.API_BASE_URL}${url}`;
    },

    getParams() {
        const store = StoreUtils.storesByName[this.id];
        if (store.type === 'const') {
            return {};
        }
        return {
            start: 0,
            limit: this.getLoadChunkSize(),
        };
    },

    _awaitEvent(eventName, options = {}) {
        return new Promise((resolve, reject) => {
            this.on(eventName, resolve, this, options);
        });
    },

    async _getRecordsOnLoad() {
        return new Bluebird((resolve, reject) => {
            this.on('load', (self, records, successful) => {
                if (successful) {
                    resolve();
                } else {
                    reject(generateClientError(CONSTANTS.ERRORS.SERVER_ERROR));
                }
            }, this, { single: true });
        });
    },

    async _loadDependantStores() {
        if (this.getDependantStores && this.getDependantStores()) {
            await this._loadDependencies();
        }
    },

    _loadDependencies() {
        return Bluebird.map(this.getDependantStores(), dependantStoreName => Ext.getStore(dependantStoreName).loadAsync());
    },

    _isErrorReturnedFromServer(records) {
        return (records && records[0] && records[0].data && typeof records[0].data.error_code !== 'undefined');
    },

    async downloadStore(requestOptions) {
        const url = this._getUrlValue(requestOptions);
        const params = this.getParams();

        if (url === null) {
            await this.directLoadAsync();
            return this.getRecords();
        }

        const requestParams = {
            url,
            method: 'GET',
            auth: true,
            params: this._setLoadParam(params),
            ...requestOptions,
        };

        return this._getAllData(requestParams);
    },

    _getUrlValue({ url }) {
        return url || this.getUrl();
    },

    async callOnChunkDownloadedOrReturnData(requestParams, chunk) {
        if (requestParams.onChunkDownloaded) {
            await requestParams.onChunkDownloaded.call(this, chunk);
            return [];
        }
        return chunk;
    },

    async _getAllData(requestParams) {
        const firstChunk = await this._request(this._cleanStreamRequestParams(requestParams));
        //
        // Old styled download where we returned an object containing the total and the data
        // as object keys. It is left here for backward compatibility.
        //
        if (firstChunk.total && firstChunk.data && firstChunk.total > firstChunk.data.length) {
            const firstChunksData = await this.callOnChunkDownloadedOrReturnData(requestParams, firstChunk.data);
            const chunksData = await this._requestRemainingChunks({ firstChunk, requestParams });
            return [...firstChunksData, ...chunksData];
        }
        //
        // New styled download where we return the data directly. Remaining chunks are needed if
        // the returned amount is matching the chunk size. In edge cases it results an extra empty request at the end if
        // the data amount matches the chunk size.
        //
        if (requestParams?.params?.limit && requestParams?.params?.limit !== -1 && Array.isArray(firstChunk) && firstChunk.length >= requestParams?.params?.limit) {
            return this._requestRemainingByLastId(firstChunk, requestParams);
        }

        const returnData = Array.isArray(firstChunk) ? firstChunk : firstChunk.data;
        return this.callOnChunkDownloadedOrReturnData(requestParams, returnData || []);
    },

    async _requestRemainingByLastId(lastChunk, requestParams) {
        //
        // Get the last downloaded record id and set in the request params so API
        // can return the next chunk where the id is greater than the last id.
        //
        requestParams.params.lastId = lastChunk[lastChunk.length - 1].id;
        //
        // Start adding records to the local store
        //
        const [processed, nextChunk] = await Promise.all([this.callOnChunkDownloadedOrReturnData(requestParams, lastChunk), this._request(this._cleanStreamRequestParams(requestParams))]);
        if (nextChunk.length < requestParams?.params?.limit) {
            return [...processed, ...await this.callOnChunkDownloadedOrReturnData(requestParams, nextChunk)];
        }
        return this._requestRemainingByLastId([...processed, ...nextChunk], requestParams);
    },

    async _requestRemainingChunks({ firstChunk, requestParams }) {
        const paramsList = this._getChunksRequestParams({ firstChunk, requestParams });

        return Bluebird.reduce(paramsList, async (chunksData, params) => {
            const chunkResult = await this._request(this._cleanStreamRequestParams(Object.assign(requestParams, { params })));
            chunksData.push(...await this.callOnChunkDownloadedOrReturnData(requestParams, chunkResult.data));
            return chunksData;
        }, []);
    },

    _cleanStreamRequestParams(requestParams) {
        const { onChunkDownloaded, ...cleanedRequestParams } = requestParams;
        return cleanedRequestParams;
    },

    _getChunksRequestParams({ firstChunk, requestParams }) {
        const limit = this.getLoadChunkSize();
        const chunksCount = Math.floor(firstChunk.total / limit);

        return [...Array(chunksCount)].map((chunk, key) => ({
            ...requestParams.params,
            limit,
            start: (key + 1) * limit,
        }));
    },

    _setLoadParam(params) {
        // add filter and sort to params
        this.setLoadOptions(params);

        return this.getRequestParameterKeyMap()
            .filter(config => Boolean(params[config.extKey]))
            .reduce((prev, config) => {
                const filters = prev[config.extKey].map(this._serialize);
                Object.assign(prev, { [config.serverKey]: JSON.stringify(filters) });

                if (config.extKey !== config.serverKey) {
                    delete prev[config.extKey];
                }

                return prev;
            }, { ...params });
    },

    _serialize(param) {
        return param.serialize();
    },
    privates: {
        attachSummaryRecord(params) {
            if (this.getUseSummaryRecord()) {
                this.callParent(params);
            }
        },
    },
});

Ext.define('FieldServices.view.company.device.details.DetailsViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.deviceDetailsViewController',
    xtype: 'deviceDetailsViewController',

    control: {
        '': {
            painted: 'onPainted',
        },
        cancelButton: {
            tap: 'onCancel',
        },
        deviceSyncButton: {
            tap: 'onDeviceSyncButtonTap',
        },
        loadDeletedDataButton: {
            tap: 'onloadDeletedDataTap',
        },
    },

    config: {
        companyId: null,
        record: null,
    },

    updateRecord() {
        const showData = this._getRecordAsJSON(this.getRecord().data);
        // TODO: create a new view/template/widget
        this.getView().down('#recordData').setHtml(`<pre>${showData}</pre>`);

        this.getView().down('#restoreBox').getController().setDevice(this.getRecord());
    },

    onCancel() {
        const view = this.getView();

        view.hide();
    },

    async onDeviceSyncButtonTap() {
        await this.showLoader(this.getView());
        try {
            const apiResponse = await FieldServices.app.callAPI({
                method: 'GET',
                url: `api/admindata/company-tables/DeviceSync/${this.getCompanyId()}`,
                params: {
                    limit: 1,
                    totalNeeded: true,
                    filter: JSON.stringify([{
                        property: 'company_id',
                        value: this.getCompanyId(),
                        operator: '=',
                    }, {
                        property: 'userdevice_id',
                        operator: '=',
                        value: this.getRecord().data.id,
                    }]),
                },
            });
            await this.clearLoader(this.getView()).catch(handleClientError);
            Ext.Msg.alert('Notification', `Number of Device Sync rows: ${apiResponse.total}`);
        } catch (error) {
            await this.clearLoader(this.getView());
            throw error;
        }
    },

    onPainted() {
        this._showDeviceData(true);
    },

    onloadDeletedDataTap() {
        this._showDeviceData(false);
    },

    _showDeviceData(isShow = true) {
        this.getView().down('#restoreBox').setHidden(isShow);
        this.getView().down('#recordData').setHidden(!isShow);
        this.getView().down('#loadDeletedDataButton').setHidden(!isShow);
    },

    _getRecordAsJSON(rawData) {
        let showData = {};
        try {
            showData = JSON.stringify(rawData, null, '\t');
        } catch (err) {
            showData = { error: err.message };
            handleClientError(err);
        }
        return showData;
    },
});

Ext.define('FieldServices.view.report.usageReport.UsageReportBaseGrid', {
    extend: 'Ext.grid.Grid',

    xtype: 'usageReportBaseGrid',

    layout: 'auto',

    variableHeights: true,
    plugins: [{
        type: 'gridexporter',
    }],

    bind: {
        masked: '{usageReportLoading}',
    },
    itemConfig: {
        viewModel: true,
    },
    emptyText: true,

    config: {
        reportName: null,
        showDiffs: false,
        convertBooleans: false,
        dataKeys: [],
        columnWidthOverrides: null,
        store: null,
    },
    discardMeasureRow: true,

    constructor(config) {
        config.columns = this.getColumnDefs(config);
        this.callParent(arguments);
    },

    getColumnDefs(config) {
        return config.dataKeys.map(key => {
            const columnFlex = config.columnWidthOverrides && config.columnWidthOverrides[key] ? config.columnWidthOverrides[key] : 1;
            const columnDef = {
                cls: 'full-text-cell',
                text: this.transformKeyToTitle(key),
                dataIndex: key,
                flex: columnFlex,
                height: 70,
                resizable: false,
                cell: {
                    encodeHtml: false,
                    cls: 'full-text-cell',
                },
            };

            columnDef.renderer = (value, record) => {
                const showDiffs = this.getShowDiffs();
                const convertBooleans = this.getConvertBooleans();

                let processedValue = value;
                if (convertBooleans && [0, 1, '0', '1'].includes(value)) {
                    processedValue = value ? 'Yes' : 'No';
                }
                if (!showDiffs || !Object.keys(record.data).includes(`${key}_diff`)) {
                    return processedValue;
                }

                const diffValue = record.data[`${key}_diff`];
                if (diffValue === 0) {
                    return processedValue;
                }
                let diffCls = diffValue > 0 ? 'upward' : 'downward';
                let diffText = `${diffValue > 0 ? '+' : ''}${diffValue}`;
                if (convertBooleans) {
                    diffText = '';
                    diffCls += ` ion-android-arrow-${diffValue > 0 ? 'up' : 'down'}`;
                }
                return `<div class="${diffCls} ">${processedValue} <i class="text-small">${diffText}</i></div>`;
            };
            return columnDef;
        });
    },

    transformKeyToTitle(key) {
        const titleWords = key.split('_').filter(word => word !== 'is' && word !== 'count');
        return titleWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },

});

Ext.define('FieldServices.view.Viewport', {
    extend: 'Ext.navigation.View',
    itemId: 'Viewport',
    alias: 'widget.viewport',
    requires: [
        'FieldServices.view.login.Login',
        'Ext.navigation.Bar',
    ],

    config: {
        layout: 'card',
        navigationBar: false,
        itemId: 'mainContainer',
        items: [
            {
                xtype: 'loginview',
                itemId: 'loginView',
            },
        ],
    },
});

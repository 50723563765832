Ext.define('FieldServices.view.report.monthlyReports.detailed.filter.MonthlyReportsFilterViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.monthlyReportsFilterViewController',
    xtype: 'monthlyReportsFilterViewController',

    config: {
        shows: null,
    },

    control: {
        monthSelect: true,
        whiteLabelSelect: true,
        promoExcludeCheckbox: {
            change: 'onPromoExcludeClick',
        },
    },

    onPromoExcludeClick(checkbox, newValue) {
        this.getView().fireEvent('promoExcludeChanged', newValue);
    },
    onChangeFilter() {
        this._changeFilter();
    },

    onDownloadCsvFile() {
        this.getView().fireEvent('exportRequested', {});
    },

    _changeFilter() {
        const filterData = this.getView().getFilters();

        this.getView().fireEvent('changeFilter', { filterData });
    },

});

const moment = require('moment');

Ext.define('FieldServices.view.company.info.clientError.ClientErrorInfoViewModel', {
    extend: 'Ext.app.ViewModel',
    requires: ['FieldServices.store.ClientErrorInfo'],
    alias: 'viewmodel.clientErrorInfoViewModel',
    xtype: 'clientErrorInfoViewModel',
    data: {
        hasExtraInfo: false,
    },
    stores: {
        aggregateStore: {
            type: 'clientErrorInfo',
            autoLoad: true,
            proxy: {
                extraParams: {
                    tableName: 'clientError',
                    groupBy: 'message',
                    fields: [{
                        name: 'occurrences',
                        unique: ['deviceId', 'dtCreated'],
                    }, {
                        name: 'deviceIds',
                        unique: 'deviceId',
                    }, {
                        name: 'stackTraces',
                        unique: 'stackTrace',
                    }, {
                        name: 'appVersions',
                        unique: 'appVersion',
                    }, {
                        name: 'data',
                        data: ['dtCreated', 'stackTrace', 'deviceId'],
                    }],
                },
            },
            filters: [
                {
                    property: 'companyId',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'dtCreated',
                    operator: '>=',
                    type: CONSTANTS.FILTER.TYPES.DATE,
                    value: moment().subtract(2, 'days'),
                }, {
                    property: 'details.isHarmful',
                    operator: '=',
                    value: true,
                }],
        },
    },
});

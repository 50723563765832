import CLIENT_SHARED_CONSTANTS from 'client/shared/constants';

const MarkdownIt = require('markdown-it');
const linkTarget = require('markdown-it-link-target');

const markdown = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    image: true,
    breaks: true,
});

markdown.use(linkTarget, {
    target: '_blank',
});

module.exports = {
    linkifyCardNumbers(comment) {
        return CLIENT_SHARED_CONSTANTS.CARD_FORMATS.reduce((prev, config) => {
            if (typeof prev !== 'string') {
                return prev;
            }
            const newValue = config.URL_PATTERN.replace(config.CARD_NUMBER_KEY, config.CARD_NUMBER_POSITION);
            return prev.replace(config.CARD_NUMBER_PATTERN, newValue);
        }, comment);
    },

    render(input) {
        return markdown.render(this.linkifyCardNumbers(input || ''));
    },

    renderInline(input) {
        return markdown.renderInline(this.linkifyCardNumbers(input || ''));
    },
};

/**
 * This is an extension of the Ext.data.Model class. All of our Model classes
 * are inherited from this class.
 *
 */
const _ = require('lodash');

export default Ext.define('FieldServices.model.override.Model', {
    extend: 'Ext.data.Model',
    identifier: 'sequential',

    // idProperty: 'internalId',
    config: {
        pnAssociations: [],
    },

    statics: {

        validationEnabled: true,

    },

    get(fieldName) {
        if (!fieldName) {
            return undefined;
        }
        return fieldName.includes('.')
            ? _.get(this.data, fieldName)
            : this.data[fieldName];
    },

    inheritableStatics: {
        replaceFields(oldFields, newFields) {
            //
            // replaceField method (whats used in Model.addFields) screws the idProperty field,
            //  - reported at: https://www.sencha.com/forum/showthread.php?303756-Model.replaceFields-reads-idProperty-from-wrong-object
            //  SO need to set it back
            //
            const proto = this.prototype;
            const idField = this.fieldsMap[this.idProperty];
            if (oldFields && oldFields.length > 0) {
                this.callParent([oldFields, newFields]);
                this.idField = idField;
                proto.idField = idField;
                idField.allowNull = true;
                idField.critical = true;
                idField.identifier = true;
                idField.defaultValue = null;
            }
        },
    },
    /**
     * Validate model if FieldServices.model.override.Model.validationEnabled flag is true
     * @returns {*}
     */
    isValid() {
        if (FieldServices.model.override.Model.validationEnabled) {
            return this.getValidation().isValid();
        }
        return true;
    },

    /**
     * For all classes inherited from this class we should read the shared
     * model definition file and use that for field definition.
     * We do this immediately at the beginning when the config is initialised
     *
     */

    /**
     * Load shared model definition and update our model definition's field config
     * with the field list read from the shared file.
     * This must be called once the class is loaded. Currently it is invoked from
     * Ext.ClassManager.createdListeners
     *
     * @param className
     */
    addSharedDefinition() {

    },

    /**
     * getter for custom model config property: documentTypeId
     *
     * we need to define custom getters for custom config proprties
     *
     * @returns {string|string|string|string|string|string|*}
     */
    getDocumentTypeId() {
        return this.config.documentTypeId;
    },

    /**
     * Remove all items from the data object which are not belonging to the
     * record.
     *
     */
    cleanRecordData() {
        const fieldMap = {};
        const me = this;
        Ext.Array.each(this.getFields(), field => {
            fieldMap[field.getName()] = true;
        });

        Ext.Object.each(this.data, key => {
            if (!fieldMap[key]) {
                delete me.data[key];
            }
        });
    },

    /**
     * Get record data
     *
     * @param record
     * @returns {{}}
     */
    getRecordData(record) {
        const fields = record.getFields();
        const data = {};
        let name;
        let value;

        fields.each(field => {
            if (field.getPersist()) {
                name = field.getName();
                value = record.get(name);
                data[name] = value;
            }
        }, this);

        return data;
    },

    /**
     * Get the item Id from the current Id by decoupling the device ID
     *
     * @returns {number}
     */
    getItemId() {
        return parseInt(this.data.id, 10) % SyncManager.TWO_POW_32;
    },

    /**
     * Get the current device Id from the record ID
     *
     * @returns {number}
     */
    getUserDeviceId() {
        return (parseInt(this.data.id, 10) - this.getItemId()) / SyncManager.TWO_POW_32;
    },

    /**
     * Used as an instance method (added by an override in enhanceModels)
     *
     * This is used to give a default 'remove' method to Models.
     * It's added in the enhanceModels function.
     *
     * @param sync
     */
    singleRemove(sync) {
        //
        // get storeName ("FieldServices.model.Customer" -> "Customer")
        //
        const storeName = this.modelName;
        const store = Ext.getStore(storeName);

        store.remove(this);
        //
        // param sync defaults to true
        //
        if (sync !== false) {
            store.sync();
        }
    },

    /**
     * ST2 getFields() function returns object of name=>field map while Ext6 returns
     * and array of fields. Some code uses ST2 getField so we provide this converter
     *
     * @returns {*}
     */
    getFieldNames() {
        return this.getFields().map(field => field.name);
    },

    /**
     * New composite remove
     *
     *  Strategy here is:
     *  remove all cascade connections, if it's a 1:n connection
     *
     *  If it's a n:m connection, remove the cascaded records only if they're
     *  not connected to any other record
     *  The linked record is removed in this case too.
     *
     *  Not supported:
     *  multiple connections, like where:
     *  a Payment is connected to Documents (n:m) as it is now PLUS a Payment is connected to
     *  some other model. If that's the case, the Payment is deleted when a document is, even if
     *  the Payment is connected to something else.
     *  Basically, a model can only appear once on the left, and once on the right side.
     *
     * @param sync
     */
    remove(sync, callerModel) {
        const me = this;
        const { modelName } = this;
        const { pnAssociations } = this.config;
        const selfId = this.data.id;
        callerModel = callerModel || [];

        //
        // recursion should be stopped here
        // find me in next associations
        //
        // console.log('remove called on', modelName, selfId);
        callerModel.push(modelName);

        Ext.Array.each(pnAssociations, association => {
            //
            // set cascadeDelete config to true by default (if its not defined)
            //
            if (typeof association.cascadeDelete === 'undefined') {
                association.cascadeDelete = true;
            }

            //
            // association should be: not serverOnly and cascadeDelete
            //
            if (!association.serverOnly && association.cascadeDelete) {
                if (association.type == 'hasMany' || association.type == 'hasOne') {
                    const foreignStore = Ext.getStore(association.model);
                    if (foreignStore) {
                        const foreignRecords = foreignStore.getRecordsByFilter(association.parameters.foreignKey.fieldName, selfId);
                        // console.log(association.model, association.parameters.foreignKey, selfId, foreignRecords);
                        Ext.Array.each(foreignRecords, rec => {
                            // console.log('call remove() on 1:n foreignRecord', association.model, rec.data.id);
                            rec.remove(false, callerModel);
                        });
                    }
                } else if (association.type == 'belongsToMany') {
                    //
                    // avoiding endless recursion
                    //
                    // if (callerModel[callerModel.length-3] !== association.model) {
                    if (callerModel[callerModel.length - 3] !== association.model) {
                        //
                        // do nothing if connection table not defined
                        //
                        if (!association.parameters.through) {
                            // console.log('[ERROR] - connection table not defined in n:m relation!');
                            return;
                        }

                        const linkStore = Ext.getStore(association.parameters.through);
                        const mStore = Ext.getStore(association.model);

                        if (linkStore && mStore) {
                            const linkRecords = linkStore.getRecordsByFilter(association.parameters.foreignKey.fieldName, selfId);

                            //
                            // iterate link records
                            //
                            Ext.Array.each(linkRecords, linkRecord => {
                                //
                                // store filterValue
                                //
                                const filterValue = linkRecord.data[association.modelKeys.foreign];
                                //
                                // remove link record from linkTable if its not a backLink to linkTable
                                //
                                if (callerModel[callerModel.length - 2] !== association.parameters.through) {
                                    // console.log('call remove() on n:m linkRecord', association.parameters.through, linkRecord.data.id);
                                    linkRecord.remove(false, callerModel);
                                }
                                //
                                // get all linked records
                                //
                                const mRecords = mStore.getRecordsByFilter(association.modelKeys.primary, filterValue);
                                Ext.Array.each(mRecords, mRecord => {
                                    if (!association.ignoreModelOnCascadeDelete) {
                                        //
                                        // remove record from linkedTable
                                        //
                                        // console.log('call remove() on n:m foreignRecord', association.model, mRecord.data.id);
                                        mRecord.remove(false, callerModel);
                                    }
                                });
                            });
                        }
                    } else {

                        // console.log('[STOP] endless recursion stopped at', association.model, 'on', callerModel[callerModel.length-1]);

                    }
                }
            }
        });

        this.singleRemove(false);

        if (sync !== false) {
            // this is the 'first level', we have to call sync's
            this.syncInCascadeOrder(modelName);
        }

        // console.log(modelName, selfId, 'has been removed!');
        // console.log('--');
    },

    /**
     *  Calls modified stores. Each store is called once only, the order is important
     *  so FK contraints are not violated on server.
     *  if multiple sync is needed on one store, like:
     *  store1.sync(), store2.sync(), store1.sync()
     *  then this strategy needs to be revised.
     *
     * @param modelName
     */
    syncInCascadeOrder(modelName, calledModel) {
        calledModel = calledModel || [];
        calledModel.push(modelName);

        //
        // invalid model!
        //
        if (!FieldServices.model[modelName]) {
            return;
        }
        //
        // extremely disgusting solution to get pnAssociations by modelName
        // would be better if we can copy statics in addSharedDefinition()
        //
        const model = new FieldServices.model[modelName]();
        const cascadeModels = model.config.pnAssociations;

        if (cascadeModels) {
            Ext.Array.each(cascadeModels, association => {
                //
                // if linkTable defined, then sync that table first
                //
                if (association.parameters.through) {
                    this.syncInCascadeOrder(association.parameters.through, calledModel);
                }
                //
                // sync association model if its not a backLink
                //
                if (!Ext.Array.contains(calledModel, association.model)) {
                    this.syncInCascadeOrder(association.model, calledModel);
                }
            });
        }

        Ext.getStore(modelName).sync();
    },

    /**
     * tells if a model is still in syncRecords table
     * @returns {boolean}
     */
    isInSyncRecords() {
        const me = this;
        const syncRecordsStore = Ext.getStore('SyncRecords');
        const syncRecords = syncRecordsStore.getRecordsByFilterFunc(record => {
            const { data } = record;
            return (data.store == me.$className.split('.')[2] && data.record_id == me.data.id && data.company_id == SyncManager.company_id && (data.operation === 'update' || data.operation === 'create'));
        });

        return syncRecords.length > 0;
    },

},

function () {
    const Model = this;
    Model.onExtended((Class, data) => {
        if (!data.statics || !data.statics.complexModel || !data.statics.complexModel.modelProperties.modelName) {
            return;
        }

        const modelFields = data.statics.complexModel.Entity.fields;
        const modelAssociations = data.statics.complexModel.modelProperties.associations;

        Class.prototype.modelName = data.statics.complexModel.modelProperties.modelName;

        if (data.config && data.config.fields && data.config.fields.length > 0) {
            Class.replaceFields(data.config.fields, ['id']);
        }

        if (modelFields) {
            const clientFields = [];
            Ext.Array.each(modelFields, item => {
                // the app will not send critical data
                delete item.critical;
                if (!item.serverOnly) {
                    clientFields.push(item);
                }
            });
            //
            // Set fields
            //
            Class.replaceFields(clientFields, ['id']);
            //
            // Import Associations if its defined (and not serverOnly)
            //
            if (modelAssociations) {
                const pnAssociations = [];
                Ext.Array.each(modelAssociations, item => {
                    if (!item.serverOnly) {
                        pnAssociations.push(item);
                    }
                });
                if (pnAssociations.length > 0) {
                    if (!data.config) {
                        data.config = {};
                    }
                    data.config.pnAssociations = pnAssociations;
                }
            }
        }
    });
});

Ext.define('FieldServices.view.whiteLabel.addNew.addNewViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.addNewWhiteLabelPartnerViewController',

    requires: [],

    control: {
        partnerDetails: {
            saveRecord: 'onNewPartnerSave',
            cancelRecordChanges: 'onCancelNewPartner',
        },
    },

    config: {},

    onNewPartnerSave() {
        this._saveNewPartner();
        this._closeModal();
    },

    onCancelNewPartner() {
        this._closeModal();
    },

    _saveNewPartner() {
        const partnerStore = Ext.getStore('WhiteLabel');
        const { record } = this.getViewModel().getData();
        partnerStore.add(record);
        partnerStore.sync();

        this.getView().fireEvent('partnerAdded');
    },

    _closeModal() {
        this.getView().destroy();
    },
});

Ext.define('FieldServices.view.company.select.SelectCompanyView', {
    extend: 'Ext.Dialog',

    requires: [
        'FieldServices.view.company.select.SelectCompanyViewController',
        'FieldServices.view.company.select.find.FindCompanyView',
        'FieldServices.view.company.select.list.SelectCompanyListView',
    ],

    alias: 'widget.selectCompanyBox',

    controller: 'selectCompanyMainCtrl',
    cls: 'companySelectorDialog',
    title: 'Find Company',
    resizable: true,
    tools: [{
        type: 'close',
        handler: 'onCloseSelectPanel',
    }],

    keyMap: {
        ESC: {
            handler: 'onCloseSelectPanel',
            scope: 'controller',
            event: 'keydown',
        },
    },

    layout: 'vbox',

    items: [
        {
            xtype: 'findCompany',
            itemId: 'findCompany',
            layout: 'vbox',
        },
        {
            xtype: 'selectCompanyListBox',
            layout: 'vbox',
            flex: 1,
            itemId: 'selectCompanyList',
        },

    ],

});

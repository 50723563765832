import ComplexModel from 'shared/modules/complexData/roles';

export default Ext.define('FieldServices.model.Roles', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,

        _getDefaultValueBy(roleId, profileId) {
            if (roleId === null) {
                return null;
            }
            const defaultValues = Ext.getStore('UserProfileRole').data.items.filter(record => record.data.role_id === roleId && record.data.userprofile_id === profileId);
            return defaultValues[0] && defaultValues[0].getId() ? 1 : 0;
        },
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',
    config: {
        fields: [{
            name: 'mainDefaultValue',
            calculate(recordData) {
                return this.owner._getDefaultValueBy(recordData.id, CONSTANTS.USER_PROFILE.MAIN_USER);
            },
        }, {
            name: 'managerDefaultValue',
            calculate(recordData) {
                return this.owner._getDefaultValueBy(recordData.id, CONSTANTS.USER_PROFILE.MANAGER);
            },
        }, {
            name: 'engineerDefaultValue',
            calculate(recordData) {
                return this.owner._getDefaultValueBy(recordData.id, CONSTANTS.USER_PROFILE.ENGINEER);
            },
        }],
    },

});

Ext.define('FieldServices.overrides.FieldSelect', {
    override: 'Ext.field.Select',

    config: {
        usePicker: false,
        autoSelect: true,
        required: true,
        /**
         * This should be esentially the same as forceSelection, but moments before 7.5 release we didn't want a global
         * change. We must find out why forceSelection isn't as atm we can call setValue with any value which doesn't
         * exists in store. Before Ext 6.5 the creditNoteView had null for its vat_id field when we set a nonexisting
         * vat_id(e.g. after changing country) but now the very first call in the init can set that value into vat_id.
         */
        allowUnmatchedValue: true,
        floatedPicker: {
            // BoundListNavigationModel binds to input field
            // Must only be enabled when list is visible
            navigationModel: {
                disabled: true,
            },
            scrollToTopOnRefresh: false,
            loadingHeight: 70,
            maxHeight: 300,
            floated: true,
            axisLock: true,
            hideAnimation: null,
        },
        isField: true,
    },
    picker: 'floated',

    show(...args) {
        this.callParent(args);
        this.setModal(true);
    },

    getPicker(...args) {
        const picker = this.callParent(args);
        picker.setModal(true);
        return picker;
    },

    onStoreDataChanged(...args) {
        //
        // hide picker if data has been changed in store
        //
        this.hidePicker(true);
        //
        // everything else keep going on the same way
        //
        this.callParent(args);
    },

    onListTap() {
        this.getPicker().hide();
    },

    /**
     * hides selectField's picker (drop-down)
     */
    hidePicker(ignoreCreate) {
        //
        // hidePicker function wasn't implemented by default
        //
        if (!ignoreCreate) {
            this.getPicker().hide();
        }
    },

    /**
     * override for PN-5504, set selection to null if value also cleared, and update the selection to
     * the auto if needed
     */
    syncValue() {
        const store = this.getStore();
        const valueField = this.getValueField();
        const displayField = this.getDisplayField();
        const forceSelection = this.getForceSelection();
        const valueNotFoundText = this.getValueNotFoundText();
        let isInput;
        let matchedRecord;
        let dataObj;

        // If we are not ready to reconcile values for any reason.
        //   We are in the middle of value syncing
        //   Store has not arrived from bind
        //   Store has not been loaded
        //   Store is currently loading
        // Then we cannot recconcile values now, this will be called later
        // when the store arrives, or is loaded.
        if (this.reconcilingValue || !store || !store.isLoaded() || store.hasPendingLoad()) {
            return;
        }

        this.reconcilingValue = true;

        this.getSelection(); // make sure selection config is flushed

        const is = {};
        is[this.syncMode] = true;
        const value = (isInput = is.input || is.filter) ? this.getInputValue() : this.getValue();
        const isCleared = value == null || value === '';

        // Get the record that matches our input value
        if (!isCleared) {
            matchedRecord = (isInput ? store.byText : store.byValue).get(value);

            if (matchedRecord) {
                if (!matchedRecord.isEntity) {
                    // Since we lookup values not id's there can be multiple matching
                    // records... so if we get back something that isn't a record, it is
                    // an array.
                    matchedRecord = matchedRecord[0];
                }
            } else if (!forceSelection) {
                // Not found in the regular indexes which index the store.
                // If we are potentially inserting unmatched values as new "isEntered"
                // records, then find a match in the valueCollection if possible.
                matchedRecord = this.findRecordByValue(value);
            }
        }

        // Either user has typed something (isInput), or we've had a setValue
        // to a value which has no match in the store, and we are not forceSelection: true.
        // We create a new record.
        if (!isCleared && !matchedRecord && !forceSelection) {
            dataObj = {};
            dataObj[displayField] = value;

            if (valueField && displayField !== valueField) {
                dataObj[valueField] = value;
            }

            matchedRecord = this.createSelectionRecord(dataObj);
            matchedRecord.isEntered = true;
        } else if (isInput || is.store || !this.getAllowUnmatchedValue()) {
            // Not in an record.isEntered situation.
            // Value is the typed value.
            if (!matchedRecord && forceSelection) {
                this.setValue(null);
                this.setSelection(null);

                // If we're processing a store load in response to remote filtering
                // then we must not clear the input value used to kick off that filter.
                // If they blur the field now, completeEdit will clear the value as unmatched.
                if (!is.filter) {
                    this.setFieldDisplay();
                }
            }
        } else {
            // Value is the set value.
            if (isCleared) {
                if (this.mustAutoSelect()) {
                    matchedRecord = store.first();

                    if (this.getAutoSelect() === 'initial') {
                        this.setAutoSelect(false);
                    }
                }
                //
                // fix for PN-5504: when the field is cleared (and the value at this point is already cleared, set to null)
                // we need to also clear the selection, later if matched record found will update to the correct selection
                // setSelection to null cannot depends on mustAutoSelect, if will set to matchedRecord anyway
                //
                //     } else {
                this.setSelection(null);
                //                 }
            } else if (!matchedRecord && valueNotFoundText) {
                // We have a value, so get the record that matches our current value.
                // Note that setValue can
                this.setError(valueNotFoundText);
            }
        }

        if (matchedRecord) {
            this.setSelection(matchedRecord);
        }

        this.reconcilingValue = false;
    },

    getFilters() {
        const filters = [];
        const property = this.fieldName ? this.fieldName : this.getName();
        const value = this.filterValue ? this.filterValue : this.getValue();
        const operator = this.filterOperator;
        if (this.getIsFilter() !== false && operator && value && value !== '') {
            const filter = {
                value, operator, property, isDisabled: !!this.getDisabled(),
            };
            filters.push(this.addTableName(filter));
        }
        return filters;
    },
});

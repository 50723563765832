import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.SettingsKeys', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.SettingsKeys'],
    alias: 'store.SettingsKeys',
    config: {
        model: 'FieldServices.model.SettingsKeys',
        storeId: 'SettingsKeys',
    },
    storeId: 'SettingsKeys',
    proxy: {
        type: 'PoweredNowApi',

        url: `${require('config.js').SERVER_URL}api/admindata/global/SettingsKeys`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',

        },
    },
});

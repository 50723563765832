Ext.define('FieldServices.view.company.purchases.extra.add.AddExtraView', {
    extend: 'Ext.form.Panel',
    requires: [
        'FieldServices.view.company.purchases.extra.add.AddExtraViewController',
    ],

    xtype: 'addExtraView',

    controller: 'addExtraViewController',

    // store: 'User',

    itemId: 'ExtraPopupId',

    scrollable: true,

    title: 'New extra consumable',

    tools: [{
        type: 'close',
        handler: 'hideExtraPopup',
    }],

    defaultType: 'textfield',
    layout: 'vbox',
    defaults: {
        labelAlign: 'top',
        flex: 1,
    },
    items: [
        {
            xtype: 'selectfield',
            itemId: 'selectFieldProducts',
            label: 'Product',
            name: 'product',
        },
        {
            xtype: 'container',
            layout: 'hbox',
            items: [{
                flex: 1,
                xtype: 'powerednow.datetimefield',
                itemId: 'purchaseDate',
                label: 'Purchase date:',
                name: 'purchase_date',
                mode: 'date',
                dateFormat: 'Y-m-d',
                value: new Date(),
            },
            {
                flex: 1,
                xtype: 'powerednow.datetimefield',
                itemId: 'expiresDate',
                label: 'Expires date:',
                name: 'expires_date',
                mode: 'date',
                dateFormat: 'Y-m-d',
                value: new Date(),
            }],
        },

        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->',
                {
                    xtype: 'button',
                    text: 'Submit',
                    iconCls: 'ion-ios-checkmark-outline',
                    handler: 'onSubmitExtra',
                }, {
                    xtype: 'button',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                    handler: 'hideExtraPopup',
                }],
        },
    ],

});

Ext.define('FieldServices.view.components.contactMethod.ContactMethodView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.components.contactMethod.ContactMethodViewModel',

    ],

    xtype: 'contactMethodView',

    itemConfig: {
        viewModel: {
            type: 'contactMethodModel',
        },
    },

    height: 110,
    margin: '10 0 0 10',

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'grideditable',
        enableDeleteButton: false,
    }],

    selectable: false,

    emptyText: 'empty',

    columns: [{
        text: 'Contact Method id',
        dataIndex: 'id',
    }, {
        text: 'Type Name',
        dataIndex: 'contactMethodTypeName',
        editable: true,
    }, {
        text: 'value',
        dataIndex: 'value',
        flex: 1,
        editable: true,
    }, {
        text: 'isValid',
        dataIndex: 'is_valid',
        editable: true,
    }, {
        text: 'isDeleted',
        dataIndex: 'isdeleted',
        renderer: 'rendererIsDeleted',
        defaultEditor: {
            xtype: 'checkbox',
        },
        editable: true,
    }],
});

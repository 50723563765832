import { ACTION } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.view.company.appointment.list.AppointmentListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.appointmentListController',
    xtype: 'appointmentListController',

    config: {
        companyId: null,
        filterValue: '',
    },
    control: {
        '': {
            painted: 'onViewShow',
        },
    },

    jobMap: {},

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this._reLoadAppointments, this);
        this.loadAppointmentTypeFilterOptions();
    },
    onViewShow() {
        this._reLoadAppointments();
    },

    loadAppointmentTypeFilterOptions() {
        const filterCmp = this.getGridFilterPlugin().getAdditionalFilterFields()[0];
        const availableTypeOptions = Object.entries(ACTION.ACTION_TYPES_STRINGS)
            .filter(typeRecord => [
                ACTION.ACTION_TYPES.ACTION_TYPE_APPOINTMENT,
                ACTION.ACTION_TYPES.ACTION_TYPE_HOLIDAY_SICKNESS_OR_OTHER,
                ACTION.ACTION_TYPES.ACTION_TYPE_SIMPLE,
                ACTION.ACTION_TYPES.ACTION_TYPE_REMINDER,
            ].includes(parseInt(typeRecord[0], 10)))
            .map(([typeId, typeName]) => ({ value: typeId.toString(), text: typeName }));
        filterCmp.options = [
            { value: '', text: 'Any' },
            ...availableTypeOptions,
        ];
    },
    _openAppointmentPopup(elem) {
        const popup = References.getAppointmentPopup().show();
        popup.getController().setCompanyId(this.getCompanyId());
        popup.getController().setRecord(elem.ownerCmp.getRecord());
    },

    async _restoreAppointment(elem) {
        const record = elem.ownerCmp.getRecord();
        if (!record.data.isdeleted) {
            throw new Error(`Cannot restore action with id: ${record.data.id}. Action is not deleted.`);
        }
        record.set({ isdeleted: false });
        await FieldServices.app.callAPIEndpointRequest('PUT', `api/admindata/company-tables/Action/${this.getCompanyId()}`, record.data);
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('appointmentFilterPlugin');
    },

    _reLoadAppointments() {
        this.getGridFilterPlugin().reloadData();
    },

    onInfoButtonTap(elem) {
        this._openAppointmentPopup(elem);
    },

    onRestoreButtonTap(elem) {
        this._restoreAppointment(elem)
            .then(() => this._reLoadAppointments())
            .catch(handleClientError);
    },

    _removeAssociationData(recordData) {
        return Object.keys(recordData).reduce((prev, key) => {
            if (key.indexOf('.') === -1) {
                prev[key] = recordData[key];
            }
            return prev;
        }, {});
    },

    _setStore(appointments) {
        const store = Ext.create('Ext.data.Store').setData((appointments || []));
        this.getView().setStore(store);

        this.clearLoader(this.getView());
    },

    rendererIsSent(value, record, title, row) {
        row.toggleClsOnRow('grid_row_isSent', (value === true || value === 1 || value === 'true'));
        return value;
    },
});

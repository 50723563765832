/**
 *
 * @class Override.Button
 * @extends Ext.Button
 *
 */
require('./Tab.scss');

Ext.define('FieldServices.overrides.Tab', {
    override: 'Ext.Tab',
});

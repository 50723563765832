Ext.define('FieldServices.view.company.document.list.PaymentListItemViewModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.PaymentListItemViewModel',
    xtype: 'PaymentListItemViewModel',

    config: {
        uiMap: {
            isdeleted: {
                ui: '-IsDeleted',
                condition(dataItem) {
                    return Boolean(dataItem.isdeleted);
                },
            },
        },
    },
});

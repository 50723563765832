Ext.define('FieldServices.view.purchaseSetup.PurchaseSetupViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.purchaseSetupViewModel',

    stores: {

        purchaseSetup: {
            type: 'PurchaseSetup',
            autoLoad: true,
            sorters: [
                {
                    property: 'id',
                    direction: 'ASC',
                },
            ],
        },
        purchaseSetupChained: {
            source: '{purchaseSetup}',
        },
    },
});

Ext.define('FieldServices.view.report.salesReport.filter.SalesReportFilterViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.salesReportFilterViewController',
    xtype: 'salesReportFilterViewController',

    control: {
        monthSelect: true,
        whiteLabelSelect: true,
        includeChurnedCheckbox: true,
        maxUserCountField: true,
    },

    async loadFilterOptions() {
        try {
            const apiResponse = await FieldServices.app.callAPI(
                {
                    url: 'api/admindata/usageReport/getFilterOptionsForSalesReports',
                    method: 'GET',
                    params: {},
                    headers: {},
                },
            );
            this.getViewModel().setData({ ...apiResponse });
            this.getMaxUserCountField().setValue(apiResponse.maxUserCount);
        } catch (error) {
            handleClientError(error);
        }
    },

    onChangeFilter() {
        this._changeFilter();
    },

    onDownloadCsvFile() {
        this.getView().fireEvent('exportRequested', {});
    },

    _changeFilter() {
        const filterData = this.getView().getFilters();
        const includeChurned = this.getIncludeChurnedCheckbox().getChecked();
        this.getView().fireEvent('changeFilter', { filterData, includeChurned });
    },

});

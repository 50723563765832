Ext.define('FieldServices.proxy.PoweredNowApi', {
    extend: 'Ext.data.proxy.Rest',
    alias: 'proxy.PoweredNowApi',
    config: {
        urlFormat: '{url}/{companyId}/{recordId}',
        readInChunks: true,
    },
    chunkSize: 500,

    getParamUrl(request) {
        const me = this;
        return me.getUrl(request);
    },

    getParamRecordId(request) {
        const operation = request.getOperation();
        const records = operation.getRecords();
        const record = records ? records[0] : null;

        if (record) {
            return record.data.id;
        }

        return null;
    },

    getParamCompanyId(request) {
        const operation = request.getOperation();
        const records = operation.getRecords();
        const record = records ? records[0] : null;

        if (record) {
            return record.store.type !== 'Company' ? record.data.company_id : record.data.id;
        }

        return null;
    },

    createUrlTemplate(request) {
        const urlFormat = this.getUrlFormat();
        const foo = new Ext.Template(urlFormat);
        return foo.apply({
            url: this.getParamUrl(request),
            // id: this.getParamId(request),
            companyId: this.getParamCompanyId(request),
            recordId: this.getParamRecordId(request),
        });
    },

    buildUrl: function buildUrl(request) {
        const me = this;
        let url = this.getParamUrl(request);
        const format = me.getFormat();
        const companyId = this.getParamCompanyId(request);
        const urlTemplate = this.createUrlTemplate(request);
        let params;

        if (me.getAppendId() && me.isValidId(companyId)) {
            if (!url.match(me.slashRe)) {
                url += '/';
            }

            url += encodeURIComponent(companyId);
            params = request.getParams();

            if (params) {
                delete params[me.getIdParam()];
            }
        }

        if (format) {
            if (!url.match(me.periodRe)) {
                url += '.';
            }

            url += format;
        }

        request.setUrl(urlTemplate);
        return urlTemplate;
    },

    applyChunkSize(value) {
        if (value < 1) {
            // We want to make it obvious during development that the config value makes no sense, but don't really need to crash
            // <debug>
            throw new Error('chunkSize must be greater than 0!');
            // </debug>
            // eslint-disable-next-line
            return 500;
        }
        return value;
    },

    getHeaders(...args) {
        const originalHeaders = this.callParent(args);
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');

        return {
            ...originalHeaders,
            ...{
                [CONSTANTS.HTTP.AUTHENTICATION_HEADER_TOKEN]: adminToken,
                [CONSTANTS.HTTP.NEED_COUNT]: true,
            },
        };
    },

    async readAsync(operation) {
        const url = this.getUrl();
        const initialParams = Ext.apply({}, this.getParams(operation));
        const params = Ext.applyIf(initialParams, this._getPreparedExtraParams() || {});
        const headers = this.getHeaders();
        // @TODO
        // Not yet working for virtual stores, that would be the biggest gain we can have, but we need to change
        // filtering/ordering logic too, and the `end` calculation is bad.
        let { start } = params;
        const { limit } = params;
        let end = (limit * params.page);
        const resultSet = new Ext.data.ResultSet({ records: [] });
        const chunkSize = Math.min(this.chunkSize, limit > 0 ? limit : this.chunkSize);
        let responseData;
        try {
            responseData = await this.getResponseDataChunk({
                url,
                params,
                headers,
                method: 'GET',
            }, operation, start, chunkSize);
            const responseLength = responseData.getRecords().length;
            start += responseLength;
            resultSet.setRecords(resultSet.getRecords().concat(responseData.getRecords()));
            if (limit === -1) {
                end = responseData.total;
            }
            end = Math.min(responseLength, end);
            // @TODO This should be modified to send to chunked request at a time, to use both API.
            // Shouldn't be that hard as we don't really need to rely on response's count property, simply
            // generate as many request promises as we need and send out everything with {concurrency: apiCount}
            // The only thing we have to figure out is to keep ordering of the results.
            let requestNext = this.getReadInChunks() && end < responseData.total;
            while (requestNext) {
                const chunkData = await this.getResponseDataChunk({
                    url,
                    params,
                    headers,
                    method: 'GET',
                }, operation, start, chunkSize);
                resultSet.setRecords(resultSet.getRecords().concat(chunkData.getRecords()));
                start += chunkData.getRecords().length;
                requestNext = chunkData.count > 0 && this.getReadInChunks();
            }
        } catch (error) {
            // @TODO What we really need here is a retry logic as we don't really have to fail if one network requests
            // fail when we are requesting thousands of records. This is nothing bad than it was, but we can make it
            // way more better with a little more effort.
            this.processResponseChunks(false, resultSet, operation);
            return;
        }
        resultSet.total = responseData.total;
        resultSet.success = true;
        resultSet.remoteTotal = responseData.remoteTotal;
        this.processResponseChunks(true, resultSet, operation);
    },
    /**
     * This is basically processResponse, with minor modifications as `operation` is meaningless, we can have
     * _lots_ of operations.
     * @param success
     * @param resultSet
     * @param operation
     */
    processResponseChunks(success, resultSet, operation) {
        if (this.destroying || this.destroyed) {
            return;
        }
        let exception;

        // Processing a response may involve updating or committing many records
        // each of which will inform the owning stores, which will ultimately
        // inform interested views which will most likely have to do a layout
        // assuming that the data shape has changed.
        // Bracketing the processing with this event gives owning stores the ability
        // to fire their own beginupdate/endupdate events which can be used by interested
        // views to suspend layouts.
        this.fireEvent('beginprocessresponse', this, {}, operation);

        if (success === true) {
            operation.process(resultSet, {}, {});
            exception = !operation.wasSuccessful();
        } else {
            this.setException(operation, {});
            exception = true;
        }

        // It is possible that exception callback destroyed the store and owning proxy,
        // in which case we can't do nothing except punt.
        if (this.destroyed) {
            if (!operation.destroyed) {
                operation.destroy();
            }

            return;
        }

        if (exception) {
            this.fireEvent('exception', this, {}, operation);
        }
        // If a JsonReader detected metadata, process it now.
        // This will fire the 'metachange' event which the Store processes to fire its own 'metachange'
        else {
            const meta = resultSet.getMetadata();
            if (meta) {
                this.onMetaChange(meta);
            }
        }

        // Tell owning store processing has finished.
        // It will fire its endupdate event which will cause interested views to
        // resume layouts.
        this.fireEvent('endprocessresponse', this, {}, operation);
    },

    /**
     * Transforms a data request into a chunked request and create a resultSet from the result data.
     *
     * @param requestOptions
     * @param operation
     * @param start
     * @param limit
     * @returns {Promise<Promise<ReadableStreamReadResult<*>> | Promise<ReadableStreamReadResult<R>>>}
     */
    async getResponseDataChunk(requestOptions, operation, start, limit) {
        const clonedRequestOptions = Ext.clone(requestOptions);
        clonedRequestOptions.params.start = start;
        clonedRequestOptions.params.skip = start;
        clonedRequestOptions.params.limit = limit;
        clonedRequestOptions.params.totalNeeded = this.getReadInChunks();
        const reader = this.getReader();
        const response = await Ext.Ajax.request(clonedRequestOptions);
        const responseData = reader.read(this.extractResponseData(response), {
            // If we're doing an update, we want to construct the models ourselves.
            recordCreator: operation.getRecordCreator()
                || reader.defaultRecordCreatorFromServer,
        });
        return responseData;
    },

    read(operation) {
        this.readAsync(operation).catch(handleClientError);
        return new Ext.data.Request();
    },

    /**
   * Creates an {@link Ext.data.Request Request} object from {@link Ext.data.operation.Operation Operation}.
   *
   * This gets called from doRequest methods in subclasses of Server proxy.
   *
   * @param {Ext.data.operation.Operation} operation The operation to execute
   * @return {Ext.data.Request} The request object
   */
    buildRequest(operation) {
        const me = this;
        const initialParams = Ext.apply({}, operation.getParams());
        // Clone params right now so that they can be mutated at any point further down the call stack
        // ----- >>>> OVERRIDE replace _prepareExtraParams
        const params = Ext.applyIf(initialParams, me._getPreparedExtraParams() || {});
        // <<<<< ---- OVERRIDE
        let request;
        let operationId;
        let idParam;

        // copy any sorters, filters etc into the params so they can be sent over the wire
        Ext.applyIf(params, me.getParams(operation));

        // Set up the entity id parameter according to the configured name.
        // This defaults to "id". But TreeStore has a "nodeParam" configuration which
        // specifies the id parameter name of the node being loaded.
        operationId = operation.getId();
        idParam = me.getIdParam();
        if (operationId !== undefined && params[idParam] === undefined) {
            params[idParam] = operationId;
        }
        request = new Ext.data.Request({
            params,
            action: operation.getAction(),
            records: operation.getRecords(),
            url: operation.getUrl(),
            operation,

            // this is needed by JsonSimlet in order to properly construct responses for
            // requests from this proxy
            proxy: me,
        });
        request.setUrl(me.buildUrl(request));

        /*
     * Save the request on the Operation. Operations don't usually care about Request and Response data, but in the
     * ServerProxy and any of its subclasses we add both request and response as they may be useful for further processing
     */
        operation.setRequest(request);

        return request;
    },

    _getPreparedExtraParams() {
        const extraParams = this.getExtraParams();
        if (!extraParams || !Ext.isObject(extraParams)) {
            return extraParams;
        }
        return Object.keys(extraParams).reduce((prev, key) => {
            const value = extraParams[key];
            let newValue = value;
            if (Ext.isObject(value)) {
                newValue = this._stringifyObject(value);
            } else if (Ext.isArray(value)) {
                newValue = this._stringifyArray(value);
            }
            prev[key] = newValue;
            return prev;
        }, {});
    },

    _stringifyArray(array) {
        return array.map(value => {
            if (Ext.isObject(value)) {
                return this._stringifyObject(value);
            }
            return value;
        });
    },

    _stringifyObject(object) {
        return JSON.stringify(object);
    },
});

Ext.define('FieldServices.view.company.purchases.extra.ExtraView', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.company.purchases.extra.ExtraViewController',
        'FieldServices.view.company.purchases.extra.add.AddExtraView',
        'FieldServices.view.widgets.list.GridListView',
    ],

    xtype: 'extraView',
    ui: '-extraView',
    itemId: 'extraList',
    cls: 'extralist',
    plugins: [{
        type: 'gridcolumnresizing',
    },
    {
        type: 'gridWithFilter',
        id: 'extraFilterPlugin',
        filterOnSearchButton: true,
        requestConfig: {
            url: 'api/admindata/company-tables/ConsumptionExtra',
            params: {
                sort: [{
                    property: 'id',
                    direction: 'ASC',
                }],
                filter: [{
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }],
                requiredFields: ['company_id'],
            },
        },
    }, {
        type: 'gridexporter',
    }],
    controller: 'extraViewController',

    titleBar: {
        shadow: false,
        items: [{
            align: 'right',
            xtype: 'button',
            iconCls: 'fa-plus-circle',
            itemId: 'addExtraButton',
            text: 'Add Extra',
        },
        {
            align: 'right',
            xtype: 'button',
            itemId: 'printExtraButton',
            iconCls: 'fa-file-excel-o',
            text: 'Export to xls',
        },
        {
            align: 'right',
            xtype: 'button',
            itemId: 'resetConsumptionsButton',
            iconCls: 'fa-key',
            text: 'Reset consumptions',
        },
        ],
    },
    layout: 'vbox',
    flex: 1,

    itemConfig: {
        viewModel: true,
    },

    emptyText: 'empty',

    columns: [
        {
            text: 'ID',
            dataIndex: 'id',
            width: 100,
        },
        {
            text: 'Type',
            dataIndex: 'type',
            width: 100,
        },
        {
            text: 'Count',
            dataIndex: 'count',
            width: 60,
        },
        {
            text: 'Created',
            dataIndex: 'dt_created',
            width: 150,
        },
        {
            text: 'Expires date',
            dataIndex: 'expires_date',
            width: 150,
        },
        {
            text: 'Product Name',
            dataIndex: 'product',
            flex: 1,
        },
        {
            text: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
        },
        {
            text: 'Purchase date',
            dataIndex: 'purchase_date',
            width: 150,
        },
        {
            text: 'Origin',
            dataIndex: 'purchase_origin',
            width: 100,
        },
        {
            text: 'Original Price',
            dataIndex: 'original_price',
            width: 150,
        },

        {
            text: 'is deleted',
            dataIndex: 'isdeleted',
            width: 100,
        },
    ],
});

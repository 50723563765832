const { SUBSCRIPTION } = require('shared/constants');

Ext.define('FieldServices.view.company.purchases.extra.add.AddExtraViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.addExtraViewController',
    xtype: 'addExtraViewController',

    config: {
        purchaseSetup: [],
        companyId: null,
    },

    init() {
        this._setSelectFields();
    },

    _setSelectFields() {
        const me = this;
        return FieldServices.app.callAPI({
            method: 'GET',
            url: 'api/admindata/purchases/getPurchaseSetup/-1',
            params: {},
        })
            .then(response => {
                if (response) {
                    this.setPurchaseSetup(response);
                    const options = response.reduce((prev, item) => [...prev, {
                        text: `${item.product_name} - ${item.ios_product_id}`,
                        value: item.ios_product_id,
                    }], []);

                    me.getView().down('#selectFieldProducts')
                        .setOptions(options);
                }
            })
            .catch(err => handleClientError(err));
    },

    async onSubmitExtra() {
        try {
            const companyId = this.getCompanyId();
            if (!companyId) {
                throw new Error('CompanyId is not defined');
            }

            await this.showLoader(this.getView());

            const extraData = this._getExtraData(companyId);

            const response = await FieldServices.app.callAPI({
                method: 'POST',
                url: `api/admindata/company/addExtra/${companyId}`,
                jsonData: extraData,
            });

            if (response.id) {
                this.hideExtraPopup();
                this.getView().fireEvent('onReload', {});
            }
        } catch (err) {
            handleClientError(err);
        }
        await this.clearLoader(this.getView());
    },

    _getExtraData(companyId) {
        const data = this.getView().getValues();
        data.isverified = 1;
        data.purchase_origin = SUBSCRIPTION.PURCHASE_ORIGIN_ID.MARKETING_FREE;
        data.company_id = parseInt(companyId, 10);
        data.quantity = 1;
        data.type = SUBSCRIPTION.PRODUCT[data.product].type;
        data.count = SUBSCRIPTION.PRODUCT[data.product].count;
        return data;
    },

    hideExtraPopup() {
        const view = this.getView();

        view.hide();
    },

});

const markdown = require('client/shared/Utils/MarkdownIt');

Ext.define('FieldServices.overrides.UtilFormat', {
    override: 'Ext.util.Format',

    /**
     * Transform an integer into a string in hexadecimal.
     *
     * @param {Number} dec The number to convert.
     * @param {Number} bytes The number of bytes to generate.
     * @return {String} The result.
     */
    decToHex(dec, bytes) {
        let hex = '';
        let i;

        // this method uses code from https://github.com/Stuk/jszip

        for (i = 0; i < bytes; i++) {
            hex += String.fromCharCode(dec & 0xff);
            dec >>>= 8;
        }
        return hex;
    },
    markdownInline(value) {
        markdown.renderInline(value);
    },
    markdown(value, a) {
        return markdown.render(value);
    },

});

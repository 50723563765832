/**
 * Created by steveetm1 on 31/07/15.
 */

Ext.define('FieldServices.view.home.HomeViewController', {
    extend: 'Deft.mvc.ViewController',
    alias: 'controller.home',

    config: {
        control: {

        },
    },

});

Ext.define('FieldServices.view.company.info.comments.CommentsViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.commentsController',
    xtype: 'commentsController',
    control: {
        newCommentForm: true,
        commentsList: true,
        sendNewCommentBtn: {
            tap: 'sendComment',
        },
    },

    async sendComment() {
        const store = Ext.getStore('CompanyCommentsStore');
        const newComment = this.createNewComment();
        this.getView().down('#newCommentField').setValue('');

        store.add(newComment);
        await store.syncAsync();
        store.sort('dt_created', 'DESC');
    },

    createNewComment() {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');
        const { comment } = this.getNewCommentForm().getValues();
        const { firstname, lastname } = adminUserData;
        return Ext.create('FieldServices.model.AdminAuditTrail', {
            user_id: adminUserData.id,
            company_id: adminUserData.company_id,
            event_type: CONSTANTS.ADMIN_AUDIT_TRAIL.EVENT_TYPES.ADD_COMMENT,
            linked_company_id: this.getViewModel().get('company.id'),
            details: {
                comment,
                commenter: `${firstname} ${lastname}`,
                commenterCompanyId: adminUserData.company_id,
            },
            dt_created: Ext.Date.format(new Date(), 'Y-m-d H:i:s'),
        });
    },

    onDeleteCommentClick(button) {
        Ext.Msg.confirm('Delete', 'Are you sure you want to delete this comment?', async choice => {
            if (choice === 'yes') {
                const store = Ext.getStore('CompanyCommentsStore');
                const record = store.findRecord('id', button.getData().get('id'));
                const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');
                record.set('isdeleted', true);
                record.set('company_id', record.get('commenterCompanyId') ?? adminUserData.company_id);
                await store.syncAsync();
                store.sort('dt_created', 'DESC');
            }
        });
    },
});

/**
 * Created by steveetm1 on 16/12/15.
 */
Ext.define('Shared.mixins.controllerConfig', {
    extend: 'Ext.Mixin',
    mixinConfig: {
        before: {
            init: 'beforeInit',
        },
    },

    beforeInit() {
        if (this.getView().config.controllerConfig) {
            this.setConfig(this.getView().config.controllerConfig);
        }
    },
});

import ComplexModel from 'shared/modules/complexData/message';

export default Ext.define('FieldServices.model.Message', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',

});

/**
 * This view is an example list of people.
 */
Ext.define('FieldServices.view.logoCollection.LogoCollection', {
    extend: 'Ext.panel.Panel',
    xtype: 'logoCollection.main',

    requires: [
        'FieldServices.view.logoCollection.components.LogoCollectionForm',
        'FieldServices.view.logoCollection.components.LogoCollectionGrid',
        'FieldServices.view.logoCollection.LogoCollectionController',
        'FieldServices.view.logoCollection.LogoCollectionModel',
    ],
    layout: {
        type: 'vbox',
        flex: 1,
        align: 'stretch',
    },
    title: 'Logo Collection',
    controller: 'logoCollection.main',
    viewModel: {
        type: 'logoCollection.main',
    },
    items: [{
        xtype: 'logoCollection.grid',
        flex: 1,
        ui: '-logoCollection',
        loadStoreWhenVisible: true,
    }, {
        xtype: 'logoCollection.fieldset',
    }],
    initConfig(config) {
        const self = this;
        this.callParent(arguments);
        if (config.noGrid) {
            this.getViewModel().setData({ noGrid: true });
        }
    },
});

Ext.define('FieldServices.view.ListItemBaseViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.listItemBaseViewModel',
    xtype: 'listItemBaseViewModel',
    config: {
        uiMap: {
            isActive: {
                ui: '-IsActive',
                condition(dataItem) {
                    return Boolean(dataItem.isActive) || Boolean(dataItem.is_active);
                },
            },
            isDeleted: {
                ui: '-IsDeleted',
                condition(dataItem) {
                    return Boolean(dataItem.isDeleted) || Boolean(dataItem.isdeleted);
                },
            },
        },
    },

    booleanToString(value) {
        return value ? 'true' : 'false';
    },

    _generateUiList(classMap) {
        return Object.values(classMap)
            .reduce((uiList, { ui, condition = () => true }) => {
                const dataItem = this.get('record').data;
                return condition.call(this, dataItem)
                    ? uiList.concat(ui)
                    : uiList;
            }, []);
    },

    formulas: {
        ui: {
            bind: '{record}',
            get() {
                const uiMap = this.getUiMap();
                return this._generateUiList(uiMap);
            },
        },
        isDeletedString: {
            bind: '{record.isdeleted}',
            get(isdeleted) {
                return this.booleanToString(isdeleted);
            },
        },
        archivedString: {
            bind: '{record.is_archived}',
            get(isArchived) {
                return this.booleanToString(isArchived);
            },
        },
        isActiveString: {
            bind: '{record.is_active}',
            get(isActive) {
                return this.booleanToString(isActive);
            },
        },
    },
});

Ext.define('FieldServices.view.company.contact.import.ContactImportView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.contact.import.ContactImportViewController',
    ],

    xtype: 'contactImportView',
    controller: 'contactImportViewController',

    layout: 'vbox',
    scrollable: true,
    modal: true,
    title: 'Customer Import ()',
    tools: [{
        type: 'close',
        handler: 'hideImportPopup',
    }],

    viewModel: {
        data: {
            currentStep: 0,
        },
        formulas: {
            backButtonText: {
                bind: '{currentStep}',
                get(currentStep) {
                    return !currentStep ? 'Cancel' : 'Back';
                },
            },
            fileValid: {
                bind: '{csvFileInput.value}',
                get(fileName) {
                    return fileName && fileName.match('.*.csv');
                },
            },
            fileError: {
                bind: '{csvFileInput.value}',
                get(fileName) {
                    if (fileName && !fileName.match('.*.csv')) {
                        return 'Uploaded file is not CSV';
                    }
                    return null;
                },
            },
        },
    },
    items: [{
        xtype: 'toolbar',
        docked: 'bottom',
        items: [{
            xtype: 'button',
            bind: {
                text: '{backButtonText}',
                handler: 'onBackButtonTapped',
            },
        }, {
            xtype: 'container',
            flex: 1,
        }, {
            xtype: 'button',
            text: 'Load file',
            bind: {
                disabled: '{!fileValid}',
                hidden: '{currentStep !== 0}',
            },
            handler: 'onLoadFileButtonTapped',
        },
        {
            xtype: 'button',
            text: 'Preview',
            bind: {
                hidden: '{currentStep !== 1}',
            },
            handler: 'onPreviewButtonTapped',
        }, {
            xtype: 'button',
            text: 'Send',
            bind: {
                hidden: '{currentStep !== 2}',
            },
            handler: 'onSendButtonTapped',
        },
        ],
    }, {
        xtype: 'formpanel',
        title: 'Load csv file',
        itemId: 'loadCsvForm',
        bind: {
            hidden: '{currentStep !== 0}',
        },
        items: [{
            xtype: 'filefield',
            name: 'csv_file',
            fieldLabel: 'csv_file',
            itemId: 'csvFileInput',
            reference: 'csvFileInput',
            errorTarget: 'under',
            bind: {
                error: '{fileError}',
            },
        }],
    }, {
        xtype: 'container',
        itemId: 'selectColumns',
        scrollable: true,
        bind: {
            hidden: '{currentStep !== 1}',
        },
        items: [{
            xtype: 'formpanel',
            itemId: 'selectMapForm',

            items: [{
                xtype: 'fieldset',
                title: 'Please Select',
                itemId: 'selectFieldList',
                items: [{
                    xtype: 'selectfield',
                    label: 'Choose data',
                    options: [],
                }],
            }],
        }, {
            xtype: 'formpanel',
            itemId: 'requiredForm',
            items: [{
                xtype: 'fieldset',
                title: 'Required Fields (rows, where all of the checked fields are missing, will be ignored)',
                itemId: 'checkFieldRequired',
                layout: 'hbox',
                items: [],
            }],
        }, {
            hidden: true,
            xtype: 'formpanel',
            itemId: 'countryIdentifierPanel',
            title: 'Identify the Countries of Customers',
            items: [{
                xtype: 'fieldset',
                itemId: 'countryIdentifierFieldset',
                layout: 'vbox',
                items: [],
            }],
        }, {
            xtype: 'formpanel',
            itemId: 'isFirstLineForm',
            items: [{
                xtype: 'fieldset',
                layout: 'hbox',
                items: [{
                    xtype: 'checkboxfield',
                    label: 'Include First Line',
                    name: 'isFirstLine',
                    checked: true,
                    labelAlign: 'top',
                }],
            }],
        }],
    }, {
        xtype: 'container',
        itemId: 'customerImportGridBox',
        bind: {
            hidden: '{currentStep !== 2}',
        },
        layout: {
            type: 'vbox',
        },
        flex: 1,
        items: [{
            xtype: 'grid',
            flex: 1,
            itemId: 'customerImportGrid',

            plugins: [{
                type: 'gridcolumnresizing',
            }],
            columns: [],
        }],
    }],
});

/**
 * Created by steveetm on 2017. 04. 20..
 *
 * Example:
 * Ext.create('Ext.tab.Pane',{
 *  plugins: {
 *      type: 'lazytabs'
 *  },
 *  items: [
 *      {
 *          xtype :'container'
 *      },
 *      {
 *          xtype :'container'
 *      },
 *      {
 *          xtype :'container',
 *          eager: true
 *      }
 *  ]
 *
 * });
 *
 * With this configuration, the first and the last container will be created only at initialization time. The second one
 * only instantiated when we click on second tab.
 *
 *
 */

Ext.define('shared.plugins.LazyTabs', {
    extend: 'Ext.plugin.Abstract',

    alias: 'plugin.lazytabs',
    /**
     *
     * @param comp - Ext.Component where this plugin binded to
     */
    init(comp) {
        this.callParent(arguments);
        this.items = comp.config.items.slice(0);
        if (this.items.length > 0) {
            // If we have at least 1 items, then always make sure that the first item is initialized instantly!
            this.items[0].eager = true;
        }
        /**
         * This .map is to create placeholder items which are superfast to create and display, as only contain the title
         * for the tabbar, nothing else, just a marking to know this is not real component.
         *
         * If the original component has an {eager: true} config, then no placeholder component will be created.
         */
        comp.config.items = this.items.map(item => (!item.eager ? {
            title: this.getTitle(item),
            tab: item.tab,
            ...(item.requiredRoles ? {
                requiredRoles: item.requiredRoles,
            } : {}),
            ...(item.requiredRole ? {
                requiredRole: item.requiredRole,
            } : {}),
            $$dynamic: true,
        } : item));

        /**
         * This is a bit hacky. If we tap on a dynamic tab, we must replace its content with the newly created, dynamic tabpanel.
         * But if we simply remove and add a new tabpanel then two things will happen:
         * - New activeItemChange event
         * - The event chain will continue with the recently removed Ext.tab.Bar and Panel.
         *
         * I couldn't find a nice and clean way yet to hook on activeItemChange, so we have an override on onTabTap.
         * If we tap on a dynamic tab ($$dynamic === true), then we remove the placeholder item and add the newly created
         * tab at that place. Then call set the tabpanel to that tab AND return with false to stop the event chain on
         * the old(removed) tab.
         *
         * If we can modify this just a little bit to run on (before)activeitemchange then we could easily apply this
         * plugin to cardlayouts or carousels too, that would be nice.
         *
         * Also had to apply this functionality on applyActiveItem, e.g. when programmatically changes to a tab which
         * is lazily instantiated.
         */
        this.cmp.getTabBar().onTabTap = this.onTabTap.bind(this);
        this.originalApplyItem = this.cmp.applyActiveItem;
        this.cmp.applyActiveItem = this.applyActiveItem.bind(this);
    },

    /**
     * This is not so nice as this plugin alone should not know about L function or title or anything. Just config.
     * Still, as we can use prompt ids for titles, we have to translate it even for the placeholder items this plugin
     * generate.
     *
     * @param item
     * @returns {String}
     */
    getTitle(item) {
        return item.promptId ? L(item.promptId, item.title) : item.title;
    },

    applyActiveItem(activeItem, currentActiveItem) {
        const result = this.originalApplyItem.call(this.cmp, activeItem, currentActiveItem);
        if (result.$$dynamic) {
            this.replaceActiveItem(activeItem);
            // Return undefined to stop the apply setter chain with the old(placeholder) item.
            // replaceActiveItem will call a new setActiveItem with the newly created item.
            return;
        }
        //
        // Old behaviour, pass it over to updateActiveItem;
        //
        return result;
    },

    /**
     *
     * @param newTab Ext.tab.Bar
     * @returns {boolean}
     */
    onTabTap(newTab) {
        const tabBar = this.cmp.getTabBar();
        const idx = tabBar.getItems().indexOf(newTab);
        const tabPanelInstance = this.cmp.innerItems[idx];
        if (tabPanelInstance.$$dynamic) {
            this.replaceActiveItem(idx);
            return false;
        }

        //
        // Old behaviour, simply activate the tab;
        //
        tabBar.setActiveTab(newTab);
    },

    /**
     * Removes the placeholder item at the given index, creates the real one and put it to its original place.
     * @param (Number} idx
     */
    replaceActiveItem(idx) {
        this.cmp.remove(this.cmp.innerItems[idx]);
        this.cmp.insert(idx, this.items[idx]);
        this.cmp.getTabBar().setActiveTab(this.cmp.getTabBar().getItems().items[idx]);
    },
});

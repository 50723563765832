/**
 *
 * @class Override.DataviewList
 * @extends {Ext.dataview.List}
 */
Ext.define('FieldServices.overrides.DataviewList', {
    override: 'Ext.dataview.List',

    privates: {
        syncRowsToHeight(force) {
            const me = this;
            if (!me.store) {
                return;
            }
            const bufferZone = me.getBufferSize();
            const { infinite } = me;
            const rowCountWas = me.getItemCount();
            let { rowHeight } = me;
            const firstTime = !rowHeight;
            const { renderInfo } = me;
            const oldIndexBottom = renderInfo && renderInfo.indexBottom;
            const storeCount = me.store.getCount();
            // When a maxHeight is configured, we use that to drive the number
            // of rows to render. We set the height of our innerCt (which is
            // position:relative) to provide a height to the list (see syncRows).
            const visibleHeight = me.getMaxHeight() || me.getVisibleHeight();
            let indexTop; let row; let
                rowCount;

            if (firstTime) {
                // On our first call here, we need to create at least one row so we
                // can measure it. For fixed row heights this is the height of all
                // rows. For variable row height, this is nominal row height.
                if (!rowCountWas) {
                    me.setItemCount(1);
                }

                row = me.dataItems[0];
                row.$height = null; // force measure
                me.rowHeight = rowHeight = me.measureItem(row);

                if (!rowCountWas && me.discardMeasureRow) {
                    row.destroy();
                    me.dataItems.length = 0;
                    me.setItemCount(0);
                }
            }

            if (infinite) {
                // Don't render more rows then we have records. Saves a whole layer of
                // hiding those extra rows...
                rowCount = Math.ceil(visibleHeight / rowHeight) + bufferZone;
                rowCount = Math.min(rowCount, storeCount);
            } else {
                rowCount = storeCount;
            }

            //
            // ----------- override is start -----------
            //                                         |
            if (rowCount < 0) {
                rowCount = 1;
            }
            //                                         |
            // ----------- override is end   -----------
            //

            me.setItemCount(rowCount);

            // Virtual stores have to be booted into life the first time through here.
            if ((firstTime && me.store.isVirtualStore) || rowCountWas !== rowCount || storeCount < oldIndexBottom) {
                if (infinite) {
                    indexTop = Math.min(storeCount - rowCount, renderInfo.indexTop);
                    indexTop = Math.max(0, indexTop);

                    if (indexTop === me.getTopRenderedIndex()) {
                        // Directly call the updater
                        me.updateTopRenderedIndex(indexTop);
                    } else {
                        me.setTopRenderedIndex(indexTop);
                    }
                }

                if (firstTime) {
                    me.refreshGrouping();
                }

                force = force !== false;

                if (force && storeCount < oldIndexBottom) {
                    // Changing the amount of rows because the data in the store is
                    // no longer sufficient to fill the view
                    renderInfo.top = renderInfo.indexTop * me.rowHeight;
                }
            }
            if (force) {
                me.syncRows();
            }
        },
    },
});

Ext.define('FieldServices.view.company.purchases.subscriptions.SubscriptionList', {
    extend: 'Ext.grid.Grid',
    xtype: 'subscriptionList',
    loadStoreWhenVisible: true,
    flex: 1,
    ui: '-subscriptions',
    requires: [
        'FieldServices.view.company.purchases.subscriptions.SubscriptionListViewModel',
        'FieldServices.view.company.purchases.subscriptions.SubscriptionListController',
        'FieldServices.view.company.purchases.subscriptions.details.DetailsView',
        'FieldServices.view.company.purchases.subscriptions.add.AddSubscriptionView',
        'Shared.Components.field.DateTimeField',
    ],

    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridWithFilter',
    }],

    controller: 'subscriptionListController',

    itemConfig: {
        viewModel: {
            type: 'subscriptionListViewModel',
        },
        bind: {
            ui: '{ui}',
        },
    },

    bind: {
        store: '{subscriptionsChainedStore}',
    },

    listeners: {
        itemTap: 'onDetailsTap',
    },

    emptyText: 'empty',
    titleBar: {
        shadow: false,
        items: [{
            align: 'right',
            xtype: 'button',
            flex: 1,
            iconCls: 'ion-android-add-circle',
            itemId: 'addSubscriptionBtn',
            text: 'Add Subscription',
        },
        ],
    },
    columns: [
        {
            text: 'ID',
            dataIndex: 'id',
            width: '60',
        }, {
            text: 'Name',
            dataIndex: 'purchaseSetupName',
            width: '150',
        }, {
            text: 'Product',
            dataIndex: 'product',
            width: '150',
        }, {
            text: 'Purchase date',
            dataIndex: 'purchaseDateView',
            width: '150',
        }, {
            text: 'Expires date',
            dataIndex: 'expiresDateView',
            width: '170',
        }, {
            text: 'Original purchase date',
            dataIndex: 'originalPurchaseDateView',
            width: '170',
        }, {
            text: 'transaction ID',
            dataIndex: 'transactionid',
            width: '120',
        }, {
            text: 'Currency',
            dataIndex: 'purchase_currency',
            width: '70',
        }, {
            text: 'Origin',
            dataIndex: 'purchaseOriginName',
            width: '150',
        }, {
            text: 'Amount',
            dataIndex: 'purchase_amount',
            width: '70',
        }, {
            text: 'isChecked',
            dataIndex: 'isChecked',
            width: '100',
        }, {
            text: 'count',
            dataIndex: 'num',
            width: '60',
        }, {
            text: 'isDeleted',
            dataIndex: 'isdeleted',
            useCheckbox: {
                excludeValue: 1,
                boxLabel: 'include Deleted Items',
            },
            width: '60',
        },
        {
            text: 'isActive',
            dataIndex: 'isActive',
            width: '100',
            useCheckbox: {
                excludeValue: 0,
                boxLabel: 'include inActive Items',
                checked: true,
            },
        }],
});

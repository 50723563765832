export default Ext.define('FieldServices.view.company.user.profile.UserProfileViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.userProfileViewController',
    xtype: 'userProfileViewController',

    control: {
        '': {
            painted: 'onPainted',
        },
        userProfileRole: true,
    },

    config: {
        mpaSeparator: '__',
        teamColumns: [],
    },

    onPainted() {
        const separator = Ext.getStore('UserProfileRoleCustom').config.byRoleIdAndUserProfileIdSeparator;
        this.setMpaSeparator(separator);
        this.getViewModel().set('mapSeparator', separator);

        this.setTeamColumns(this.getViewModel().get('teamColumns'));
    },

    onClose() {
        this._closePopup();
    },

    async saveModifications(button) {
        button.setDisplayed(true);

        this._saveCustomRoles()
            .catch(handleClientError);
    },

    setDefaultAll() {
        this._applyAll(true);
    },

    disableAll() {
        this._applyAll(false);
    },

    _applyAll(toDefault) {
        this.getView().down('#userProfileRole').getStore().data.items.forEach(userProfileRole => {
            if (!this._skipRolesToToggle().includes(userProfileRole.data.id)) {
                userProfileRole.set({
                    main: toDefault ? userProfileRole.data.mainDefaultValue : false,
                    manager: toDefault ? userProfileRole.data.managerDefaultValue : false,
                    engineer: toDefault ? userProfileRole.data.engineerDefaultValue : false,
                });
            }
        });
    },

    _skipRolesToToggle() {
        return [
            CONSTANTS.ROLES.PERMISSIONS.CAN_CREATE_PROMPTS,
            CONSTANTS.ROLES.PERMISSIONS.CAN_EDIT_PROMPTS,
            CONSTANTS.ROLES.PERMISSIONS.CAN_VIEW_PROMPTS,
        ];
    },

    _closePopup() {
        this.getView().destroy();
    },

    async _saveCustomRoles() {
        this.getViewModel().set('isLoading', true);
        this._getModifiedRecords().forEach(record => {
            Object.keys(record.modified).forEach(modifiedKey => {
                this._changeCustomRole(modifiedKey, record);
            });
        });

        await Ext.getStore('UserProfileRoleCustom').syncAsync();
        this.getUserProfileRole().getStore().sync();

        //
        // reload the store from remote proxy to get all the ids filled
        //
        Ext.getStore('UserProfileRoleCustom').reload();

        Ext.Msg.alert('Alert', 'Changes will only appear in the app after the reloading.', () => {
            this.getViewModel().set('isLoading', false);
            this._closePopup();
        });
    },

    _changeCustomRole(modifiedKey, record) {
        const roleId = record.data.id;
        const profileId = this._getProfileIdByKey(modifiedKey);
        const customRole = this._getCustomRoleRecord(roleId, profileId);
        const newMode = record.get(modifiedKey) ? 1 : 0;

        if (customRole) {
            const oldMode = customRole.data.mode;
            if (oldMode !== newMode) {
                customRole.set('mode', newMode);
                customRole.set('company_id', this.getViewModel().get('companyId'));
            }
        } else {
            const newCustom = this._generateCustomProfileRoleData(newMode, roleId, profileId);
            this._getUserProfileRoleCustomStore().add(newCustom);
        }
    },

    _generateCustomProfileRoleData(newMode, roleId, profileId) {
        return {
            company_id: this.getViewModel().get('companyId'),
            mode: newMode,
            role_id: roleId,
            userprofile_id: profileId,
            isdeleted: 0,
        };
    },

    _getModifiedRecords() {
        return this.getUserProfileRole().getStore().getModifiedRecords();
    },

    _getProfileIdByKey(modifiedKey) {
        const team = this.getTeamColumns().filter(column => column.key === modifiedKey);
        return team[0].constantValue || null;
    },

    _getCustomRoleRecord(roleId, userProfileId) {
        const mapKey = this._generateMapKey(roleId, userProfileId);
        return Ext.getStore('UserProfileRoleCustom').data.byRoleIdAndUserProfileId.get(mapKey);
    },
    _generateMapKey(roleId, userProfileId) {
        return [roleId, userProfileId].join(this.getMpaSeparator());
    },

    _getUserProfileRoleCustomStore() {
        return Ext.getStore('UserProfileRoleCustom');
    },

});

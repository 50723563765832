import ComplexModel from 'shared/modules/complexData/settingsKeys';

export default Ext.define('FieldServices.model.SettingsKeys', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,

        async getDefaultValue(settingsKeysId) {
            return Ext.getStore('SettingsKeys').loadAsync()
                .then(SettingsKeysTable => SettingsKeysTable.getById(settingsKeysId).data.default_value)
                .catch(handleClientError);
        },
        async getDefaultValueBoolean(settingsKeysId, defaultValue) {
            return Ext.getStore('SettingsKeys').loadAsync()
                .then(SettingsKeysTable => SettingsKeysTable.getById(settingsKeysId).data.defaultValue !== defaultValue)
                .catch(handleClientError);
        },

    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'id',

});

Ext.define('FieldServices.view.widgets.user.find.FindUserView', {
    extend: 'Ext.form.Panel',
    requires: [
        'FieldServices.view.widgets.user.find.FindUserViewController',
    ],

    xtype: 'findUserForm',

    controller: 'findUserFormCtrl',

    layout: 'vbox',

    title: 'Find User',

    defaultType: 'textfield',

    items: [
        {
            name: 'email',
            label: 'login email',
        }, {
            name: 'firstname',
            label: 'firstname',
        }, {
            name: 'lastname',
            label: 'lastname',
        },
        {
            xtype: 'button',
            text: 'Find',
            handler: 'onSetUserList',
        },

    ],

});

Ext.define('FieldServices.view.logs.ApplicationErrors', {
    extend: 'Ext.grid.Grid',

    requires: [
        'FieldServices.view.widgets.list.GridListView',
        'FieldServices.view.logs.MongoLogController',
    ],

    controller: 'mongoLogController',
    xtype: 'applicationErrorsTable',
    plugins: [{
        type: 'gridcolumnresizing',
    },
    {
        type: 'gridWithFilter',
        id: 'applicationErrorsFilterPlugin',
        filterOnSearchButton: true,
        paging: true,
        requestConfig: {
            url: 'api/admindata/log/Mongo',
            params: {
                limit: 500,
                totalNeeded: true,
                filter: [{
                    property: 'table',
                    value: 'applicationerrors',
                }],
            },
        },
        dateRangeFilters: [{
            property: '_id',
            mode: 'datetime',
            fixedRangeMode: 'dateRange',
            convertToObjectId: true,
            selectableRangeLimit: {
                value: 7,
                intervalType: Ext.Date.DAY,
            },
            label: 'Log date:',
            dbType: 'mongo',
        }],
    }, {
        type: 'gridexporter',
    }],

    layout: 'vbox',
    flex: 1,

    itemConfig: {
        viewModel: true,
    },

    emptyText: 'empty',
    sortable: false,

    columns: [
        {
            text: 'Name',
            dataIndex: 'name',
            flex: 1,
        },
        {
            text: 'App Version',
            dataIndex: 'serverVersion',
            flex: 1,
        },
        {
            text: 'Message',
            dataIndex: 'message',
            flex: 4,
        },
        {
            text: 'Log date',
            dataIndex: 'date',
            gridWithFilter: {
                skipFromFilter: true,
            },
            flex: 1,
        },
        {
            text: 'Stack Trace',
            dataIndex: 'stack',
            flex: 3,
        },
        {
            text: 'Severity',
            dataIndex: 'severity',
            gridWithFilter: {
                operator: 'numberLike',
            },
            flex: 1,
        },
    ],
});

require('./Grid.scss');

Ext.define('FieldServices.overrides.Grid', {
    override: 'Ext.grid.Grid',

    requires: [
        'Ext.grid.plugin.Exporter',
    ],

    onColumnSortRemoved(container, column) {
        this.fireEvent('columnSortRemoved', this, column);
    },

    privates: {
        updateHeaderContainer(headerContainer) {
            if (headerContainer) {
                headerContainer.on({
                    columnresize: 'onColumnResize',
                    columnshow: 'onColumnShow',
                    columnhide: 'onColumnHide',
                    columnadd: 'onColumnAdd',
                    columnmove: 'onColumnMove',
                    columnremove: 'onColumnRemove',
                    columnsort: 'onColumnSort',
                    columnsortRemoved: 'onColumnSortRemoved',
                    scope: this,
                });
            }
        },
    },
});

Ext.define('FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.monthlyReportsDetailedViewController',
    xtype: 'monthlyReportsDetailedViewController',

    control: {
        monthlyReportsFilter: {
            changeFilter: 'loadMonthlyData',
            exportRequested: 'onExportRequested',
            promoExcludeChanged: 'setPromotionalSubscriptionFilter',
        },
        gridTabs: true,
    },

    config: {
        companyId: null,
    },

    onExportRequested() {
        const activeTab = this.getGridTabs().getActiveItem();
        const reportName = activeTab.tab.config.title.replace(' ', '_');
        const reportDate = this.getMonthlyReportsFilter().getDisplayValue();
        activeTab.saveDocumentAs({ fileName: `${reportName} ${reportDate}.xlsx` });
    },

    async loadMonthlyData({ filterData }) {
        this.getViewModel().setData({ monthlyDataLoading: true });
        try {
            await this.loadStore('MonthlyPurchaseReportData', 'getSubscriptionsForMonth', filterData);
            await this.loadStore('ActiveSubsAtMonthEnd', 'getSubscribersForMonth', filterData);
            await this.loadStore('NewRegistrationsInMonth', 'getNewRegistrationsForMonth', filterData);

            this.getViewModel().setData({ monthlyDataLoading: false });
        } catch (error) {
            this.getViewModel().setData({ monthlyDataLoading: false });
            handleClientError(error);
        }
    },

    async loadStore(storeName, apiEndpoint, filterData) {
        const apiResponse = await FieldServices.app.callAPI(
            {
                url: `api/admindata/purchases/${apiEndpoint}/0`,
                method: 'GET',
                params: { filter: Ext.encode(filterData) },
                headers: {},
            },
        );

        const data = apiResponse || [];
        Ext.getStore(storeName).setData(data);
    },

    setPromotionalSubscriptionFilter(excludePromo) {
        ['MonthlyPurchaseReportData', 'ActiveSubsAtMonthEnd'].forEach(storeName => {
            const store = Ext.getStore(storeName);
            if (excludePromo) {
                store.addFilter({
                    property: 'purchaseOrigin',
                    operator: '!=',
                    value: 4,
                    id: 'promoSubFilter',
                });
            } else {
                store.removeFilter('promoSubFilter');
            }
        });
    },

});

Ext.define('FieldServices.view.system.systemMain.systemMainView', {
    extend: 'Ext.panel.Panel',
    xtype: 'systemMainView',
    title: 'System',
    layout: 'fit',
    flex: 1,
    ui: '-systemMainView',

    requires: [
        'FieldServices.view.system.systemMain.systemMainViewModel',
    ],

    itemConfig: {
        viewModel: true,
    },

    viewModel: 'systemMainViewModel',

    items: [
        {
            xtype: 'container',
            items: [
                {
                    xtype: 'button',
                    text: 'Send Push Notifications',
                    itemId: 'sendNotificationButton',
                    width: '300px',
                },
            ],
        },
    ],

});

/**
 * This view is an example list of people.
 */
require('./PromptListView.scss');

Ext.define('FieldServices.view.prompt.list.PromptsListView', {
    extend: 'Ext.grid.Grid',
    xtype: 'prompt.grid',

    requires: [
        'Ext.IndexBar',
    ],
    controller: 'prompt.list',
    plugins: {
        type: 'gridWithFilter',
    },
    bind: {
        store: '{prompts}',
        hidden: '{noGrid}',
    },

    titleBar: {
        shadow: false,
        items: [{
            align: 'right',
            xtype: 'button',
            text: '...',
            stretchMenu: true,
            arrow: false,
            menu: {

                indented: false,
                items: [{
                    text: 'new',
                    status: 1,
                    itemId: 'newPromptButton',
                }],
            },
        }],
    },

    scrollable: true,
    selModel: 'rowmodel',
    ui: '-prompt',
    bufferedRenderer: true,

    multiColumnSort: true,
    indexBar: true,

    currentIndex: null,
    searchRegExp: null,

    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Code',
            dataIndex: 'code',
            width: 150,
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Subcode',
            dataIndex: 'subcode',
            width: 230,
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Type',
            dataIndex: 'type',
            width: 150,
        },
        {
            text: 'Value',
            dataIndex: 'value',
            flex: 1,
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Description',
            dataIndex: 'description',
            flex: 1,
            filter: {
                type: 'string',
            },
        },
        {
            text: 'Language',
            dataIndex: 'languageId',
            width: 150,
            renderer: 'languageRenderer',
            filterByRenderer: true,
        },
        {
            text: 'Country',
            dataIndex: 'countryId',
            width: 150,
            renderer: 'countryRenderer',
            filterByRenderer: true,
        },
    ],
});

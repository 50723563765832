const Bluebird = require('bluebird');
const { memoizeRunningPromise } = require('shared/decorators');
import CONTACT_TO_ADDRESS from '@powerednow/shared/constants/contactToAddress';

Ext.define('FieldServices.view.company.contact.info.ContactInfoViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.contactInfoViewController',
    xtype: 'contactInfoViewController',

    control: {
        contactList: true,
        contactBoxView: true,
        customerPanel: true,
        orphanPanel: true,
    },

    config: {
        customerData: null,
        companyId: null,
        customerId: null,
        sourceData: {},
    },


    onClose() {
        if (this._getModifiedStores().length > 0) {
            Ext.Msg.confirm('Confirm', 'All modifications will be lost. Are you sure want to proceed?', (btn) => {
                if (btn === 'yes') {
                    this.revertChanges();
                    this._closePopup();
                }
            });
        } else {
            this._closePopup();
        }
    },

    restoreCustomer() {
        this.setDeletedValue(false);
    },
    deleteCustomer() {
        this.setDeletedValue(true);
    },

    setDeletedValue(statusValue) {
        this.getCustomerPanel()
            .query('grid')
            .map(grid => grid.getStore())
            .forEach(store => store.getRecords().forEach(record => this._setIsDeleted(record, statusValue)));
    },

    saveModifications() {
        this.showLoader(this.getView());

        Bluebird.all(this._getModifiedStores().map(store => store.syncAsync()))
            .then(() => {
                this.getView().fireEvent('dataChanged', {});
            })
            .catch(handleClientError)
            .finally(() => {
                this.clearLoader(this.getView());
                this._closePopup();
            });
    },

    revertChanges() {
        this._getModifiedStores()
            .forEach(store => store.getModifiedRecords().map(record => record.reject()));
    },

    async updateCustomerData(customerData) {
        this.setCompanyId(customerData.company_id);
        this.setCustomerId(customerData.id);
        try {
            await this._loadContent();
            this._addListenersToAllGridStores('datachanged', () => {
                this.getViewModel().set('dataChanged', this._getModifiedStores().length > 0);
            });
        } catch (err) {
            handleClientError(err);
            this.getView().hide();
        }
    },

    _closePopup() {
        this.getCustomerPanel().removeAll();
        this.getOrphanPanel().removeAll();

        this.getView().hide();
    },

    _setIsDeleted(record, statusValue) {
        const isDeleted = record.data.isdeleted;
        if (typeof isDeleted !== 'undefined') {
            record.set({ isdeleted: statusValue });
        }
    },

    _addListenersToAllGridStores(eventName, eventHandler) {
        this._getAllGridStores()
            .forEach((store) => {
                store.on(eventName, eventHandler, this);
            });
    },

    _getAllGridStores() {
        return this.getView()
            .query('grid')
            .map(grid => grid.getStore());
    },

    _getModifiedStores() {
        return this._getAllGridStores()
            .filter(store => store.getModifiedRecords().length > 0);
    },

    _getStoresWithNewRecords() {
        return this._getAllGridStores()
            .filter(store => store.getNewRecords().length > 0);
    },

    async _loadContent() {
        this.showLoader(this.getView());
        await this._updateContent();
        this.clearLoader(this.getView());
    },

    async _updateContent() {
        this.getCustomerPanel().removeAll();
        this.getOrphanPanel().removeAll();
        const { contacts, orphanContacts } = await this._getSourceDataFromServer();

        this._createViewPanels(contacts, orphanContacts);
    },

    _createViewPanels(source, orphanContacts) {
        this.getView().setTitle(this._getCustomerTitle());

        this.getCustomerPanel().add(Ext.create(this.getView().getButtonsViewConfig()));

        this.getCustomerPanel().add(this._getCustomerView(source));

        if (source && source.ContactToAddresses) {
            this._addSitesToPanel(source.ContactToAddresses);
        }

        if (orphanContacts && orphanContacts.Contacts) {
            this._addOrphanToPanel(orphanContacts.Contacts);
        }
        this._convertNemRecordsToEditState();
    },

    _convertNemRecordsToEditState() {
        const storesWithNewRecords = this._getStoresWithNewRecords();
        if (storesWithNewRecords) {
            storesWithNewRecords.forEach((store) => {
                store.getNewRecords().forEach(record => record.commit(true));
            });
        }
    },

    _addSitesToPanel(contactToAddresses) {
        if (!contactToAddresses || !Array.isArray(contactToAddresses)) {
            return;
        }
        contactToAddresses.sort((a, b) => a.type - b.type);
        contactToAddresses.forEach((contactToAddress) => {
            const title = CONTACT_TO_ADDRESS.LABELS[parseInt(contactToAddress.type, 10)];
            const viewConfig = this.getView().getPanelViewConfig();
            const view = Ext.create(viewConfig).setTitle(title);
            const panel = this.getCustomerPanel().add(view);

            panel.add(this._getContentViews(contactToAddress));
        });
    },

    _addOrphanToPanel(orphanContacts) {
        if (!orphanContacts || !Array.isArray(orphanContacts)) {
            return;
        }
        orphanContacts.forEach((contact) => {
            const title = 'Orphan (belongs to no contactToAddress (main/invoice/registered/site), nor defined as alternate of any other contact)';
            const viewConfig = this.getView().getPanelViewConfig();
            const view = Ext.create(viewConfig).setTitle(title);
            const panel = this.getOrphanPanel().add(view);

            panel.add(this._getContentViews({}, contact));
        });
    },

    _getContentViews(contactToAddress, contact = null) {
        if (!contactToAddress) {
            return [];
        }
        const isOrphanContact = Boolean(contact);
        if (isOrphanContact) {
            return [
                this._getContactView(contact),
                this._getContactMethodsView(contact.ContactMethods, isOrphanContact),
            ].filter(item => !this._isEmpty(item));
        }
        return [
            this._getContactToAddress(contactToAddress),
            this._getContactView(contactToAddress.Contact),
            this._getAddressView(contactToAddress.Address),
            this._getContactMethodsView(contactToAddress.Contact.ContactMethods),
            ...this._getAlternateContactsViews(contactToAddress.Contact.AlternativeContact),

        ].filter(item => !this._isEmpty(item));
    },

    _getAlternateContactsViews(alternateContact) {
        const views = [];
        if (!alternateContact) {
            return views;
        }

        const viewTitle = 'Alternate Contact';
        const viewConfig = this.getView().getAlternateViewConfig();
        views.push(Ext.create(viewConfig).setData({ viewTitle }));

        views.push(this._getContactView(alternateContact));
        if (alternateContact.ContactMethods) {
            views.push(this._getContactMethodsView(alternateContact.ContactMethods));
        }

        return views;
    },

    _getCustomerView(contact) {
        if (!contact || !contact.id) {
            return {};
        }

        return Ext.create({
            xtype: 'customerView',
        }).setStore(Ext.create('FieldServices.store.Customer').setData([contact]));
    },

    _getContactView(contact) {
        if (!contact || !contact.id) {
            return {};
        }

        return Ext.create({
            xtype: 'contactListView',
        }).setStore(Ext.create('FieldServices.store.Contact').setData([contact]));
    },

    _getContactMethodsView(ContactMethods) {
        if (!ContactMethods || ContactMethods.length === 0) {
            return {};
        }

        return Ext.create({
            xtype: 'contactMethodView',
        }).setStore(Ext.create('FieldServices.store.ContactMethod').setData(ContactMethods));
    },

    _getAddressView(address) {
        if (!address || !address.id) {
            return {};
        }

        return Ext.create({
            xtype: 'addressView',
        }).setStore(Ext.create('FieldServices.store.Address').setData([address]));
    },

    _getContactToAddress(contactToAddress) {
        if (!contactToAddress || !contactToAddress.id) {
            return {};
        }
        return Ext.create({
            xtype: 'contactToAddressView',
        }).setStore(Ext.create('FieldServices.store.ContactToAddress').setData([contactToAddress]));
    },

    _isEmpty(data) {
        if (Array.isArray(data)) {
            return data.length === 0;
        }
        return !data;
    },

    _getCustomerTitle() {
        const customer = this.getCustomerData();
        return `${customer.company} - id: ${customer.id} <small>(You can double-click the row to modify the data.)</small>`;
    },

    @memoizeRunningPromise
    _getSourceDataFromServer() {
        return FieldServices.app.callAPI({
            method: 'GET',
            url: `api/admindata/customer/getContactInfo/${this.getCompanyId()}`,
            params: {
                customerId: this.getCustomerId(),
            },
        });
    },
});

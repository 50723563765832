const _ = require('lodash');

Ext.define('FieldServices.view.logoCollection.LogoCollectionController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.logoCollection.main',
    config: {
        control: {
            '#actionBtn': {
                tap: 'onClickActionBtn',
            },
            '#clearBtn': {
                tap: 'onClickClearBtn',
            },
            '#deleteBtn': {
                tap: 'onClickDeleteBtn',
            },
            '#uploadButton': {
                tap: 'onClickUploadButton',
            },
        },
    },

    /**
     * This function maps the countryId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */
    countryRenderer(value) {
        return (_.get(Ext.getStore('countries').getById(value), 'data.name')) || '';
    },

    /**
     * This function maps the languageId to a meaningful name to show in prompts grid
     *
     * @param value
     * @returns {*}
     */

    logoCollectionCategoryRenderer(value) {
        return (value && Ext.getStore('logoCollectionCategory').getById(value).data.name);
    },
    /**
     * Handles the click of our multi-function button which serves as a update/save button, based on the selection state of the grid.
     * If we have row selected in prompts grid, then this button will save the record(if any changes made into record).
     * If we don't have row selected, this button creates a new record in prompts db and reload the prompts grid's store.
     * TODO This method should NOT reload the store, instead use the newly inserted record id. To achieve this, we have to modify both nodeserver, to give back the new record's id
     * and
     *
     * @param {Ext.button.Button} btn
     */
    async onClickActionBtn(btn) {
        const logoCollectionStore = this.getView().down('#logoCollectionGrid').getStore();
        const currentItem = this.getView().getViewModel().get('currentLogoCollection');
        const isUpdateMode = this.getView().getViewModel().get('updateMode');

        if (!currentItem?.data?.country_id) { // new upload or if we miss it
            currentItem.country_id = this.getView().down('#countryId').getSelection().id;
        }
        if (!currentItem?.data?.category) { // new upload or if we miss it
            currentItem.category = this.getView().down('#categoryId').getSelection().id;
        }
        if (!isUpdateMode) {
            if (currentItem?.CDNUrl) {
                logoCollectionStore.insert(logoCollectionStore.data.items.length, currentItem);
            }
            logoCollectionStore.sync({
                callback: async () => {
                    await this.syncComplete(true);
                },
            });
        } else {
            const apiUrl = `api/admindata/global/logoCollection/${currentItem.data.id}`;
            await FieldServices.app.callAPIEndpointRequest('PUT', apiUrl, currentItem.data);
            await this.syncComplete();
        }
    },

    onClickUploadButton() {
        const self = this;

        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');

        const uploadForm = new Ext.form.FormPanel({
            fileUpload: true,
            width: 400,
            height: 200,
            style: 'margin: 5px',
            enctype: 'multipart/form-data',
            method: 'POST',
            url: `${require('config.js').SERVER_URL}api/admindata/fileupload/${adminToken}`,
            items: [
                {
                    xtype: 'panel',
                    itemId: 'logoPreview',
                    bind: {
                        html: '{previewSrc}',
                    },
                },
                {
                    xtype: 'filebutton',
                    width: 230,
                    name: 'uploadFile',
                    docked: 'bottom',
                    itemId: 'uploadFile',
                    fieldLabel: 'Select a file',
                    reference: 'uploadFile',
                    publishes: ['value'],
                    accept: 'image/*', // This restricts to all image types
                },

            ],
        });

        const uploadWindow = new Ext.Window(
            {
                title: 'Import image file',
                constrain: true,
                width: '45%',
                height: 350,
                plain: true,
                modal: true,
                items: [uploadForm],
                buttons: [
                    {
                        text: 'Submit',
                        itemId: 'submitButton',
                        bind: {
                            disabled: '{selectedFiles.length < 1}',
                        },
                        handler() {
                            if (uploadForm.isValid()) { // make sure the form contains valid data before submitting
                                uploadWindow.setMasked({
                                    xtype: 'loadmask',
                                });
                                const formData = new FormData();
                                formData.append('uploadFile', this.lookupViewModel().get('selectedFiles')[0]);
                                FieldServices.app.callAPI({
                                    method: 'POST',
                                    url: `api/admindata/fileupload/${adminToken}`,
                                    formData,
                                }).then(data => {
                                    self.getView().down('#CDNUrl').setValue(data.url);
                                    self.getView().down('#logoName').setValue(data.name.match(/^(.*?)\.\w+/)[1]);
                                    uploadWindow.setMasked(false);
                                    uploadWindow.destroy();
                                }).catch(err => {
                                    Ext.Msg.alert('Failed', err.msg);
                                });
                            } else { // display error alert if the data is invalid
                                Ext.Msg.alert('Invalid Data', 'Please correct form errors.');
                            }
                        },
                    },
                    {
                        text: 'Close',
                        handler() {
                            uploadWindow.destroy();
                        },
                    },
                ],
                viewModel: {
                    formulas: {
                        selectedFiles: {
                            bind: '{uploadFile.value}',
                            get(_) {
                                return this.getView().down('#uploadFile').getFiles();
                            },
                        },
                        previewSrc: {
                            bind: '{selectedFiles}',
                            get(files) {
                                if (files.length < 1) {
                                    return '<p>Upload a logo to see the preview</p>';
                                } return `<img src="${URL.createObjectURL(files[0])}" style="height: 200px" id="imgToUpload">`;
                            },
                        },
                    },
                },
            },
        ).show();
    },

    /**
     *
     * @param {Ext.button.Button} btn
     */
    onClickClearBtn(btn) {
        this.getView().down('#logoCollectionGrid').deselectAll();
    },
    async onClickDeleteBtn(btn) {
        const currentItem = this.getViewModel().data.currentLogoCollection.id;
        const apiUrl = `api/admindata/global/logoCollection/${currentItem}`;
        await FieldServices.app.callAPIEndpointRequest('delete', apiUrl);
        await this.syncComplete();
    },
    async syncComplete(scroll = false) {
        this.getView().down('#logoCollectionGrid').setMasked({
            xtype: 'loadmask',
        });
        await this.getView().down('#logoCollectionGrid').getStore().waitToLoad();
        this.getView().down('#logoCollectionGrid').getStore().load();
        if (scroll) {
          this.getView().down('#logoCollectionGrid').scrollToRecord(this.getView().down('#logoCollectionGrid').getStore().last());
        }

        this.getView().down('#logoCollectionGrid').setMasked(false);
    },
});

Ext.define('FieldServices.view.company.select.CompanyFormViewController', {
    extend: 'Ext.app.ViewController',

    alias: 'controller.findCompanyFormCtrl',
    xtype: 'findCompanyFormCtrl',

    onSetCompanySubmit() {
        console.log('onSetUserList widget', arguments);

        const formValues = this.getView().getValues();
        let isEmptyForm = true;

        const filters = [];

        for (const key in formValues) {
            if (formValues[key]) {
                isEmptyForm = false;

                const isId = key === 'id';
                const value = isId ? formValues[key] : `%${formValues[key]}%`;
                const operator = isId ? '=' : 'like';
                filters.push({
                    property: key,
                    value,
                    operator,
                });
            }
        }

        if (isEmptyForm) {
            Ext.Msg.alert('alert', 'The company form is  empty.');
            return;
        }

        const storeName = 'Company';

        Ext.getStore(storeName).clearFilter();
        Ext.getStore(storeName).filter(filters);
    },

});

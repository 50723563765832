Ext.define('FieldServices.view.company.settings.CompanySettingsViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.companySettingsController',
    xtype: 'companySettingsController',

    mixins: ['FieldServices.mixins.subPage'],
    control: {
        trialExpirationDateField: true,
        concurrentLoginLimitModeField: true,
        concurrentLoginLimitValue: true,
        limitCertificatesToggleField: true,
        mainSettingsContainer: true,
    },

    featureRecord: null,
    companyId: null,

    init() {
        this.callParent();
        Socket.on('forceLogoutOneUserDone', this._forceLogoutOneUserDone.bind(this));
        Socket.on('forceLogoutAllUserDone', this._forceLogoutAllUserDone.bind(this));
        this.loadPermissions().catch(handleClientError);
    },

    async loadPermissions() {
        const canChangeWhiteLabel = await AuthManager.userHasRole(CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_ANY_WHITELABEL);
        const isTopLevelAdmin = await AuthManager.userHasRole(CONSTANTS.ROLES.PERMISSIONS.CAN_ACCESS_SYSTEM_PAGE);
        this.getViewModel().setData({ canChangeWhiteLabel, isTopLevelAdmin });
    },

    onPageShow() {
        this.featureRecord = this.getViewModel().get('companyFeatureRecord');
        this.loadCompanyCertLimited();
    },

    loadCompanyCertLimited() {
        if (this.getViewModel().get('selectedCompanyData').id) {
            this.sendBlockedUsersTableRequest({
                method: 'GET',
                action: 'getItem',
            }).then(result => {
                this.getViewModel().setData({
                    newCertificatesBlockedCurrentValue: result,
                    newCertificatesBlockedValue: result,
                });
            }).catch(handleClientError);
        }
    },

    async sendBlockedUsersTableRequest(options) {
        const { method, data, action } = options;
        const apiUrl = `api/admindata/limitCompany/${this.getViewModel().get('selectedCompanyData').id}/${action}`;
        return FieldServices.app.callAPIEndpointRequest(method, apiUrl, data);
    },

    openRoleSettingsModal() {
        const popup = References.getProfileRolePopup();
        popup.show();
    },

    forceLogoutAllUsers() {
        Utilities.forceLogoutAllUser(this.getViewModel().get('selectedCompanyData').id);
    },

    _forceLogoutAllUserDone(responseData) {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');

        if (responseData.adminCompanyId && responseData.adminCompanyId === adminUserData.companyId) {
            Ext.toast({
                message: 'Force logout is done',
                timeout: 3000,
            });
        }
    },

    forceLogoutOneUser(companyId, userId) {
        Utilities.forceLogoutOneUser(companyId, userId);
    },

    _forceLogoutOneUserDone(responseData) {
        const adminUserData = Ext.util.LocalStorage.get('auth').getObject('userData');

        if (responseData.adminCompanyId && responseData.adminCompanyId === adminUserData.companyId) {
            Ext.toast({
                message: 'Force logout is done',
                timeout: 3000,
            });
        }
    },

    async saveChangedSettings() {
        const viewModel = this.getViewModel();
        const recordsToCheck = ['company', 'companyFeatureRecord', 'maxEmailLimitPerMonthSettingRecord', 'renewableFormSettingRecord', 'xeroIntegrationRecord'];
        recordsToCheck.forEach(recordName => {
            const record = viewModel.get(recordName);
            if (record.dirty || record.phantom) {
                viewModel.setData({ syncingStoreCount: viewModel.get('syncingStoreCount') + 1 });
                record.store.sync({
                    success: () => {
                        viewModel.setData({ syncingStoreCount: viewModel.get('syncingStoreCount') - 1 });
                        record.store.load();
                    },
                });
            }
        });
        if (viewModel.get('newCertificatesDirty')) {
            viewModel.set('newCertificatesBlockedValue', viewModel.get('newCertificatesBlockedCurrentValue'));
            await this.sendBlockedUsersTableRequest({
                method: 'PUT',
                action: 'updateItem',
            });
        }
    },
});
